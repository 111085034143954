<script setup lang="ts">
import { defineProps } from 'vue';
const props = defineProps({ text: String });
</script>

<template>
	<div class="flex flex-col items-center justify-center">
		<patternlib-spinner
			duration="1000"
			progress="25"
			spin="true"
			type="indeterminate">
		</patternlib-spinner>
		<p>{{ props.text }}</p>
	</div>
</template>
