import { i18n } from 'src/plugins/i18n';
import { defineComponent } from 'vue';
import { uuid } from 'vue-uuid';

// eslint-disable-next-line
let interval = null as any;

export default defineComponent({
	name: 'Bn010View',
	data() {
		return {
			backendApiUrl: '',
			buttonEnabled: true,
			accessTokenExpireInSeconds: 0,
		};
	},
	async beforeMount() {
		this.backendApiUrl = `${process.env.VUE_APP_BACKEND_BASE_URL}/appanda/api/v2/bn/upload`;
	},
	mounted() {
		document
			.querySelector('patternlib-dropzone')
			?.addEventListener('lhError', this.handlePatternlibError);
		document
			.querySelector('patternlib-dropzone')
			?.addEventListener('lhSuccess', this.handlePatternlibSuccess);

		//	interval = window.setInterval(this.setButtonEnabled, 1000);
	},
	unmounted() {
		window.clearInterval(interval);
	},
	methods: {
		// eslint-disable-next-line
		handlePatternlibError(e: any) {},
		// eslint-disable-next-line
		handlePatternlibSuccess(e: any) {
			this.showSuccessModal(i18n.global.t('upload.success'));
		},
		showSuccessModal(successMsg: string) {
			const app_div = document.getElementById('app');
			const patternlib_toast = document.createElement('patternlib-toast');
			patternlib_toast.setAttribute('position', 'bottom-left');
			patternlib_toast.addEventListener('lhClose', () => {
				app_div?.removeChild(patternlib_toast);
			});

			app_div?.prepend(patternlib_toast);

			if (patternlib_toast != null) {
				patternlib_toast.createToastItem({
					itemId: uuid.v4(),
					message: successMsg,
					showIcon: false,
					type: 'success',
					showUndo: false,
					autoHide: true,
					delay: 6000,
					subline: '',
				});
			}
		},
	},
});
