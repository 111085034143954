<script setup lang="ts">
import { TimeZones, TimeZonesApiFactory } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import { ToastType } from '@/store/GlobalUIStore';
import { useLoginStore } from '@/store/LoginStore';
import { VueHttpRequestStateT } from '@/types';
import { PatternLibGenericEvent } from '@/types/patternlib';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed, Ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import NotificationFlyout from './NotificationFlyout.vue';
import HttpHelper from '@/helper/HttpResponseHelper';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';

const loginStore = useLoginStore();
const { language, isAuthenticated } = storeToRefs(useLoginStore());
const router = useRouter();
const langPickerRef = ref();
const avatarRef = ref();
const timezoneRef = ref();
const { getConfig } = useHttpConfiguration();
const selectedTimeZone = ref();
const displayToast = useToast();
const timezones: Ref<TimeZones[] | undefined> = ref([]);
const httpReq = reactive<VueHttpRequestStateT>({ isLoading: false });
const dateTime: Ref<string> = ref('');

const userInitials = computed(() => {
  return loginStore.userInitials;
});

const fetchTimeZones = async () => {
  try {
    httpReq.isLoading = true;
    const response = await TimeZonesApiFactory(getConfig()).getTimeZones(language.value.split('-')[0]);
    timezones.value = HttpHelper.getRequestData<TimeZones[]>(response, 200);

    selectedTimeZone.value = loginStore.timeZone;

    httpReq.error = { isError: false };
  } catch (error: any) {
    httpReq.error = {
      isError: true,
      detail: { message: error.message }
    };
    displayToast(error.message, ToastType.WARNING);
  } finally {
    httpReq.isLoading = false;
  }
};

const options =
  '[{"label": "English", "value": "en", "detail": "(US)"}, {"label": "Deutsch", "value": "de", "detail": "(Deutschland)"}]';
const handleLanguageSelect = (e: PatternLibGenericEvent) => {
  loginStore.setLanguageSetting(e.detail.selected);

  fetchTimeZones();
};

const goToDashboard = () => {
  router.push('/');
};

const showProfileView = () => {
  if (isAuthenticated) router.push('/profile');
};

const handleTimeZoneSelection = (selected: any, _updated: any) => {
  selectedTimeZone.value = selected.detail.selected.value;
  loginStore.setTimezone(selectedTimeZone.value);
};

onMounted(() => {
  langPickerRef.value?.addEventListener('lhSelect', handleLanguageSelect);
  avatarRef.value.addEventListener('click', showProfileView);
  timezoneRef.value?.addEventListener('lhSelect', handleTimeZoneSelection);

  setInterval(() => {
    dateTime.value = DateTimeFormatter.getCurrentDateTime(selectedTimeZone.value);
  }, 1000);

  setTimeout(() => {
    fetchTimeZones();
  }, 3000);
});
</script>

<template>
  <div class="header-container">
    <div class="first-container">
      <div class="header-item">
        <div class="logo" @click="goToDashboard">
          <img src="../../assets/logo.b91f3a9e.png" alt="Liebherr" />
          <p>Appliance Analytics Dashboard</p>
        </div>
      </div>

      <div class="header-item">
        <div class="more-container">
          <div class="section">
            <patternlib-language-picker
              ref="langPickerRef"
              :selected="language"
              :options="options"
              show="false"
              flyout-position="auto"
              width="256px"
            >
            </patternlib-language-picker>
            <NotificationFlyout />
            <patternlib-avatar ref="avatarRef" :initials="userInitials"> </patternlib-avatar>
          </div>
        </div>
      </div>
    </div>

    <div class="second-container">
      <p class="date-time">{{ dateTime }}</p>
      <patternlib-dropdown
        ref="timezoneRef"
        :value="selectedTimeZone"
        placeholder="Select Timezone"
        width="40%"
        show-remove-icon="false"
        :disabled="httpReq.isLoading"
        filterable="true"
        size="small"
      >
        <div v-for="(timezone, index) of timezones" :key="index">
          <patternlib-option :value="timezone.zoneIanaName" :label="timezone.zoneDisplayName"></patternlib-option>
        </div>
      </patternlib-dropdown>
    </div>
  </div>
</template>

<style lang="scss" src="./GlobalStyles.scss" />
