import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "search-container",
  "data-cy": "lh-searchbox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_searchbox = _resolveComponent("patternlib-searchbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_patternlib_searchbox, {
      controlled: "",
      ref: "searchBox",
      label: _ctx.t('search.ask_for_device_serial_id'),
      type: "default",
      placeholder: "12.345.678.9",
      readonly: "false",
      width: "100%",
      value: _ctx.serialId,
      size: "big",
      "show-remove-icon": "true"
    }, null, 8, ["label", "value"])
  ]))
}