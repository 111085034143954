/* tslint:disable */
/* eslint-disable */
/**
 * AppAnDa backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeviceFile } from '../model';
// @ts-ignore
import { Deviceplate } from '../model';
// @ts-ignore
import { HouseholdDto } from '../model';
// @ts-ignore
import { NetStateDto } from '../model';
// @ts-ignore
import { ProblemDetails } from '../model';
// @ts-ignore
import { UpnDto } from '../model';
/**
 * SmartDeviceApi - axios parameter creator
 * @export
 */
export const SmartDeviceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedState: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getConnectedState', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/SmartDevice/{deviceSerialId}/netstate`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceFiles: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getDeviceFiles', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/SmartDevice/{deviceSerialId}/files`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevicePlate: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getDevicePlate', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/SmartDevice/{deviceSerialId}/deviceplate`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {string} [upn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHouseholdByUpn: async (
      deviceSerialId: string,
      upn?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getHouseholdByUpn', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/SmartDevice/{deviceSerialId}/upn`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (upn !== undefined) {
        localVarQueryParameter['upn'] = upn;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpnsByDeviceSerialId: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getUpnsByDeviceSerialId', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/SmartDevice/{deviceSerialId}/upns`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SmartDeviceApi - functional programming interface
 * @export
 */
export const SmartDeviceApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SmartDeviceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnectedState(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetStateDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectedState(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDeviceFiles(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceFile>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceFiles(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDevicePlate(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deviceplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicePlate(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {string} [upn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHouseholdByUpn(
      deviceSerialId: string,
      upn?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseholdDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHouseholdByUpn(deviceSerialId, upn, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUpnsByDeviceSerialId(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpnDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpnsByDeviceSerialId(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * SmartDeviceApi - factory interface
 * @export
 */
export const SmartDeviceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SmartDeviceApiFp(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedState(deviceSerialId: string, options?: any): AxiosPromise<NetStateDto> {
      return localVarFp.getConnectedState(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeviceFiles(deviceSerialId: string, options?: any): AxiosPromise<Array<DeviceFile>> {
      return localVarFp.getDeviceFiles(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDevicePlate(deviceSerialId: string, options?: any): AxiosPromise<Deviceplate> {
      return localVarFp.getDevicePlate(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {string} [upn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHouseholdByUpn(deviceSerialId: string, upn?: string, options?: any): AxiosPromise<HouseholdDto> {
      return localVarFp.getHouseholdByUpn(deviceSerialId, upn, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpnsByDeviceSerialId(deviceSerialId: string, options?: any): AxiosPromise<Array<UpnDto>> {
      return localVarFp.getUpnsByDeviceSerialId(deviceSerialId, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * SmartDeviceApi - interface
 * @export
 * @interface SmartDeviceApi
 */
export interface SmartDeviceApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApiInterface
   */
  getConnectedState(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<NetStateDto>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApiInterface
   */
  getDeviceFiles(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Array<DeviceFile>>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApiInterface
   */
  getDevicePlate(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Deviceplate>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {string} [upn]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApiInterface
   */
  getHouseholdByUpn(deviceSerialId: string, upn?: string, options?: AxiosRequestConfig): AxiosPromise<HouseholdDto>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApiInterface
   */
  getUpnsByDeviceSerialId(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Array<UpnDto>>;
}

/**
 * SmartDeviceApi - object-oriented interface
 * @export
 * @class SmartDeviceApi
 * @extends {BaseAPI}
 */
export class SmartDeviceApi extends BaseAPI implements SmartDeviceApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApi
   */
  public getConnectedState(deviceSerialId: string, options?: AxiosRequestConfig) {
    return SmartDeviceApiFp(this.configuration)
      .getConnectedState(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApi
   */
  public getDeviceFiles(deviceSerialId: string, options?: AxiosRequestConfig) {
    return SmartDeviceApiFp(this.configuration)
      .getDeviceFiles(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApi
   */
  public getDevicePlate(deviceSerialId: string, options?: AxiosRequestConfig) {
    return SmartDeviceApiFp(this.configuration)
      .getDevicePlate(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {string} [upn]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApi
   */
  public getHouseholdByUpn(deviceSerialId: string, upn?: string, options?: AxiosRequestConfig) {
    return SmartDeviceApiFp(this.configuration)
      .getHouseholdByUpn(deviceSerialId, upn, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmartDeviceApi
   */
  public getUpnsByDeviceSerialId(deviceSerialId: string, options?: AxiosRequestConfig) {
    return SmartDeviceApiFp(this.configuration)
      .getUpnsByDeviceSerialId(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }
}
