<template>
	<DataViewSkeleton
		:header="$t('privacyView.imprintHeader')"
		:isLoading="isLoading"
		:error="{ isError: error.isError }">
		<template #content>
			<div v-html="data" class="mt-5 text-[18px] leading-10"></div>
		</template>
	</DataViewSkeleton>
</template>

<script lang="ts" setup>
import { LegalTextApiFactory } from '@/api';
import { useFetch } from '@/composables/common/useFetch';
import { useLoginStore } from '@/store/LoginStore';
import { storeToRefs } from 'pinia';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';

const { getConfig } = useHttpConfiguration();
const { language } = storeToRefs(useLoginStore());

const getImprint = async () => {
	const langDetails = languageMapping[language.value];
	if (!langDetails) throw new Error('Language not valid');
	return await LegalTextApiFactory(getConfig()).getImprintFromLegalTextApi(
		langDetails.lng,
		langDetails.country
	);
};

const { data, isLoading, error } = useFetch(getImprint);

const languageMapping: Record<string, { lng: string; country: string }> = {
	en: { lng: 'en', country: 'us' },
	de: { lng: 'de', country: 'de' },
};
</script>
