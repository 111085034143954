import {
  ApplianceHistoryApiFactory,
  ApplianceLocationData,
  ApplianceStats,
  ConnectedAppliancesByTypeDto,
  ConnectedAppliancesByTypeModelDto,
  ConnectedAppliancesDto
} from '@/api';
import { StatisticsApiFactory } from '@/api/backend/api/statistics-api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import HttpHelper from '@/helper/HttpResponseHelper';
import ApplianceSelection from '@/views/index/statistics/ApplianceSelection.vue';
import AvailableModes from '@/views/index/statistics/AvailableModes.vue';
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import StatisticsMapView from './StatisticsMapView.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';

export default defineComponent({
  components: { AvailableModes, ApplianceSelection, StatisticsMapView, LoadingSpinner, DataViewSkeleton },
  setup(props) {
    const applianceType = ref(-1);
    const applianceModel = ref();
    const applianceModels = ref();
    const { t } = useI18n();
    const { getConfig } = useHttpConfiguration();
    const connectedAppliancesByType: Ref<ConnectedAppliancesByTypeDto[] | undefined> = ref();
    const connectedAppliancesStates: Ref<ConnectedAppliancesByTypeModelDto[] | undefined> = ref();
    const coolerZoneStates: Ref<ConnectedAppliancesByTypeModelDto[] | undefined> = ref();
    const freezerZoneStates: Ref<ConnectedAppliancesByTypeModelDto[] | undefined> = ref();
    const wineZoneStates: Ref<ConnectedAppliancesByTypeModelDto[] | undefined> = ref();
    const bioFreshZoneStates: Ref<ConnectedAppliancesByTypeModelDto[] | undefined> = ref();
    const appliancesActivatedModes = ref();
    const showStateCharts = ref(false);
    const showMore = ref(false);
    const noOfZones = ref(0);
    const selectedDomain = ref('');
    const appliancesPerCountry: Ref<ApplianceStats[]> = ref([]);
    const appliancesWithLocation: Ref<ApplianceLocationData[]> = ref([]);

    const onApplianceTypeSelected = async (type: number, numberOfZones: number) => {
      applianceType.value = type;
      showMore.value = false;
      noOfZones.value = numberOfZones;
      await fetchApplianceModels(type, numberOfZones);
    };

    const onApplianceModelSelected = async (model: string) => {
      if (model) {
        applianceModel.value = model;
        showMore.value = false;
        showStateCharts.value = true;

        await fetchAppliancesStates(applianceType.value, noOfZones.value, applianceModel.value);
        await getConnectedAppliancesWithLocations();
      } else {
        showStateCharts.value = false;
      }
    };

    const onDomainSelected = async (domain: string) => {
      if (domain === t('general.all_domains')) {
        selectedDomain.value = '';
      } else {
        selectedDomain.value = domain;
      }
      await getConnectedAppliancesWithLocations();
    };

    const fetchApplianceModels = async (type: number, numberOfZones: number) => {
      if (type === -1) {
        // Show all connected appliances
        showStateCharts.value = false;
        await loadAllConnectedAppliances();
      } else {
        // Show connected appliances by type
        const response = await StatisticsApiFactory(getConfig()).getConnectedAppliancesByType(
          type,
          numberOfZones,
          selectedDomain.value
        );
        connectedAppliancesByType.value = HttpHelper.getRequestData<ConnectedAppliancesByTypeDto[]>(response, 200);
        applianceModels.value = connectedAppliancesByType.value.map(appliance => {
          return { label: appliance.name ? appliance.name : 'NOT DEFINED', value: appliance.name };
        });
      }
    };

    const loadAllConnectedAppliances = async () => {
      const response = await StatisticsApiFactory(getConfig()).getConnectedAppliances(selectedDomain.value);
      connectedAppliancesByType.value = HttpHelper.getRequestData<ConnectedAppliancesDto[]>(response, 200);
      connectedAppliancesByType.value = connectedAppliancesByType.value.filter(
        appliance => appliance.count && appliance.count > 10
      );
      applianceModels.value = connectedAppliancesByType.value.map(appliance => {
        return { label: appliance.name, value: appliance.name };
      });
    };

    const chartOptions = computed(() => {
      const height = 50 * (connectedAppliancesByType.value ? connectedAppliancesByType.value.length : 5);
      return {
        chart: {
          type: 'bar',
          responsive: true,
          height: showMore.value
            ? height.toString() + 'px'
            : connectedAppliancesByType.value && connectedAppliancesByType.value.length < 5
            ? '200px'
            : '500px',
          toolbar: {
            show: false
          }
        },
        zoom: {
          enabled: false
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '85%',
            dataLabels: {
              // position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          },
          categories: showMore.value
            ? applianceModels.value.map((appliance: { label: string; value: string }) => appliance.label)
            : applianceModels.value.map((appliance: { label: string; value: string }) => appliance.label).slice(0, 10)
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            rotateAlways: true,
            align: 'center',
            hideOverlappingLabels: true,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }: any) {
            return `Count: ${series[seriesIndex][dataPointIndex]}`;
          }
        }
      };
    });

    const series = computed(() => {
      return [
        {
          name: '',
          data: showMore.value
            ? connectedAppliancesByType.value?.map(appliance => appliance.count)
            : connectedAppliancesByType.value?.map(appliance => appliance.count).slice(0, 10)
        }
      ];
    });

    const fetchAppliancesStates = async (type: number, numberOfZones: number, model: string) => {
      const response = await StatisticsApiFactory(getConfig()).getConnectedAppliancesByTypeAndModel(
        type,
        numberOfZones,
        model,
        selectedDomain.value
      );
      connectedAppliancesStates.value = HttpHelper.getRequestData<ConnectedAppliancesByTypeModelDto[]>(response, 200);

      coolerZoneStates.value = connectedAppliancesStates.value.filter(appliance => appliance.type === 0);
      freezerZoneStates.value = connectedAppliancesStates.value.filter(appliance => appliance.type === 1);
      wineZoneStates.value = connectedAppliancesStates.value.filter(appliance => appliance.type === 2);
      bioFreshZoneStates.value = connectedAppliancesStates.value.filter(appliance => appliance.type === 4);
    };

    const onShowMoreClicked = () => {
      showMore.value = !showMore.value;
    };

    const getStateChartOptions = (states: ConnectedAppliancesByTypeModelDto[] | undefined) => {
      return {
        chart: {
          type: 'bar',
          responsive: true,
          height: states && states.length < 5 ? '200px' : '500px',
          toolbar: {
            show: false
          }
        },
        zoom: {
          enabled: false
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: '85%',
            dataLabels: {
              // position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          },
          categories: states?.map(state => state.temperatureSetpoint)
        },
        yaxis: {
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            },
            formatter: (value: any, _index: number) => {
              return `${value} °C`;
            }
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }: any) {
            return `Count: ${series[seriesIndex][dataPointIndex]}`;
          }
        }
      };
    };

    const getStateSeries = (states: ConnectedAppliancesByTypeModelDto[] | undefined) => {
      return [
        {
          name: '',
          data: states?.map(state => state.count)
        }
      ];
    };

    const filterAndAdjustAppliancesLocationData = () => {
      appliancesWithLocation.value = appliancesWithLocation.value.filter(c => c.lat !== null && c.lon !== null);

      let appliancesWithSameLocation: ApplianceLocationData[] = [];
      let appliancesWithDiffLocation: ApplianceLocationData[] = [];

      const locationMap = new Map();

      appliancesWithLocation.value.forEach(appliance => {
        const key = `${appliance.city}-${appliance.lat}-${appliance.lon}`;

        if (locationMap.has(key)) {
          locationMap.get(key).push(appliance);
        } else {
          locationMap.set(key, [appliance]);
        }
      });

      locationMap.forEach(appliances => {
        if (appliances.length > 1) {
          appliancesWithSameLocation = appliancesWithSameLocation.concat(appliances);
        } else {
          appliancesWithDiffLocation = appliancesWithDiffLocation.concat(appliances);
        }
      });

      // For appliances at same location (same longitude and latitude)
      // Just add small degrees of longitude and latitude
      // to show all appliances (even at same location)
      const increment = 0.0001;

      appliancesWithSameLocation = appliancesWithSameLocation.map((appliance, index) => {
        appliance.lon = (appliance.lon as number) + Math.floor(Math.random() * 10) * increment;
        appliance.lat = (appliance.lat as number) + Math.floor(Math.random() * 10) * increment;
        return appliance;
      });

      appliancesWithLocation.value = [];
      appliancesWithLocation.value = [...appliancesWithDiffLocation, ...appliancesWithSameLocation];
    };

    const getConnectedAppliancesPerCountry = async () => {
      const response = await ApplianceHistoryApiFactory(getConfig()).getConnectedAppliancesPerCountry();
      appliancesPerCountry.value = HttpHelper.getRequestData<ApplianceStats[]>(response, 200);
    };

    const getConnectedAppliancesWithLocations = async () => {
      appliancesWithLocation.value = [];
      const response = await ApplianceHistoryApiFactory(getConfig()).getConnectedAppliancesWithGeoLocations();
      appliancesWithLocation.value = HttpHelper.getRequestData<ApplianceLocationData[]>(response, 200);

      if (selectedDomain.value === t('general.consumer') || selectedDomain.value === t('general.business')) {
        appliancesWithLocation.value = appliancesWithLocation.value.filter(a => a.domain === selectedDomain.value);
      }

      if (applianceModel.value) {
        appliancesWithLocation.value = appliancesWithLocation.value.filter(a => a.name === applianceModel.value);
      }

      filterAndAdjustAppliancesLocationData();
    };

    const fetchAllApplianceData = async () => {
      if (applianceType.value === -1) {
        await loadAllConnectedAppliances();
        await getConnectedAppliancesPerCountry();
        await getConnectedAppliancesWithLocations();
      }
    };

    const reload = () => {
      fetchAllApplianceData();
    };

    onMounted(() => {
      fetchAllApplianceData();
    });

    return {
      onApplianceTypeSelected,
      onApplianceModelSelected,
      onDomainSelected,
      applianceModels,
      chartOptions,
      series,
      onShowMoreClicked,
      showMore,
      connectedAppliancesByType,
      connectedAppliancesStates,
      coolerZoneStates,
      freezerZoneStates,
      bioFreshZoneStates,
      wineZoneStates,
      getStateChartOptions,
      getStateSeries,
      showStateCharts,
      applianceType,
      appliancesActivatedModes,
      noOfZones,
      applianceModel,
      selectedDomain,
      appliancesPerCountry,
      appliancesWithLocation,
      reload
    };
  }
});
