import { AxiosResponse } from 'axios';

export default class HttpHelper {
	static getRequestData = <T>(
		data: AxiosResponse<T>,
		successCode = 200
	): T => {
		if (data.status !== successCode) {
			throw new Error('Error');
		}

		return data.data;
	};

	static extractRequestDataWithoutError = <T>(
		data: AxiosResponse<T>,
		successCode = 200
	): T | undefined => {
		if (data.status !== successCode) {
			return undefined;
		}

		return data.data;
	};
}
