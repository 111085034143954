import { Configuration, ConfigurationParameters } from '@/api';
import { useLoginStore } from '@/store/LoginStore';

export function useHttpConfiguration() {
	const loginStore = useLoginStore();

	const getConfig = () => {
		const config: ConfigurationParameters = {
			accessToken: loginStore.accessToken,
			basePath: process.env.VUE_APP_BACKEND_BASE_URL,
		};
		return new Configuration(config);
	};

	return { getConfig };
}
