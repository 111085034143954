import { ApplianceHistoryApiFactory, FailureAlarmEntryResponse } from '@/api';
import TheButton from '@/components/basic/Button/TheButton.vue';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import { router } from '@/router';
import { ToastType } from '@/store/GlobalUIStore';
import { useLoginStore } from '@/store/LoginStore';
import { PatternLibDatePickerEvent, PatternLibEvent } from '@/types/patternlib';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { Ref, computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type DatePickerStateT = {
  start: string;
  end: string;
};

type ErrorStatsType = {
  keys: string[];
  count: number[];
};

export enum CustomRangeSelector {
  LastSixMonths = 'LastSixMonths',
  LastThreeMonths = 'LastThreeMonths',
  LastMonth = 'LastMonth',
  LastWeek = 'LastWeek',
  Today = 'Today'
}

export default defineComponent({
  components: { DataViewSkeleton, TheButton },
  setup() {
    const tableRef = ref();
    const paginationRef = ref();
    const datePickerRef = ref();
    const { timeZone, language } = storeToRefs(useLoginStore());
    const { t } = useI18n();
    const currentPage: Ref<number> = ref(1);
    const lastPage: Ref<number> = ref(1);
    const customSelectorRef = ref();

    const paginationDropdownRef = ref();
    const failures: Ref<FailureAlarmEntryResponse[] | undefined> = ref([]);
    const selectedTimeRange: Ref<DatePickerStateT | undefined> = ref();
    const maxDate = DateTime.now().toISO();
    const minDate = DateTime.now().minus({ months: 6 }).toISO();

    const isLoading = ref(true);
    const shortTextErrorStats: Ref<ErrorStatsType | undefined> = ref();
    const applianceErrorStats: Ref<ErrorStatsType | undefined> = ref();
    const displayToast = useToast();

    const showStatistics: Ref<boolean> = ref(false);

    const customRangeSelector = ref(CustomRangeSelector.LastSixMonths);

    const reload = () => {
      fetchFailureAlarms();
    };

    const headers = computed(() => {
      return [
        {
          label: t('failures.group'),
          align: 'left',
          sorting: 'false',
          filterType: 'singleselect',
          options: [
            {
              label: 'Business',
              value: 'Business'
            },
            {
              label: 'Consumer',
              value: 'Consumer'
            }
          ]
        },
        {
          label: t('failures.serialno'),
          align: 'left',
          sorting: 'false',
          filterType: 'textinput',
          placeholder: 'Search'
        },
        {
          label: 'Zone Index',
          align: 'left',
          sorting: 'false',
          placeholder: 'Search'
        },
        {
          label: t('failures.model'),
          align: 'left',
          sorting: 'false',
          filterType: 'textinput',
          placeholder: 'Search',
          width: '10rem'
        },
        {
          label: t('failures.time_of_error'),
          align: 'left',
          sorting: 'true'
        },
        {
          label: t('failures.type'),
          align: 'left',
          sorting: 'false',
          filterType: 'textinput',
          placeholder: 'Search '
        },
        {
          label: t('failures.short_text'),
          align: 'left',
          sorting: 'false',
          filterType: 'textinput',
          placeholder: 'Search'
        },
        {
          label: t('failures.country'),
          align: 'left',
          sorting: 'false',
          filterType: 'textinput',
          placeholder: 'Search'
        }
      ];
    });

    selectedTimeRange.value = { start: minDate, end: maxDate };

    const datePickerLocale = computed(() => {
      return language.value;
    });

    const hasStatistics = computed(() => {
      return (
        (shortTextErrorStats.value && shortTextErrorStats.value.keys.length > 0) ||
        (applianceErrorStats.value && applianceErrorStats.value.keys.length > 0)
      );
    });

    const shortTextChartOptions = computed(() => {
      return {
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          categories: shortTextErrorStats.value?.keys,
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const startDate = DateTimeFormatter.getFormattedDateTimeShortFormat(
              new Date(selectedTimeRange.value?.start as string).toISOString(),
              timeZone.value
            );

            const endDate = DateTimeFormatter.getFormattedDateTimeShortFormat(
              new Date(selectedTimeRange.value?.end as string).toISOString(),
              timeZone.value
            );

            return (
              '<div class="custom-tooltip">' +
              '<span>' +
              `${w.config.xaxis.categories[dataPointIndex]}` +
              '</span>' +
              `<span>Count : ${series[seriesIndex][dataPointIndex]}</span>` +
              `<span>${startDate} - ${endDate}</span>` +
              '</div>'
            );
          }
        }
      };
    });

    const applianceErrorChartOptions = computed(() => {
      return {
        chart: {
          height: 500,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          categories: applianceErrorStats.value?.keys,
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const startDate = DateTimeFormatter.getFormattedDateTimeShortFormat(
              new Date(selectedTimeRange.value?.start as string).toISOString(),
              timeZone.value
            );

            const endDate = DateTimeFormatter.getFormattedDateTimeShortFormat(
              new Date(selectedTimeRange.value?.end as string).toISOString(),
              timeZone.value
            );

            return (
              '<div class="custom-tooltip">' +
              '<span>' +
              `${w.config.xaxis.categories[dataPointIndex]}` +
              '</span>' +
              `<span>Count : ${series[seriesIndex][dataPointIndex]}</span>` +
              `<span>${startDate} - ${endDate}</span>` +
              '</div>'
            );
          }
        }
      };
    });

    const displayDeviceDetails = (serialNo: string, targetView: string) => {
      router.push({
        name: 'DefaultDeviceView',
        params: { serialNumber: serialNo, deviceType: targetView?.toLowerCase() }
      });
    };

    const formatDateTime = (dateTimeISO: string | undefined) => {
      return DateTimeFormatter.getFormattedDateTimeShortFormat(dateTimeISO, timeZone.value);
    };

    const fetchFailureAlarms = () => {
      const { getConfig } = useHttpConfiguration();
      const config = getConfig();

      isLoading.value = true;

      tableRef.value.startLoading();

      ApplianceHistoryApiFactory(config)
        .getAllAlarmFailures(
          new Date(selectedTimeRange.value?.start as string).toISOString(),
          new Date(selectedTimeRange.value?.end as string).toISOString(),
          language.value
        )
        .then(response => {
          failures.value = response.data;
          lastPage.value = Math.ceil(failures.value.length / 10);

          shortTextErrorStats.value = getShortTextErrorStats(failures.value);
          applianceErrorStats.value = getApplianceErrorStats(failures.value);
        })
        .catch(error => {
          displayToast('Unable to fetch failure alarms', ToastType.ALERT);
          throw new Error(`Unable to fetch failure alarms due to ${error}`);
        })
        .finally(() => {
          isLoading.value = false;
          tableRef.value?.updateTable();
        });
    };

    const getShortTextErrorStats = (failures: FailureAlarmEntryResponse[]) => {
      const errorStatistics: { [key: string]: number } = {};

      failures.forEach(item => {
        if (item.shortText && item.shortText !== '-') {
          if (errorStatistics[item.shortText]) {
            errorStatistics[item.shortText]++;
          } else {
            errorStatistics[item.shortText] = 1;
          }
        }
      });

      // Sorting the errorStatistics object by count
      const sortedErrorStatisticsArray = Object.entries(errorStatistics).sort((a, b) => b[1] - a[1]);

      // Reconstructing the object from the filtered array
      const filteredErrorStatistics: { [key: string]: number } = Object.fromEntries(sortedErrorStatisticsArray);

      return {
        keys: Object.keys(filteredErrorStatistics).slice(0, 10),
        count: Object.values(filteredErrorStatistics).slice(0, 10)
      };
    };

    const getApplianceErrorStats = (failures: FailureAlarmEntryResponse[]) => {
      const errorStatistics: { [key: string]: number } = {};

      failures.forEach(failure => {
        if (failure.model) {
          if (errorStatistics[failure.model]) {
            errorStatistics[failure.model]++;
          } else {
            errorStatistics[failure.model] = 1;
          }
        }
      });

      // Sorting the errorStatistics object by count
      const sortedErrorStatisticsArray = Object.entries(errorStatistics).sort((a, b) => b[1] - a[1]);

      // Reconstructing the object from the filtered array
      const filteredErrorStatistics: { [key: string]: number } = Object.fromEntries(sortedErrorStatisticsArray);

      return {
        keys: Object.keys(filteredErrorStatistics).slice(0, 20),
        count: Object.values(filteredErrorStatistics).slice(0, 20)
      };
    };

    const shortTextSeries = computed(() => {
      return [{ data: shortTextErrorStats.value?.count }];
    });

    const applianceErrorSeries = computed(() => {
      return [{ data: applianceErrorStats.value?.count }];
    });

    const handleDatePickerSubmit = (event: PatternLibDatePickerEvent) => {
      const updatedRange = event.detail.value;
      if (!selectedTimeRange.value) {
        return;
      }

      const startDate = updatedRange.start.toISOString();
      const endDate = updatedRange.end.toISOString();

      selectedTimeRange.value = { start: startDate, end: endDate };

      fetchFailureAlarms();
    };

    const showErrorStatistics = () => {
      showStatistics.value = !showStatistics.value;
    };

    const downloadFailureAlarms = () => {
      let csvHeader = '';

      headers.value.forEach(header => {
        csvHeader = csvHeader + header.label + ';';
      });

      csvHeader = csvHeader + '\n';

      const csvRows = failures.value?.map(failure => {
        return `${failure.domain};${failure.serialNo};${failure.zoneIndex};${failure.model};${formatDateTime(
          failure.changedAtUtc
        )};${failure.error};${failure.shortText} \n`;
      });

      const csvContent = csvHeader + csvRows?.join('');

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${selectedTimeRange.value?.start}-${selectedTimeRange.value?.end}_failure_alarms.csv`
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };

    const handleRangeSelection = (e: CustomEvent) => {
      const selectedRange = e.detail.value;

      switch (selectedRange) {
        case CustomRangeSelector.LastSixMonths:
          selectedTimeRange.value = { start: DateTime.now().minus({ months: 6 }).toISO(), end: DateTime.now().toISO() };
          customRangeSelector.value = CustomRangeSelector.LastSixMonths;
          return;

        case CustomRangeSelector.LastThreeMonths:
          selectedTimeRange.value = { start: DateTime.now().minus({ months: 3 }).toISO(), end: DateTime.now().toISO() };
          customRangeSelector.value = CustomRangeSelector.LastThreeMonths;
          return;
        case CustomRangeSelector.LastMonth:
          selectedTimeRange.value = { start: DateTime.now().minus({ months: 1 }).toISO(), end: DateTime.now().toISO() };
          customRangeSelector.value = CustomRangeSelector.LastMonth;
          return;
        case CustomRangeSelector.LastWeek:
          selectedTimeRange.value = { start: DateTime.now().minus({ days: 7 }).toISO(), end: DateTime.now().toISO() };
          customRangeSelector.value = CustomRangeSelector.LastWeek;
          return;
        case CustomRangeSelector.Today:
          selectedTimeRange.value = {
            start: DateTime.now().startOf('day').toISO(),
            end: DateTime.now().endOf('day').toISO()
          };
          customRangeSelector.value = CustomRangeSelector.Today;
          return;
      }
    };

    onMounted(() => {
      fetchFailureAlarms();
    });

    watch(
      () => datePickerRef.value,
      () => {
        datePickerRef.value?.addEventListener('lhChange', handleDatePickerSubmit);
        customSelectorRef.value?.addEventListener('lhChange', handleRangeSelection);
      }
    );

    watch(
      () => tableRef.value,
      () => {
        tableRef.value?.addEventListener('lhActionClick', (e: PatternLibEvent) => {
          const targetView = e.detail?.clickedRow?.items[0].value;
          const serialNo = e.detail?.clickedRow?.items[1].value;
          displayDeviceDetails(serialNo, targetView);
        });
      }
    );

    watch(
      () => timeZone.value,
      () => {
        // It's better to re-render history charts after changing timezone
        fetchFailureAlarms();
      }
    );

    watch(
      () => language.value,
      () => {
        // It's better to re-render history charts after changing language
        fetchFailureAlarms();
      }
    );

    onUnmounted(() => {
      datePickerRef.value?.removeEventListener('lhChange', handleDatePickerSubmit);
      tableRef.value?.removeEventListener('lhActionClick');
      customSelectorRef.value?.removeEventListener('lhChange', handleRangeSelection);
    });

    return {
      datePickerRef,
      t,
      CustomRangeSelector,
      customSelectorRef,
      customRangeSelector,
      isLoading,
      reload,
      failures,
      headers,
      tableRef,
      paginationRef,
      formatDateTime,
      paginationDropdownRef,
      currentPage,
      selectedTimeRange,
      datePickerLocale,
      maxDate,
      minDate,
      shortTextChartOptions,
      applianceErrorChartOptions,
      shortTextSeries,
      applianceErrorSeries,
      showErrorStatistics,
      showStatistics,
      shortTextErrorStats,
      applianceErrorStats,
      hasStatistics,
      downloadFailureAlarms
    };
  }
});
