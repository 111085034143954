import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useMaintenance } from '@/composables/common/useMaintenance';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';

export default defineComponent({
  components: { DataViewSkeleton },

  setup() {
    const { getMaintenanceModeInfo, setMaintenanceMode } = useMaintenance();
    const toggleMaintenanceModeRef = ref();

    const isMaintenanceActivated: Ref<boolean | undefined> = ref(false);

    const fetchMaintenanceInfo = async () => {
      const maintenanceInfo = await getMaintenanceModeInfo();
      isMaintenanceActivated.value = maintenanceInfo.maintenance;
    };

    const toggleMaintenanceMode = () => {
      isMaintenanceActivated.value = !isMaintenanceActivated.value;
      setMaintenanceMode(isMaintenanceActivated.value);
    };

    onMounted(() => {
      fetchMaintenanceInfo();
      toggleMaintenanceModeRef.value?.addEventListener('lhChange', toggleMaintenanceMode);
    });

    return {
      toggleMaintenanceModeRef,
      isMaintenanceActivated
    };
  }
});
