import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pt-4" }
const _hoisted_2 = {
  slot: "card-content",
  class: "card--container flex h-full flex-col",
  style: {"padding":"24px"}
}
const _hoisted_3 = { class: "font-head text-h3" }
const _hoisted_4 = {
  key: 0,
  id: "chart",
  class: "pt-4"
}
const _hoisted_5 = {
  key: 1,
  class: "state-container pt-4"
}
const _hoisted_6 = { class: "ml-4 font-head text-h4" }
const _hoisted_7 = { class: "ml-4 font-head text-h4" }
const _hoisted_8 = { class: "ml-4 font-head text-h4" }
const _hoisted_9 = { class: "ml-4 font-head text-h4" }
const _hoisted_10 = {
  key: 0,
  class: "pt-4"
}
const _hoisted_11 = {
  key: 1,
  class: "pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplianceSelection = _resolveComponent("ApplianceSelection")!
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_patternlib_textlink = _resolveComponent("patternlib-textlink")!
  const _component_AvailableModes = _resolveComponent("AvailableModes")!
  const _component_patternlib_card = _resolveComponent("patternlib-card")!
  const _component_StatisticsMapView = _resolveComponent("StatisticsMapView")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_DataViewSkeleton = _resolveComponent("DataViewSkeleton")!

  return (_openBlock(), _createBlock(_component_DataViewSkeleton, {
    header: _ctx.$t('statistics.title'),
    onOnErrorActionClick: _ctx.reload
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ApplianceSelection, {
        onApplianceTypeSelected: _ctx.onApplianceTypeSelected,
        onApplianceModelSelected: _ctx.onApplianceModelSelected,
        onDomainSelected: _ctx.onDomainSelected,
        applianceModels: _ctx.applianceModels
      }, null, 8, ["onApplianceTypeSelected", "onApplianceModelSelected", "onDomainSelected", "applianceModels"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_patternlib_card, { noStates: "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(!_ctx.showStateCharts ? _ctx.$t('statistics.connectedappliancesbymodel') : _ctx.$t('statistics.settemperature')), 1),
              (!_ctx.showStateCharts && _ctx.connectedAppliancesByType && _ctx.connectedAppliancesByType.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_apexchart, {
                      type: "bar",
                      options: _ctx.chartOptions,
                      series: _ctx.series,
                      height: "500px"
                    }, null, 8, ["options", "series"]),
                    (_ctx.applianceModels.length > 5)
                      ? (_openBlock(), _createBlock(_component_patternlib_textlink, {
                          key: 0,
                          style: { textAlign: 'end', paddingBottom: 1.5 + 'rem' },
                          label: _ctx.showMore ? _ctx.$t('general.showless') : _ctx.$t('general.showmore'),
                          onClick: _ctx.onShowMoreClicked
                        }, null, 8, ["label", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_AvailableModes, {
                      applianceType: _ctx.applianceType,
                      numberOfZones: _ctx.noOfZones,
                      domain: _ctx.selectedDomain
                    }, null, 8, ["applianceType", "numberOfZones", "domain"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.coolerZoneStates && _ctx.coolerZoneStates.length > 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          id: "coolerChart",
                          class: _normalizeClass([_ctx.applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg'])
                        }, [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('general.coolerzone')), 1),
                          _createVNode(_component_apexchart, {
                            type: "bar",
                            options: _ctx.getStateChartOptions(_ctx.coolerZoneStates),
                            series: _ctx.getStateSeries(_ctx.coolerZoneStates)
                          }, null, 8, ["options", "series"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.freezerZoneStates && _ctx.freezerZoneStates.length > 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          id: "freezerChart",
                          class: _normalizeClass([_ctx.applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg'])
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('general.freezerzone')), 1),
                          _createVNode(_component_apexchart, {
                            type: "bar",
                            options: _ctx.getStateChartOptions(_ctx.freezerZoneStates),
                            series: _ctx.getStateSeries(_ctx.freezerZoneStates)
                          }, null, 8, ["options", "series"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.wineZoneStates && _ctx.wineZoneStates.length > 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          id: "wineChart",
                          class: _normalizeClass([_ctx.applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg'])
                        }, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('general.winezone')), 1),
                          _createVNode(_component_apexchart, {
                            type: "bar",
                            options: _ctx.getStateChartOptions(_ctx.wineZoneStates),
                            series: _ctx.getStateSeries(_ctx.wineZoneStates)
                          }, null, 8, ["options", "series"])
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.bioFreshZoneStates && _ctx.bioFreshZoneStates.length > 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          id: "bioFreshChart",
                          class: _normalizeClass([_ctx.applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg'])
                        }, [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('general.biofreshzone')), 1),
                          _createVNode(_component_apexchart, {
                            type: "bar",
                            options: _ctx.getStateChartOptions(_ctx.bioFreshZoneStates),
                            series: _ctx.getStateSeries(_ctx.bioFreshZoneStates)
                          }, null, 8, ["options", "series"])
                        ], 2))
                      : _createCommentVNode("", true),
                    _createVNode(_component_AvailableModes, {
                      applianceType: _ctx.applianceType,
                      numberOfZones: _ctx.noOfZones,
                      model: _ctx.applianceModel,
                      domain: _ctx.selectedDomain
                    }, null, 8, ["applianceType", "numberOfZones", "model", "domain"])
                  ]))
            ])
          ]),
          _: 1
        }),
        (_ctx.appliancesPerCountry.length > 0 && _ctx.appliancesWithLocation.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_StatisticsMapView, {
                appliancesPerCountry: _ctx.appliancesPerCountry,
                appliancesWithLocation: _ctx.appliancesWithLocation
              }, null, 8, ["appliancesPerCountry", "appliancesWithLocation"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_LoadingSpinner, { text: "Loading Maps" })
            ]))
      ])
    ]),
    _: 1
  }, 8, ["header", "onOnErrorActionClick"]))
}