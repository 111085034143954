import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextPlaceholder = _resolveComponent("TextPlaceholder")!
  const _component_KeyValueView = _resolveComponent("KeyValueView")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isDataAvailable())
      ? (_openBlock(), _createBlock(_component_TextPlaceholder, {
          key: 0,
          text: _ctx.$t('device.smartDevice.errorText')
        }, null, 8, ["text"]))
      : (_openBlock(), _createBlock(_component_KeyValueView, {
          key: 1,
          data: _ctx.data.data,
          config: _ctx.data.config
        }, null, 8, ["data", "config"]))
  ]))
}