import { Base, BaseState, HistoryItem, Zone, ZoneState } from '@/api';
import {
  ChartType,
  CustomTimeSeriesDataSet,
  ITimeLineChartConfig,
  ITimelineOption,
  StackData,
  TimeLineChartData,
  TimeSeriesInterval,
  TimelineData
} from '@/types/DeviceValueMapping';
import { IOptions } from './ChartsBuilder';
import HistoryChartsConfiguration from './HistoryChartsConfiguration';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useLoginStore } from '@/store/LoginStore';
import ChartConstants from '@/constants/ChartConstants';
import { ApplianceHistoryKeyEnum } from '../ApplianceHistoryKeyEnum';
import { BaseHistoryKeyEnum } from '../BaseHistoryKeyEnum';
import { ZoneHistoryKeyEnum } from '../ZoneHistoryKeyEnum';

export default class TimelineChartHelper {
  public static generateTimelineChart(
    data: Base | Zone | undefined,
    options: IOptions,
    baseState: BaseState,
    zoneStates: ZoneState[]
  ): ITimeLineChartConfig {
    const res = TimelineChartHelper.buildTimeLine(data, options, baseState);
    const timelineDataSet = TimelineChartHelper.createTimeLineDataSet(
      res.data,
      options.accessKey ?? ApplianceHistoryKeyEnum.Base,
      baseState,
      zoneStates
    );

    return {
      data: timelineDataSet
    };
  }

  private static buildTimeLine(data: Zone | Base | undefined, options: IOptions, baseState: BaseState) {
    const result: Record<string, Array<TimeSeriesInterval>> = {};

    let minDate = 0;
    let maxDate = 0;

    const configKey =
      options.accessKey === ApplianceHistoryKeyEnum.Base ? ApplianceHistoryKeyEnum.Base : ApplianceHistoryKeyEnum.Zone;
    const userSettings = options.userSettings[configKey] as Record<string, boolean>;

    Object.keys(data ?? {}).forEach(key => {
      const config = (HistoryChartsConfiguration[configKey] as Record<string, ITimelineOption>)[key];
      const displayData = userSettings[key];

      if (key === 'softwareVersion') {
        // Need slightly different handling for software versions
        const softwareVersions = (data as Record<number, Array<HistoryItem>>)[key];

        softwareVersions.forEach(softwareVersionHistory => {
          const values = softwareVersionHistory;
          const resultKey = `softwareVersion-${values[0].subValue}`;

          const { minDate: newMinDate, maxDate: newMaxDate } = this.processTimeLineData(
            resultKey,
            values,
            config,
            options,
            displayData,
            result,
            minDate,
            maxDate,
            baseState
          );
          minDate = newMinDate;
          maxDate = newMaxDate;
        });
      } else {
        const values = (data as Record<string, Array<HistoryItem>>)[key];

        const { minDate: newMinDate, maxDate: newMaxDate } = this.processTimeLineData(
          key,
          values,
          config,
          options,
          displayData,
          result,
          minDate,
          maxDate,
          baseState
        );
        minDate = newMinDate;
        maxDate = newMaxDate;
      }
    });

    return {
      data: result,
      firstDate: minDate,
      lastDate: maxDate
    };
  }

  private static processTimeLineData(
    key: string,
    values: Array<HistoryItem>,
    config: ITimelineOption,
    options: IOptions,
    displayData: boolean,
    result: Record<string, Array<TimeSeriesInterval>>,
    minDate: number,
    maxDate: number,
    baseState: BaseState
  ): { minDate: number; maxDate: number } {
    if (!values || !config || !displayData || config.type !== ChartType.TIMELINE) {
      return { minDate, maxDate };
    }

    // Data decimation
    const limit = 300;

    const from = DateTime.fromMillis(options.interval.from).minus({ day: 1 }).toMillis();
    const to = DateTime.fromMillis(options.interval.to).plus({ day: 1 }).toMillis();

    if (values?.length > limit) {
      values = values.filter(
        d =>
          DateTime.fromFormat(d.x as string, 'yyyy-MM-dd HH:mm:ss').toMillis() >= from &&
          DateTime.fromFormat(d.x as string, 'yyyy-MM-dd HH:mm:ss').toMillis() <= to
      );

      values = this.decimateHistoryData(values, limit);
    }

    const propertyTimelineData = TimelineChartHelper.buildTimeLineItem(values, config, baseState);
    if (!propertyTimelineData) return { minDate, maxDate };

    result[key] = propertyTimelineData.data;

    if (propertyTimelineData.firstDate < minDate || minDate === 0) {
      minDate = propertyTimelineData.firstDate;
    }

    if (propertyTimelineData.lastDate > maxDate || maxDate === 0) {
      maxDate = propertyTimelineData.lastDate;
    }

    return { minDate, maxDate };
  }

  private static decimateHistoryData(values: HistoryItem[], limit: number): HistoryItem[] {
    let counter = values?.length;

    // Ensure the values array is not empty and limit is valid
    if (!values || counter <= limit || limit < 0) {
      return values;
    }

    // Continue removing random elements until the counter is within the limit
    while (counter > limit) {
      const itemsToRemove = counter - limit;
      const indicesToRemove = new Set<number>();

      // Generate unique random indices to remove
      while (indicesToRemove.size < itemsToRemove) {
        const randomIndex = Math.floor(Math.random() * counter);
        indicesToRemove.add(randomIndex);
      }

      // Filter out elements at the randomly chosen indices
      values = values.filter((_, index) => !indicesToRemove.has(index));
      counter = values.length;
    }

    return values;
  }

  private static buildTimeLineItem(
    data: Array<HistoryItem>,
    config: ITimelineOption,
    baseState: BaseState
  ): TimelineData | undefined {
    if (!data || data?.length === 0) {
      return undefined;
    }

    const states = config.states;

    const intervals: Array<TimeSeriesInterval> = [];
    const lastState: {
      date: string;
      value: number;
    } = {
      date: data[0].x as string,
      value: data[0].y as any
    };

    for (const [index, value] of data.entries()) {
      const date = value.x as string;
      const val = value.y as any;

      let lastVal = states ? states[lastState.value] : lastState.value;
      const currentVal = states ? states[val] : val;

      if (config.label === BaseHistoryKeyEnum.CoolingSystemState && baseState.coolingSystemType === 4) {
        // Micro expansion valve
        if (lastState.value === 0) {
          lastVal = states ? states[1] : '-'; // POS_CLOSE
        } else if (lastState.value === 80) {
          lastVal = states ? states[6] : '-'; // POS_OPEN
        } else {
          lastVal = states ? states[5] : '-'; // POS_MID
        }
      }

      if (currentVal !== lastVal) {
        const interval: TimeSeriesInterval = {
          start: lastState.date,
          end: date,
          name: lastVal?.toString()
        };
        intervals.push(interval);
        lastState.date = date;
        lastState.value = val;
      }

      if (index === data.length - 1 && data.length !== 0) {
        const interval: TimeSeriesInterval = {
          start: lastState.date,
          // If the last received value is active alarm, we need a end date with diff of at-least 2 mins to show it
          end: DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').plus({ minute: 2 }).toFormat('yyyy-MM-dd HH:mm:ss'),
          name: states?.[val] as string
        };
        intervals.push(interval);
        lastState.date = date;
        lastState.value = val;
      }
    }

    return {
      data: intervals,
      firstDate: new Date(data[0].x as string).getTime(),
      lastDate: new Date(data[data.length - 1].x as string).getTime()
    };
  }

  private static createTimeLineDataSet(
    timeLineData: TimeLineChartData,
    accessKey: string,
    baseState: BaseState,
    zoneStates: ZoneState[]
  ) {
    let datasets: Array<CustomTimeSeriesDataSet> = [];
    const chartProperties = Object.keys(timeLineData);
    const labelGrouping: Record<string, Array<StackData>> = {};
    const configKey =
      accessKey === ApplianceHistoryKeyEnum.Base ? ApplianceHistoryKeyEnum.Base : ApplianceHistoryKeyEnum.Zone;
    const config = HistoryChartsConfiguration[configKey] as Record<string, ITimelineOption>;

    let labels = chartProperties.map(prop => {
      if (prop.includes('softwareVersion')) {
        return `SoftwareVersion-${prop.split('-')[1]}`;
      }
      return config[prop].label;
    });

    chartProperties.forEach(group => {
      const colors = config[group]?.colors;
      const extremePoints: [number, number] = [0, 0];

      const data = timeLineData[group].map((period, index) => {
        const label = group.includes('softwareVersion')
          ? `SoftwareVersion-${group.split('-')[1]}`
          : config[group].label;
        let start = this.formatDateTime(new Date(period.start).getTime());
        let end = this.formatDateTime(new Date(period.end).getTime());
        let stack: StackData | undefined = undefined;
        let firstStackEntry = false;

        if (index === 0) {
          extremePoints[0] = start;
        }

        if (index == timeLineData[group].length - 1) {
          extremePoints[1] = end;
        }

        if (labelGrouping[group] === undefined) {
          stack = { stack: 'Stack0', lastDate: end };
          labelGrouping[group] = [stack];
          firstStackEntry = true;
        } else {
          labelGrouping[group].forEach(item => {
            if (stack === undefined && item.lastDate <= start) {
              stack = { ...item };
              item.lastDate = end;
            }
          });

          if (stack === undefined) {
            const stackIndex = labelGrouping[group].length;
            stack = {
              stack: 'Stack' + stackIndex,
              lastDate: end
            };
            labelGrouping[group].push(stack);
            firstStackEntry = true;
          }
        }

        const data: Array<null | [number, number]> = labels.map(() => null);

        if (!firstStackEntry) {
          start -= stack.lastDate;
          end -= stack.lastDate;
        }
        const position = labels.indexOf(label);

        data[position] = [start, end];

        const color = colors ? colors[period.name] : TimelineChartHelper.getRandomColor(index);

        const entry: CustomTimeSeriesDataSet = {
          label: period.name,
          extremePoints: extremePoints,
          stackLabel: label,
          data: data,
          skipNull: true,
          backgroundColor: color ?? '#f0f5fc',
          minBarLength: 0.75,
          stack: group,
          fromDate: firstStackEntry ? this.formatDateTime(new Date(period.start).getTime()) : stack.lastDate,
          toDate: this.formatDateTime(new Date(period.end).getTime()),
          dataLabels: {
            formatter: () => group
          },
          spanGaps: true
        };

        return entry;
      });

      datasets = [...datasets, ...data];
    });

    labels = labels.map(label => {
      return accessKey === ApplianceHistoryKeyEnum.Base
        ? this.getStackLabelForBase(label, baseState)
        : this.getStackLabelForZone(label, zoneStates, accessKey);
    });

    return {
      labels,
      datasets: datasets
    };
  }

  private static getStackLabelForBase(labelString: string | string[], baseState: BaseState) {
    const label = labelString.toString().trim();

    if (
      label.toLowerCase() === BaseHistoryKeyEnum.CoolingSystemState.toLowerCase() &&
      baseState.coolingSystemShortText
    ) {
      return `${label}-${baseState.coolingSystemShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.WaterSystemWaterSafetyValveState.toLowerCase() &&
      baseState.waterSystemWaterSafetyValveShortText
    ) {
      return `${label}-${baseState.waterSystemWaterSafetyValveShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.ReminderDustFilterState.toLowerCase() &&
      baseState.reminderDustFilterShortText
    ) {
      return `${label}-${baseState.reminderDustFilterShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.WaterSystemWaterDispenserValveState.toLowerCase() &&
      baseState.waterSystemWaterDispenserValveShortText
    ) {
      return `${label}-${baseState.waterSystemWaterDispenserValveShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.WaterSystemWaterIceCubeValveState.toLowerCase() &&
      baseState.waterSystemWaterIcecubeValveShortText
    ) {
      return `${label}-${baseState.waterSystemWaterIcecubeValveShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.ReminderAirFilterState.toLowerCase() &&
      baseState.reminderAirFilterShortText
    ) {
      return `${label}-${baseState.reminderAirFilterShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.CompressorErrorGeneral.toLowerCase() &&
      baseState.compressorShortText
    ) {
      return `${label}-${baseState.compressorShortText}`;
    } else if (
      label.toLowerCase() === BaseHistoryKeyEnum.ReminderWaterFilterState.toLowerCase() &&
      baseState.reminderWaterFilterShortText
    ) {
      return `${label}-${baseState.reminderWaterFilterShortText}`;
    } else {
      return label;
    }
  }

  private static getStackLabelForZone(labelString: string | string[], zoneStates: ZoneState[], accessKey: string) {
    const label = labelString.toString().trim();

    let zoneState: ZoneState | undefined = undefined;

    if (accessKey === 'zone0') {
      zoneState = zoneStates[0];
    } else if (accessKey === 'zone1') {
      zoneState = zoneStates[1];
    } else if (accessKey === 'zone2') {
      zoneState = zoneStates[2];
    } else if (accessKey === 'zone3') {
      zoneState = zoneStates[3];
    }

    if (label.toLowerCase() === ZoneHistoryKeyEnum.Door.toLowerCase() && zoneState?.doorShortText) {
      return `${label}-${zoneState?.doorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.BiofreshPlusHeaterState.toLowerCase() &&
      zoneState?.biofreshplusHeaterShortText
    ) {
      return `${label}-${zoneState?.biofreshplusHeaterShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.BiofreshPlusHumidifierState.toLowerCase() &&
      zoneState?.biofreshplusHumidifierShortText
    ) {
      return `${label}-${zoneState?.biofreshplusHumidifierShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.BiofreshPlusLightState.toLowerCase() &&
      zoneState?.biofreshplusLightShortText
    ) {
      return `${label}-${zoneState?.biofreshplusLightShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.IceMakerWaterPumpState.toLowerCase() &&
      zoneState?.iceMakerWaterPumpShortText
    ) {
      return `${label}-${zoneState?.iceMakerWaterPumpShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.IceMakerWaterInletHeaterState.toLowerCase() &&
      zoneState?.iceMakerWaterInletHeaterShortText
    ) {
      return `${label}-${zoneState?.iceMakerWaterInletHeaterShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.IceMakerWaterTankSensorState.toLowerCase() &&
      zoneState?.iceMakerWaterTankSensorShortText
    ) {
      return `${label}-${zoneState?.iceMakerWaterTankSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.IceMakerTrayMotorState.toLowerCase() &&
      zoneState?.iceMakerTrayMotorShortText
    ) {
      return `${label}-${zoneState?.iceMakerTrayMotorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.IceMakerDrawerSensorState.toLowerCase() &&
      zoneState?.iceMakerDrawerSensorShortText
    ) {
      return `${label}-${zoneState?.iceMakerDrawerSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.AutodoorKnockSensorState.toLowerCase() &&
      zoneState?.autoDoorKnockSensorShortText
    ) {
      return `${label}-${zoneState?.autoDoorKnockSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.DefrostHeaterState.toLowerCase() &&
      zoneState?.defrostHeaterShortText
    ) {
      return `${label}-${zoneState?.defrostHeaterShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.DefrostWaterDrainHeaterState.toLowerCase() &&
      zoneState?.defrostWaterDrainHeaterShortText
    ) {
      return `${label}-${zoneState?.defrostWaterDrainHeaterShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.AirSensorErrorGeneral.toLowerCase() &&
      zoneState?.airSensorShortText
    ) {
      return `${label}-${zoneState?.airSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.EvaporatorSensorErrorGeneral.toLowerCase() &&
      zoneState?.evaporatorSensorShortText
    ) {
      return `${label}-${zoneState?.evaporatorSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.HumidityFanErrorGeneral.toLowerCase() &&
      zoneState?.humidityFanShortText
    ) {
      return `${label}-${zoneState?.humidityFanShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.WaterDispenserState.toLowerCase() &&
      zoneState?.waterDispenserShortText
    ) {
      return `${label}-${zoneState?.waterDispenserShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.WineHeaterState.toLowerCase() &&
      zoneState?.wineHeaterShortText
    ) {
      return `${label}-${zoneState?.wineHeaterShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.BiofreshAirSensorErrorGeneral.toLowerCase() &&
      zoneState?.biofreshAirSensorShortText
    ) {
      return `${label}-${zoneState?.biofreshAirSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.SafetySensorErrorGeneral.toLowerCase() &&
      zoneState?.safetySensorShortText
    ) {
      return `${label}-${zoneState?.safetySensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.ProductSensorErrorGeneral.toLowerCase() &&
      zoneState?.productSensorShortText
    ) {
      return `${label}-${zoneState?.productSensorShortText}`;
    } else if (
      label.toLowerCase() === ZoneHistoryKeyEnum.HumidifierState.toLowerCase() &&
      zoneState?.humidifierShortText
    ) {
      return `${label}-${zoneState?.humidifierShortText}`;
    } else {
      return label;
    }
  }

  private static formatDateTime(dateTime: number): number {
    const { timeZone } = storeToRefs(useLoginStore());
    const utcStart = DateTime.fromMillis(dateTime, { zone: 'utc' });
    const formatted = utcStart.setZone(timeZone.value).toISO({ includeOffset: false });

    return DateTime.fromISO(formatted + 'Z' ?? '', { zone: timeZone.value }).toMillis();
  }

  private static getRandomColor(index: number): string {
    const colors = [
      ChartConstants.BASE_COLORS.BASE,
      ChartConstants.BASE_COLORS.COLOR_GREEN,
      ChartConstants.BASE_COLORS.COLOR_PINK,
      ChartConstants.BASE_COLORS.COLOR_LILA,
      ChartConstants.BASE_COLORS.COLOR_SALMON,
      ChartConstants.BASE_COLORS.COLOR_ORANGE
    ];

    return colors[index];
  }
}
