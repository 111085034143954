<template>
  <div>
    <patternlib-headline
      separator="bottom"
      class="mb-header block"
      :headline="$t('profile.title')"
      heading-tag="h1"
    ></patternlib-headline>
    <br />

    <patternlib-list>
      <patternlib-list-item>
        <span>Name</span>
        <span slot="value">{{ user?.profile.name }}</span>
      </patternlib-list-item>
      <patternlib-list-item>
        <span>E-Mail</span>
        <span slot="value">{{ user?.profile.email }}</span>
      </patternlib-list-item>
      <patternlib-list-item>
        <span>UPN</span>
        <span slot="value">{{ user?.profile.sub }}</span>
      </patternlib-list-item>

      <patternlib-list-item v-if="isDevelopmentEnv">
        <span>Access token</span>
        <span slot="value">
          <div class="flex flex-row items-center justify-center gap-x-3">
            <patternlib-textinput
              readonly
              placeholder="placeholder"
              :value="user?.access_token"
              type="undefined"
              maxlength="255"
              max="400"
              min="-400"
              additional-info=""
              additional-info-position="top"
              show-remove-icon="true"
            ></patternlib-textinput>
            <span>
              <TheButton
                label="Copy"
                @click="copyToken"
                type="secondary"
                size="small"
                :icon="isCopied ? 'checklist' : 'duplicate'"
            /></span>
          </div>
        </span>
      </patternlib-list-item>
      <patternlib-list-item>
        <span>Roles</span>
        <span slot="value">
          <patternlib-accordion class="w-full">
            <patternlib-accordion-item label="Roles" class="">
              <div class="max-h-52 overflow-auto break-words">
                <patternlib-pill
                  class="m-2 max-w-md"
                  v-for="role in user?.profile.role"
                  :key="role"
                  :label="role"
                  no-states
                ></patternlib-pill>
              </div>
            </patternlib-accordion-item>
          </patternlib-accordion>
        </span>
      </patternlib-list-item>
    </patternlib-list>

    <div class="flex flex-row justify-end">
      <TheButton label="Logout" class="mt-8" @click="logout" />
    </div>
  </div>
</template>

<script src="./ProfileView.ts" lang="ts"></script>
<style src="./ProfileView.scss" lang="scss" scoped></style>
