<template>
	<div></div>
</template>

<script lang="ts">
//import { useLoginStore } from "@/store/LoginStore";
import { useLoginStore } from 'src/store/LoginStore';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LogoutView',
	mounted() {
		const loginStore = useLoginStore();
		loginStore.logout();
		loginStore.redirectToLiebherrLogin();
	},
});
</script>
