import { PatternLibGenericEvent } from '@/types/patternlib';
import { defineComponent, onMounted, PropType, ref } from 'vue';

type OptionType = {
	label: string;
	icon?: string;
};

export default defineComponent({
	emits: ['onChange'],
	props: {
		options: { type: Object as PropType<Array<OptionType>> },
		disabled: { type: Boolean },
		active: { type: Number },
	},
	setup(props, context) {
		const toggle = ref();

		const handleClick = (event: PatternLibGenericEvent) => {
			context.emit('onChange', event.detail.value);
		};

		onMounted(() => {
			toggle.value?.addEventListener('lhChange', handleClick);
		});
	},
});
