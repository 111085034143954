<template>
	<patternlib-multiselect
		:value="
			activeLabels && activeLabels.length > 0 ? activeLabels : undefined
		"
		ref="dropdownRef"
		:disabled="disabled"
		label="Label"
		name="undefined"
		placeholder="Select an option"
		selected-options-length="0"
		width="100%">
		<patternlib-check-option
			v-for="key of options"
			:key="key"
			:value="key"
			:label="key"></patternlib-check-option>
	</patternlib-multiselect>
</template>
<script setup lang="ts">
import { PatternLibGenericEvent } from '@/types/patternlib';
import { PropType, defineProps, ref, onMounted, defineEmits } from 'vue';

defineProps({
	options: Object as PropType<Array<string>>,
	disabled: Boolean,
	label: String,
	placeholder: String,
	activeLabels: Object as PropType<Array<string>>,
});
const emit = defineEmits(['select']);
const dropdownRef = ref();

const onChange = (event: PatternLibGenericEvent) => {
	const selectedElements: Array<{ value: number; label: string }> =
		event.detail.selected;

	const keys = selectedElements
		? selectedElements.map((el) => el.label)
		: selectedElements;

	emit('select', keys);
};

onMounted(() => {
	dropdownRef.value.addEventListener('lhSelect', onChange);
});
</script>
