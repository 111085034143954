import { PropType, computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { ApplianceLocationData, ApplianceStats } from '@/api';
import pinUrl from '@/assets/pin.svg';
import pinPink from '@/assets/pin_pink.svg';
import pinYellow from '@/assets/pin_yellow.svg';
import pinOrange from '@/assets/pin_orange.svg';
import pinGreen from '@/assets/pin_green.svg';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { LoadingSpinner },
  props: {
    appliancesPerCountry: { type: Object as PropType<ApplianceStats[]>, default: [] as ApplianceStats[] },
    appliancesWithLocation: {
      type: Object as PropType<ApplianceLocationData[]>,
      default: [] as ApplianceLocationData[]
    }
  },
  setup(props) {
    const countryOverviewMapRef = ref();
    const appliancesOverviewMapRef = ref();
    const { t } = useI18n();
    const tabsRef = ref();
    const activeTab = ref(0);

    const getPopupContent = (item: ApplianceLocationData) => {
      return (
        `<div style="display: flex"><p style="font-weight: bold">Name : </p> <p style="padding-left: 4px"> ${item.name}</p></div>` +
        `<div style="display: flex"><p style="font-weight: bold">SerialNo : </p> <p style="padding-left: 4px"> ${item.serialNo}</p></div>` +
        `<div style="display: flex"><p style="font-weight: bold">CmType : </p> <p style="padding-left: 4px"> ${item.cmType}</p></div>` +
        `<div style="display: flex"><p style="font-weight: bold">PbType : </p> <p style="padding-left: 4px"> ${item.pbType}</p></div>` +
        `<div style="display: flex"><p style="font-weight: bold">City : </p> <p style="padding-left: 4px"> ${item.city}</p></div>` +
        `<div style="display: flex"><p style="font-weight: bold">Country : </p> <p style="padding-left: 4px"> ${item.country}</p></div>`
      );
    };

    const getPinUrl = (item: ApplianceLocationData) => {
      switch (item.cmType) {
        case 1:
          return pinYellow;
        case 2:
          return pinOrange;
        case 8:
          return pinPink;
        case 9:
          return pinGreen;
        case 6:
        default:
          return pinUrl;
      }
    };

    const mapKey = computed(() => {
      return process.env.VUE_APP_MAP_KEY;
    });

    const initializeCountryOverviewMap = async () => {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY as string,
        version: 'weekly'
      });

      await loader.load().then(async () => {
        const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

        const mapOptions: google.maps.MapOptions = {
          zoom: 3,
          center: { lat: 48.294900379236296, lng: 9.708459410148382 },
          styles: styles['silver']
        };

        // Create the map instance
        const map = new Map(countryOverviewMapRef.value as HTMLElement, mapOptions);

        // Create and initialize the InfoWindow
        const infoWindow = new InfoWindow({
          content: '',
          disableAutoPan: true
        });

        // Add some markers to the map.
        const markers = props.appliancesPerCountry.map(item => {
          const marker = new google.maps.Marker({
            position: { lat: item.latitude as number, lng: item.longitude as number },
            map: map
          });

          // Open info window when marker is clicked
          marker.addListener('click', () => {
            infoWindow.setContent(
              `<div style="display: flex"><p style="font-weight: bold">${item.country} : </p> <p style="padding-left: 4px"> ${item.count}</p></div>`
            );
            infoWindow.open(map, marker);
          });
          return marker;
        });

        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map });
      });
    };

    const initializeAppliancesOverviewMap = async () => {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_KEY as string,
        version: 'weekly'
      });

      let map: google.maps.Map;

      await loader.load().then(async res => {
        const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

        const mapOptions: google.maps.MapOptions = {
          zoom: 2,
          center: { lat: 48.294900379236296, lng: 9.708459410148382 },
          styles: styles['silver']
        };

        map = new google.maps.Map(appliancesOverviewMapRef.value as HTMLElement, mapOptions);

        const infoWindow = new google.maps.InfoWindow({
          content: '',
          disableAutoPan: true
        });

        // Add some markers to the map.
        const markers = props.appliancesWithLocation.map(item => {
          const marker = new google.maps.Marker({
            position: { lat: item.lat as number, lng: item.lon as number },
            map: map
          });

          // markers can only be keyboard focusable when they have click listeners
          // open info window when marker is clicked
          marker.addListener('click', () => {
            infoWindow.setContent(getPopupContent(item));
            infoWindow.open(map, marker);
          });
          return marker;
        });

        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map, algorithmOptions: { maxZoom: 8 } });
      });
    };

    const styles: Record<string, google.maps.MapTypeStyle[]> = {
      default: [],
      silver: [
        {
          elementType: 'geometry',
          stylers: [{ color: '#f5f5f5' }]
        },
        {
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [{ color: '#616161' }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#f5f5f5' }]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#bdbdbd' }]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#eeeeee' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#757575' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#e5e5e5' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#ffffff' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#757575' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#dadada' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#616161' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [{ color: '#e5e5e5' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [{ color: '#eeeeee' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#c9c9c9' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }]
        }
      ],

      night: [
        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#6b9a76' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#17263c' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#17263c' }]
        }
      ],

      retro: [
        { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#c9b2a6' }]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#dcd2be' }]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#ae9e90' }]
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry',
          stylers: [{ color: '#dfd2ae' }]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#dfd2ae' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#93817c' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [{ color: '#a5b076' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#447530' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#f5f1e6' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [{ color: '#fdfcf8' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#f8c967' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#e9bc62' }]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [{ color: '#e98d58' }]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#db8555' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#806b63' }]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [{ color: '#dfd2ae' }]
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#8f7d77' }]
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ebe3cd' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [{ color: '#dfd2ae' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#b9d3c2' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#92998d' }]
        }
      ],

      hiding: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }]
        }
      ]
    };

    const onTabsChanged = (event: any) => {
      activeTab.value = event.detail.value;
    };

    onMounted(async () => {
      await initializeCountryOverviewMap();
      await initializeAppliancesOverviewMap();

      tabsRef.value.addEventListener('lhChange', onTabsChanged);
    });

    return {
      mapKey,
      countryOverviewMapRef,
      appliancesOverviewMapRef,
      t,
      tabsRef,
      activeTab
    };
  }
});
