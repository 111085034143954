import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { i18n } from './plugins/i18n';

// register web components from Liebherr Pattern Library
import './main.css';
import VueApexCharts from 'vue3-apexcharts';
import {
	applyPolyfills,
	defineCustomElements,
} from '@liebherr/patternlib/loader';
import '@liebherr/patternlib/dist/patternlib/patternlib.css';
import '@liebherr/patternlib/www/assets/css/patternlib-fonts.css';
import { router } from './router';
import '@/api/AxiosConfiguration';

applyPolyfills().then(() => {
	defineCustomElements(window);
});

// router

const pinia = createPinia();
const app = createApp(App);
app.use(VueApexCharts);

// eslint-disable-next-line
app.config.compilerOptions.isCustomElement = (tag: any) => {
	return tag.startsWith('patternlib-');
};

app.use(router);
app.use(pinia);
app.use(i18n);

app.mount('#app');
