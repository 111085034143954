import {
  ActivatedApplianceModes,
  ConnectedAppliancesBaseModes,
  ConnectedAppliancesModes,
  ConnectedAppliancesZoneModes
} from '@/api';
import { StatisticsApiFactory } from '@/api/backend/api/statistics-api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import HttpHelper from '@/helper/HttpResponseHelper';
import { computed, defineComponent, Ref, ref, watch } from 'vue';

export default defineComponent({
  props: {
    applianceType: { type: Number, default: -1 },
    numberOfZones: { type: Number, default: 1 },
    model: { type: String, default: '' },
    domain: { type: String, default: '' }
  },
  setup(props) {
    const { getConfig } = useHttpConfiguration();
    const appliancesActivatedModes = ref();
    const connectedAppliancesModes = ref();
    const applianceBaseModes: Ref<ConnectedAppliancesBaseModes | undefined> = ref();
    const coolingZonesModes: Ref<ConnectedAppliancesZoneModes | undefined> = ref();
    const freezerZonesModes: Ref<ConnectedAppliancesZoneModes | undefined> = ref();
    const wineZonesModes: Ref<ConnectedAppliancesZoneModes | undefined> = ref();
    const bioFreshZonesModes: Ref<ConnectedAppliancesZoneModes | undefined> = ref();

    const loadAllConnectedApplianceModes = async () => {
      const response = await StatisticsApiFactory(getConfig()).getConnectedAppliancesActivatedModes(props.domain);
      appliancesActivatedModes.value = HttpHelper.getRequestData<ActivatedApplianceModes>(response, 200);
    };

    const activatedChartOptions = (showAll: boolean, isForZone: boolean, yAxisLabel?: string) => {
      return {
        chart: {
          type: 'bar',
          responsive: true,
          toolbar: {
            show: false
          }
        },
        zoom: {
          enabled: false
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              // position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            trim: true,
            hideOverlappingLabels: false,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          },
          categories: getLabels(showAll, isForZone)
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          },
          title: {
            text: yAxisLabel,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'var(--copytext-font-family)',
              fontWeight: 500
            }
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    };

    const getApplianceBaseModesChartSeries = computed(() => {
      return [
        {
          name: '',
          data: [
            applianceBaseModes.value?.nightModeCount,
            applianceBaseModes.value?.holidayModeCount,
            applianceBaseModes.value?.partyModeCount,
            applianceBaseModes.value?.energySaverModeCount,
            applianceBaseModes.value?.sabbathModeCount,
            applianceBaseModes.value?.childLockCount,
            applianceBaseModes.value?.ecoModeCount
          ]
        }
      ];
    });

    const getModesChartSeries = (zonesModes: ConnectedAppliancesZoneModes) => {
      return [
        {
          name: '',
          data: [zonesModes.superCoolCount, zonesModes.superFrostCount, zonesModes.iceMakerCount]
        }
      ];
    };

    const getActivatedModesChartSeries = computed(() => {
      return [
        {
          name: '',
          data: [
            appliancesActivatedModes.value.nightModeCount,
            appliancesActivatedModes.value.holidayModeCount,
            appliancesActivatedModes.value.partyModeCount,
            appliancesActivatedModes.value.energySaverModeCount,
            appliancesActivatedModes.value.sabbathModeCount,
            appliancesActivatedModes.value.childLockCount,
            appliancesActivatedModes.value.ecoModeCount,
            appliancesActivatedModes.value.superCoolCount,
            appliancesActivatedModes.value.superFrostCount,
            appliancesActivatedModes.value.iceMakerCount
          ]
        }
      ];
    });

    const loadConnectedAppliancesModesByType = async () => {
      const response = await StatisticsApiFactory(getConfig()).getConnectedAppliancesActivatedModesByType(
        props.applianceType,
        props.numberOfZones,
        props.domain
      );
      appliancesActivatedModes.value = HttpHelper.getRequestData<ActivatedApplianceModes>(response, 200);
    };

    const getLabels = (showAll: boolean, isForZone: boolean) => {
      if (showAll) {
        return [
          'NightMode',
          'HolidayMode',
          'PartyMode',
          'EnergySaverMode',
          'SabbathMode',
          'ChildLock',
          'EcoMode',
          'SuperCool',
          'SuperFrost',
          'IceMaker'
        ];
      } else if (isForZone) {
        return ['SuperCool', 'SuperFrost', 'IceMaker'];
      }
      return ['NightMode', 'HolidayMode', 'PartyMode', 'EnergySaverMode', 'SabbathMode', 'ChildLock', 'EcoMode'];
    };

    const loadConnectedAppliancesModesByModel = async () => {
      appliancesActivatedModes.value = undefined;

      const response = await StatisticsApiFactory(getConfig()).getConnectedAppliancesModesByModel(
        props.model,
        props.domain
      );
      connectedAppliancesModes.value = HttpHelper.getRequestData<ConnectedAppliancesModes>(response, 200);
      applianceBaseModes.value = connectedAppliancesModes.value.baseModes;

      coolingZonesModes.value = {
        superCoolCount: connectedAppliancesModes.value.coolerZoneModes?.superCoolCount,
        superFrostCount: connectedAppliancesModes.value.coolerZoneModes?.superFrostCount,
        iceMakerCount: connectedAppliancesModes.value.coolerZoneModes?.iceMakerCount
      };

      freezerZonesModes.value = {
        superCoolCount: connectedAppliancesModes.value.freezerZoneModes?.superCoolCount,
        superFrostCount: connectedAppliancesModes.value.freezerZoneModes?.superFrostCount,
        iceMakerCount: connectedAppliancesModes.value.freezerZoneModes?.iceMakerCount
      };

      wineZonesModes.value = {
        superCoolCount: connectedAppliancesModes.value.wineZoneModes?.superCoolCount,
        superFrostCount: connectedAppliancesModes.value.wineZoneModes?.superFrostCount,
        iceMakerCount: connectedAppliancesModes.value.wineZoneModes?.iceMakerCount
      };

      bioFreshZonesModes.value = {
        superCoolCount: connectedAppliancesModes.value.bioFreshZoneModes?.superCoolCount,
        superFrostCount: connectedAppliancesModes.value.bioFreshZoneModes?.superFrostCount,
        iceMakerCount: connectedAppliancesModes.value.bioFreshZoneModes?.iceMakerCount
      };

      // If appliance type is Cooler, Freezer, Wine or BioFresh (1-zone Appliances)
      if (props.applianceType === 0) {
        // Cooler
        freezerZonesModes.value = undefined;
        wineZonesModes.value = undefined;
        bioFreshZonesModes.value = undefined;
      } else if (props.applianceType === 1) {
        // Freezer
        coolingZonesModes.value = undefined;
        wineZonesModes.value = undefined;
        bioFreshZonesModes.value = undefined;
      } else if (props.applianceType === 2) {
        // Wine
        freezerZonesModes.value = undefined;
        coolingZonesModes.value = undefined;
        bioFreshZonesModes.value = undefined;
      } else if (props.applianceType === 4) {
        // BioFresh
        freezerZonesModes.value = undefined;
        coolingZonesModes.value = undefined;
        wineZonesModes.value = undefined;
      }
    };

    const loadConnectedAppliancesStatistics = async () => {
      if (props.applianceType === -1 && props.model === '') {
        await loadAllConnectedApplianceModes();
      } else if (props.model) {
        await loadConnectedAppliancesModesByModel();
      } else {
        await loadConnectedAppliancesModesByType();
      }
    };

    watch(
      () => props.applianceType,
      async () => {
        await loadConnectedAppliancesStatistics();
      },
      { immediate: true }
    );

    watch(
      () => props.domain,
      async () => {
        await loadConnectedAppliancesStatistics();
      },
      { immediate: true }
    );

    watch(
      () => props.model,
      async () => {
        if (props.model) {
          await loadConnectedAppliancesStatistics();
        }
      },
      { immediate: true }
    );

    return {
      activatedChartOptions,
      getActivatedModesChartSeries,
      appliancesActivatedModes,
      connectedAppliancesModes,
      coolingZonesModes,
      freezerZonesModes,
      wineZonesModes,
      bioFreshZonesModes,
      applianceBaseModes,
      getApplianceBaseModesChartSeries,
      getModesChartSeries
    };
  }
});
