import { useLoginStore } from 'src/store/LoginStore';
import TheButton from '@/components/basic/Button/TheButton.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { defineComponent, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
	name: 'LoginView',
	components: {
		TheButton,
		LoadingSpinner,
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();
		const loginStore = useLoginStore();
		const redirectToLoginClient = () => {
			const path = route.query.path;
			loginStore.redirectToLiebherrLogin(
				typeof path === 'string' ? path : undefined
			);
		};

		onBeforeMount(() => {
			redirectToLoginClient();
		});

		return { redirectToLoginClient, t };
	},
});
