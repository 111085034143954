/* tslint:disable */
/* eslint-disable */
/**
 * AppAnDa backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseInfo } from '../model';
// @ts-ignore
import { BaseState } from '../model';
// @ts-ignore
import { ProblemDetails } from '../model';
// @ts-ignore
import { StateBusNodes } from '../model';
// @ts-ignore
import { ZoneInfo } from '../model';
// @ts-ignore
import { ZoneState } from '../model';
/**
 * ApplianceStateApi - axios parameter creator
 * @export
 */
export const ApplianceStateApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseInfo: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getBaseInfo', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceState/base/{deviceSerialId}/info`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseState: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getBaseState', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceState/base/{deviceSerialId}/state`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStateBusNodesInformation: async (
      deviceSerialId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getStateBusNodesInformation', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceState/state-bus-nodes/{deviceSerialId}`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneInfo: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getZoneInfo', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceState/zone/{deviceSerialId}/info`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneInfoByType: async (
      deviceSerialId: string,
      type: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getZoneInfoByType', 'deviceSerialId', deviceSerialId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getZoneInfoByType', 'type', type);
      const localVarPath = `/appanda/api/v2/ApplianceState/zone/{deviceSerialId}/{type}/info`
        .replace(`{${'deviceSerialId'}}`, encodeURIComponent(String(deviceSerialId)))
        .replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneState: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getZoneState', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceState/zone/{deviceSerialId}/state`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneStateByType: async (
      deviceSerialId: string,
      type: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getZoneStateByType', 'deviceSerialId', deviceSerialId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getZoneStateByType', 'type', type);
      const localVarPath = `/appanda/api/v2/ApplianceState/zone/{deviceSerialId}/{type}/state`
        .replace(`{${'deviceSerialId'}}`, encodeURIComponent(String(deviceSerialId)))
        .replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ApplianceStateApi - functional programming interface
 * @export
 */
export const ApplianceStateApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ApplianceStateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBaseInfo(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseInfo(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBaseState(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseState(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStateBusNodesInformation(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateBusNodes>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStateBusNodesInformation(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getZoneInfo(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneInfo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getZoneInfo(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getZoneInfoByType(
      deviceSerialId: string,
      type: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getZoneInfoByType(deviceSerialId, type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getZoneState(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneState>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getZoneState(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getZoneStateByType(
      deviceSerialId: string,
      type: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneState>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getZoneStateByType(deviceSerialId, type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ApplianceStateApi - factory interface
 * @export
 */
export const ApplianceStateApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApplianceStateApiFp(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseInfo(deviceSerialId: string, options?: any): AxiosPromise<BaseInfo> {
      return localVarFp.getBaseInfo(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseState(deviceSerialId: string, options?: any): AxiosPromise<BaseState> {
      return localVarFp.getBaseState(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStateBusNodesInformation(deviceSerialId: string, options?: any): AxiosPromise<Array<StateBusNodes>> {
      return localVarFp.getStateBusNodesInformation(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneInfo(deviceSerialId: string, options?: any): AxiosPromise<Array<ZoneInfo>> {
      return localVarFp.getZoneInfo(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneInfoByType(deviceSerialId: string, type: number, options?: any): AxiosPromise<ZoneInfo> {
      return localVarFp.getZoneInfoByType(deviceSerialId, type, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneState(deviceSerialId: string, options?: any): AxiosPromise<Array<ZoneState>> {
      return localVarFp.getZoneState(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZoneStateByType(deviceSerialId: string, type: number, options?: any): AxiosPromise<ZoneState> {
      return localVarFp.getZoneStateByType(deviceSerialId, type, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * ApplianceStateApi - interface
 * @export
 * @interface ApplianceStateApi
 */
export interface ApplianceStateApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getBaseInfo(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<BaseInfo>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getBaseState(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<BaseState>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getStateBusNodesInformation(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Array<StateBusNodes>>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getZoneInfo(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ZoneInfo>>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getZoneInfoByType(deviceSerialId: string, type: number, options?: AxiosRequestConfig): AxiosPromise<ZoneInfo>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getZoneState(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ZoneState>>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApiInterface
   */
  getZoneStateByType(deviceSerialId: string, type: number, options?: AxiosRequestConfig): AxiosPromise<ZoneState>;
}

/**
 * ApplianceStateApi - object-oriented interface
 * @export
 * @class ApplianceStateApi
 * @extends {BaseAPI}
 */
export class ApplianceStateApi extends BaseAPI implements ApplianceStateApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getBaseInfo(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getBaseInfo(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getBaseState(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getBaseState(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getStateBusNodesInformation(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getStateBusNodesInformation(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getZoneInfo(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getZoneInfo(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getZoneInfoByType(deviceSerialId: string, type: number, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getZoneInfoByType(deviceSerialId, type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getZoneState(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getZoneState(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceStateApi
   */
  public getZoneStateByType(deviceSerialId: string, type: number, options?: AxiosRequestConfig) {
    return ApplianceStateApiFp(this.configuration)
      .getZoneStateByType(deviceSerialId, type, options)
      .then(request => request(this.axios, this.basePath));
  }
}
