/* eslint-disable @typescript-eslint/no-explicit-any */
import { Base, HistoryItem, Zone } from '@/api';

export interface IScale {
  min: number;
  max: number;
}

export type IntersectionLine = {
  position: { x: number; y: number };
  draw: boolean;
};

export interface IChartOptionSettings {
  interval: { from: number; to: number };
  onScaleChange: (scale: IScale) => void;
  synchronizedMode: boolean;
}

// Timeline types
export type TimeSeriesInterval = {
  start: string;
  end: string;
  name: string;
};

export interface StackData {
  stack: string;
  lastDate: number;
}

export type TimelineData = {
  data: Array<TimeSeriesInterval>;
  firstDate: number;
  lastDate: number;
};

export type ChartData = {
  datasets: IChartData;
  options: {
    timeline: any;
    lineChart: any;
  };
};

export type CustomTimeSeriesDataSet = {
  label: string;
  extremePoints: [number, number];
  stackLabel: string | Array<string>;
  data: Array<any>;
  skipNull: boolean;
  backgroundColor: string;
  minBarLength: number;
  stack: string;
  fromDate: number;
  toDate: number;
  dataLabels: {
    formatter: () => string;
  };
  spanGaps: boolean;
};

export enum LineType {
  NORMAL,
  DASHED,
  HIDDEN
}

export type TimeLineChartData = Record<string, Array<TimeSeriesInterval>>;

export enum ChartType {
  LINECHART,
  TIMELINE
}

//ConfigurationOptions
export interface IChartOption {
  label: string | Array<string>;
  type: ChartType;
  isTemperatureMode: boolean;
  show: boolean;
  isFallbackProp?: boolean;
}

export interface ITimelineOption extends IChartOption {
  type: ChartType.TIMELINE;
  states?: Record<number, string>;
  colors?: Record<string, string>;
}

export interface ILineChartOption extends IChartOption {
  type: ChartType.LINECHART;
  color: string;
  lineType: LineType;
  stepped?: boolean;
}

export type ChartSettingsType = ITimelineOption | ILineChartOption;

export type HistoryChartsConfiguration = {
  base: Record<keyof Base, ChartSettingsType>;
  zone: Record<keyof Required<Zone>, ChartSettingsType>;
};

type ChartSettingsBase = Omit<Base, 'temperatureUnit'>;
type ChartSettingsZone = Omit<
  Zone,
  | 'temperatureUnit'
  | 'setTempCelsius'
  | 'setTempFahrenheit'
  | 'currentTempCelsius'
  | 'currentTempFahrenheit'
  | 'powerFailureAlarmUpperTemperatureUnit'
  | 'temperatureAlarmUpperTemperatureUnit'
>;

// Charts settings

export interface ChartUserSettings {
  useFahrenheit: boolean;
  useOverallView: boolean;
  synchronizedCharts: boolean;
  showMergedCharts: boolean;
  predefinedFilterCooling: boolean;
  base: Record<keyof ChartSettingsBase, boolean>;
  zone: Record<keyof ChartSettingsZone, boolean>;
}

//ChartsBuilder

export type ILineChartDataSet = {
  key: string;
  label: string | Array<string>;
  backgroundColor: string;
  borderColor: string;
  color: string;
  borderDash: Array<number>;
  data: Array<HistoryItem>;
  pointStyle: 'circle' | 'rect';
  borderWidth: number;
  stepped?: boolean;
  fill: boolean;
  pointRadius: number;
  pointHoverRadius: number;
  shortText?: string;
  spanGaps: boolean;
};

export type ITimelineChartData = {
  labels: Array<string | string[]>;
  datasets: Array<any>;
};

export type ITimeLineChartConfig = {
  data: ITimelineChartData;
};

export type ILineChartConfig = {
  data: { datasets: Array<ILineChartDataSet> };
};

export type ILineChartsConfig = {
  base?: ILineChartConfig;
  zones?: { [key: string]: ILineChartConfig };
};

export type ITimelineChartsConfig = {
  base?: ITimeLineChartConfig;
  zones?: { [key: string]: ITimeLineChartConfig };
};

export type IChartData = {
  base?: {
    temperature?: ILineChartConfig;
    timeline?: ITimeLineChartConfig;
    speed?: ILineChartConfig;
    time?: ILineChartConfig;
    percentage?: ILineChartConfig;
    voltage?: ILineChartConfig;
    allCharts?: ILineChartConfig;
    defrost?: ILineChartConfig;
  };
  zone0?: {
    temperature?: ILineChartConfig;
    timeline?: ITimeLineChartConfig;
    speed?: ILineChartConfig;
    time?: ILineChartConfig;
    percentage?: ILineChartConfig;
    voltage?: ILineChartConfig;
    allCharts?: ILineChartConfig;
    defrost?: ILineChartConfig;
  };
  zone1?: {
    temperature?: ILineChartConfig;
    timeline?: ITimeLineChartConfig;
    speed?: ILineChartConfig;
    time?: ILineChartConfig;
    percentage?: ILineChartConfig;
    voltage?: ILineChartConfig;
    allCharts?: ILineChartConfig;
    defrost?: ILineChartConfig;
  };
  zone2?: {
    temperature?: ILineChartConfig;
    timeline?: ITimeLineChartConfig;
    speed?: ILineChartConfig;
    time?: ILineChartConfig;
    percentage?: ILineChartConfig;
    voltage?: ILineChartConfig;
    allCharts?: ILineChartConfig;
    defrost?: ILineChartConfig;
  };
  zone3?: {
    temperature?: ILineChartConfig;
    timeline?: ITimeLineChartConfig;
    speed?: ILineChartConfig;
    time?: ILineChartConfig;
    percentage?: ILineChartConfig;
    voltage?: ILineChartConfig;
    allCharts?: ILineChartConfig;
    defrost?: ILineChartConfig;
  };
};

/* export type IChartData = Record<
	string,
	{ temperature?: ILineChartConfig; timeLine?: any }
>; */
