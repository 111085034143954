<template>
  <patternlib-button
    :label="label"
    :width="width"
    :size="size"
    :type="type"
    :disabled="disabled"
    :display="fullWidth ? 'block' : 'inline-block'"
    no-states="false"
    :loading="isLoading ? 'true' : 'false'"
    icon-position="left"
    v-on:click="$emit('click')"
  >
    <patternlib-icon v-if="icon" :icon-name="icon" size="24px" slot="icon" />
  </patternlib-button>
</template>

<script lang="ts" src="./TheButton.ts" />
