import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "app-container__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalConfirmationDialog = _resolveComponent("GlobalConfirmationDialog")!
  const _component_GlobalToast = _resolveComponent("GlobalToast")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_GlobalConfirmationDialog),
        _createVNode(_component_GlobalToast),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppHeader),
          _createVNode(_component_NavigationBar),
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_AppFooter)
      ])
    ]),
    _: 1
  }))
}