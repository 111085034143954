<template>
	<DataViewSkeleton :header="$t('unauthorizedView.header')">
		<template #content>
			<div
				class="flex min-h-[50vh] flex-col items-center justify-center gap-3">
				<TextPlaceholder :text="$t('unauthorizedView.text')" />
				<TheButton
					:label="$t('unauthorizedView.btnText')"
					size="small"
					icon="home"
					@click="goHome" />
			</div>
		</template>
	</DataViewSkeleton>
</template>

<script lang="ts" setup>
import TheButton from '@/components/basic/Button/TheButton.vue';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';
import TextPlaceholder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const goHome = () => {
	router.push('/');
};
</script>
