import { ApplianceHistoryResponse, Base, BaseState, Zone, ZoneState } from '@/api';
import {
  ChartUserSettings,
  IChartData,
  ILineChartConfig,
  ILineChartDataSet,
  ILineChartsConfig,
  ITimeLineChartConfig,
  ITimelineChartsConfig
} from '@/types/DeviceValueMapping';
import TimelineChartHelper from './TimelineChartHelper';
import LineChartHelper from './LineChartHelper';
import { ApplianceHistoryKeyEnum } from '../ApplianceHistoryKeyEnum';
import { BaseHistoryKeyEnum } from '../BaseHistoryKeyEnum';
import { ZoneHistoryKeyEnum } from '../ZoneHistoryKeyEnum';

export type IOptions = {
  accessKey?: string;
  userSettings: ChartUserSettings;
  interval: {
    from: number;
    to: number;
  };
  temperatureUnit: number;
};

export default class ChartsBuilder {
  static createChartData(
    data: ApplianceHistoryResponse,
    baseState: BaseState | undefined,
    zoneStates: ZoneState[] | undefined,
    options: IOptions
  ) {
    const timelineDataSet = ChartsBuilder.generateTimelineCharts(
      data,
      options,
      baseState as BaseState,
      zoneStates as ZoneState[]
    );

    const lineChartsDataset = ChartsBuilder.generateLineCharts(
      data,
      options,
      baseState as BaseState,
      zoneStates as ZoneState[]
    );

    const result: IChartData = {};

    for (const x of Object.keys(data)) {
      const key = x as keyof ApplianceHistoryResponse;

      if (key === ApplianceHistoryKeyEnum.Base) {
        result[key] = ChartsBuilder.getAccumulatedBaseChartsData(
          timelineDataSet,
          lineChartsDataset,
          options,
          key,
          baseState as BaseState
        );
      } else if (key === 'zones') {
        if (options.accessKey && options.accessKey.includes(ApplianceHistoryKeyEnum.Zone)) {
          result[options.accessKey] = ChartsBuilder.getAccumulatedZoneChartsData(
            timelineDataSet,
            lineChartsDataset,
            options.accessKey,
            baseState as BaseState,
            key,
            options
          );
        } else {
          Object.keys(data.zones as { [key: string]: Zone }).forEach(zoneKey => {
            result[zoneKey] = ChartsBuilder.getAccumulatedZoneChartsData(
              timelineDataSet,
              lineChartsDataset,
              zoneKey,
              baseState as BaseState,
              key,
              options
            );
          });
        }
      }
    }

    return result;
  }

  private static getAccumulatedBaseChartsData(
    timelineDataSet: ITimelineChartsConfig,
    lineChartsDataset: ILineChartsConfig,
    options: IOptions,
    key: string,
    baseState: BaseState
  ) {
    const result: IChartData = {};

    const timelineCharts = timelineDataSet[key];
    const lineCharts = this.getLineCharts(key, lineChartsDataset[key], baseState);

    const lineChartDataSets = lineChartsDataset[key]?.data.datasets.filter(c => c?.data?.length > 0);

    if (options.userSettings.showMergedCharts) {
      if (!timelineCharts && !lineChartDataSets) {
        result[key] = undefined;
      } else {
        result[key] = {
          timeline: timelineCharts,
          allCharts: { data: { datasets: lineChartDataSets as ILineChartDataSet[] } }
        };
      }
    } else {
      if (!lineCharts && !timelineCharts) {
        result[key] = undefined;
      } else {
        result[key] = {
          timeline: timelineCharts,
          temperature: lineCharts?.temperature,
          speed: lineCharts?.speed,
          time: lineCharts?.time,
          percentage: lineCharts?.percentage,
          voltage: lineCharts?.voltage
        };
      }
    }

    return result[key];
  }

  private static getAccumulatedZoneChartsData(
    timelineDataSet: ITimelineChartsConfig,
    lineChartsDataset: ILineChartsConfig,
    zoneKey: string,
    baseState: BaseState,
    key: string,
    options: IOptions
  ) {
    const result: IChartData = {};
    let lineChartDataSets: ILineChartDataSet[] = [];

    const timelineCharts = (timelineDataSet.zones as { [key: string]: ITimeLineChartConfig })[zoneKey];
    const lineCharts = this.getLineCharts(
      key,
      (lineChartsDataset.zones as { [key: string]: ILineChartConfig })[zoneKey],
      baseState
    );

    if (lineChartsDataset.zones && Object.keys(lineChartsDataset.zones).length > 0) {
      lineChartDataSets = (lineChartsDataset.zones as { [key: string]: ILineChartConfig })[
        zoneKey
      ].data.datasets.filter(c => c?.data?.length > 0);
    }

    if (options.userSettings.showMergedCharts) {
      if (!timelineCharts && !lineChartDataSets) {
        result[zoneKey] = undefined;
      } else {
        result[zoneKey] = {
          timeline: timelineCharts,
          allCharts: { data: { datasets: lineChartDataSets as ILineChartDataSet[] } }
        };
      }
    } else {
      if (!lineCharts && !timelineCharts) {
        result[zoneKey] = undefined;
      } else {
        result[zoneKey] = {
          timeline: timelineCharts,
          temperature: lineCharts?.temperature,
          speed: lineCharts?.speed,
          time: lineCharts?.time,
          percentage: lineCharts?.percentage,
          voltage: lineCharts?.voltage,
          defrost: lineCharts?.defrost
        };
      }
    }

    return result[zoneKey];
  }

  private static getLineCharts(
    key: string,
    lineChartDataSet: ILineChartConfig | undefined,
    baseState: BaseState | undefined
  ) {
    const temperatureDataSets: ILineChartDataSet[] = [];
    const speedChartDataSets: ILineChartDataSet[] = [];
    const voltageChartDataSets: ILineChartDataSet[] = [];
    const percentageChartDataSets: ILineChartDataSet[] = [];
    const defrostChartDataSets: ILineChartDataSet[] = [];
    const timeChartDataSets: ILineChartDataSet[] = [];

    if (!lineChartDataSet) {
      return undefined;
    }

    if (key === ApplianceHistoryKeyEnum.Base) {
      lineChartDataSet?.data.datasets.forEach(dataSet => {
        if (dataSet.label === BaseHistoryKeyEnum.CompressorValue) {
          dataSet.label = `${dataSet.label} (RPM)`;
          speedChartDataSets.push(dataSet);
        } else if (dataSet.label === BaseHistoryKeyEnum.CondenserFanValue) {
          switch (baseState?.condenserFanType) {
            case 0: // Voltage
              dataSet.label = `${dataSet.label} (V)`;
              voltageChartDataSets.push(dataSet);
              return;
            case 1: // AC
              return;
            case 2: // RPM
              dataSet.label = `${dataSet.label} (RPM)`;
              speedChartDataSets.push(dataSet);
              return;
          }
        } else if (dataSet.label === BaseHistoryKeyEnum.CoolingSystemPositionValue) {
          defrostChartDataSets.push(dataSet);
        } else {
          temperatureDataSets.push(dataSet);
        }
      });

      return {
        speed: { data: { datasets: speedChartDataSets.filter(x => x.data?.length > 0) } },
        percentage: { data: { datasets: percentageChartDataSets.filter(x => x.data?.length > 0) } },
        voltage: { data: { datasets: voltageChartDataSets.filter(x => x.data?.length > 0) } },
        time: { data: { datasets: timeChartDataSets.filter(x => x.data?.length > 0) } },
        temperature: { data: { datasets: temperatureDataSets.filter(x => x.data?.length > 0) } },
        defrost: { data: { datasets: defrostChartDataSets.filter(x => x.data?.length > 0) } }
      };
    } else if (key === 'zones') {
      lineChartDataSet?.data.datasets.forEach(dataSet => {
        if (dataSet.label === ZoneHistoryKeyEnum.DefrostCcrValue) {
          dataSet.label = `${dataSet.label} (min)`;
          timeChartDataSets.push(dataSet);
        } else if (
          dataSet.label === ZoneHistoryKeyEnum.FanValue ||
          dataSet.label === ZoneHistoryKeyEnum.HumidityFanValue ||
          dataSet.label === ZoneHistoryKeyEnum.IceMakerFanValue
        ) {
          dataSet.label = `${dataSet.label} (V)`;
          voltageChartDataSets.push(dataSet);
        } else if (
          dataSet.label === ZoneHistoryKeyEnum.LightValue ||
          dataSet.label === ZoneHistoryKeyEnum.HumiditySensorValue ||
          dataSet.label === ZoneHistoryKeyEnum.HumiditySetpoint ||
          dataSet.label === ZoneHistoryKeyEnum.HumidityDisplayed
        ) {
          dataSet.label = `${dataSet.label} (%)`;
          percentageChartDataSets.push(dataSet);
        } else if (
          dataSet.label === ZoneHistoryKeyEnum.DefrostPhasesCountMaxTimeReachedCount ||
          dataSet.label === ZoneHistoryKeyEnum.DefrostPhasesCountInefficientCount
        ) {
          defrostChartDataSets.push(dataSet);
        } else {
          temperatureDataSets.push(dataSet);
        }
      });

      return {
        speed: { data: { datasets: speedChartDataSets.filter(x => x.data?.length > 0) } },
        percentage: { data: { datasets: percentageChartDataSets.filter(x => x.data?.length > 0) } },
        voltage: { data: { datasets: voltageChartDataSets.filter(x => x.data?.length > 0) } },
        time: { data: { datasets: timeChartDataSets.filter(x => x.data?.length > 0) } },
        temperature: { data: { datasets: temperatureDataSets.filter(x => x.data?.length > 0) } },
        defrost: { data: { datasets: defrostChartDataSets.filter(x => x.data?.length > 0) } }
      };
    } else {
      return {};
    }
  }

  private static generateLineCharts(
    historyData: ApplianceHistoryResponse,
    options: IOptions,
    baseState: BaseState,
    zoneStates: ZoneState[]
  ) {
    const { accessKey } = options;
    const response: ILineChartsConfig = { zones: {} };

    if (accessKey) {
      let data: Base | Zone = {};

      if (accessKey === ApplianceHistoryKeyEnum.Base) {
        data = historyData[accessKey] as Base;
      } else if (accessKey.includes(ApplianceHistoryKeyEnum.Zone)) {
        data = (historyData.zones as { [key: string]: Zone })?.[accessKey] as Zone;
      }
      const lineChart = LineChartHelper.generateLineChart(data, options, accessKey, baseState, zoneStates);

      if (lineChart && response.zones) {
        if (accessKey === ApplianceHistoryKeyEnum.Base) {
          response[accessKey] = { data: { datasets: lineChart } };
        } else {
          response.zones[accessKey] = { data: { datasets: lineChart } };
        }
      }
    } else {
      for (const key in historyData) {
        if (key === ApplianceHistoryKeyEnum.Base) {
          const accessKey = key;
          const data = historyData[accessKey];

          const lineChart = LineChartHelper.generateLineChart(data, options, accessKey, baseState, zoneStates);

          if (lineChart) {
            response[accessKey] = { data: { datasets: lineChart } };
          }
        } else if (key === 'zones') {
          Object.keys(historyData.zones as { [key: string]: Zone | undefined }).forEach(zoneKey => {
            const zoneHistoryData = historyData.zones?.[zoneKey] as Zone;

            const lineChart = LineChartHelper.generateLineChart(
              zoneHistoryData,
              options,
              zoneKey,
              baseState,
              zoneStates
            );

            if (lineChart) {
              (response.zones as { [key: string]: ILineChartConfig })[zoneKey] = { data: { datasets: lineChart } };
            }
          });
        }
      }
    }

    return response;
  }

  private static generateTimelineCharts(
    data: ApplianceHistoryResponse,
    options: IOptions,
    baseState: BaseState,
    zoneStates: ZoneState[]
  ) {
    const response: ITimelineChartsConfig = { zones: {} };

    const { accessKey } = options;

    if (accessKey) {
      let historyData: Base | Zone = {};

      if (accessKey === ApplianceHistoryKeyEnum.Base) {
        historyData = data[accessKey] as Base;
        response[accessKey] = TimelineChartHelper.generateTimelineChart(historyData, options, baseState, zoneStates);
      } else if (accessKey.includes(ApplianceHistoryKeyEnum.Zone) && response.zones) {
        historyData = (data.zones as { [key: string]: Zone })?.[accessKey] as Zone;
        response.zones[accessKey] = TimelineChartHelper.generateTimelineChart(
          historyData,
          options,
          baseState,
          zoneStates
        );
      }
    } else {
      for (const key in data) {
        const accessKey = key;
        if (accessKey === ApplianceHistoryKeyEnum.Base) {
          const historyData = data[accessKey] as Base;
          response[accessKey] = TimelineChartHelper.generateTimelineChart(
            historyData,
            {
              ...options,
              accessKey: accessKey
            },
            baseState,
            zoneStates
          );
        } else if (accessKey === 'zones') {
          Object.keys(data[accessKey] as { [key: string]: Zone | undefined }).forEach(zoneKey => {
            const zoneHistoryData = (data[accessKey] as { [key: string]: Zone })?.[zoneKey] as Zone;

            (
              response.zones as {
                [key: string]: ITimeLineChartConfig;
              }
            )[zoneKey] = TimelineChartHelper.generateTimelineChart(
              zoneHistoryData,
              {
                ...options,
                accessKey: zoneKey as any
              },
              baseState,
              zoneStates
            );
          });
        }
      }
    }
    return response;
  }
}
