<script setup lang="ts">
import { Toast, useGlobalUIStore } from '@/store/GlobalUIStore';
import { ref, watch } from 'vue';

const toastStore = useGlobalUIStore();
const toast = ref();

watch(
  () => toastStore.toasts,
  () => {
    toastStore.toasts.forEach(createToastItem);
  }
);

const createToastItem = (toastItem: Toast) => {
  if (!toast.value) return;

  const duration = 4 * 1000;

  toast.value.createToastItem({
    itemId: toastItem.id,
    message: toastItem.message,
    showIcon: false,
    type: toastItem.type,
    showUndo: false,
    autoHide: true,
    delay: duration
  });

  removeToast(toastItem.id);
};

const removeToast = (id: string) => {
  toastStore.removeToast(id);
};
</script>

<template>
  <patternlib-toast data-cy="toastContainer" ref="toast" position="bottom-center" width="500px"></patternlib-toast>
</template>
