import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_table_cell = _resolveComponent("patternlib-table-cell")!
  const _component_patternlib_table = _resolveComponent("patternlib-table")!
  const _component_DataViewSkeleton = _resolveComponent("DataViewSkeleton")!

  return (_openBlock(), _createBlock(_component_DataViewSkeleton, {
    header: _ctx.t('history.title'),
    isLoading: _ctx.isLoading,
    error: { isError: _ctx.error.isError, useAction: true },
    onOnErrorActionClick: _ctx.reload
  }, {
    content: _withCtx(() => [
      (_ctx.data)
        ? (_openBlock(), _createBlock(_component_patternlib_table, {
            key: 0,
            "data-cy": "historyTable",
            ref: "tableRef",
            "implicit-ids": "",
            "no-grid-lines": "",
            "no-data-label": _ctx.$t('general.no_information_available'),
            columns: [
					{ label: _ctx.$t('device.serial_id'), align: 'left' },
					{ label: _ctx.$t('device.name'), align: 'left' },
					{ label: _ctx.$t('device.last_searched_at'), align: 'left' },
					{ label: _ctx.$t('device.type'), align: 'left' },
				],
            actions: [{ label: _ctx.$t('general.show') }]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data ?? [], (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
                  _createVNode(_component_patternlib_table_cell, {
                    value: item.deviceSerialId
                  }, null, 8, ["value"]),
                  _createVNode(_component_patternlib_table_cell, {
                    value: item.deviceName ?? '-'
                  }, null, 8, ["value"]),
                  _createVNode(_component_patternlib_table_cell, {
                    value: item.createdAtUtc
                  }, null, 8, ["value"]),
                  _createVNode(_component_patternlib_table_cell, {
                    value: item.targetView
                  }, null, 8, ["value"])
                ], 64))
              }), 128))
            ]),
            _: 1
          }, 8, ["no-data-label", "columns", "actions"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["header", "isLoading", "error", "onOnErrorActionClick"]))
}