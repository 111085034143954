export enum BaseHistoryKeyEnum {
  ApplianceState = 'ApplianceState',
  ApplianceMainMode = 'ApplianceMainMode',
  TemperatureUnit = 'TemperatureUnit',
  PartyMode = 'PartyMode',
  HolidayMode = 'HolidayMode',
  EnergySaverMode = 'EnergySaverMode',
  SabbathMode = 'SabbathMode',
  ChildLock = 'ChildLock',
  NightMode = 'NightMode',
  WaterFilter = 'WaterFilter',
  AirFilter = 'AirFilter',
  DustFilter = 'DustFilter',
  FailureAlarmState = 'FailureAlarmState',
  AmbientSensorTemperatureValue = 'AmbientSensorTemperatureValue',
  CompressorValue = 'CompressorValue',
  PresentationLightValue = 'PresentationLightValue',
  CondenserFanValue = 'CondenserFanValue',
  CondenserFanErrorGeneral = 'CondenserFanErrorGeneral',
  CoolingSystemState = 'CoolingSystemState',
  CoolingSystemPositionValue = 'CoolingSystemPositionValue',
  StatusLightState = 'StatusLightState',
  WaterSystemWaterDispenserValveState = 'WaterSystemWaterDispenserValveState',
  WaterSystemWaterIceCubeValveState = 'WaterSystemWaterIceCubeValveState',
  WaterSystemWaterSafetyValveState = 'WaterSystemWaterSafetyValveState',
  EcoMode = 'EcoMode',
  SoftwareVersion = 'SoftwareVersion',
  ReminderDustFilterState = 'ReminderDustFilterState',
  ReminderWaterFilterState = 'ReminderWaterFilterState',
  ReminderAirFilterState = 'ReminderAirFilterState',
  CompressorErrorGeneral = 'CompressorErrorGeneral',
  NetInterfaceRssi = 'NetInterfaceRssi'
}
