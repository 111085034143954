import { Configuration, Notification, NotificationApiFactory } from '@/api';
import AppConstants from '@/constants/ApplicationConstants';
import { ChartUserSettings } from '@/types/DeviceValueMapping';
import CmpHelper from '@/views/shared/CmpHelper';
import Oidc, { User, UserManager, WebStorageStateStore } from 'oidc-client';
import { defineStore } from 'pinia';
import { i18n } from 'src/plugins/i18n';

export enum LanguageSetting {
  DE = 'de',
  EN = 'en',
  BROWSER = 'browser'
}

export type UserNotifications = {
  notifications: Array<Notification>;
  isLoading: boolean;
  error: boolean;
  initialized: boolean;
};

export interface LoginStoreState {
  user: User | undefined;
  developerMode: boolean;
  chartSettings: ChartUserSettings;
  storedFilter: Record<string, unknown> | null;
  languageSetting: LanguageSetting | null;
  notifications: UserNotifications;
  timezone: string | null;
  userManager: UserManager | null;
}

/** Enable oidc-client logging */
Oidc.Log.logger = console;

const userStore = new WebStorageStateStore({
  prefix: 'appanda.',
  store: window.sessionStorage
});

const defaultChartSettings: ChartUserSettings = {
  useFahrenheit: false,
  useOverallView: true,
  synchronizedCharts: true,
  showMergedCharts: false,
  predefinedFilterCooling: false,
  base: {
    applianceState: true,
    applianceMainMode: true,
    partyMode: true,
    holidayMode: false,
    energySaverMode: true,
    sabbathMode: false,
    childLock: true,
    nightMode: false,
    waterFilter: false,
    airFilter: false,
    dustFilter: false,
    failureAlarmState: true,
    ambientSensorTemperatureValue: true,
    compressorValue: true,
    compressorErrorGeneral: false,
    presentationLightValue: false,
    condenserFanValue: false,
    condenserFanErrorGeneral: false,
    coolingSystemState: false,
    coolingSystemPositionValue: false,
    statusLightState: false,
    waterSystemWaterDispenserValveState: false,
    waterSystemWaterIceCubeValveState: false,
    waterSystemWaterSafetyValveState: false,
    ecoMode: false,
    softwareVersion: true,
    netInterfaceRssi: true
  },
  zone: {
    state: true,
    temperatureDisplayed: true,
    temperatureSetpoint: true,
    cleaningMode: true,
    door: false,
    superFrost: true,
    iceMakerState: true,
    iceMakerWaterEmptyAlarm: false,
    iceMakerDrawerMissingAlarm: false,
    iceMakerDrawerFullAlarm: false,
    iceMakerFailureAlarm: false,
    iceMakerTrayMotorState: false,
    humidifierState: false,
    doorAlarmState: true,
    doorLockState: true,
    temperatureAlarmUpperState: false,
    temperatureAlarmUpperTemperatureValue: false,
    temperatureAlarmLowerState: false,
    temperatureAlarmLowerTemperatureValue: false,
    powerFailureAlarmUpperState: false,
    powerFailureAlarmUpperTemperatureValue: false,
    powerFailureAlarmLowerState: false,
    powerFailureAlarmLowerTemperatureValue: false,
    superCool: false,
    bioFresh: false,
    biofreshAirSensorTemperatureValue: false,
    biofreshAirSensorErrorGeneral: false,
    waterDispenser: false,
    airSensorErrorGeneral: false,
    airSensorTemperatureValue: false,
    autodoorKnockSensorState: false,
    autodoorMotorErrorGeneral: false,
    autodoorMotorErrorOverheat: false,
    autodoorMotorState: false,
    autodoorMotorTemperatureValue: false,
    autodoorObstacleAlarm: false,
    autodoorOverheatAlarm: false,
    defrostHeaterErrorGeneral: false,
    defrostHeaterState: false,
    defrostState: false,
    defrostManualDefrost: false,
    defrostWaterDrainHeaterState: false,
    defrostPhasesCountMaxTimeReachedCount: false,
    defrostPhasesCountInefficientCount: false,
    evaporatorSensorErrorGeneral: false,
    evaporatorSensorTemperatureValue: false,
    fanErrorGeneral: false,
    fanValue: false,
    humidityFanErrorGeneral: false,
    humidityFanValue: false,
    humiditySensorErrorGeneral: false,
    humiditySensorValue: false,
    humidityReminderState: true,
    lightValue: false,
    temperatureCompensationHeaterState: false,
    waterDispenserErrorGeneral: false,
    waterDispenserState: false,
    wineHeaterState: false,
    iceMakerFanValue: false,
    iceMakerWaterPumpState: false,
    iceMakerWaterInletHeaterState: false,
    iceMakerWaterTankSensorState: false,
    iceMakerDrawerSensorState: false,
    biofreshplusHeaterState: false,
    biofreshplusHumidifierState: false,
    biofreshplusLightState: false,
    iceMakerAirSensorErrorGeneral: false,
    iceMakerAirSensorTemperatureValue: false,
    iceMakerDrawerSensorErrorGeneral: false,
    humidityDisplayed: false,
    humiditySetpoint: false,
    productSensorErrorGeneral: false,
    productSensorTemperatureValue: false,
    safetySensorTemperatureValue: false,
    safetySensorErrorGeneral: false
  }
};

const intialNotificationState: UserNotifications = {
  notifications: [],
  isLoading: true,
  error: false,
  initialized: false
};

export const useLoginStore = defineStore('login', {
  state: (): LoginStoreState => ({
    user: undefined,
    developerMode: false,
    chartSettings: defaultChartSettings,
    notifications: intialNotificationState,
    storedFilter: null,
    languageSetting: null,
    timezone: null,
    userManager: null
  }),
  getters: {
    isUserAdmin(state: LoginStoreState) {
      const roles: Array<string> = state.user?.profile.role ?? [];
      return roles.includes(AppConstants.ROLES.ADMIN_ROLE);
    },
    isUserDeveloper(state: LoginStoreState): boolean {
      const roles: Array<string> = state.user?.profile.role ?? [];
      return roles.includes(AppConstants.ROLES.DEV_ROLE);
    },
    isAuthenticated(state: LoginStoreState) {
      return state.user !== undefined;
    },
    accessToken(state: LoginStoreState): string | undefined {
      return state.user?.access_token;
    },
    userRoles(state: LoginStoreState): Array<string> {
      return state.user?.profile.role ?? [];
    },
    language(state: LoginStoreState): string {
      if (state.languageSetting === null || state.languageSetting === LanguageSetting.BROWSER) {
        // Determine the default browser language
        // Language navigator takes values like de-DE or en-DE
        // => as we only use de or en, we have to remove the suffix
        let language = '';
        if (navigator.language.includes('-')) {
          language = navigator.language.split('-')[0];
        } else {
          language = navigator.language;
        }

        // The language has to be one of 'de' or 'en', as we offer translations for those two
        if (Object.values(LanguageSetting).includes(language as LanguageSetting)) {
          return language;
        }

        // Otherwise, return the english version as default
        return 'en';
      } else {
        return state.languageSetting;
      }
    },
    userInitials(state: LoginStoreState): string {
      // The initials are retrieved by taking the first letters of the given name and the family name.
      // If, for some reason, the given name and family name are empty, then we fall back on the 'name' property.
      // If that one is empty, too, then we use a fixed string
      if (
        state.user?.profile.given_name !== undefined &&
        state.user.profile.given_name.length >= 1 &&
        state.user?.profile.family_name !== undefined &&
        state.user.profile.family_name.length >= 1
      ) {
        return (state.user.profile.given_name[0] + state.user.profile.family_name[0]).toUpperCase();
      } else if (state.user?.profile.name !== undefined && state.user.profile.name.length >= 1) {
        return state.user.profile.name[0].toUpperCase();
      } else {
        return 'XY';
      }
    },

    isDevelopmentEnv(): boolean {
      return process.env.NODE_ENV === 'localhost' || process.env.NODE_ENV === 'development';
    },
    timeZone(state: LoginStoreState): string {
      if (!state.timezone) {
        const timezone = localStorage.getItem('timezone');
        return timezone ? timezone : 'Europe/Berlin';
      } else {
        return state.timezone ?? 'Europe/Berlin';
      }
    },
    getDefaultChartSettings(): ChartUserSettings {
      return defaultChartSettings;
    }
  },
  actions: {
    initializeUserManager() {
      if (!this.userManager) {
        this.userManager = new UserManager({
          automaticSilentRenew: true,
          authority: process.env.VUE_APP_LOGIN_AUTHORITY,
          client_id: process.env.VUE_APP_LOGIN_CLIENT_ID,
          client_secret: process.env.VUE_APP_LOGIN_CLIENT_SECRET,
          redirect_uri: window.location.origin + '/login-callback',
          response_type: process.env.VUE_APP_LOGIN_RESPONSE_TYPE,
          scope: process.env.VUE_APP_LOGIN_SCOPE,
          post_logout_redirect_uri: window.location.origin + '/',
          silent_redirect_uri: window.location.origin + '/silent-refresh.html',
          userStore: userStore
        });

        this.userManager.events.addSilentRenewError(error => {
          console.error('Silent renew error:', error);
        });

        this.userManager.events.addAccessTokenExpiring(() => {
          console.log('Access token expiring...');
        });
      }
    },
    redirectToLiebherrLogin(state?: string): void {
      this.userManager?.signinRedirect({ state });
    },
    initializeDevMode(): void {
      if (location.hostname === 'localhost') {
        this.developerMode = true;
      }
    },
    initializeNotifications(): void {
      const token = this.user?.access_token;

      if (!token) return;

      const config = {
        accessToken: token,
        basePath: process.env.VUE_APP_BACKEND_BASE_URL
      };

      this.notifications.isLoading = true;

      NotificationApiFactory(new Configuration(config))
        .getUserNotifications()
        .then(req => {
          this.notifications.initialized = true;
          this.notifications.notifications = req.data ?? [];
        })
        .catch(() => {
          this.notifications.error = true;
        })
        .finally(() => {
          this.notifications.isLoading = false;
        });
    },
    updateNotifications(notifications: Array<Notification>): void {
      this.notifications.notifications = notifications;
    },
    logout(): void {
      this.user = undefined;

      this.userManager?.stopSilentRenew();
      this.userManager?.signoutRedirect().then(result => {
        console.log('Logout was successful : ', result);
      });
    },
    async loginResult(): Promise<void> {
      this.initializeUserManager();
      await this.userManager
        ?.signinRedirectCallback()
        .then(user => {
          this.user = user;

          this.userManager?.getUser().then(user => {
            if (user) {
              console.debug('user found');
            } else {
              console.debug('No user!');
            }
          });
        })
        .catch(error => {
          console.log('Login failed due to : ', error);
          this.redirectToLiebherrLogin();
        });
    },
    async checkStoredLogin(): Promise<void> {
      this.initializeUserManager();
      const result = await userStore.getAllKeys();
      if (result && result.length === 1) {
        const storedLogin = await userStore.get(result[0]);
        const storedUser = JSON.parse(storedLogin) as User;

        // check whether the stored token has expired
        // Typically the access token is renewed 60 seconds before it runs out. We leave some room.
        const storedExpirationDate = new Date(storedUser.expires_at * 1000);
        const now = new Date();
        const renewalGap: number = 61 * 1000;
        const renewalTime = now.getTime() + renewalGap;
        const renewalDate = new Date(renewalTime);

        //if (storedExpirationDate.getTime() > now.getTime() + renewalGap) {
        if (storedExpirationDate.getTime() > renewalDate.getTime()) {
          // accept the token (by not deleting it) and store the user
          this.user = storedUser;
          //await this.fetchUserFromBackend();
        } else {
          // delete the stored key
          userStore.remove(result[0]);
          throw new Error();
        }
      } else {
        // delete the stored key
        userStore.remove(result[0]);
        throw new Error();
      }
    },
    setLanguageSetting(language: LanguageSetting): void {
      this.languageSetting = language;
      this.updateLocale();
    },
    setLanguageString(language: string) {
      this.languageSetting = language as LanguageSetting;
      this.updateLocale();
    },
    initializeLanguage() {
      const lng = localStorage.getItem('language');

      if (lng) {
        this.setLanguageString(lng);
      } else {
        const navigatorLanguage = navigator.language;
        let languageToSet = navigatorLanguage;

        if (navigatorLanguage.includes('-')) {
          languageToSet = navigatorLanguage.split('-')[0];
        }

        this.setLanguageString(languageToSet);
      }
    },
    initializeTimeZone() {
      const timezone = localStorage.getItem('timezone');
      if (!timezone) {
        // Default time zone
        this.setTimezone('Europe/Berlin');
      }
    },
    toggleDevMode(): void {
      this.developerMode = !this.developerMode;
    },
    updateLocale(): void {
      i18n.global.locale.value = this.language as any;
      localStorage.setItem('language', this.language);

      // Update usercentrics language
      CmpHelper.updateLanguage(this.language);
    },
    async isAccessTokenExpired(): Promise<boolean> {
      const result = await userStore.getAllKeys();

      if (!result || result.length !== 1) {
        return false;
      }

      const storedLogin = await userStore.get(result[0]);
      const storedUser = JSON.parse(storedLogin) as User;

      // check whether the stored token has expired
      // Typically the access token is renewed 60 seconds before it runs out. We leave some room.
      const storedExpirationDate = new Date(storedUser.expires_at * 1000);
      const now = new Date();
      const renewalGap: number = 61 * 1000;
      const renewalTime = now.getTime() + renewalGap;
      const renewalDate = new Date(renewalTime);

      return storedExpirationDate.getTime() < renewalDate.getTime();
    },
    async getAccessTokenExpiry(): Promise<number> {
      const userStoreResult = await userStore.getAllKeys();
      const storedLogin = await userStore.get(userStoreResult[0]);
      const storedUser = JSON.parse(storedLogin) as User;

      if (!storedUser) return -1000;

      return Math.round((new Date(storedUser.expires_at * 1000).getTime() - new Date().getTime()) / 1000);
    },
    updateChartSettings(settings: ChartUserSettings): void {
      this.chartSettings = settings;
      localStorage.setItem('chartSettings', JSON.stringify(settings));
    },
    resetChartSettings(): void {
      this.chartSettings = defaultChartSettings;
    },
    initializeChartSettings(): void {
      const settings = localStorage.getItem('chartSettings');
      if (settings) {
        const existingChartSettings = JSON.parse(settings);
        if (
          Object.keys(existingChartSettings.base).length !== Object.keys(defaultChartSettings.base).length ||
          Object.keys(existingChartSettings.zone).length !== Object.keys(defaultChartSettings.zone).length
        ) {
          // It means that new base or zone signals have added / removed
          this.chartSettings = defaultChartSettings;
        } else {
          this.chartSettings = existingChartSettings;
        }
      }
    },
    setTimezone(timezone: string): void {
      this.timezone = timezone;
      localStorage.setItem('timezone', this.timezone);
    }
  }
});
