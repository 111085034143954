import { defineStore } from 'pinia';
import { uuid } from 'vue-uuid';

export enum ToastType {
	SUCCESS = 'success',
	WARNING = 'warning',
	ALERT = 'alert',
	DEFAULT = 'default',
}

export type Toast = {
	type: ToastType;
	message: string;
	id: string;
};

export type Confirmation = {
	label: string;
	text: string;
	confirmBtnLabel: string;
	onConfirm: () => void;
};

export interface GlobalUIState {
	toasts: Array<Toast>;
	confirmation?: Confirmation;
}

export const useGlobalUIStore = defineStore('globalUI', {
	state: (): GlobalUIState => ({
		toasts: [],
		confirmation: undefined,
	}),
	getters: {
		getToasts(state: GlobalUIState) {
			return state.toasts;
		},
	},
	actions: {
		addToast(message: string, type: ToastType) {
			const id = uuid.v4();
			const newToast = { message, type, id: id };
			this.toasts = [newToast, ...this.toasts];
		},
		removeToast(id: string) {
			this.toasts = this.toasts.filter((t) => t.id != id);
		},
		displayConfirmation(confirmaton: Confirmation) {
			this.confirmation = confirmaton;
		},
	},
});
