/* tslint:disable */
/* eslint-disable */
/**
 * AppAnDa backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplianceHistoryResponse } from '../model';
// @ts-ignore
import { ApplianceLocationData } from '../model';
// @ts-ignore
import { ApplianceStats } from '../model';
// @ts-ignore
import { AvailableIntervals } from '../model';
// @ts-ignore
import { BaseHistoryResponse } from '../model';
// @ts-ignore
import { FailureAlarmEntryResponse } from '../model';
// @ts-ignore
import { HistoryEntry } from '../model';
// @ts-ignore
import { ProblemDetails } from '../model';
// @ts-ignore
import { ZoneHistoryResponse } from '../model';
/**
 * ApplianceHistoryApi - axios parameter creator
 * @export
 */
export const ApplianceHistoryApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlarmFailures: async (
      startDate: string,
      endDate: string,
      language?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('getAllAlarmFailures', 'startDate', startDate);
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists('getAllAlarmFailures', 'endDate', endDate);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/alarm/failures`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceBaseHistory: async (
      serialNumber: string,
      startDate: string,
      endDate: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serialNumber' is not null or undefined
      assertParamExists('getApplianceBaseHistory', 'serialNumber', serialNumber);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('getApplianceBaseHistory', 'startDate', startDate);
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists('getApplianceBaseHistory', 'endDate', endDate);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/{serialNumber}/base`.replace(
        `{${'serialNumber'}}`,
        encodeURIComponent(String(serialNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceHistory: async (
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serialNumber' is not null or undefined
      assertParamExists('getApplianceHistory', 'serialNumber', serialNumber);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('getApplianceHistory', 'startDate', startDate);
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists('getApplianceHistory', 'endDate', endDate);
      // verify required parameter 'numberOfZones' is not null or undefined
      assertParamExists('getApplianceHistory', 'numberOfZones', numberOfZones);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/{serialNumber}`.replace(
        `{${'serialNumber'}}`,
        encodeURIComponent(String(serialNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (numberOfZones !== undefined) {
        localVarQueryParameter['numberOfZones'] = numberOfZones;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceZoneHistory: async (
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serialNumber' is not null or undefined
      assertParamExists('getApplianceZoneHistory', 'serialNumber', serialNumber);
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists('getApplianceZoneHistory', 'startDate', startDate);
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists('getApplianceZoneHistory', 'endDate', endDate);
      // verify required parameter 'numberOfZones' is not null or undefined
      assertParamExists('getApplianceZoneHistory', 'numberOfZones', numberOfZones);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/{serialNumber}/zone`.replace(
        `{${'serialNumber'}}`,
        encodeURIComponent(String(serialNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (numberOfZones !== undefined) {
        localVarQueryParameter['numberOfZones'] = numberOfZones;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableDataIntervals: async (
      deviceSerialId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('getAvailableDataIntervals', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/{deviceSerialId}/availableIntervals`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedAppliancesPerCountry: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appanda/api/v2/ApplianceHistory/appliances/per-country`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedAppliancesWithGeoLocations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appanda/api/v2/ApplianceHistory/appliances/geo-locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} serialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentHistoryEntriesOfAppliance: async (
      serialNumber: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serialNumber' is not null or undefined
      assertParamExists('getRecentHistoryEntriesOfAppliance', 'serialNumber', serialNumber);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/base/{serialNumber}/recent`.replace(
        `{${'serialNumber'}}`,
        encodeURIComponent(String(serialNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} serialNumber
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentHistoryEntriesOfApplianceZone: async (
      serialNumber: string,
      type: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serialNumber' is not null or undefined
      assertParamExists('getRecentHistoryEntriesOfApplianceZone', 'serialNumber', serialNumber);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getRecentHistoryEntriesOfApplianceZone', 'type', type);
      const localVarPath = `/appanda/api/v2/ApplianceHistory/zone/{serialNumber}/{type}/recent`
        .replace(`{${'serialNumber'}}`, encodeURIComponent(String(serialNumber)))
        .replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeWarehouse: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appanda/api/v2/ApplianceHistory/wakeUp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ApplianceHistoryApi - functional programming interface
 * @export
 */
export const ApplianceHistoryApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ApplianceHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAlarmFailures(
      startDate: string,
      endDate: string,
      language?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FailureAlarmEntryResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAlarmFailures(
        startDate,
        endDate,
        language,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplianceBaseHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplianceBaseHistory(
        serialNumber,
        startDate,
        endDate,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplianceHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplianceHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplianceHistory(
        serialNumber,
        startDate,
        endDate,
        numberOfZones,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplianceZoneHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplianceZoneHistory(
        serialNumber,
        startDate,
        endDate,
        numberOfZones,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableDataIntervals(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableIntervals>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDataIntervals(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnectedAppliancesPerCountry(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplianceStats>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectedAppliancesPerCountry(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConnectedAppliancesWithGeoLocations(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplianceLocationData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectedAppliancesWithGeoLocations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} serialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecentHistoryEntriesOfAppliance(
      serialNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryEntry>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentHistoryEntriesOfAppliance(
        serialNumber,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} serialNumber
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecentHistoryEntriesOfApplianceZone(
      serialNumber: string,
      type: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryEntry>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentHistoryEntriesOfApplianceZone(
        serialNumber,
        type,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeWarehouse(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initializeWarehouse(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ApplianceHistoryApi - factory interface
 * @export
 */
export const ApplianceHistoryApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApplianceHistoryApiFp(configuration);
  return {
    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAlarmFailures(
      startDate: string,
      endDate: string,
      language?: string,
      options?: any
    ): AxiosPromise<Array<FailureAlarmEntryResponse>> {
      return localVarFp
        .getAllAlarmFailures(startDate, endDate, language, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceBaseHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      options?: any
    ): AxiosPromise<BaseHistoryResponse> {
      return localVarFp
        .getApplianceBaseHistory(serialNumber, startDate, endDate, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options?: any
    ): AxiosPromise<ApplianceHistoryResponse> {
      return localVarFp
        .getApplianceHistory(serialNumber, startDate, endDate, numberOfZones, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} serialNumber
     * @param {string} startDate
     * @param {string} endDate
     * @param {number} numberOfZones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplianceZoneHistory(
      serialNumber: string,
      startDate: string,
      endDate: string,
      numberOfZones: number,
      options?: any
    ): AxiosPromise<ZoneHistoryResponse> {
      return localVarFp
        .getApplianceZoneHistory(serialNumber, startDate, endDate, numberOfZones, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableDataIntervals(deviceSerialId: string, options?: any): AxiosPromise<AvailableIntervals> {
      return localVarFp.getAvailableDataIntervals(deviceSerialId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedAppliancesPerCountry(options?: any): AxiosPromise<Array<ApplianceStats>> {
      return localVarFp.getConnectedAppliancesPerCountry(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectedAppliancesWithGeoLocations(options?: any): AxiosPromise<Array<ApplianceLocationData>> {
      return localVarFp.getConnectedAppliancesWithGeoLocations(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} serialNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentHistoryEntriesOfAppliance(serialNumber: string, options?: any): AxiosPromise<Array<HistoryEntry>> {
      return localVarFp
        .getRecentHistoryEntriesOfAppliance(serialNumber, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} serialNumber
     * @param {number} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentHistoryEntriesOfApplianceZone(
      serialNumber: string,
      type: number,
      options?: any
    ): AxiosPromise<Array<HistoryEntry>> {
      return localVarFp
        .getRecentHistoryEntriesOfApplianceZone(serialNumber, type, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeWarehouse(options?: any): AxiosPromise<void> {
      return localVarFp.initializeWarehouse(options).then(request => request(axios, basePath));
    }
  };
};

/**
 * ApplianceHistoryApi - interface
 * @export
 * @interface ApplianceHistoryApi
 */
export interface ApplianceHistoryApiInterface {
  /**
   *
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} [language]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getAllAlarmFailures(
    startDate: string,
    endDate: string,
    language?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<FailureAlarmEntryResponse>>;

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getApplianceBaseHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<BaseHistoryResponse>;

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {number} numberOfZones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getApplianceHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    numberOfZones: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ApplianceHistoryResponse>;

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {number} numberOfZones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getApplianceZoneHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    numberOfZones: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ZoneHistoryResponse>;

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getAvailableDataIntervals(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<AvailableIntervals>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getConnectedAppliancesPerCountry(options?: AxiosRequestConfig): AxiosPromise<Array<ApplianceStats>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getConnectedAppliancesWithGeoLocations(options?: AxiosRequestConfig): AxiosPromise<Array<ApplianceLocationData>>;

  /**
   *
   * @param {string} serialNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getRecentHistoryEntriesOfAppliance(
    serialNumber: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<HistoryEntry>>;

  /**
   *
   * @param {string} serialNumber
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  getRecentHistoryEntriesOfApplianceZone(
    serialNumber: string,
    type: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<HistoryEntry>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApiInterface
   */
  initializeWarehouse(options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * ApplianceHistoryApi - object-oriented interface
 * @export
 * @class ApplianceHistoryApi
 * @extends {BaseAPI}
 */
export class ApplianceHistoryApi extends BaseAPI implements ApplianceHistoryApiInterface {
  /**
   *
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} [language]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getAllAlarmFailures(startDate: string, endDate: string, language?: string, options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getAllAlarmFailures(startDate, endDate, language, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getApplianceBaseHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    options?: AxiosRequestConfig
  ) {
    return ApplianceHistoryApiFp(this.configuration)
      .getApplianceBaseHistory(serialNumber, startDate, endDate, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {number} numberOfZones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getApplianceHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    numberOfZones: number,
    options?: AxiosRequestConfig
  ) {
    return ApplianceHistoryApiFp(this.configuration)
      .getApplianceHistory(serialNumber, startDate, endDate, numberOfZones, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} serialNumber
   * @param {string} startDate
   * @param {string} endDate
   * @param {number} numberOfZones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getApplianceZoneHistory(
    serialNumber: string,
    startDate: string,
    endDate: string,
    numberOfZones: number,
    options?: AxiosRequestConfig
  ) {
    return ApplianceHistoryApiFp(this.configuration)
      .getApplianceZoneHistory(serialNumber, startDate, endDate, numberOfZones, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getAvailableDataIntervals(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getAvailableDataIntervals(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getConnectedAppliancesPerCountry(options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getConnectedAppliancesPerCountry(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getConnectedAppliancesWithGeoLocations(options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getConnectedAppliancesWithGeoLocations(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} serialNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getRecentHistoryEntriesOfAppliance(serialNumber: string, options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getRecentHistoryEntriesOfAppliance(serialNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} serialNumber
   * @param {number} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public getRecentHistoryEntriesOfApplianceZone(serialNumber: string, type: number, options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .getRecentHistoryEntriesOfApplianceZone(serialNumber, type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceHistoryApi
   */
  public initializeWarehouse(options?: AxiosRequestConfig) {
    return ApplianceHistoryApiFp(this.configuration)
      .initializeWarehouse(options)
      .then(request => request(this.axios, this.basePath));
  }
}
