import TheButton from '@/components/basic/Button/TheButton.vue';
import { useToast } from '@/composables/common/useToast';
import { ToastType } from '@/store/GlobalUIStore';
import { storeToRefs } from 'pinia';
import { useLoginStore } from 'src/store/LoginStore';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ChartSettings from './ChartSettings.vue';
import historyChartsConfig from '@/helper/HistoryCharts/HistoryChartsConfiguration';
import { useRouter } from 'vue-router';
import { SecurityApiFactory } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useFetch } from '@/composables/common/useFetch';
import AppConstants from '@/constants/ApplicationConstants';

export default defineComponent({
  name: 'ProfileView',
  components: { TheButton, ChartSettings },

  setup() {
    const { user, developerMode, isUserAdmin, chartSettings } = storeToRefs(useLoginStore());
    const { logout, isDevelopmentEnv } = useLoginStore();
    const { getConfig } = useHttpConfiguration();
    const router = useRouter();
    const { t } = useI18n();
    const isCopied = ref(false);
    const displayToast = useToast();

    const copyToken = () => {
      const token = user.value?.access_token;
      navigator.clipboard.writeText(token ?? '');
      isCopied.value = true;
      displayToast(t('copy.success_message'), ToastType.SUCCESS);

      setTimeout(() => {
        isCopied.value = false;
      }, 3000);
    };

    const copyApiKey = (key: string) => {
      navigator.clipboard.writeText(key ?? '');
      isCopied.value = true;
      displayToast(t('copy.success_message'), ToastType.SUCCESS);

      setTimeout(() => {
        isCopied.value = false;
      }, 3000);
    };

    const apiKeyRequest = useFetch(() => SecurityApiFactory(getConfig()).getUsersApiKeys());

    const navigateToTicketCreation = () => {
      router.push({ name: 'TicketView' });
    };

    const getTicketType = (ticketType?: number) => {
      if (ticketType === undefined) return;
      return AppConstants.TICKET.TYPES.find(el => el.value === ticketType)?.label;
    };

    return {
      apiKeyRequest,
      logout,
      getTicketType,
      historyChartsConfig,
      chartSettings,
      user,
      t,
      copyToken,
      copyApiKey,
      isCopied,
      isUserAdmin,
      developerMode,
      navigateToTicketCreation,
      isDevelopmentEnv
    };
  }
});
