<template>
  <div>
    <patternlib-headline
      separator="bottom"
      class="mb-header block"
      :headline="$t('device.zone.state')"
      heading-tag="h3"
    >
    </patternlib-headline>

    <div v-if="zoneData.length == 0">
      <p>{{ $t('general.no_information_available') }}</p>
    </div>

    <div v-else>
      <div class="zone-container flex gap-x-4">
        <div class="zone mt-3" v-for="zone in zoneData" :key="zone.id">
          <patternlib-headline
            separator="bottom"
            class="mb-header block"
            :headline="getHeadline(zone)"
            heading-tag="h5"
          >
          </patternlib-headline>
          <KeyValueView :config="zoneStateRenderProps(zone)" :data="zone">
            <template #customItem="{ key, options, value }">
              <template v-if="key === 'iceMakerState'">
                <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.ICEMAKER, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'bioFreshPlus'">
                <span
                  role="button"
                  class="customListEntry"
                  @click="displayModal(ModalTypeEnum.BIOFRESHPLUS, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'bioFresh'">
                <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.BIOFRESH, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key == 'autoDoorMode'">
                <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.AUTODOOR, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key == 'door'">
                <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.DOOR, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'id'">
                <span
                  class="customListEntry"
                  role="button"
                  @click="displayModal(ModalTypeEnum.ZONEFAILURE, zone.id ?? -1)"
                  >{{ options.description.text }}
                  <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon>
                </span>
                <span slot="value">
                  <patternlib-pill
                    :label="countZoneAlarm(value)"
                    type="status"
                    color="status-success-outlined"
                    v-if="countZoneAlarm(value) == 0"
                  >
                  </patternlib-pill>
                  <patternlib-pill :label="countZoneAlarm(value)" type="status" color="status-warning-outlined" v-else>
                  </patternlib-pill>
                </span>
              </template>
              <template v-if="key === 'defrostHeaterErrorGeneral'">
                <span
                  role="button"
                  class="customListEntry"
                  @click="displayModal(ModalTypeEnum.DEFROSTHEATER, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'fanErrorGeneral'">
                <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.FAN, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'airSensorErrorGeneral'">
                <span
                  role="button"
                  class="customListEntry"
                  @click="displayModal(ModalTypeEnum.AirSensor, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'evaporatorSensorErrorGeneral'">
                <span
                  role="button"
                  class="customListEntry"
                  @click="displayModal(ModalTypeEnum.EvaporatorSensor, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
              <template v-if="key === 'iceMakerAirSensorErrorGeneral'">
                <span
                  role="button"
                  class="customListEntry"
                  @click="displayModal(ModalTypeEnum.IceMakerAirSensor, zone.id ?? -1)"
                  >{{ options.description.text }} <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon
                ></span>
                <span slot="value">
                  <TextPill :options="options.pillOptions" :property="value" />
                </span>
              </template>
            </template>
          </KeyValueView>

          <div class="additional-information">
            <span
              >{{ $t('zonestate.title') }} ({{ zone.typeName }} - {{ zone.zoneIndex }}):
              {{ $t('general.last_updated_at') }}
              {{ formatDateTime(zone.changedAtUtc ?? '') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="modalSettings.isModalOpen"
      @onClose="handleModalClosure"
      :icon="modalSettings.icon"
      :title="modalSettings.title"
      :imgSrc="modalSettings.img"
    >
      <KeyValueView :config="modalSettings.config" :data="modalSettings.data" />
    </Modal>
  </div>
</template>

<script src="./ZoneStateComponent.ts" lang="ts" />
<style scoped lang="scss" src="./ZoneStateComponent.scss"></style>
