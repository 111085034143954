export class predefinedFilterSettings {
  public static getCoolingFilterBaseSettings() {
    return {
      applianceState: false,
      applianceMainMode: false,
      partyMode: false,
      holidayMode: false,
      energySaverMode: false,
      sabbathMode: false,
      childLock: false,
      nightMode: false,
      waterFilter: false,
      airFilter: false,
      dustFilter: false,
      failureAlarmState: false,
      ambientSensorTemperatureValue: true,
      compressorValue: true,
      compressorErrorGeneral: false,
      presentationLightValue: false,
      condenserFanValue: true,
      condenserFanErrorGeneral: false,
      coolingSystemState: true,
      statusLightState: false,
      waterSystemWaterDispenserValveState: false,
      waterSystemWaterIceCubeValveState: false,
      waterSystemWaterSafetyValveState: false,
      ecoMode: false,
      softwareVersion: false,
      netInterfaceRssi: false
    };
  }

  public static getCoolingFilterZoneSettings() {
    return {
      state: true,
      temperatureDisplayed: true,
      temperatureSetpoint: true,
      cleaningMode: false,
      door: true,
      superFrost: false,
      iceMakerState: true,
      iceMakerWaterEmptyAlarm: false,
      iceMakerDrawerMissingAlarm: false,
      iceMakerDrawerFullAlarm: false,
      iceMakerFailureAlarm: false,
      iceMakerTrayMotorState: false,
      humidifierState: false,
      doorAlarmState: false,
      doorLockState: false,
      temperatureAlarmUpperState: false,
      temperatureAlarmUpperTemperatureValue: false,
      temperatureAlarmLowerState: false,
      temperatureAlarmLowerTemperatureValue: false,
      powerFailureAlarmUpperState: false,
      powerFailureAlarmUpperTemperatureValue: false,
      powerFailureAlarmLowerState: false,
      powerFailureAlarmLowerTemperatureValue: false,
      superCool: false,
      bioFresh: false,
      biofreshAirSensorTemperatureValue: true,
      biofreshAirSensorErrorGeneral: false,
      waterDispenser: false,
      airSensorErrorGeneral: false,
      airSensorTemperatureValue: true,
      autodoorKnockSensorState: false,
      autodoorMotorErrorGeneral: false,
      autodoorMotorErrorOverheat: false,
      autodoorMotorState: false,
      autodoorMotorTemperatureValue: false,
      autodoorObstacleAlarm: false,
      autodoorOverheatAlarm: false,
      defrostHeaterErrorGeneral: false,
      defrostHeaterState: false,
      defrostState: true,
      defrostManualDefrost: false,
      defrostWaterDrainHeaterState: false,
      defrostPhasesCountMaxTimeReachedCount: false,
      defrostPhasesCountInefficientCount: false,
      evaporatorSensorErrorGeneral: false,
      evaporatorSensorTemperatureValue: true,
      fanErrorGeneral: false,
      fanValue: true,
      humidityFanErrorGeneral: false,
      humidityFanValue: false,
      humiditySensorErrorGeneral: false,
      humiditySensorValue: false,
      humidityReminderState: false,
      lightValue: false,
      temperatureCompensationHeaterState: false,
      waterDispenserErrorGeneral: false,
      waterDispenserState: false,
      wineHeaterState: false,
      iceMakerFanValue: false,
      iceMakerWaterPumpState: false,
      iceMakerWaterInletHeaterState: false,
      iceMakerWaterTankSensorState: false,
      iceMakerDrawerSensorState: false,
      biofreshplusHeaterState: false,
      biofreshplusHumidifierState: false,
      biofreshplusLightState: false,
      iceMakerAirSensorErrorGeneral: false,
      iceMakerAirSensorTemperatureValue: false,
      iceMakerDrawerSensorErrorGeneral: false,
      humidityDisplayed: false,
      humiditySetpoint: false,
      productSensorErrorGeneral: false,
      productSensorTemperatureValue: false,
      safetySensorTemperatureValue: false,
      safetySensorErrorGeneral: false
    };
  }
}
