export class SmartDeviceMapper {
  static getInstallation(typologyArray: string[]) {
    switch (typologyArray[0]) {
      case 'D':
        return 'Décor panel ready';
      case 'I':
        return 'Integrated';
      case 'S':
        return 'Side wall heating';
      case 'U':
        return 'Undercounter';
      default:
        return 'Freestanding';
    }
  }

  static getTemperatureZones(typologyArray: string[]) {
    if (typologyArray[0] == 'D' || typologyArray[0] == 'I' || typologyArray[0] == 'S' || typologyArray[0] == 'U') {
      // Not freestanding
      switch (typologyArray[1]) {
        case 'C':
          return 'Combination';
        case 'F':
          return 'Freezer';
        case 'R':
          return 'Refrigerator';
        case 'W':
          switch (typologyArray[2]) {
            case 'P':
              return 'Wine Presentation';
            case 'S':
              return 'Wine Storage';
            case 'F':
              return 'Wine Food Service';
            default:
              return '';
          }
        default:
          return '';
      }
    } else {
      // Freestanding
      switch (typologyArray[0]) {
        case 'C':
          return 'Combination';
        case 'F':
          return 'Freezer';
        case 'R':
          return 'Refrigerator';
        case 'W':
          switch (typologyArray[1]) {
            case 'P':
              return 'Wine Presentation';
            case 'S':
              return 'Wine Storage';
            case 'F':
              return 'Wine Food Service';
            default:
              return '';
          }
        default:
          return '';
      }
    }
  }

  static getHeight(modelName: string) {
    const modelNameNumbers = modelName.split(' ')[1].split('');
    switch (`${modelNameNumbers[0]}${modelNameNumbers[1]}`) {
      case '33':
        return '45';
      case '34':
        return '60';
      case '35':
        return '72';
      case '37':
        return '82-87';
      case '39':
        return '88';
      case '40':
        return '102';
      case '41':
        return '122';
      case '42':
        return '125';
      case '45':
        return '140';
      case '46':
        return '145';
      case '48':
        return '158';
      case '50':
        return '165';
      case '51':
        return '178';
      case '52':
        return '185';
      case '57':
        return '200';
      case '59':
        return '203';
      case '62':
        return '125';
      case '66':
        return '145';
      case '70':
        return '165';
      case '72':
        return '185';
      case '74':
        return '200';
      case '75':
        return '165';
      case '76':
        return '185';
      case '77':
        return '200';
      default:
        return '';
    }
  }

  static formatCmType(cmType: number) {
    switch (cmType) {
      case 1:
        return `CM00${cmType}`;
      case 2:
        return `CM00${cmType}`;
      case 3:
        return `CM00${cmType}`;
      case 4:
        return `CM00${cmType}`;
      case 5:
        return `CM00${cmType}`;
      case 6:
        return `CM00${cmType}`;
      case 7:
        return `CM00${cmType}`;
      case 8:
        return `CM00${cmType}`;
      case 9:
        return `CM00${cmType}`;
      default:
        return `Unkown`;
    }
  }
  static getValueClass(modelName: string) {
    const DEFAULT_CLASS = '';
    if (!modelName || typeof modelName !== 'string') {
      return DEFAULT_CLASS;
    }

    const modelNameNumbers = modelName.split(' ');
    if (modelNameNumbers.length < 2) {
      return DEFAULT_CLASS;
    }

    const classNumber = modelNameNumbers[1].split('');

    if (classNumber.length < 2) {
      return DEFAULT_CLASS;
    }

    switch (Number(classNumber[2])) {
      case 0:
        return 'PURE Series';
      case 1:
        return 'PURE Series';
      case 2:
        return 'PLUS Series';
      case 3:
        return 'PLUS Series';
      case 4:
        return 'PLUS Series';
      case 5:
        return 'PRIME Series';
      case 6:
        return 'PRIME Series';
      case 7:
        return 'PEAK Series';
      case 8:
        return 'PEAK Series';
      case 9:
        return 'PEAK Series';
      default:
        return '';
    }
  }

  static transformValueClass(valueClass: string) {
    if (!valueClass) {
      return '';
    } else if (valueClass === '-') {
      return valueClass;
    }
    return `${valueClass.toUpperCase()} Series`;
  }
}
