<template>
	<patternlib-headline
		separator="none"
		:headline="t('historyCharts.tutorial.heading')"
		heading-tag="h1"></patternlib-headline>

	<div class="flex flex-col items-center justify-center gap-2">
		<patternlib-steps ref="stepper">
			<patternlib-steps-item active></patternlib-steps-item>
			<patternlib-steps-item></patternlib-steps-item>
			<patternlib-steps-item></patternlib-steps-item>
			<patternlib-steps-item></patternlib-steps-item>
		</patternlib-steps>
		<patternlib-steps-counter :value-max="MAX_STEPS" :value-current="currentStep + 1"></patternlib-steps-counter>
	</div>

	<div class="flex items-center justify-center">
		<div v-if="currentStep === 0">
			<patternlib-headline
				separator="none"
				:headline="t('historyCharts.tutorial.panLabel')"
				heading-tag="h2"></patternlib-headline>

			<span>
				{{ t('historyCharts.tutorial.panText') }}
			</span>

			<img src="/assets/chartInfo/gifs/panning.gif" class="mt-4" alt="Loading" />
		</div>

		<div v-if="currentStep === 1">
			<patternlib-headline
				separator="none"
				:headline="t('historyCharts.tutorial.zoomLabel')"
				heading-tag="h2"></patternlib-headline>

			<span>
				{{ t('historyCharts.tutorial.zoomTextDrag') }}
			</span>

			<img src="/assets/chartInfo/gifs/dragZoom.gif" class="mt-4" alt="Loading" />
		</div>

		<div v-if="currentStep === 2">
			<patternlib-headline
				separator="none"
				:headline="t('historyCharts.tutorial.zoomLabel')"
				heading-tag="h2"></patternlib-headline>

			<span>
				{{ t('historyCharts.tutorial.zoomTextScroll') }}
			</span>

			<img src="/assets/chartInfo/gifs/scrollingWheel.gif" class="mt-4" alt="Loading" />
		</div>

		<div v-if="currentStep === 3" class="mt-10 flex flex-col items-center justify-center gap-3">
			<patternlib-headline
				separator="none"
				:headline="t('historyCharts.tutorial.demoText')"
				heading-tag="h2"></patternlib-headline>

			<TheButton v-if="!showDemo" @click="renderDemo" :label="t('historyCharts.tutorial.startDemo')" />
		</div>
	</div>
</template>

<script lang="ts" setup>
import { PatternLibEvent } from '@/types/patternlib';
import TheButton from '@/components/basic/Button/TheButton.vue';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close']);

const { t } = useI18n();
const stepper = ref();
const currentStep = ref(0);
const MAX_STEPS = 4;
const showDemo = ref(false);
const router = useRouter();

onMounted(() => {
	stepper.value?.addEventListener('lhChangeItem', (e: PatternLibEvent) => {
		currentStep.value = (e.detail.itemId as number) ?? 0;
		showDemo.value = false;
	});
});

const renderDemo = () => {
	const routeData = router.resolve({ name: 'ChartDemo' });

	window.open(routeData.href, '_blank');
	emit('close');
};
</script>
