import { defineComponent, onMounted, ref } from 'vue';
import { StateKeyValue } from '../StateSettings';
import { PatternLibEvent } from '@/types/patternlib';

export default defineComponent({
  props: {
    identifier: { type: String, default: 'base' },
    label: { type: String, default: null },
    settings: { type: Array<StateKeyValue>, default: [] }
  },
  emits: ['evtChartSettingsUpdated'],
  setup(props, { emit }) {
    const checkBoxRef = ref();

    const updateChartSettingsProp = (e: PatternLibEvent) => {
      const { value, name } = e.detail;

      const [identifier, stateLabel] = name.split('-');

      emit('evtChartSettingsUpdated', { key: stateLabel, value: value, identifier: identifier });
    };

    onMounted(() => {
      checkBoxRef.value.forEach((item: HTMLElement) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        item?.addEventListener('lhChange', updateChartSettingsProp);
      });
    });

    return {
      checkBoxRef
    };
  }
});
