export enum ZoneHistoryKeyEnum {
  Door = 'Door',
  BiofreshplusHeaterState = 'BiofreshplusHeaterState',
  BiofreshplusHumidifierState = 'BiofreshplusHumidifierState',
  BiofreshplusLightState = 'BiofreshplusLightState',
  BiofreshplusUpperAirBaffleValue = 'BiofreshplusUpperAirBaffleValue',
  BiofreshplusLowerAirBaffleValue = 'BiofreshplusLowerAirBaffleValue',
  IceMakerWaterPumpState = 'IceMakerWaterPumpState',
  IceMakerWaterInletHeaterState = 'IceMakerWaterInletHeaterState',
  IceMakerWaterTankSensorState = 'IceMakerWaterTankSensorState',
  IceMakerTrayMotorState = 'IceMakerTrayMotorState',
  IceMakerDrawerSensorState = 'IceMakerDrawerSensorState',
  AutodoorKnockSensorState = 'AutodoorKnockSensorState',
  DefrostHeaterState = 'DefrostHeaterState',
  DefrostWaterDrainHeaterState = 'DefrostWaterDrainHeaterState',
  AirSensorErrorGeneral = 'AirSensorErrorGeneral',
  EvaporatorSensorErrorGeneral = 'EvaporatorSensorErrorGeneral',
  HumidityFanErrorGeneral = 'HumidityFanErrorGeneral',
  WaterDispenserState = 'WaterDispenserState',
  WineHeaterState = 'WineHeaterState',
  BiofreshAirSensorErrorGeneral = 'BiofreshAirSensorErrorGeneral',
  SafetySensorErrorGeneral = 'SafetySensorErrorGeneral',
  ProductSensorErrorGeneral = 'ProductSensorErrorGeneral',
  HumidifierState = 'HumidifierState',
  FanValue = 'FanValue',
  HumidityFanValue = 'HumidityFanValue',
  AirSensorTemperatureValue = 'AirSensorTemperatureValue',
  EvaporatorSensorTemperatureValue = 'EvaporatorSensorTemperatureValue',
  IceMakerAirSensorTemperatureValue = 'IceMakerAirSensorTemperatureValue',
  BiofreshAirSensorTemperatureValue = 'BiofreshAirSensorTemperatureValue',
  DefrostCcrValue = 'DefrostCcrValue',
  IceMakerFanValue = 'IceMakerFanValue',
  DefrostPhasesCountMaxTimeReachedCount = 'DefrostPhasesCountMaxTimeReachedCount',
  DefrostPhasesCountInefficientCount = 'DefrostPhasesCountInefficientCount',
  LightValue = 'LightValue',
  HumiditySensorValue = 'HumiditySensorValue',
  HumiditySetpoint = 'HumiditySetpoint',
  HumidityDisplayed = 'HumidityDisplayed',
  BiofreshPlusLightState = 'BiofreshPlusLightState',
  BiofreshPlusHumidifierState = 'BiofreshPlusHumidifierState',
  BiofreshPlusHeaterState = 'BiofreshPlusHeaterState'
}
