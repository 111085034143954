import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_icon = _resolveComponent("patternlib-icon")!
  const _component_patternlib_button = _resolveComponent("patternlib-button")!

  return (_openBlock(), _createBlock(_component_patternlib_button, {
    label: _ctx.label,
    width: _ctx.width,
    size: _ctx.size,
    type: _ctx.type,
    disabled: _ctx.disabled,
    display: _ctx.fullWidth ? 'block' : 'inline-block',
    "no-states": "false",
    loading: _ctx.isLoading ? 'true' : 'false',
    "icon-position": "left",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_patternlib_icon, {
            key: 0,
            "icon-name": _ctx.icon,
            size: "24px",
            slot: "icon"
          }, null, 8, ["icon-name"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "width", "size", "type", "disabled", "display", "loading"]))
}