<script lang="ts" src="./HistoryChartsOverview.ts" />

<template>
  <div ref="observerRef" class="relative">
    <patternlib-headline
      separator="bottom"
      class="mb-header block"
      :headline="t('history.chart_title')"
      heading-tag="h3"
    >
    </patternlib-headline>

    <div class="flex justify-between" style="align-items: end" v-if="historyIntervalsLoaded">
      <patternlib-datepicker
        data-demo="datepicker"
        ref="datePickerRef"
        date-range
        size="small"
        width="40%"
        :disabled="
          !historyIntervalsLoaded ||
          historyDeviceBaseDataRequest.isLoading.value ||
          historyDeviceZoneDataRequest.isLoading.value
        "
        :date="selectedTimeRange?.value"
        :maximum-date="selectedTimeRange?.max"
        :minimum-date="selectedTimeRange?.min"
        :applyButtonText="t('historyCharts.fetchData_btn_label')"
        :label="t('historyCharts.selectTimeRange')"
        disable-manual-input="true"
        :locale="datePickerLocale"
      >
      </patternlib-datepicker>

      <TheButton
        data-demo="settingsBtn"
        icon="settings"
        :label="t('profile.chartSettings.label')"
        :fullWidth="true"
        width="300px"
        :disabled="historyDeviceBaseDataRequest.isLoading.value || historyDeviceZoneDataRequest.isLoading.value"
        @click="toggleSettingsModal"
      />
    </div>

    <div class="my-5 flex flex-row items-center">
      <TextPlaceHolder
        v-if="!historyIntervalsLoaded"
        class="h-14 justify-end"
        :text="$t('historyCharts.no_data')"
        centered
      />
      <TextPlaceHolder
        v-else-if="!historyDeviceBaseDataRequest.data.value && !historyDeviceZoneDataRequest.data.value"
        class="h-14 justify-end"
        icon="digital-touch"
        :text="$t('historyCharts.placeholder_text')"
        centered
      />
    </div>

    <LoadingSpinner
      v-if="historyDeviceBaseDataRequest.isLoading.value || historyDeviceZoneDataRequest.isLoading.value"
      :text="t('historyCharts.loading_history_data')"
      class="mt-10"
      data-cy="loadingSpinner"
    />

    <div v-else-if="historyDeviceBaseDataRequest.data.value && historyDeviceZoneDataRequest.data.value">
      <div class="mt-block flex flex-col gap-2 xl:flex-row" v-if="!useOverallView">
        <AppDropdown
          data-demo="singleViewDropdown"
          class="w-full xl:w-1/3"
          :activeItem="selectedHistoryType"
          :options="dropDownOptions"
          @select="updateDropdown"
          :disabled="false"
          hideRemoveIcon="true"
          placeholder="Select info type"
          label="Select info"
        />
      </div>

      <HistoryCharts
        :serialId="serialId"
        :chartsData="chartsData"
        :selectedHistoryType="selectedHistoryType"
        :zones="zones"
        :currentZoom="currentZoom"
        @onZoomReset="resetZoom"
        @onUpdatedIntersectionLine="updateIntersectionLine"
      ></HistoryCharts>
    </div>
    <AppModal
      :title="t('profile.chartSettings.label')"
      icon="settings"
      v-if="isSettingsModalOpen"
      @onClose="toggleSettingsModal"
    >
      <ChartSettingsComponent @close="toggleSettingsModal" />
    </AppModal>
  </div>
</template>
