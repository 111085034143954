import { DeviceFile } from '@/api';
import { defineComponent, PropType } from 'vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import { useToast } from '@/composables/common/useToast';
import { ToastType } from '@/store/GlobalUIStore';

export default defineComponent({
	name: 'DeviceConfiguration',
	components: { TextPlaceHolder },
	props: {
		deviceFiles: {
			type: Object as PropType<Array<DeviceFile>>,
			required: true,
		},
	},
	setup(props) {
		const displayToast = useToast();

		const downloadFile = (fileIndex: number) => {
			const file = props.deviceFiles[fileIndex];

			if (!file || !file.fileUrl) return;

			fetch(file.fileUrl)
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = file.fileName ?? 'file';
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				})
				.catch(() => {
					displayToast(
						'File could not be downloaded',
						ToastType.ALERT
					);
				});
		};
		return { downloadFile };
	},
});
