import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  slot: "value"
}
const _hoisted_5 = {
  slot: "content",
  style: {"padding":"10px"}
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 2,
  slot: "value"
}
const _hoisted_8 = {
  slot: "content",
  style: {"padding":"10px"}
}
const _hoisted_9 = {
  key: 3,
  slot: "value"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  slot: "content",
  style: {"padding":"10px"}
}
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_headline = _resolveComponent("patternlib-headline")!
  const _component_patternlib_list_item = _resolveComponent("patternlib-list-item")!
  const _component_Pill = _resolveComponent("Pill")!
  const _component_patternlib_tooltip = _resolveComponent("patternlib-tooltip")!
  const _component_patternlib_list = _resolveComponent("patternlib-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.config.header)
      ? (_openBlock(), _createBlock(_component_patternlib_headline, {
          key: 0,
          separator: _ctx.config?.header.hasSeperator && 'bottom',
          headline: _ctx.config?.header.value,
          "heading-tag": "h3",
          class: "mb-header block"
        }, null, 8, ["separator", "headline"]))
      : _createCommentVNode("", true),
    _createVNode(_component_patternlib_list, {
      width: "100%",
      textwrap: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.props, (value, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (value.isHidden)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1))
              : (value.hideIfNotAvailable && (_ctx.data[key] === null || _ctx.data[key] === undefined))
                ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                : (value.type === 2)
                  ? (_openBlock(), _createBlock(_component_patternlib_list_item, {
                      key: 2,
                      "item-height": _ctx.config?.item.height,
                      "label-width": _ctx.config?.item.labelWidth
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "customItem", {
                          key: key,
                          options: value,
                          value: _ctx.data[key]
                        })
                      ]),
                      _: 2
                    }, 1032, ["item-height", "label-width"]))
                  : (_openBlock(), _createBlock(_component_patternlib_list_item, {
                      key: 3,
                      "item-height": _ctx.config?.item.height,
                      "label-width": _ctx.config?.item.labelWidth
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          innerHTML: value.description?.text
                        }, null, 8, _hoisted_3),
                        (value.type === 1 && value.pillOptions)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                              (value.tooltip)
                                ? (_openBlock(), _createBlock(_component_patternlib_tooltip, {
                                    key: 0,
                                    "tooltip-position": "center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", null, [
                                        _createVNode(_component_Pill, {
                                          options: value.pillOptions,
                                          property: _ctx.data[key]
                                        }, null, 8, ["options", "property"])
                                      ]),
                                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data[value.tooltipContent ?? '']), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createBlock(_component_Pill, {
                                    key: 1,
                                    options: value.pillOptions,
                                    property: _ctx.data[key]
                                  }, null, 8, ["options", "property"]))
                            ]))
                          : _createCommentVNode("", true),
                        (value.type === 3 && value.renderAsHTML)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              slot: "value",
                              innerHTML: value.computeCB && value.computeCB(_ctx.data[key] ?? 1)
                            }, null, 8, _hoisted_6))
                          : (value.type === 3)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                (value.tooltip)
                                  ? (_openBlock(), _createBlock(_component_patternlib_tooltip, {
                                      key: 0,
                                      "tooltip-position": "right"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", null, _toDisplayString(value.computeCB ? value.computeCB(_ctx.data[key] ?? 1) : ''), 1),
                                        _createElementVNode("div", _hoisted_8, _toDisplayString(value.tooltipContent ?? ''), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]))
                            : (value.type === 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                  (value.tooltip)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _createVNode(_component_patternlib_tooltip, {
                                          "tooltip-position": "right",
                                          style: {"cursor":"pointer"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", null, _toDisplayString(_ctx.renderValue(_ctx.data[key])), 1),
                                            _createElementVNode("div", _hoisted_11, _toDisplayString(value.tooltipContent ?? ''), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.renderValue(_ctx.data[key])), 1))
                                ]))
                              : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["item-height", "label-width"]))
          ], 64))
        }), 128))
      ]),
      _: 3
    })
  ]))
}