// fetch.js
import { AxiosPromise } from 'axios';
import { onMounted, Ref, ref } from 'vue';

type HttpError = {
	isError: boolean;
	detail?: {
		status: number;
		message: string;
	};
};

export type ResponseT<T> = {
	data: Ref<T | undefined>;
	error: Ref<{
		isError: boolean;
		detail?: {
			status: number;
			message: string;
		};
	}>;
	isLoading: Ref<boolean>;
	executeRequest: () => void;
};

export function useFetch<T>(
	apiRequest: () => AxiosPromise<T>,
	successStatusCode = 200,
	initalFetch = true
): ResponseT<T> {
	const data = ref<T>();
	const error = ref<HttpError>({ isError: false });
	const isLoading = ref<boolean>(false);

	const executeRequest = () => {
		try {
			isLoading.value = true;
			error.value = error.value = { isError: false };
			apiRequest()
				.then((response) => {
					const { data: apiData, status, statusText } = response;

					if (status === successStatusCode) {
						data.value = apiData;
						error.value = { isError: false };
					} else {
						error.value = {
							isError: true,
							detail: { status, message: statusText },
						};
					}
				})
				.catch((err) => {
					error.value = {
						isError: true,
						detail: { status: 400, message: err.message },
					};
				})
				.finally(() => {
					isLoading.value = false;
				});
		} catch (err) {
			error.value = {
				isError: true,
				detail: { status: 400, message: '' },
			};
		}
	};

	onMounted(() => {
		if (initalFetch) executeRequest();
	});

	return { data, error, isLoading, executeRequest };
}
