import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { i18n } from 'src/plugins/i18n';
import SearchComponent from '@/components/search/SearchComponent.vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { HistoryApiFactory, SniApiFactory, UserApiFactory, VwSearchHistory } from '@/api';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';
import { useToast } from '@/composables/common/useToast';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { ToastType } from '@/store/GlobalUIStore';
import { useLoginStore } from '@/store/LoginStore';
import { useFetch } from '@/composables/common/useFetch';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'HomeView',
  components: {
    DataViewSkeleton,
    SearchComponent,
    TextPlaceHolder,
    LoadingSpinner
  },
  setup() {
    const { getConfig } = useHttpConfiguration();
    const displayToast = useToast();
    const { t } = useI18n();

    const historyImages = ref<Array<string>>([]);
    const isFetchingImages = ref(false);

    const getSearchHistory = () => {
      return HistoryApiFactory(getConfig()).getSearchHistory(5);
    };
    const { data: smartDeviceSearchHistory, error, isLoading } = useFetch<Array<VwSearchHistory>>(getSearchHistory);

    watch(
      error,
      newErr => {
        if (newErr.isError === true) {
          displayToast(i18n.global.t('error.history_not_loaded'), ToastType.WARNING);
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      addUser();
    });

    const addUser = async () => {
      const loginStore = await useLoginStore();

      await loginStore.checkStoredLogin().then(() => {
        UserApiFactory(getConfig()).addUser();
      });
    };

    const historyItems = computed(() => {
      return smartDeviceSearchHistory.value ? smartDeviceSearchHistory.value : [];
    });

    const getHistoryImages = async () => {
      const requests: any = [];
      const images: Array<string> = [];
      isFetchingImages.value = true;

      for (const i of historyItems.value) {
        if (!i.deviceSerialId) continue;
        const image = SniApiFactory(getConfig()).getDeviceImage(i.deviceSerialId);

        requests.push(image);
      }

      const results = await Promise.all(requests);

      results.forEach(res => {
        if (res.status === 200) {
          images.push(res.data);
        }
      });

      historyImages.value = images;
      isFetchingImages.value = false;
    };

    watch(
      () => historyItems.value,
      () => {
        getHistoryImages();
      }
    );

    return {
      isLoading,
      historyItems,
      error,
      t,
      historyImages,
      isFetchingImages
    };
  }
});
