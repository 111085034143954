import { useLoginStore } from '@/store/LoginStore';
import axios from 'axios';

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(err) => {
		if (err.response.status === 401) {
			const loginStore = useLoginStore();
			const path = window.location.pathname;
			loginStore.redirectToLiebherrLogin(path);
		}

		return err;
	}
);

axios.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);
