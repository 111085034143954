import {useLoginStore} from '@/store/LoginStore';
import {storeToRefs} from 'pinia';
import { computed, defineComponent } from 'vue';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';
import VuePdfEmbed from 'vue-pdf-embed';

export default defineComponent({
	components: { DataViewSkeleton, VuePdfEmbed },
	setup() {
		const { language } = storeToRefs(useLoginStore());
		
		const paths: Record<string, string> = {
			de: './assets/privacy/2023-01-09-DE.pdf',
			en: './assets/privacy/2023-01-10-EN.pdf'
		};
		
		const filePath = computed(() => {
			const path = paths[language.value];
			if (!path) return undefined;
			return path;
		});
		
		return {
			language, paths, filePath
		}
	}
});