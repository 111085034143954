import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_http_error_page = _resolveComponent("patternlib-http-error-page")!

  return (_openBlock(), _createBlock(_component_patternlib_http_error_page, {
    "status-code": "503",
    message: _ctx.$t('maintenanceModeView.message'),
    "suppress-animation": "false"
  }, null, 8, ["message"]))
}