<template>
  <patternlib-pill type="status" :label="data.label" :color="pillType" />
</template>

<script setup lang="ts">
import { PropType, defineProps, computed } from 'vue';
import { PillOptions, PillStatusEnum } from '../KeyValueView';

const props = defineProps({
  property: { type: String, required: true },
  options: { type: Object as PropType<Record<string, PillOptions>> }
});

const getRenderProps = () => {
  const { options, property } = props;

  if (!options) {
    return { label: 'ui error ' + property, type: 'alert' };
  }

  const key = options[property] ? property : 'default';
  const data = options[key];

  if (!data) {
    return { label: 'ui error ' + property, type: 'alert' };
  }

  return {
    label: options[key].value,
    type: options[key].type
  };
};

const pillType = computed(() => {
  if (data.value.type === PillStatusEnum.SUCCESS) {
    return 'status-success-outlined';
  } else if (data.value.type === PillStatusEnum.ALERT) {
    return 'status-alert-outlined';
  } else if (data.value.type === PillStatusEnum.WARNING) {
    return 'status-warning-outlined';
  }

  return 'status-default';
});

const data = computed(() => getRenderProps());
</script>
