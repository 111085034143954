import { BaseInfo, SniApiFactory } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import SerialNumberFormatter from '@/helper/SerialNumberFormatter';
import { ToastType } from '@/store/GlobalUIStore';
import { PatternLibInputFieldEvent } from '@/types/patternlib';
import { LhChangeValue } from '@liebherr/patternlib';
import { i18n } from 'src/plugins/i18n';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SearchComponent',
  props: {
    deviceSerialId: String
  },

  setup(props) {
    const { getConfig } = useHttpConfiguration();
    const router = useRouter();
    const searchBox = ref();
    const displayToast = useToast();
    const baseInfo = ref<BaseInfo>({} as BaseInfo);
    const { t } = useI18n({ useScope: 'global' });
    const serialId = ref(props.deviceSerialId);

    const format2SerialNumber = (input: string) => {
      const transformedSerialNumber = SerialNumberFormatter.format2SerialNumber(input);

      document.querySelector('patternlib-searchbox')?.setAttribute('value', transformedSerialNumber);

      return transformedSerialNumber;
    };

    const searchChange = (event: any) => {
      let value = event.detail.value.replace(/[^0-9.]/g, '');

      if (value) {
        // Restrict to maximum of 3 dots
        const parts = value.split('.');
        if (parts.length > 4) {
          value = parts.slice(0, 4).join('') + '.' + parts.slice(4).join('');
        }

        // Remove additional dots and format the string
        value = value.replace(/\./g, '');

        // Restrict input length to 9 digits (##.###.###.#)
        if (value.length > 9) {
          value = value.slice(0, 9);
        }

        // Format the value as ##.###.###.#
        let formattedValue = value;
        if (value.length > 2) {
          formattedValue = value.slice(0, 2) + '.' + value.slice(2);
        }
        if (value.length > 5) {
          formattedValue = formattedValue.slice(0, 6) + '.' + formattedValue.slice(6);
        }
        if (value.length > 8) {
          formattedValue = formattedValue.slice(0, 10) + '.' + formattedValue.slice(10);
        }
        serialId.value = formattedValue;
      } else {
        serialId.value = value;
      }
    };

    const handleSearch = (e: PatternLibInputFieldEvent) => {
      const searchText = e.detail.toString();
      search(searchText);
    };

    const search = async (searchText: string) => {
      if (searchText == null) {
        searchText = document.querySelector('patternlib-searchbox')?.value as string;
      }
      searchText = searchText ? searchText.trim() : '';

      if (!SerialNumberFormatter.isSerialNumberValid(searchText)) {
        displayToast(i18n.global.t('search.not_performed'), ToastType.WARNING);
        return;
      }
      const formattedSearchText = format2SerialNumber(searchText);

      try {
        const { data, status } = await SniApiFactory(getConfig()).getDeviceInfo(formattedSearchText);

        if (status !== 200 && status != 204) {
          throw new Error();
        }

        router.replace({
          name: 'DefaultDeviceView',
          params: { serialNumber: formattedSearchText, deviceType: data.deviceKind?.toLowerCase() }
        });
      } catch (error) {
        displayToast(i18n.global.t('search.not_performed'), ToastType.WARNING);
        return;
      }
    };
    onMounted(() => {
      searchBox.value?.addEventListener('lhEnterPress', handleSearch);
      searchBox.value?.addEventListener('lhSearch', handleSearch);
      searchBox.value?.addEventListener('lhChange', searchChange);
    });
    onUnmounted(() => {
      searchBox.value?.removeEventListener('lhEnterPress', handleSearch);
      searchBox.value?.removeEventListener('lhSearch', handleSearch);
      searchBox.value?.addEventListener('lhChange', searchChange);
    });
    return { baseInfo, t, searchBox, serialId };
  }
});
