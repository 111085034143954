<template>
  <patternlib-modal
    class="appModal"
    ref="modalRef"
    allow-close-on-backdrop="true"
    height="auto"
    max-height="100vh"
    hide-cancel="true"
    hide-submit="true"
    :icon-name="icon"
    keep-open-on-confirm="false"
    margin-top="auto"
    :modal-title="title"
    show="true"
    show-icon="true"
    width="700px"
    img-position="top"
  >
    <patternlib-image
      v-if="imgSrc"
      slot="img"
      :src="imgSrc"
      alt="Testimage"
      width="100%"
      height="100%"
    ></patternlib-image>
    <div slot="modal-content"><slot></slot></div>
  </patternlib-modal>
</template>
<script lang="ts" src="./AppModal.ts" />
<style lang="scss" src="./AppModal.scss"></style>
