<script lang="ts">
import { BaseInfoMapper } from '@/views/index/smartdevice/components/BaseInfoMapper';
import { computed, defineComponent, PropType, ref } from 'vue';
import { BaseInfo, BaseState, StateBusNodes } from '@/api';
import { SmartDeviceMapper } from '@/helper/SmartDeviceMapper';
import KeyValueView from '@/components/basic/KeyValueView/KeyValueView.vue';
import { useI18n } from 'vue-i18n';
import { EntryType, KeyValueViewConfig } from '@/components/basic/KeyValueView/KeyValueView';
import AppModal from '@/components/basic/Modal/AppModal.vue';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import { storeToRefs } from 'pinia';
import { useLoginStore } from '@/store/LoginStore';

type TCustomDisplayedData = BaseInfo & { type?: string } & { netInterfaceRssi?: number } & {
  netInterfaceType?: number;
} & { stateBusNodes: Array<StateBusNodes> };

export default defineComponent({
  name: 'SoftwareInformation',
  components: { KeyValueView, AppModal },
  props: {
    deviceSerialId: {
      type: String,
      required: true
    },
    isAvailableAtSmartDevice: {
      type: Boolean,
      default: false
    },

    baseInfo: {
      type: Object as PropType<any>,
      require: true
    },
    baseState: {
      type: Object as PropType<BaseState>,
      default: null
    },
    stateBusNodes: {
      type: Object as PropType<Array<StateBusNodes>>,
      default: null
    }
  },
  setup(props) {
    const { t } = useI18n();
    const showBusNodesInfoModal = ref(false);
    const stateBusNodesInfo = props.stateBusNodes;
    const { timeZone } = storeToRefs(useLoginStore());

    const generateDeviceDataConfig = () => {
      const isHidden = false;
      const config: KeyValueViewConfig<TCustomDisplayedData> = {
        item: { height: '48px', labelWidth: '50%', noSeparator: false },
        header: { value: 'Softwareinformation', hasSeperator: true },
        props: {
          type: {
            description: { text: t('cm.type') },
            type: EntryType.TEXT
          },
          cmApp: {
            description: { text: t('cm.app') },
            type: EntryType.TEXT
          },
          cmIdent: {
            description: { text: t('cm.ident') },
            type: EntryType.TEXT,
            isHidden
          },
          cmPruef: {
            description: { text: t('cm.pruef') },
            type: EntryType.TEXT,
            isHidden
          },
          cmMac: {
            description: { text: t('cm.mac') },
            type: EntryType.TEXT,
            isHidden
          },
          netInterfaceRssi: {
            description: { text: t('cm.interface_rssi') },
            type: EntryType.COMPUTED,
            isHidden: props.baseState.netInterfaceRssi === 0,
            renderAsHTML: true,
            computeCB: (value: number) => {
              if (value < -30 && value >= -75) {
                // High
                return `<patternlib-pill style="padding-top: 4px" type="status" label="High" color='status-success-outlined' />`;
              } else if (value <= -76 && value >= -99) {
                // Medium
                return `<patternlib-pill style="padding-top: 4px" type="status" label="Medium" color='status-warning-outlined' />`;
              } else {
                // Low
                return `<patternlib-pill style="padding-top: 4px" type="status" label="Low" color='status-error-outlined' />`;
              }
            }
          },
          netInterfaceType: {
            description: { text: t('interface.type') },
            type: EntryType.COMPUTED,
            renderAsHTML: true,
            computeCB: (value: number) => {
              switch (value) {
                case 0:
                  return t('interface.no_interface');
                case 1:
                  return t('interface.wifi_station_active');
                case 2:
                  return t('interface.wifi_ap_active');
                case 3:
                  return t('interface.wifi_csap_active');
                case 4:
                  return t('interface.ethernet_active');
                default:
                  return '-';
              }
            }
          },
          pbType: {
            description: { text: t('pb.type') },
            type: EntryType.TEXT,
            isHidden
          },
          pbWhoami: {
            description: { text: t('pb.whoami') },
            type: EntryType.TEXT,
            isHidden
          },
          uiType: {
            description: { text: t('ui.type') },
            type: EntryType.TEXT,
            isHidden
          },
          uiWhoami: {
            description: { text: t('ui.whoami') },
            type: EntryType.TEXT,
            isHidden
          },
          stateBusNodes: {
            description: { text: t('ui.state_bus_nodes') },
            type: EntryType.CUSTOM
            // isHidden: props.stateBusNodes === null
          }
        }
      };

      const data = props.baseInfo ?? {};

      if (data) {
        data['type'] = SmartDeviceMapper.formatCmType(props.baseInfo?.cmType ?? -1);

        if (typeof data.pbType === 'number') {
          data['pbType'] = BaseInfoMapper.mapPbType(data.pbType);
        }

        if (typeof data.uiType === 'number') {
          data['uiType'] = BaseInfoMapper.mapUiType(data.uiType);
        }

        data['netInterfaceRssi'] = props.baseState.netInterfaceRssi ?? '-';
        data['netInterfaceType'] = props.baseState.netInterfaceType ?? '-';
      }

      return { config, data };
    };

    const data = computed(() => generateDeviceDataConfig());

    const hasData = computed(() => {
      return props.baseInfo.serialNo;
    });

    const toggleSoftwareDialog = () => {
      showBusNodesInfoModal.value = !showBusNodesInfoModal.value;
    };

    const getFormattedDateTime = (changedAt: string | undefined) => {
      if (!changedAt) {
        return '-';
      }
      return DateTimeFormatter.getFormattedDateTime(changedAt, timeZone.value);
    };

    return { t, data, hasData, toggleSoftwareDialog, showBusNodesInfoModal, stateBusNodesInfo, getFormattedDateTime };
  }
});
</script>
<template>
  <div class="flex flex-col">
    <div v-if="hasData">
      <KeyValueView :config="data.config" :data="data.data">
        <template #customItem="{ options }">
          <span class="customListEntry">
            {{ options.description.text }}
          </span>
          <span slot="value" style="cursor: pointer">
            <patternlib-icon icon-name="link" size="16px" bold @click="toggleSoftwareDialog()"></patternlib-icon>
          </span>
        </template>
      </KeyValueView>
      <AppModal title="State Bus Nodes Information" v-if="showBusNodesInfoModal" @onClose="toggleSoftwareDialog">
        <patternlib-table
          implicit-ids
          no-grid-lines
          :no-data-label="t('state_bus_nodes.no_data')"
          :columns="[
            { label: t('state_bus_nodes.address'), align: 'left' },
            { label: t('state_bus_nodes.whoami'), align: 'left' },
            { label: t('state_bus_nodes.changed_at'), align: 'left' }
          ]"
        >
          <template v-for="(busNode, index) in stateBusNodesInfo" :key="index">
            <patternlib-table-cell :value="busNode.busNodesAddress"> </patternlib-table-cell>
            <patternlib-table-cell :value="busNode.busNodesWhoami"> </patternlib-table-cell>
            <patternlib-table-cell :value="getFormattedDateTime(busNode.changedAtUtc)"> </patternlib-table-cell>
          </template>
        </patternlib-table>
      </AppModal>
    </div>
  </div>
</template>
