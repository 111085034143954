import ChartConstants from '@/constants/ChartConstants';
import { ChartType, HistoryChartsConfiguration, LineType } from '@/types/DeviceValueMapping';

const config: HistoryChartsConfiguration = {
  base: {
    airFilter: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AirFilter',
      states: ChartConstants.TimelineValues.FILTER,
      colors: ChartConstants.TimelineColors.FILTER
    },
    dustFilter: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DustFilter',
      states: ChartConstants.TimelineValues.FILTER,
      colors: ChartConstants.TimelineColors.FILTER
    },
    childLock: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'ChildLock',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    energySaverMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'EnergySaverMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    failureAlarmState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'FailureAlarmState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },

    holidayMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'HolidayMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    nightMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'NightMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    softwareVersion: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'Software version'
    },
    partyMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'PartyMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    sabbathMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'SabbathMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    applianceState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'ApplianceState',
      colors: ChartConstants.TimelineColors.BASE_STATE,
      states: ChartConstants.TimelineValues.BASE_STATE
    },
    applianceMainMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'ApplianceMainMode',
      colors: ChartConstants.TimelineColors.BASE_MAIN_MODE,
      states: ChartConstants.TimelineValues.BASE_MAIN_MODE
    },
    temperatureUnit: {
      type: ChartType.TIMELINE,
      show: false,
      isTemperatureMode: false,
      label: 'TemperatureMode'
    },
    waterFilter: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterFilter',
      colors: ChartConstants.TimelineColors.FILTER,
      states: ChartConstants.TimelineValues.FILTER
    },
    ambientSensorTemperatureValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: true,
      label: 'AmbientSensorTemperatureValue',
      color: ChartConstants.BASE_COLORS.COLOR_YELLOW,
      lineType: LineType.NORMAL,
      stepped: true
    },
    compressorValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'CompressorValue',
      color: ChartConstants.BASE_COLORS.FANSPEED_1,
      lineType: LineType.NORMAL,
      stepped: true
    },
    presentationLightValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'PresentationLightValue',
      color: ChartConstants.BASE_COLORS.COLOR_LILA,
      lineType: LineType.NORMAL,
      stepped: true
    },
    condenserFanValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'CondenserFanValue',
      color: ChartConstants.BASE_COLORS.OK,
      lineType: LineType.NORMAL,
      stepped: true
    },
    condenserFanErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'CondenserFanErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    compressorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'CompressorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    coolingSystemState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'CoolingSystemState',
      colors: ChartConstants.TimelineColors.COOLING_STATE,
      states: ChartConstants.TimelineValues.COOLING_STATE
    },
    coolingSystemPositionValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'CoolingSystemPositionValue',
      color: ChartConstants.BASE_COLORS.COLOR_SALMON,
      lineType: LineType.NORMAL,
      stepped: true
    },
    statusLightState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'StatusLightState',
      colors: ChartConstants.TimelineColors.STATUS_LIGHT_STATE,
      states: ChartConstants.TimelineValues.STATUS_LIGHT_STATE
    },
    waterSystemWaterDispenserValveState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterSystemWaterDispenserValveState',
      colors: ChartConstants.TimelineColors.WATER_VALVE_STATE,
      states: ChartConstants.TimelineValues.WATER_VALVE_STATE
    },
    waterSystemWaterIceCubeValveState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterSystemWaterIceCubeValveState',
      colors: ChartConstants.TimelineColors.WATER_VALVE_STATE,
      states: ChartConstants.TimelineValues.WATER_VALVE_STATE
    },
    waterSystemWaterSafetyValveState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterSystemWaterSafetyValveState ',
      colors: ChartConstants.TimelineColors.WATER_VALVE_STATE,
      states: ChartConstants.TimelineValues.WATER_VALVE_STATE
    },
    ecoMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'EcoMode ',
      colors: ChartConstants.TimelineColors.ECO_MODE_STATE,
      states: ChartConstants.TimelineValues.ECO_MODE_STATE
    },
    netInterfaceRssi: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'NetInterfaceRssi',
      states: ChartConstants.TimelineValues.NET_INTERFACE_RSSI_STATE,
      colors: ChartConstants.TimelineColors.NET_INTERFACE_RSSI
    }
  },
  zone: {
    bioFresh: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'BioFresh',
      colors: ChartConstants.TimelineColors.BIOFRESH,
      states: ChartConstants.TimelineValues.BIOFRESH
    },
    cleaningMode: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'CleaningMode',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    door: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'Door',
      colors: ChartConstants.TimelineColors.DOOR,
      states: ChartConstants.TimelineValues.DOOR
    },
    doorAlarmState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DoorAlarmState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    doorLockState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DoorLockState',
      colors: ChartConstants.TimelineColors.DOOR_LOCK_STATE,
      states: ChartConstants.TimelineValues.DOOR_LOCK_STATE
    },
    iceMakerDrawerFullAlarm: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'IceMakerDrawerFullAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    iceMakerDrawerMissingAlarm: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'IceMakerDrawerMissingAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    humidifierState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'HumidifierState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    iceMakerFailureAlarm: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerFailureAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    iceMakerState: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'IceMakerState',
      colors: ChartConstants.TimelineColors.ICE_MAKERSTATE,
      states: ChartConstants.TimelineValues.ICE_MAKERSTATE
    },
    iceMakerTrayMotorState: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'IceMakerTrayMotorState',
      colors: ChartConstants.TimelineColors.ICE_MAKER_TRAY_MOTOR_STATE,
      states: ChartConstants.TimelineValues.ICE_MAKER_TRAY_MOTOR_STATE
    },
    iceMakerWaterEmptyAlarm: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'iceMakerWaterEmptyAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    powerFailureAlarmUpperState: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'PowerFailureAlarmUpperState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    powerFailureAlarmLowerState: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'PowerFailureAlarmLowerState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    powerFailureAlarmUpperTemperatureValue: {
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      show: true,
      label: 'Temperature (PowerFailureAlarmUpper)',
      color: ChartConstants.BASE_COLORS.ALARM_TEMPERATURE,
      stepped: true,
      lineType: LineType.NORMAL
    },
    powerFailureAlarmLowerTemperatureValue: {
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      show: true,
      label: 'Temperature (PowerFailureAlarmLower)',
      color: ChartConstants.BASE_COLORS.COLOR_YELLOW,
      stepped: true,
      lineType: LineType.NORMAL
    },
    state: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'OperatingState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    superCool: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'SuperCool',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    superFrost: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'SuperFrost',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    humidityReminderState: {
      type: ChartType.TIMELINE,
      isTemperatureMode: false,
      show: true,
      label: 'HumidityReminderState',
      colors: ChartConstants.TimelineColors.HUMIDITY_REMINDER,
      states: ChartConstants.TimelineValues.HUMIDITY_REMINDER
    },
    temperatureDisplayed: {
      color: ChartConstants.BASE_COLORS.currentTemperature,
      stepped: true,
      show: true,
      isFallbackProp: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'Temperature'
    },
    humidityDisplayed: {
      color: ChartConstants.BASE_COLORS.COLOR_PINK,
      stepped: true,
      show: true,
      isFallbackProp: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'HumidityDisplayed'
    },
    humiditySetpoint: {
      color: ChartConstants.BASE_COLORS.COLOR_TEAL,
      stepped: true,
      show: true,
      isFallbackProp: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'HumiditySetpoint'
    },
    temperatureAlarmUpperState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'TemperatureAlarmUpperState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    temperatureAlarmUpperTemperatureUnit: {
      type: ChartType.TIMELINE,
      show: false,
      isTemperatureMode: false,
      label: 'TemperatureAlarmUpperTemperatureUnit'
    },
    temperatureAlarmUpperTemperatureValue: {
      color: ChartConstants.BASE_COLORS.COLOR_DARK_PINK,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'TemperatureAlarmUpperTemperatureValue'
    },
    temperatureAlarmLowerState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'TemperatureAlarmLowerState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    temperatureAlarmLowerTemperatureValue: {
      color: ChartConstants.BASE_COLORS.COLOR_TEAL,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'TemperatureAlarmLowerTemperatureValue'
    },
    biofreshAirSensorTemperatureValue: {
      color: ChartConstants.BASE_COLORS.COLOR_PINK,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'BiofreshAirSensorTemperatureValue'
    },
    temperatureSetpoint: {
      color: ChartConstants.BASE_COLORS.setTemperature,
      stepped: true,
      isFallbackProp: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'Set temperature',
      show: true
    },
    temperatureUnit: {
      type: ChartType.TIMELINE,
      show: false,
      isTemperatureMode: false,
      label: 'TemperatureUnit'
    },
    waterDispenser: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterDispenser',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    autodoorObstacleAlarm: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorObstacleAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    autodoorOverheatAlarm: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorOverheatAlarm',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    autodoorKnockSensorState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorKnockSensorState',
      colors: ChartConstants.TimelineColors.KNOCK_SENSOR_STATE,
      states: ChartConstants.TimelineValues.SENSOR_KNOCK_STATE
    },
    autodoorMotorState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorMotorState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    defrostHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DefrostHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    defrostState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DefrostState',
      colors: ChartConstants.TimelineColors.DEFROST_STATE,
      states: ChartConstants.TimelineValues.DEFROST_STATE
    },
    defrostWaterDrainHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DefrostWaterDrainHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    defrostManualDefrost: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DefrostManualDefrost',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    defrostPhasesCountMaxTimeReachedCount: {
      color: ChartConstants.BASE_COLORS.COLOR_TEAL,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'DefrostPhasesCountMaxTimeReachedCount'
    },
    defrostPhasesCountInefficientCount: {
      color: ChartConstants.BASE_COLORS.COLOR_PINK,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'DefrostPhasesCountInefficientCount'
    },
    waterDispenserState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterDispenserState',
      colors: ChartConstants.TimelineColors.ALARM,
      states: ChartConstants.TimelineValues.ALARM
    },
    wineHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WineHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    temperatureCompensationHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'TemperatureCompensationHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    autodoorMotorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorMotorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    autodoorMotorErrorOverheat: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AutodoorMotorErrorOverheat',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    defrostHeaterErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'DefrostHeaterErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    fanErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'FanErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    airSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'AirSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    biofreshAirSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'BiofreshAirSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    evaporatorSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'EvaporatorSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    waterDispenserErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'WaterDispenserErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    humiditySensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'HumiditySensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    humidityFanErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'HumidityFanErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    autodoorMotorTemperatureValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: true,
      label: 'AutodoorMotorTemperatureValue',
      color: ChartConstants.BASE_COLORS.COLOR_ORANGE,
      lineType: LineType.NORMAL,
      stepped: true
    },
    fanValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'FanValue',
      color: ChartConstants.BASE_COLORS.FANSPEED_1,
      lineType: LineType.NORMAL,
      stepped: true
    },
    humidityFanValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'HumidityFanValue',
      color: ChartConstants.BASE_COLORS.FANSPEED_2,
      lineType: LineType.NORMAL,
      stepped: true
    },
    lightValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'LightValue',
      color: ChartConstants.BASE_COLORS.COLOR_TEAL,
      lineType: LineType.NORMAL,
      stepped: true
    },
    airSensorTemperatureValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: true,
      label: 'AirSensorTemperatureValue',
      color: ChartConstants.BASE_COLORS.COLOR_YELLOW,
      lineType: LineType.NORMAL,
      stepped: true
    },
    evaporatorSensorTemperatureValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: true,
      label: 'EvaporatorSensorTemperatureValue',
      color: ChartConstants.BASE_COLORS.COLOR_PURPLE,
      lineType: LineType.NORMAL,
      stepped: true
    },
    humiditySensorValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'HumiditySensorValue',
      color: ChartConstants.BASE_COLORS.COLOR_DARK_GREY,
      lineType: LineType.NORMAL,
      stepped: true
    },
    biofreshplusLightState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'BiofreshPlusLightState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    biofreshplusHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'BiofreshPlusHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    biofreshplusHumidifierState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'BiofreshPlusHumidifierState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    iceMakerWaterPumpState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerWaterPumpState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    iceMakerDrawerSensorState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerDrawerSensorState',
      colors: {
        DRAWER_MISSING: ChartConstants.BASE_COLORS.ERROR,
        DRAWER_PRESENT: ChartConstants.BASE_COLORS.BASE
      },
      states: { 0: 'DRAWER_MISSING', 1: 'DRAWER_PRESENT' }
    },
    iceMakerWaterInletHeaterState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerWaterInletHeaterState',
      colors: ChartConstants.TimelineColors.OPERATION_STATUS,
      states: ChartConstants.TimelineValues.OPERATION_STATUS
    },
    iceMakerWaterTankSensorState: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerWaterTankSensorState',
      colors: {
        WATER_EMPTY: ChartConstants.BASE_COLORS.ERROR,
        WATER_NOT_EMPTY: ChartConstants.BASE_COLORS.BASE
      },
      states: { 0: 'WATER_EMPTY', 1: 'WATER_NOT_EMPTY' }
    },
    iceMakerDrawerSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerDrawerSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    iceMakerAirSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerAirSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    iceMakerFanValue: {
      type: ChartType.LINECHART,
      show: true,
      isTemperatureMode: false,
      label: 'IceMakerFanValue',
      color: ChartConstants.BASE_COLORS.COLOR_GREEN,
      lineType: LineType.NORMAL,
      stepped: true
    },
    iceMakerAirSensorTemperatureValue: {
      color: ChartConstants.BASE_COLORS.COLOR_SOME_PURPLE,
      stepped: true,
      show: true,
      lineType: LineType.NORMAL,
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      label: 'IceMakerAirSensorTemperatureValue'
    },
    productSensorTemperatureValue: {
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      show: true,
      label: 'ProductTemperatureSensorTemperature',
      color: ChartConstants.BASE_COLORS.COLOR_SOMETHING,
      stepped: true,
      lineType: LineType.NORMAL
    },
    safetySensorTemperatureValue: {
      type: ChartType.LINECHART,
      isTemperatureMode: true,
      show: true,
      label: 'SafetyTemperatureSensorTemperature',
      color: ChartConstants.BASE_COLORS.COLOR_DARK_GREY,
      stepped: true,
      lineType: LineType.NORMAL
    },
    safetySensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'SafetySensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    },
    productSensorErrorGeneral: {
      type: ChartType.TIMELINE,
      show: true,
      isTemperatureMode: false,
      label: 'ProductSensorErrorGeneral',
      colors: ChartConstants.TimelineColors.AUTO_DOOR_MOTOR_ERROR,
      states: ChartConstants.TimelineValues.AUTO_DOOR_MOTOR_ERROR
    }
  }
};

export default config;
