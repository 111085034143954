<template>
  <div style="width: 100%">

    <div v-if="model && connectedAppliancesModes">
      <div v-if="applianceBaseModes">
        <span class="font-head text-h4 ml-4">
          {{ $t('general.appliance') }} - {{ $t('statistics.title') }}
      </span>
        <apexchart type="bar" height="300" :options="activatedChartOptions(false, false)"
                   :series="getApplianceBaseModesChartSeries">
        </apexchart>
      </div>

      <div v-if="coolingZonesModes">
        <span class="font-head text-h4 ml-4">
          {{ $t('general.coolerzone') }} - {{ $t('statistics.title') }}
      </span>
        <apexchart type="bar" height="300" :options="activatedChartOptions(false, true)"
                   :series="getModesChartSeries(coolingZonesModes)">
        </apexchart>
      </div>

      <div v-if="freezerZonesModes">
      <span class="font-head text-h4 ml-4 pt-4">
          {{ $t('general.freezerzone') }} - {{ $t('statistics.title') }}
      </span>
        <apexchart type="bar" height="300" :options="activatedChartOptions(false, true)"
                   :series="getModesChartSeries(freezerZonesModes)">
        </apexchart>
      </div>

      <div v-if="wineZonesModes">
      <span class="font-head text-h4 ml-4 pt-4">
          {{ $t('general.winezone') }} - {{ $t('statistics.title') }}
      </span>
        <apexchart type="bar" height="300" :options="activatedChartOptions(false, true)"
                   :series="getModesChartSeries(wineZonesModes)">
        </apexchart>
      </div>

      <div v-if="bioFreshZonesModes">
      <span class="font-head text-h4 ml-4 pt-4">
          {{ $t('general.biofreshzone') }} - {{ $t('statistics.title') }}
      </span>
        <apexchart type="bar" height="300" :options="activatedChartOptions(false, true)"
                   :series="getModesChartSeries(bioFreshZonesModes)">
        </apexchart>
      </div>

    </div>
    <div v-else-if="appliancesActivatedModes">
      <span class="font-head text-h4 ml-4">
                 {{ $t('statistics.activatedfunctionsofconnectedappliances') }}
      </span>
      <apexchart type="bar" height="300" :options="activatedChartOptions(true, false, $t('statistics.numberofactivations').toString())"
                 :series="getActivatedModesChartSeries">
      </apexchart>
    </div>
  </div>
</template>

<script lang="ts" src="./AvailableModes.ts"></script>