<template>
  <DataViewSkeleton :header="t('failures.title')" @onErrorActionClick="reload">
    <template #content>
      <div style="display: flex; justify-content: space-between; margin-bottom: 1rem; align-items: end">
        <patternlib-datepicker
          ref="datePickerRef"
          date-range
          size="small"
          width="40%"
          show-remove-icon="false"
          :date="selectedTimeRange"
          :applyButtonText="t('general.apply')"
          :label="t('historyCharts.selectTimeRange')"
          :maximum-date="maxDate"
          :minimum-date="minDate"
          :disabled="isLoading"
          disable-manual-input="true"
          hideButtonsOnDesktop="false"
          :locale="datePickerLocale"
        >
          <patternlib-texttoggle size="small" slot="header" ref="customSelectorRef" :value="customRangeSelector">
            <patternlib-texttoggle-item :value="CustomRangeSelector.LastSixMonths">
              {{ t('general.last_six_months') }}
            </patternlib-texttoggle-item>
            <patternlib-texttoggle-item :value="CustomRangeSelector.LastThreeMonths">
              {{ t('general.last_three_months') }}
            </patternlib-texttoggle-item>
            <patternlib-texttoggle-item :value="CustomRangeSelector.LastMonth">
              {{ t('general.last_month') }}</patternlib-texttoggle-item
            >
            <patternlib-texttoggle-item :value="CustomRangeSelector.LastWeek">
              {{ t('general.last_week') }}</patternlib-texttoggle-item
            >
            <patternlib-texttoggle-item :value="CustomRangeSelector.Today">
              {{ t('general.today') }}
            </patternlib-texttoggle-item>
          </patternlib-texttoggle>
        </patternlib-datepicker>

        <TheButton icon="download" :label="t('failures.download')" size="small" @click="downloadFailureAlarms" />
      </div>

      <patternlib-table
        data-cy="historyTable"
        :prevent-loading="isLoading"
        ref="tableRef"
        implicit-ids
        :no-data-label="$t('general.no_information_available')"
        pagination
        rows-per-page="10"
        :page="currentPage"
        :columns="headers"
        :actions="[{ label: $t('general.show') }]"
        class="mb-4"
      >
        <template v-for="item in failures ?? []" :key="item">
          <patternlib-table-cell :value="item.domain"></patternlib-table-cell>
          <patternlib-table-cell :value="item.serialNo"></patternlib-table-cell>
          <patternlib-table-cell :value="item.zoneIndex"></patternlib-table-cell>
          <patternlib-table-cell :value="item.model"></patternlib-table-cell>
          <patternlib-table-cell :value="formatDateTime(item.changedAtUtc)"></patternlib-table-cell>
          <patternlib-table-cell :value="item.error"></patternlib-table-cell>
          <patternlib-table-cell :value="item.shortText"></patternlib-table-cell>
          <patternlib-table-cell :value="item.countryCode"></patternlib-table-cell>
        </template>
      </patternlib-table>

      <div v-if="!isLoading && hasStatistics" class="mt-4">
        <span class="font-head text-h3">Error Statistics</span>
        <div id="chart-1" v-if="shortTextErrorStats && shortTextErrorStats.keys.length > 0">
          <apexchart type="bar" height="500" :options="shortTextChartOptions" :series="shortTextSeries"></apexchart>
        </div>

        <div id="chart-2">
          <apexchart
            type="bar"
            height="500"
            :options="applianceErrorChartOptions"
            :series="applianceErrorSeries"
          ></apexchart>
        </div>
      </div>
    </template>
  </DataViewSkeleton>
</template>

<script lang="ts" src="./FailureView.ts"></script>
<style lang="css">
.custom-tooltip {
  border-radius: 0px;
  font-size: 14px;
  font-family: var(--copytext-font-family);
}

.custom-tooltip span {
  display: block;
}

.custom-tooltip span:first-child {
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
