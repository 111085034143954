<script setup lang="ts">
import { useGlobalUIStore } from '@/store/GlobalUIStore';
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

const { confirmation } = storeToRefs(useGlobalUIStore());
const showDialog = ref(false);
const dialogRef = ref();

watch(
	() => confirmation?.value,
	() => {
		showDialog.value = confirmation?.value !== undefined;
	}
);

const onConfirm = () => {
	confirmation?.value?.onConfirm();
	showDialog.value = false;
};

const onClose = () => {
	showDialog.value = false;
};

onMounted(() => {
	dialogRef.value?.addEventListener('lhConfirm', onConfirm);
	dialogRef.value?.addEventListener('lhCancel', onClose);
	dialogRef.value?.addEventListener('lhClose', onClose);
});
</script>

<template>
	<patternlib-modal
		ref="dialogRef"
		allow-close-on-backdrop="true"
		cancel-label="CANCEL"
		confirm-button-width="auto"
		confirm-button-disabled="false"
		confirm-label="CONFIRM"
		confirm-type="primary"
		height="auto"
		max-height="100vh"
		hide-cancel="false"
		hide-submit="false"
		icon-name="info-circle"
		keep-open-on-confirm="false"
		margin-top="auto"
		:modal-title="confirmation?.label"
		:show="showDialog && confirmation"
		show-icon="true"
		width="500px"
		img-position="top">
		<span slot="modal-content">
			{{ confirmation?.text }}
		</span>
	</patternlib-modal>
</template>
