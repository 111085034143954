import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { slot: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_toggle = _resolveComponent("patternlib-toggle")!
  const _component_patternlib_list_item = _resolveComponent("patternlib-list-item")!
  const _component_patternlib_list = _resolveComponent("patternlib-list")!
  const _component_DataViewSkeleton = _resolveComponent("DataViewSkeleton")!

  return (_openBlock(), _createBlock(_component_DataViewSkeleton, {
    header: _ctx.$t('admin.home.headline')
  }, {
    content: _withCtx(() => [
      _createVNode(_component_patternlib_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_patternlib_list_item, null, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('maintenanceModeView.maintenanceMode')), 1),
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_patternlib_toggle, {
                  ref: "toggleMaintenanceModeRef",
                  checked: _ctx.isMaintenanceActivated,
                  disabled: "false",
                  "label-off": _ctx.$t('general.off'),
                  "label-on": _ctx.$t('general.on'),
                  "additional-info": "",
                  "additional-info-position": "top",
                  name: "toggle",
                  status: "default",
                  "success-text": "Success",
                  "error-text": "Error",
                  "warning-text": "Warning"
                }, null, 8, ["checked", "label-off", "label-on"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["header"]))
}