<template>
  <DataViewSkeleton :header="$t('statistics.title')" @onErrorActionClick="reload">
    <template #content>
      <ApplianceSelection
        @applianceTypeSelected="onApplianceTypeSelected"
        @applianceModelSelected="onApplianceModelSelected"
        @domainSelected="onDomainSelected"
        :applianceModels="applianceModels"
      >
      </ApplianceSelection>

      <div class="pt-4">
        <patternlib-card noStates>
          <div slot="card-content" class="card--container flex h-full flex-col" style="padding: 24px">
            <span class="font-head text-h3">
              {{ !showStateCharts ? $t('statistics.connectedappliancesbymodel') : $t('statistics.settemperature') }}
            </span>

            <div
              id="chart"
              v-if="!showStateCharts && connectedAppliancesByType && connectedAppliancesByType.length > 0"
              class="pt-4"
            >
              <apexchart type="bar" :options="chartOptions" :series="series" height="500px"></apexchart>
              <patternlib-textlink
                v-if="applianceModels.length > 5"
                :style="{ textAlign: 'end', paddingBottom: 1.5 + 'rem' }"
                :label="showMore ? $t('general.showless') : $t('general.showmore')"
                @click="onShowMoreClicked"
              >
              </patternlib-textlink>

              <AvailableModes
                :applianceType="applianceType"
                :numberOfZones="noOfZones"
                :domain="selectedDomain"
              ></AvailableModes>
            </div>

            <div class="state-container pt-4" v-else>
              <div
                id="coolerChart"
                v-if="coolerZoneStates && coolerZoneStates.length > 0"
                :class="[applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg']"
              >
                <span class="ml-4 font-head text-h4">
                  {{ $t('general.coolerzone') }}
                </span>
                <apexchart
                  type="bar"
                  :options="getStateChartOptions(coolerZoneStates)"
                  :series="getStateSeries(coolerZoneStates)"
                >
                </apexchart>
              </div>
              <div
                id="freezerChart"
                v-if="freezerZoneStates && freezerZoneStates.length > 0"
                :class="[applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg']"
              >
                <span class="ml-4 font-head text-h4">
                  {{ $t('general.freezerzone') }}
                </span>
                <apexchart
                  type="bar"
                  :options="getStateChartOptions(freezerZoneStates)"
                  :series="getStateSeries(freezerZoneStates)"
                >
                </apexchart>
              </div>
              <div
                id="wineChart"
                v-if="wineZoneStates && wineZoneStates.length > 0"
                :class="[applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg']"
              >
                <span class="ml-4 font-head text-h4">
                  {{ $t('general.winezone') }}
                </span>
                <apexchart
                  type="bar"
                  :options="getStateChartOptions(wineZoneStates)"
                  :series="getStateSeries(wineZoneStates)"
                >
                </apexchart>
              </div>
              <div
                id="bioFreshChart"
                v-if="bioFreshZoneStates && bioFreshZoneStates.length > 0"
                :class="[applianceType === 5 ? 'state-chart-sm' : 'state-chart-lg']"
              >
                <span class="ml-4 font-head text-h4">
                  {{ $t('general.biofreshzone') }}
                </span>
                <apexchart
                  type="bar"
                  :options="getStateChartOptions(bioFreshZoneStates)"
                  :series="getStateSeries(bioFreshZoneStates)"
                >
                </apexchart>
              </div>

              <AvailableModes
                :applianceType="applianceType"
                :numberOfZones="noOfZones"
                :model="applianceModel"
                :domain="selectedDomain"
              ></AvailableModes>
            </div>
          </div>
        </patternlib-card>

        <div class="pt-4" v-if="appliancesPerCountry.length > 0 && appliancesWithLocation.length > 0">
          <StatisticsMapView
            :appliancesPerCountry="appliancesPerCountry"
            :appliancesWithLocation="appliancesWithLocation"
          >
          </StatisticsMapView>
        </div>
        <div v-else class="pt-4">
          <LoadingSpinner text="Loading Maps"></LoadingSpinner>
        </div>
      </div>
    </template>
  </DataViewSkeleton>
</template>

<script src="./StatisticsView.ts" lang="ts" />
<style lang="scss" src="./StatisticsView.scss" />
