import {
  ApplianceHistoryApiFactory,
  ApplianceStateApiFactory,
  HistoryApiFactory,
  AvailableIntervals,
  BaseInfo,
  BaseState,
  DeviceDto,
  DeviceFile,
  Deviceplate,
  HouseholdDto,
  NetStateDto,
  SmartDeviceApiFactory,
  SniApiFactory,
  UpnDto,
  StateBusNodes
} from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import HttpHelper from '@/helper/HttpResponseHelper';
import SerialNumberFormatter from '@/helper/SerialNumberFormatter';
import { SmartDeviceMapper } from '@/helper/SmartDeviceMapper';
import { ToastType } from '@/store/GlobalUIStore';
import { AxiosResponse } from 'axios';
import { onBeforeMount, onMounted, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export type ResponseT = {
  data: {
    plate: Ref<Deviceplate | undefined>;
    device: Ref<DeviceDto | undefined>;
    error: Ref<boolean>;
    deviceImg: Ref<string | undefined>;
    upn: Ref<string | undefined>;
    baseInfo: Ref<BaseInfo | undefined>;
    baseState: Ref<BaseState | undefined>;
    files: Ref<Array<DeviceFile>>;
    household: Ref<HouseholdDto | undefined>;
    showPageContent: Ref<boolean>;
    isAvailableAtSmartDevice: Ref<boolean>;
    netState: Ref<NetStateDto | undefined>;
    availableIntervals: Ref<AvailableIntervals | undefined>;
    stateBusNodes: Ref<Array<StateBusNodes> | undefined>;
  };
  action: {
    copyToClipboard: () => void;
    fetchData: () => Promise<void>;
  };
};

export function useSmartDevice(serialId: Ref<string | undefined>): ResponseT {
  const plate = ref<Deviceplate | undefined>();
  const device = ref<DeviceDto | undefined>();
  const error = ref(false);
  const deviceImg = ref<string>();
  const upn = ref<string>();
  const baseInfo = ref<BaseInfo>();
  const baseState = ref<BaseState>();
  const netState: Ref<NetStateDto | undefined> = ref();
  const files = ref<Array<DeviceFile>>([]);
  const stateBusNodes = ref<Array<StateBusNodes>>();
  const household = ref<HouseholdDto>();
  const showPageContent = ref<boolean>(false);
  const isAvailableAtSmartDevice = ref<boolean>(false);

  //hooks
  const { getConfig } = useHttpConfiguration();
  const { t } = useI18n();
  const route = useRoute();
  const displayToast = useToast();
  const availableIntervals: Ref<AvailableIntervals | undefined> = ref();

  const getDeviceData = async (serialId: string) => {
    const config = getConfig();
    const plateRequest = SmartDeviceApiFactory(config).getDevicePlate(serialId);

    const deviceInfoRequest = SniApiFactory(config).getDeviceInfo(serialId);
    const img = SniApiFactory(config).getDeviceImage(serialId);

    const filesRequest = SmartDeviceApiFactory(config).getDeviceFiles(serialId);

    const baseInfoRequest = ApplianceStateApiFactory(config).getBaseInfo(serialId);

    const baseStateRequest = ApplianceStateApiFactory(config).getBaseState(serialId);

    const availableIntervalRequest = ApplianceHistoryApiFactory(config).getAvailableDataIntervals(serialId);

    const stateBusNodesInfoRequest = ApplianceStateApiFactory(config).getStateBusNodesInformation(serialId);

    const [plateRes, deviceRes, filesRes, baseRes, stateRes, image, availableIntervalRes, stateBusNodesRes] =
      await Promise.all([
        plateRequest,
        deviceInfoRequest,
        filesRequest,
        baseInfoRequest,
        baseStateRequest,
        img,
        availableIntervalRequest,
        stateBusNodesInfoRequest
      ]);

    plate.value = HttpHelper.extractRequestDataWithoutError<Deviceplate>(plateRes);
    device.value = HttpHelper.extractRequestDataWithoutError<DeviceDto>(deviceRes);

    files.value = HttpHelper.extractRequestDataWithoutError<Array<DeviceFile>>(filesRes) ?? [];

    baseInfo.value = HttpHelper.extractRequestDataWithoutError<BaseInfo>(baseRes);
    baseState.value = HttpHelper.extractRequestDataWithoutError<BaseState>(stateRes);
    deviceImg.value = HttpHelper.extractRequestDataWithoutError<string>(image as AxiosResponse<string, string>);
    availableIntervals.value = HttpHelper.extractRequestDataWithoutError<AvailableIntervals>(availableIntervalRes);
    stateBusNodes.value = HttpHelper.extractRequestDataWithoutError<Array<StateBusNodes>>(stateBusNodesRes);
  };

  const getSmartDeviceData = async (serialId: string, upn: string) => {
    const config = getConfig();

    const householdReqeust = SmartDeviceApiFactory(config).getHouseholdByUpn(serialId, upn);

    const [householdResponse] = await Promise.all([householdReqeust]);

    household.value = HttpHelper.extractRequestDataWithoutError<HouseholdDto>(householdResponse);
  };

  const fetchNetState = async (serialNo: string) => {
    const config = getConfig();

    const netStateResponse = await SmartDeviceApiFactory(config).getConnectedState(serialNo);
    netState.value = HttpHelper.extractRequestDataWithoutError<NetStateDto>(netStateResponse);
  };

  const fetchData = async () => {
    const serialNr = serialId.value;
    if (!serialNr) return;
    const config = getConfig();
    error.value = false;
    showPageContent.value = false;
    try {
      setSearchHistory(serialNr);
      const res = await SmartDeviceApiFactory(config).getUpnsByDeviceSerialId(serialNr);

      fetchNetState(serialNr);

      const upnList = HttpHelper.getRequestData<Array<UpnDto>>(res);

      if (upnList.length > 0) {
        upn.value = upnList[0].upn as string;
      }

      const requests: any = [];
      requests.push(getDeviceData(serialNr));

      if (!upn.value) {
        isAvailableAtSmartDevice.value = false;
      } else {
        isAvailableAtSmartDevice.value = true;
        const deviceSmartData = getSmartDeviceData(serialNr, upn.value);
        requests.push(deviceSmartData);
      }

      await Promise.all(requests);
      mergeDeviceData();
      showPageContent.value = true;
      error.value = false;
    } catch (e) {
      let msg = t('error');
      if (e instanceof Error) {
        msg = e?.message;
      }
      error.value = true;
      displayToast(msg, ToastType.WARNING);
    }
  };

  const mergeDeviceData = () => {
    if (household.value && household.value.deviceList) {
      household.value.deviceList.sort((a, b) => {
        if (!a.nickname && !b.nickname) return 0;
        if (!a.nickname) return 1;
        if (!b.nickname) return -1;
        return a.nickname.toLowerCase() > b.nickname.toLowerCase() ? 1 : -1;
      });
    }
  };

  const initComponent = () => {
    plate.value = undefined;
    error.value = false;
    upn.value = undefined;
    baseInfo.value = undefined;
    baseState.value = undefined;
    files.value = [];
    household.value = undefined;
    isAvailableAtSmartDevice.value = false;
    netState.value = undefined;
  };

  const setSearchHistory = (serialId: string) => {
    const config = getConfig();

    HistoryApiFactory(config)
      .addProductToHistory(serialId, serialId)
      .then(data => {
        if (data.status !== 200) {
          throw new Error();
        }
      })
      .catch(error => {
        displayToast('Set search history: ' + error.message, ToastType.WARNING);
      });
  };

  onMounted(() => {
    fetchData();
  });

  const copyToClipboard = () => {
    //let deviceInfo = '';
    let softwareInfo = '';
    let householdInfo = '';

    if (baseInfo.value) {
      const type = SmartDeviceMapper.formatCmType(baseInfo.value.cmType ?? -1);
      softwareInfo = `SOFTWAREINFORMATION\n`;
      softwareInfo += `CmType: ${type}\n`;
      softwareInfo += `CmApp: ${baseInfo.value.cmApp}\n`;
      softwareInfo += `CmIdent: ${baseInfo.value.cmIdent}\n`;
      softwareInfo += `CmPruef: ${baseInfo.value.cmPruef}\n`;
      softwareInfo += `PbType: ${baseInfo.value.pbType}\n`;
      softwareInfo += `PbWhoami: ${baseInfo.value.pbWhoami}\n`;
      softwareInfo += `UiType: ${baseInfo.value.uiType}\n`;
      softwareInfo += `UiWhoami: ${baseInfo.value.uiWhoami}\n`;
      softwareInfo += `\n`;
    }

    if (household.value) {
      const upn = household.value.upnList?.join('|');
      householdInfo = `HOUSEHOLD\n`;
      householdInfo += `Upn: ${upn}\n`;
      householdInfo += `Household id: ${household.value.householdId}\n`;
      householdInfo += '\n';
    }

    let deviceConfig = `DEVICE CONFIGURATION\n`;

    files.value.forEach(file => {
      deviceConfig += `${file.fileType}: ${file.fileName}\n`;
    });

    deviceConfig += '\n';

    const timestamp = `Copied timestamp: ${new Date().toISOString()}`;

    const info =
      //deviceInfo +
      softwareInfo + householdInfo + deviceConfig + timestamp;

    navigator.clipboard.writeText(info);

    displayToast(t('copy.success_message'), ToastType.SUCCESS);
  };

  const initializeComponent = () => {
    const deviceSerialId = serialId.value as string;

    if (!deviceSerialId || !SerialNumberFormatter.isSerialNumberValid(deviceSerialId)) {
      displayToast(t('search.not_performed'), ToastType.WARNING);
      //router.push('/home');
    }
    serialId.value = deviceSerialId;
  };

  const isSmartDeviceRoute = () => {
    const isConsumerRoute = route.path.includes('/consumer/');

    const isDefaultRoute = route.path.includes('/device/');

    const isBusinessRoute = route.path.includes('/business/');

    return isConsumerRoute || isDefaultRoute || isBusinessRoute;
  };

  onBeforeMount(() => {
    initializeComponent();
  });

  watch(serialId, () => {
    initComponent();
    initializeComponent();
    fetchData();
  });

  return {
    data: {
      plate,
      error,
      device,
      deviceImg,
      upn,
      baseInfo,
      baseState,
      files,
      household,
      showPageContent,
      isAvailableAtSmartDevice,
      netState,
      availableIntervals,
      stateBusNodes
    },
    action: {
      copyToClipboard,
      fetchData
    }
  };
}
