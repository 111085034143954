/* tslint:disable */
/* eslint-disable */
/**
 * AppAnDa backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiKey } from '../model';
/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} targetApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addApiKey: async (targetApi: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'targetApi' is not null or undefined
      assertParamExists('addApiKey', 'targetApi', targetApi);
      const localVarPath = `/appanda/api/v2/Security/AddApiKey/{targetApi}`.replace(
        `{${'targetApi'}}`,
        encodeURIComponent(String(targetApi))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiKeyById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteApiKeyById', 'id', id);
      const localVarPath = `/appanda/api/v2/Security/apiKeys/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllApiKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appanda/api/v2/Security/apiKeys/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersApiKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/appanda/api/v2/Security/apiKeys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} targetApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addApiKey(
      targetApi: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addApiKey(targetApi, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApiKeyById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiKeyById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllApiKeys(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApiKeys(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersApiKeys(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiKeys(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SecurityApiFp(configuration);
  return {
    /**
     *
     * @param {string} targetApi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addApiKey(targetApi: string, options?: any): AxiosPromise<void> {
      return localVarFp.addApiKey(targetApi, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiKeyById(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteApiKeyById(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllApiKeys(options?: any): AxiosPromise<Array<ApiKey>> {
      return localVarFp.getAllApiKeys(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersApiKeys(options?: any): AxiosPromise<Array<ApiKey>> {
      return localVarFp.getUsersApiKeys(options).then(request => request(axios, basePath));
    }
  };
};

/**
 * SecurityApi - interface
 * @export
 * @interface SecurityApi
 */
export interface SecurityApiInterface {
  /**
   *
   * @param {string} targetApi
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApiInterface
   */
  addApiKey(targetApi: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApiInterface
   */
  deleteApiKeyById(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApiInterface
   */
  getAllApiKeys(options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApiInterface
   */
  getUsersApiKeys(options?: AxiosRequestConfig): AxiosPromise<Array<ApiKey>>;
}

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI implements SecurityApiInterface {
  /**
   *
   * @param {string} targetApi
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApi
   */
  public addApiKey(targetApi: string, options?: AxiosRequestConfig) {
    return SecurityApiFp(this.configuration)
      .addApiKey(targetApi, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApi
   */
  public deleteApiKeyById(id: string, options?: AxiosRequestConfig) {
    return SecurityApiFp(this.configuration)
      .deleteApiKeyById(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApi
   */
  public getAllApiKeys(options?: AxiosRequestConfig) {
    return SecurityApiFp(this.configuration)
      .getAllApiKeys(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecurityApi
   */
  public getUsersApiKeys(options?: AxiosRequestConfig) {
    return SecurityApiFp(this.configuration)
      .getUsersApiKeys(options)
      .then(request => request(this.axios, this.basePath));
  }
}
