<script lang="ts" setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';
defineProps({
	useReload: { type: Boolean, default: false },
	centered: { type: Boolean, default: false },
	text: { type: String },
	icon: { type: String, default: 'triangle-with-exclamation-mark' },
});
const emits = defineEmits(['click']);
const pillRef = ref();

onMounted(() => {
	pillRef.value?.addEventListener('click', () => {
		emits('click');
	});
});
</script>

<template>
	<div
		data-cy="errorPlaceholder"
		:class="`flex flex-col justify-center  ${
			centered ? 'items-center' : 'items-start'
		} `">
		<div class="flex flex-row items-center justify-center">
			<patternlib-icon
				class="mr-2"
				bold="false"
				display="block"
				color="var(--color-black)"
				:icon-name="icon"
				size="30px"></patternlib-icon>

			<span class="break-words">{{
				text ? text : $t('general.no_information_available')
			}}</span>
		</div>
		<span class="ml-2 mt-2" v-if="useReload">
			<patternlib-pill
				ref="pillRef"
				data-cy="reloadPill"
				pill-icon="filter-reset"
				color="var(--color-ruby-dark)"
				label="Reload"
				error-status></patternlib-pill>
		</span>
	</div>
</template>
