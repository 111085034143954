<template>
	<DataViewSkeleton
		:header="$t('privacyView.privacyHeader')"
		:isLoading="false"
		:error="{ isError: !filePath }">
		<template #content>
      <div
          class="mt-2 flex max-h-[70vh] w-[1080px] flex-row overflow-y-scroll border-2 border-solid border-gray-700">
				<vue-pdf-embed :source="filePath" width="1000" />
			</div>
		</template>
	</DataViewSkeleton>
</template>

<script lang="ts" src="./PrivacyView.ts" />

