import { BaseInfo, BaseState } from '@/api';
import { EntryType, KeyValueViewConfig, PillStatusEnum } from '@/components/basic/KeyValueView/KeyValueView';
import { ModalSettings } from '@/views/index/smartdevice/components/ModalSettings';
import { ModalTypeEnum } from '@/views/index/smartdevice/components/ModalTypeEnum';
import { TimePeriodHelper } from '@/views/index/smartdevice/components/TimePeriodHelper';

export class BaseStateHelper {
  private static stateApplicationMapping: Record<number, string> = {
    0: 'APPLICATION_INIT_NO',
    1: 'APPLICATION_INIT_HW',
    2: 'APPLICATION_WAIT_FOR_SYSTEM_INIT',
    3: 'APPLICATION_INIT_MAINMODES',
    4: 'APPLICATION_RUNNING_MAINMODES',
    5: 'APPLICATION_STOPPED',
    6: 'APPLICATION_INIT_MAINMODES_POWERFAILURE',
    7: 'APPLICATION_FACTORY_RESET_NEEDED',
    8: 'APPLICATION_ROBUSTNESS_RESET',
    252: 'APPLICATION_ERROR_NV',
    253: 'APPLICATION_ERROR_PERS',
    254: 'APPLICATION_ERROR_HW',
    255: 'APPLICATION_ERROR_GENERIC'
  };

  private static stateMainModeMapping: Record<number, string> = {
    0: 'MAINMODE_COOLING',
    1: 'MAINMODE_DEMO',
    2: 'MAINMODE_REMOTE',
    3: 'MAINMODE_RFU',
    4: 'MAINMODE_EOL_TEST',
    5: 'MAINMODE_EMC_TEST',
    6: 'MAINMODE_SERVICE',
    7: 'MAINMODE_BATTERY',
    8: 'MAINMODE_DEMO_REMOTE'
  };

  private static getCompressorModalData(baseState: BaseState, baseInfo: BaseInfo, t: any) {
    const data = {
      compressorType: baseState.compressorType ?? 0,
      compressorMin: baseState.compressorMin ?? 0,
      compressorMax: baseState.compressorMax ?? 0,
      compressorSteps: baseState.compressorSteps ?? '-',
      compressorSwitchOnDelay: baseState.compressorSwitchOnDelay ?? 0, // in minutes
      compressorTotalOnTime: baseState.compressorTotalOnTime ?? 0 // in seconds
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        compressorType: {
          description: { text: t('device.base.compressor_type') },
          type: EntryType.PILL,
          isHidden: baseInfo.capsCompressor !== 1,
          pillOptions: {
            0: { type: PillStatusEnum.DEFAULT, value: 'GQ013' },
            1: { type: PillStatusEnum.DEFAULT, value: 'GQ011' }
          }
        },
        compressorMin: {
          description: { text: t('device.base.compressor_min') },
          type: EntryType.TEXT,
          isHidden: baseInfo.capsCompressor !== 1
        },
        compressorMax: {
          description: { text: t('device.base.compressor_max') },
          type: EntryType.TEXT,
          isHidden: baseInfo.capsCompressor !== 1
        },
        compressorSteps: {
          description: { text: t('device.base.compressor_steps') },
          type: EntryType.TEXT,
          isHidden: baseInfo.capsCompressor !== 1
        },
        compressorSwitchOnDelay: {
          description: {
            text: t('device.base.compressor_switch_on_delay')
          },
          type: EntryType.COMPUTED,
          renderAsHTML: true,
          isHidden: baseInfo.capsCompressor !== 1,
          computeCB: (value: number) => {
            // Time in minutes
            return TimePeriodHelper.mapTime(value * 60);
          }
        },
        compressorTotalOnTime: {
          description: {
            text: t('device.base.compressor_total_on_time')
          },
          type: EntryType.COMPUTED,
          isHidden: baseInfo.capsCompressor !== 1,
          renderAsHTML: true,
          computeCB: (value: number) => {
            // Time in seconds
            return TimePeriodHelper.mapTime(value);
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getErrorGeneralModalData(
    errorGeneral: number | undefined | null,
    errorLogCount: number | undefined | null,
    errorLogTime: number | undefined | null,
    t: any
  ) {
    const data = {
      errorGeneral: errorGeneral === 0 ? t('general.no') : t('general.yes'),
      errorLogCount: errorLogCount ?? 0,
      errorLogTime: errorLogTime ? errorLogTime : 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        errorGeneral: {
          description: { text: 'Error General' },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        errorLogCount: {
          description: { text: 'Error log count' },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        errorLogTime: {
          description: { text: 'Error log time' },
          type: EntryType.COMPUTED,
          isHidden: false,
          hideIfNotAvailable: true,
          renderAsHTML: true,
          computeCB: (value: number) => {
            // Time in seconds
            return TimePeriodHelper.mapTime(value);
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  public static getModalSettings(baseState: BaseState, baseInfo: BaseInfo, modalType: ModalTypeEnum, t: any) {
    let modalData = {};
    let modalConfig;
    let modalTitle = '';

    switch (modalType) {
      case ModalTypeEnum.COMPRESSOR: {
        const modalConfiguration = this.getCompressorModalData(baseState, baseInfo, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.base.compressor_settings');
        break;
      }
      case ModalTypeEnum.AMBIENTSENSOR: {
        const modalConfiguration = this.getErrorGeneralModalData(
          baseState.ambientSensorErrorGeneral,
          baseState.ambientSensorErrLogCount,
          baseState.ambientSensorErrLogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.base.ambient_sensor_error_general');
        break;
      }
      case ModalTypeEnum.COMPRESSORERROR: {
        const modalConfiguration = this.getErrorGeneralModalData(
          baseState.compressorErrorGeneral,
          baseState.compressorErrLogCount,
          baseState.compressorErrLogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.base.compressor_error_general');
        break;
      }
      case ModalTypeEnum.CONDENSERERROR: {
        const modalConfiguration = this.getErrorGeneralModalData(
          baseState.condenserFanErrorGeneral,
          baseState.condenserFanErrLogCount,
          baseState.condenserFanErrLogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.base.condenser_error_general');
        break;
      }
    }

    const modalSettings: ModalSettings = {
      data: modalData,
      config: modalConfig,
      icon: t('refrigeration-freezing'),
      title: modalTitle,
      isModalOpen: true,
      type: modalType,
      currentModal: ''
    };

    return modalSettings;
  }

  public static getAdditionalDescriptionText(shortText: string | undefined | null) {
    if (shortText) {
      return `(${shortText})`;
    }
    return '';
  }

  public static getStateAndApplianceMode(state: number | undefined | null) {
    if (state) {
      const binary = state.toString(2).padStart(16, '0'); // Convert decimal to 16-bit binary string
      const binaryPart1 = binary.slice(8); // Select the first 8 bits
      const binaryPart2 = binary.slice(0, 8); // Select the last 8 bits

      const stateApplication = parseInt(binaryPart1, 2); // Convert the first 8 bits back to decimal
      const stateMainMode = parseInt(binaryPart2, 2); // Convert the last 8 bits back to decimal

      const stateApplicationText = (this.stateApplicationMapping[stateApplication] as string) || 'Undefined';
      const stateMainModeText = this.stateMainModeMapping[stateMainMode] || 'Undefined';

      return { applicationState: stateApplicationText, applianceMainMode: stateMainModeText };
    }

    return { applicationState: '-', applianceMainMode: '-' };
  }
}
