export default class DateFormatter {
  public static formatToPatternLibDateString(dateStr: string, fullDay = false): string {
    const date = new Date(dateStr);

    const hours = !fullDay ? '00:00' : '23:59';

    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}`;
  }

  public static formatToDayStringToDisplay(dateStr?: string): string {
    if (!dateStr) return '-';

    const date = new Date(dateStr);

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }

  public static substractDays(dateStr: string, days: number) {
    const date = new Date(dateStr);

    const oneDayInSeconds = 86400;

    const unixTimeInSeconds = Math.floor(date.getTime() / 1000);

    const result = unixTimeInSeconds - oneDayInSeconds * days;

    return new Date(result * 1000);
  }
}
