<template>
	<div>
		<patternlib-headline
			separator="bottom"
			class="mb-header block"
			:headline="$t('device.configuration')"
			heading-tag="h3"></patternlib-headline>
		<div
			class="flex-container"
			v-if="deviceFiles && deviceFiles.length > 0">
			<div class="flex-item big">
				<patternlib-table
					implicit-ids
					no-grid-lines
					:columns="[
						{ label: $t('file.id'), align: 'left' },
						{ label: $t('file.name'), align: 'left' },
						{ label: $t('file.type'), align: 'left' },
						{ label: $t('file.last_update'), align: 'left' },
						{ label: 'Action', align: 'left' },
					]">
					<!-- row 1 (index 0) -->

					<template
						v-for="(file, index) in deviceFiles"
						:key="file.fileId ?? index">
						<patternlib-table-cell
							:value="file.fileId"></patternlib-table-cell>
						<patternlib-table-cell
							:value="file.fileName"></patternlib-table-cell>
						<patternlib-table-cell
							:value="file.fileType"></patternlib-table-cell>
						<patternlib-table-cell
							:value="file.lastUpdate"></patternlib-table-cell>
						<patternlib-table-cell
							:slot="`${index}-4`"
							:value="`row${index + 1}4`">
							<div
								class="mx-3 flex flex-row gap-3 text-gray-600"
								v-if="file.fileUrl"
								@click="downloadFile(index)">
								<div class="flex">
									<patternlib-icon
										size="32px"
										icon-name="download"></patternlib-icon>
								</div>
								<a
									:href="file.fileUrl"
									target="_blank"
									class="flex">
									<patternlib-icon
										size="32px"
										icon-name="visible"></patternlib-icon
								></a>
							</div>
						</patternlib-table-cell>
					</template>
				</patternlib-table>
			</div>
		</div>

		<TextPlaceHolder v-else />
	</div>
</template>

<script src="./DeviceConfigurationComponent.ts" lang="ts"></script>
<style lang="scss" src="./DeviceConfigurationComponent.scss"></style>
