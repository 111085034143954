import RegisteredDevicesOverviewComponent from '@/views/index/smartdevice/components/RegisteredDevicesOverviewComponent.vue';
import { DeviceKindEnum } from '@/views/index/smartdevice/DeviceKindEnum';
import { defineComponent, onBeforeMount, Ref, ref, watch, onMounted } from 'vue';
import SearchComponent from 'src/components/search/SearchComponent.vue';
import { useI18n } from 'vue-i18n';
import BaseStateComponent from '../components/base-state/BaseStateComponent.vue';
import ZoneStateComponent from '../components/zone-state/ZoneStateComponent.vue';
import SoftwareInformation from '../components/SoftwareInformationComponent.vue';
import DeviceConfiguration from '../components/device-configuration/DeviceConfigurationComponent.vue';
import DeviceData from '../components/DeviceData.vue';
import SmartDeviceComponent from '../components/SmartDeviceComponent.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import { SmartDeviceMapper } from '@/helper/SmartDeviceMapper';
import TheButton from '@/components/basic/Button/TheButton.vue';
import ToggleButton from '@/components/basic/ToggleButton/ToggleButton.vue';
import OverviewChart from '../components/charts/HistoryChartsOverview.vue';
import { useSmartDevice } from '../composeable/useSmartDevice';
import { ApplianceDataApiFactory, ApplianceHistoryApiFactory, ZoneState } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import { ToastType } from '@/store/GlobalUIStore';

export default defineComponent({
  props: {
    serialNumber: { type: String, default: '' },
    deviceType: { type: String, default: '' }
  },
  components: {
    RegisteredDevicesOverviewComponent,
    SearchComponent,
    BaseStateComponent,
    ZoneStateComponent,
    SoftwareInformation,
    DeviceData,
    LoadingSpinner,
    SmartDeviceComponent,
    DeviceConfiguration,
    TheButton,
    ToggleButton,
    TextPlaceHolder,
    OverviewChart
  },

  setup(props) {
    //state
    const { getConfig } = useHttpConfiguration();
    const config = getConfig();
    const serialId: Ref<string> = ref('');
    const deviceKind: Ref<string> = ref('');
    const displayToast = useToast();
    const isDataAvailable: Ref<boolean> = ref(false);
    const { t } = useI18n();

    serialId.value = props.serialNumber;
    deviceKind.value = props.deviceType;

    const { action, data } = useSmartDevice(serialId);

    const zoneStates: Ref<ZoneState[] | undefined> = ref();

    onBeforeMount(() => {
      ApplianceHistoryApiFactory(config).initializeWarehouse();
    });

    const handleNavigation = (deviceKindType: string) => {
      deviceKind.value = deviceKindType;
    };

    const handleZoneState = (states: ZoneState[]) => {
      if (states) {
        zoneStates.value = states;
      }
    };

    onMounted(() => {
      window.scrollTo(0, 0);
      ApplianceDataApiFactory(config)
        .isDataAvailable(serialId.value)
        .then(response => {
          isDataAvailable.value = response.data.isDataAvailable as boolean;
        })
        .catch(error => {
          displayToast('Data of appliance is not available. ' + error.message, ToastType.WARNING);
        });
    });

    watch(
      () => props.serialNumber,
      () => {
        serialId.value = props.serialNumber;
      }
    );

    watch(
      () => props.deviceType,
      () => {
        deviceKind.value = props.deviceType;
      }
    );

    return {
      serialId,
      deviceKind,
      DeviceKindEnum,
      ...data,
      ...action,
      t,
      handleNavigation,
      handleZoneState,
      zoneStates,
      getValueClass: SmartDeviceMapper.getValueClass,
      isDataAvailable
    };
  }
});
