import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"grid","grid-template-columns":"12fr 0fr","justify-items":"flex-start"} }
const _hoisted_2 = { style: {"font-family":"'LiebherrText-Regular', sans-serif"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_checkbox = _resolveComponent("patternlib-checkbox")!
  const _component_patternlib_list_item = _resolveComponent("patternlib-list-item")!
  const _component_patternlib_accordion_item = _resolveComponent("patternlib-accordion-item")!
  const _component_patternlib_accordion = _resolveComponent("patternlib-accordion")!

  return (_openBlock(), _createBlock(_component_patternlib_accordion, null, {
    default: _withCtx(() => [
      _createVNode(_component_patternlib_accordion_item, { label: _ctx.label }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings, (setting) => {
            return (_openBlock(), _createBlock(_component_patternlib_list_item, {
              key: setting.key,
              itemHeight: "36px",
              width: "100%"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(setting.key), 1),
                  _createVNode(_component_patternlib_checkbox, {
                    name: `${_ctx.identifier}-${setting.key}`,
                    checked: setting.value,
                    ref_for: true,
                    ref: "checkBoxRef",
                    label: "",
                    status: "default",
                    required: "false",
                    disabled: "false",
                    indeterminate: "false"
                  }, null, 8, ["name", "checked"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }))
}