<template>
  <div
    v-if="chartsData"
    data-demo="chartLayout"
    :class="`gap-y my-5 grid grid-cols-1 gap-10 ${useOverallView && 'xl:grid-cols-2'}`"
  >
    <div
      v-for="(data, index) of Object.values(chartsData.datasets)"
      :key="index"
      :class="`shadow-xl h-full border border-solid border-slate-300 px-2 py-3 ${
        data && Object.keys(chartsData.datasets)[index] === ApplianceHistoryKeyEnum.Base ? 'order-last' : ''
      } ${!data ? 'hidden' : 'h-full'}`"
    >
      <div v-if="data" class="h-full" ref="chartElementRef">
        <div class="flex justify-between">
          <patternlib-headline
            separator="bottom"
            :headline="`${Object.keys(chartsData.datasets)[index].toUpperCase()}`"
            heading-tag="h2"
          >
          </patternlib-headline>

          <HistoryChartBasicInfo />
        </div>
        <div v-for="key of Object.keys(data)" :key="key" style="margin-bottom: 3rem">
          <div v-if="key === 'timeline'">
            <HistoryChart
              class="flex-1"
              type="timeline"
              header="Timeline"
              :zoom="currentZoom"
              :data="data[key]"
              :isSynchronized="chartSettings.synchronizedCharts"
              :intersectionLine="intersectionLine"
              @updateIntersectionLine="updateIntersectionLine"
              @resetZoom="resetZoom"
              :option="chartsData.options.timeline"
            />
          </div>

          <div v-else>
            <div v-if="!chartSettings.showMergedCharts">
              <HistoryChart
                v-if="showChart(data[key])"
                class="flex-1"
                type="line"
                :header="key.toUpperCase()"
                :zoom="currentZoom"
                :data="data[key]"
                :intersectionLine="intersectionLine"
                @updateIntersectionLine="updateIntersectionLine"
                @resetZoom="resetZoom"
                :option="chartsData.options.lineChart"
                :isSynchronized="chartSettings.synchronizedCharts"
              />
            </div>
            <div v-else>
              <HistoryChart
                class="flex-1"
                type="line"
                header="All Charts"
                :zoom="currentZoom"
                :data="data.allCharts"
                :intersectionLine="intersectionLine"
                :option="chartsData.options.lineChart"
                @updateIntersectionLine="updateIntersectionLine"
                @resetZoom="resetZoom"
                :isSynchronized="chartSettings.synchronizedCharts"
              />
            </div>
          </div>
        </div>

        <patternlib-accordion style="margin-top: 24px">
          <patternlib-accordion-item :label="t('historyCharts.last_history_messages')">
            <div v-if="Object.keys(chartsData.datasets)[index] === ApplianceHistoryKeyEnum.Base">
              <div v-for="(entry, index) of baseHistoryEntries" :key="index">
                <div class="history-entry">
                  <p>{{ formatPath(entry.path) }}</p>
                  <p>{{ formatDateTime(entry.changedAtUtc) }}</p>
                  <hr />
                </div>
              </div>
            </div>

            <div
              v-for="(zoneHistory, keyIndex) of zoneHistoryEntries[Object.keys(chartsData.datasets)[index]]"
              class="history-entry"
              :key="keyIndex"
            >
              <p>{{ formatPath(zoneHistory.path) }}</p>
              <p>{{ formatDateTime(zoneHistory.changedAtUtc) }}</p>
              <hr />
            </div>
          </patternlib-accordion-item>
        </patternlib-accordion>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./HistoryCharts.ts"></script>
<style lang="scss" src="./HistoryCharts.scss"></style>
