import { useLoginStore } from 'src/store/LoginStore';
import { RouteRecordRaw, NavigationGuardNext } from 'vue-router';

/* GENERAL */
import LoginView from '@/views/login/LoginView.vue';
import LoginResult from '@/views/loginResult/LoginResult.vue';
import LogoutView from '@/views/logout/LogoutView.vue';
import PermissionDenied from '@/views/shared/PermissionDenied.vue';
import { createRouter, createWebHistory } from 'vue-router';

/* INDEX */
import HomeView from '@/views/index/home/HomeView.vue';
import DefaultDeviceView from '@/views/index/smartdevice/defaultView/DefaultDeviceView.vue';
import HistoryView from '@/views/index/history/HistoryView.vue';
import ProfileView from '@/views/index/profile/ProfileView.vue';
import Bn010View from '@/views/index/bn010/Bn010View.vue';
import ServicesView from '@/views/index/services/ServicesView.vue';
import ChartDemoPage from '@/views/index/demo/ChartDemoPage.vue';
import TicketView from '@/views/index/ticket/TicketView.vue';
import TicketDetailView from '@/views/index/ticket/CreateTicket.vue';
import StatisticsView from '@/views/index/statistics/StatisticsView.vue';
import FailuresView from '@/views/index/failures/FailuresView.vue';

/* ADMIN */
import MessagesView from '@/views/admin/messages/MessagesView.vue';
import AdminHomeView from '@/views/admin/home/AdminHomeView.vue';
import AdminTicketOverview from '@/views/admin/tickets/AdminTicketOverview.vue';
import ApiKeysOverview from '@/views/admin/apiKeys/ApiKeysOverview.vue';

/* SHARED */
import NotFoundView from '@/views/shared/NotFoundView.vue';
import MaintenanceMode from '@/views/shared/MaintenanceMode.vue';
import AppConstants from '@/constants/ApplicationConstants';
import ImprintView from '@/views/shared/ImprintView.vue';
import PrivacyView from '@/views/shared/PrivacyView.vue';
import { RouteHelper } from './RouteHelper';

export type CustomMetaConfig = {
  redirect_to?: boolean;
  isAdminRoute: boolean;
  isDevRoute?: boolean;
  isNavBarRoute: boolean;
  label?: string;
  roles?: Array<string>;
};

export const routes: Array<
  RouteRecordRaw & {
    meta?: CustomMetaConfig;
  }
> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: true,
      label: 'Home',
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/services',
    name: 'ServicesView',
    component: ServicesView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: true,
      label: 'Services',
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/device/:serialNumber/:deviceType',
    name: 'DefaultDeviceView',
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: false,
      label: '',
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    props: true,
    component: DefaultDeviceView,
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/history',
    name: 'HistoryView',

    component: HistoryView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: false,
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/statistics',
    name: 'StatisticsView',
    component: StatisticsView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: true,
      label: 'statistics.title',
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/failures',
    name: 'FailuresView',
    component: FailuresView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: true,
      label: 'failures.title',
      roles: [AppConstants.ROLES.USER_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: false
    },
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/bn010',
    name: 'Bn010View',
    component: Bn010View,
    meta: {
      redirect_to: true,
      isAdminRoute: false,
      isNavBarRoute: false,
      isDevRoute: true,
      developmentFeature: true,
      label: 'BN010',
      roles: [AppConstants.ROLES.USER_ROLE, AppConstants.ROLES.DEV_ROLE, AppConstants.ROLES.DEV_ROLE]
    },
    beforeEnter: RouteHelper.authenticationGuard
  },

  {
    path: '/admin/home',
    name: 'AdminHomeView',
    meta: {
      isAdminRoute: true,
      isDevRoute: false,
      isNavBarRoute: true,
      label: 'Admin',
      roles: [AppConstants.ROLES.USER_ROLE, AppConstants.ROLES.ADMIN_ROLE, AppConstants.ROLES.DEV_ROLE]
    },
    component: AdminHomeView,
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      isAdminRoute: false,
      isDevRoute: false,
      isNavBarRoute: false
    },
    component: LoginView,
    beforeEnter: async (to, from, next: NavigationGuardNext): Promise<void> => {
      const loginStore = useLoginStore();
      try {
        const nextRoutePath = to.query.path?.toString() ?? '/';

        if (loginStore.isAuthenticated) {
          next(nextRoutePath);
        } else {
          await loginStore
            .checkStoredLogin()
            .then(() => {
              // accept the route

              next(nextRoutePath);
              return;
            })
            .catch(() => {
              // authentication was not possible, redirect to login page

              next();
              return;
            });
        }
      } catch (error) {
        next();
        return;
      }
    }
  },
  {
    path: '/login-callback',
    name: 'LoginResult',
    component: LoginResult,
    meta: {
      isAdminRoute: false,
      isDevRoute: false,
      isNavBarRoute: false
    }
    // Behaviour is implemented inside the component
    // ==> No before enter needed
  },

  {
    path: '/logout',
    name: 'LogoutView',
    component: LogoutView,
    meta: {
      isAdminRoute: false,
      isDevRoute: false,
      isNavBarRoute: false
    },
    // eslint-disable-next-line
    beforeEnter: (to, from, next: NavigationGuardNext): void => {
      //alert('Enter /login-callback');

      const loginStore = useLoginStore();

      loginStore.logout();
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: PermissionDenied,
    meta: {
      isAdminRoute: false,
      isDevRoute: false,
      isNavBarRoute: false
    }
  },
  {
    path: '/maintenance-mode',
    name: 'MaintenanceMode',
    component: MaintenanceMode
  },
  {
    path: '/imprint',
    name: 'ImprintView',
    component: ImprintView,
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/privacy',
    name: 'PrivacyView',
    component: PrivacyView,
    beforeEnter: RouteHelper.authenticationGuard
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundView',
    component: NotFoundView
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
});

// save last visited route in cookie for inital redirection
router.afterEach(to => {
  const { meta, path } = to;
  if (meta.redirect_to) {
    document.cookie = `redirect_to=${path}`;
  }
});
