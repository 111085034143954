<template>
  <patternlib-notification-flyout height="300" width="500">
    <patternlib-badge slot="icon" ref="badgeRef" :value="notificationCount"></patternlib-badge>

    <div slot="flyout-headline">{{ t('notifications.headline') }}</div>
    <div slot="flyout-counter">({{ notificationCount }})</div>

    <div slot="flyout-items">
      <patternlib-notification-item
        @click="openAssociatedNotificationView(notification.uiMapping ?? '')"
        :is-important="notification.type !== 0"
        :viewed="false"
        v-for="(notification, index) of notifications.notifications"
        :key="notification.id"
      >
        <div slot="icon-start">
          <patternlib-icon :icon-name="notification.type === 0 ? 'envelope' : 'warning'" size="24px" />
        </div>
        <div slot="headline">{{ notification.title }}</div>
        <div slot="info">
          <span class="font-text-bold">{{ DateFormatter.formatToDayStringToDisplay(notification.createdAtUtc) }}</span>
          <p>{{ notification.message }}</p>
        </div>
        <div slot="icon-end" class="cursor-pointer">
          <patternlib-icon
            @click="(e: any) => {
									deleteNotification(index);
									e.stopPropagation()
								}
						"
            class="cursor-pointer"
            icon-name="bin"
            size="24px"
            ref="deleteIcons"
          />
        </div>
      </patternlib-notification-item>
    </div>
    <div slot="flyout-bottom" v-if="notificationCount as number > 0">
      <patternlib-textlink
        @click="deleteAllNotifications"
        ref="deleteAllBtn"
        :label="t('notifications.clearAllBtnText')"
        size="big"
        icon-position="left"
        bold="true"
        uppercase="true"
      >
        <patternlib-icon icon-name="bin" size="24px" slot="icon" />
      </patternlib-textlink>
    </div>
  </patternlib-notification-flyout>
</template>

<script lang="ts" setup>
import { NotificationApiFactory } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { router } from '@/router';
import { useLoginStore } from '@/store/LoginStore';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import DateFormatter from '@/helper/DateFormatter';
import { useI18n } from 'vue-i18n';

const loginStore = useLoginStore();
const { notifications } = storeToRefs(useLoginStore());
const { getConfig } = useHttpConfiguration();
const deleteIcons = ref();
const deleteAllBtn = ref();
const { t } = useI18n();

const notificationCount = computed(() => {
  const notificationCount = notifications.value.notifications.length;

  if (notificationCount > 99) {
    return '99+';
  } else {
    return notificationCount;
  }
});

const deleteNotification = (index: number) => {
  const notificationsCopy = [...notifications.value.notifications];

  const id = notificationsCopy[index]?.id;
  if (!id) return;

  NotificationApiFactory(getConfig()).deleteNotification(id);

  notificationsCopy.splice(index, 1);

  loginStore.updateNotifications(notificationsCopy);
};

const openAssociatedNotificationView = (uiMapping: string) => {
  const routes: Record<string, string> = {
    '#ticketResponse': '/tickets',
    '#ticketRequest': '/admin/tickets',
    '#apiKey': '/profile'
  };

  if (uiMapping in routes) {
    router.push(routes[uiMapping]);
  }
};

const deleteAllNotifications = () => {
  NotificationApiFactory(getConfig()).deleteUserNotifications();
  loginStore.updateNotifications([]);
};
</script>
