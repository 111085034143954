export default class SerialNumberFormater {
  public static isSerialNumberValid(input: string): boolean {
    const serialnumberPattern = /\d{2}.\d{3}.\d{3}.\d/g;
    const serialnumberPattern2 = /\d{9}/g;

    if (!input) return false;

    const serialId = input.trim();
    if (!serialnumberPattern.test(serialId) && !serialnumberPattern2.test(serialId)) {
      return false;
    }

    return this.checkInternalSerialNumber(serialId);
  }

  private static checkInternalSerialNumber(input: string): boolean {
    const serialId = input.replaceAll('.', '');

    if (serialId.length != 9) return false;

    const n: any = [];

    for (let i = 0; i < serialId.length; i++) {
      n.push(parseInt(serialId[i]));
    }

    const checkSum1 = n[0] + n[2] + n[4] + n[6];
    const checkSum2 = n[1] + n[3] + n[5] + n[7];
    const s1 = checkSum1 + 3 * checkSum2;
    const checkSum3 = 10 - (s1 % 10);
    const checkDigit = checkSum3 === 10 ? 0 : checkSum3;
    return n[8] === checkDigit;
  }

  public static format2SerialNumber(input: string) {
    const formattedString = input.replaceAll('.', '');

    return formattedString.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/g, '$1.$2.$3.$4');
  }
}
