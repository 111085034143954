export default class AppConstants {
	public static readonly NOTIFICATION_FETCH_INTERVAL = 60 * 1000;
	static readonly ROLES = class {
		static readonly ADMIN_ROLE =
			process.env.VUE_APP_USER_ADMIN_ACCESS_ROLE ?? '-';
		static readonly USER_ROLE = process.env.VUE_APP_USER_ACCESS_ROLE ?? '-';
		static readonly DEV_ROLE =
			process.env.VUE_APP_USER_DEV_ACCESS_ROLE ?? '-';
	};

	static readonly TICKET = class {
		static readonly TYPES = [{ label: 'HistoryApiKey access', value: 0 }];
	};
}
