import { i18n } from '@/plugins/i18n';
import Shepherd from 'shepherd.js';

export default class DemoTour {
	private tour: Shepherd.Tour;

	constructor() {
		this.tour = new Shepherd.Tour({
			useModalOverlay: true,
			defaultStepOptions: {
				cancelIcon: {
					enabled: true,
				},
				classes: 'customShepherdContainer',
				scrollTo: { behavior: 'smooth', block: 'center' },
			},
		});
	}

	public startTour() {
		this.initTour();
		this.tour.start();
	}

	public stopTour() {
		this.tour.cancel();
	}

	public initTour() {
		const config = this.getFirstStep();
		const secondStep = this.getSecondStep();
		const layoutStep = this.getLayoutStep();
		const helpStep = this.getHelpStep();
		const actionStep = this.getActionButtonStep();
		const structureStep = this.getStructureStep();
		this.tour.addSteps([
			config,
			secondStep,
			layoutStep,
			helpStep,
			actionStep,
			structureStep,
		]);
	}

	getFirstStep() {
		return {
			arrow: true,
			title: i18n.global.t('chartDemo.settingsStep.title'),
			text: i18n.global.t('chartDemo.settingsStep.text'),
			attachTo: {
				element: '[data-demo="settingsBtn"]',
				on: 'right-start',
			},
			buttons: [
				{
					action: () => {
						return this.tour.cancel();
					},
					classes: 'shephardBtn',
					text: i18n.global.t('chartDemo.cancel'),
				},
				{
					action: () => {
						return this.tour.next();
					},
					classes: 'shephardBtn',
					text: i18n.global.t('chartDemo.next'),
				},
			],
			id: 'creating',
		} as const;
	}

	getSecondStep() {
		return {
			arrow: true,
			title: i18n.global.t('chartDemo.datepicker.title'),
			text: i18n.global.t('chartDemo.datepicker.text'),
			attachTo: {
				element: '[data-demo="datepicker"]',
				on: 'right-start',
			},
			buttons: this.getButtons(),
			id: 'creating',
		} as const;
	}

	getButtons(lastStep = false) {
		return [
			{
				action: () => {
					return this.tour.back();
				},
				classes: 'shephardBtn',
				text: i18n.global.t('chartDemo.back'),
			},
			{
				action: () => {
					return this.tour.next();
				},
				classes: 'shephardBtn',
				text: lastStep
					? i18n.global.t('chartDemo.complete')
					: i18n.global.t('chartDemo.next'),
			},
		];
	}

	getLayoutStep() {
		const singleViewElement = '[data-demo="singleViewDropdown"]';

		const isOverallView =
			document.querySelectorAll(singleViewElement).length == 0;

		const title = !isOverallView
			? i18n.global.t('chartDemo.singleViewStep.title')
			: i18n.global.t('chartDemo.overallViewStep.title');
		const text = !isOverallView
			? i18n.global.t('chartDemo.singleViewStep.text')
			: i18n.global.t('chartDemo.overallViewStep.text');

		return {
			arrow: true,
			title: title,
			text: text,
			attachTo: {
				element: '[data-demo="chartLayout"]',
				on: 'top-start',
			},
			buttons: this.getButtons(),
			id: 'creating',
		} as const;
	}

	getHelpStep() {
		const element = '[data-demo="historyChartInfo"]';

		const title = i18n.global.t('chartDemo.helpStep.title');

		const text = i18n.global.t('chartDemo.helpStep.text');

		return {
			arrow: true,
			title: title,
			text: text,
			attachTo: {
				element: element,
				on: 'top-start',
			},
			buttons: this.getButtons(),
			id: 'creating',
		} as const;
	}

	getStructureStep() {
		const element = '[data-demo="historyChart"]';

		const title = i18n.global.t('chartDemo.structureStep.title');

		const text = i18n.global.t('chartDemo.structureStep.text');

		return {
			arrow: true,
			title: title,
			text: text,
			attachTo: {
				element: element,
				on: 'top-start',
			},
			buttons: this.getButtons(true),
			id: 'creating',
		} as const;
	}

	getActionButtonStep() {
		const element = '[data-demo="actions"]';

		const title = i18n.global.t('chartDemo.actionStep.title');

		const text = i18n.global.t('chartDemo.actionStep.text');

		return {
			arrow: true,
			title: title,
			text: text,
			attachTo: {
				element: element,
				on: 'left-start',
			},
			buttons: this.getButtons(),
			id: 'creating',
		} as const;
	}
}
