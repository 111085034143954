<template>
  <div class="search-container" data-cy="lh-searchbox">
    <patternlib-searchbox
      controlled
      ref="searchBox"
      :label="t('search.ask_for_device_serial_id')"
      type="default"
      placeholder="12.345.678.9"
      readonly="false"
      width="100%"
      :value="serialId"
      size="big"
      show-remove-icon="true"
    >
    </patternlib-searchbox>
  </div>
</template>

<script lang="ts" src="./SearchComponent.ts"></script>
