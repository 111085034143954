import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-255412be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "zone-container flex gap-x-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { slot: "value" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { slot: "value" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { slot: "value" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { slot: "value" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { slot: "value" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { slot: "value" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { slot: "value" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { slot: "value" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { slot: "value" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { slot: "value" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { slot: "value" }
const _hoisted_26 = { class: "additional-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_headline = _resolveComponent("patternlib-headline")!
  const _component_patternlib_icon = _resolveComponent("patternlib-icon")!
  const _component_TextPill = _resolveComponent("TextPill")!
  const _component_patternlib_pill = _resolveComponent("patternlib-pill")!
  const _component_KeyValueView = _resolveComponent("KeyValueView")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_patternlib_headline, {
      separator: "bottom",
      class: "mb-header block",
      headline: _ctx.$t('device.zone.state'),
      "heading-tag": "h3"
    }, null, 8, ["headline"]),
    (_ctx.zoneData.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('general.no_information_available')), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zoneData, (zone) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "zone mt-3",
                key: zone.id
              }, [
                _createVNode(_component_patternlib_headline, {
                  separator: "bottom",
                  class: "mb-header block",
                  headline: _ctx.getHeadline(zone),
                  "heading-tag": "h5"
                }, null, 8, ["headline"]),
                _createVNode(_component_KeyValueView, {
                  config: _ctx.zoneStateRenderProps(zone),
                  data: zone
                }, {
                  customItem: _withCtx(({ key, options, value }) => [
                    (key === 'iceMakerState')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.ICEMAKER, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_4),
                          _createElementVNode("span", _hoisted_5, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'bioFreshPlus')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.BIOFRESHPLUS, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_6),
                          _createElementVNode("span", _hoisted_7, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'bioFresh')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.BIOFRESH, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_8),
                          _createElementVNode("span", _hoisted_9, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key == 'autoDoorMode')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.AUTODOOR, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_10),
                          _createElementVNode("span", _hoisted_11, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key == 'door')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.DOOR, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_12),
                          _createElementVNode("span", _hoisted_13, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'id')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                          _createElementVNode("span", {
                            class: "customListEntry",
                            role: "button",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.ZONEFAILURE, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_14),
                          _createElementVNode("span", _hoisted_15, [
                            (_ctx.countZoneAlarm(value) == 0)
                              ? (_openBlock(), _createBlock(_component_patternlib_pill, {
                                  key: 0,
                                  label: _ctx.countZoneAlarm(value),
                                  type: "status",
                                  color: "status-success-outlined"
                                }, null, 8, ["label"]))
                              : (_openBlock(), _createBlock(_component_patternlib_pill, {
                                  key: 1,
                                  label: _ctx.countZoneAlarm(value),
                                  type: "status",
                                  color: "status-warning-outlined"
                                }, null, 8, ["label"]))
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'defrostHeaterErrorGeneral')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.DEFROSTHEATER, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_16),
                          _createElementVNode("span", _hoisted_17, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'fanErrorGeneral')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.FAN, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_18),
                          _createElementVNode("span", _hoisted_19, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'airSensorErrorGeneral')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.AirSensor, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_20),
                          _createElementVNode("span", _hoisted_21, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'evaporatorSensorErrorGeneral')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.EvaporatorSensor, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_22),
                          _createElementVNode("span", _hoisted_23, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (key === 'iceMakerAirSensorErrorGeneral')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 10 }, [
                          _createElementVNode("span", {
                            role: "button",
                            class: "customListEntry",
                            onClick: ($event: any) => (_ctx.displayModal(_ctx.ModalTypeEnum.IceMakerAirSensor, zone.id ?? -1))
                          }, [
                            _createTextVNode(_toDisplayString(options.description.text) + " ", 1),
                            _createVNode(_component_patternlib_icon, {
                              "icon-name": "link",
                              size: "16px",
                              bold: ""
                            })
                          ], 8, _hoisted_24),
                          _createElementVNode("span", _hoisted_25, [
                            _createVNode(_component_TextPill, {
                              options: options.pillOptions,
                              property: value
                            }, null, 8, ["options", "property"])
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["config", "data"]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('zonestate.title')) + " (" + _toDisplayString(zone.typeName) + " - " + _toDisplayString(zone.zoneIndex) + "): " + _toDisplayString(_ctx.$t('general.last_updated_at')) + " " + _toDisplayString(_ctx.formatDateTime(zone.changedAtUtc ?? '')), 1)
                ])
              ]))
            }), 128))
          ])
        ])),
    (_ctx.modalSettings.isModalOpen)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          onOnClose: _ctx.handleModalClosure,
          icon: _ctx.modalSettings.icon,
          title: _ctx.modalSettings.title,
          imgSrc: _ctx.modalSettings.img
        }, {
          default: _withCtx(() => [
            _createVNode(_component_KeyValueView, {
              config: _ctx.modalSettings.config,
              data: _ctx.modalSettings.data
            }, null, 8, ["config", "data"])
          ]),
          _: 1
        }, 8, ["onOnClose", "icon", "title", "imgSrc"]))
      : _createCommentVNode("", true)
  ]))
}