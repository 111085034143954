import { useHttpConfiguration } from './useHttpConfiguration';
import { AdminApiFactory } from '@/api';
import { useToast } from './useToast';
import { ToastType } from '@/store/GlobalUIStore';
import { useRouter } from 'vue-router';

export function useMaintenance() {
  const { getConfig } = useHttpConfiguration();
  const config = getConfig();
  const displayToast = useToast();
  const router = useRouter();

  const getMaintenanceModeInfo = async () => {
    try {
      return (await AdminApiFactory(config).getMaintenanceModeInfo()).data;
    } catch (error) {
      displayToast(`Unable to get maintenance mode information due to ${error}`);
      throw error;
    }
  };

  const setMaintenanceMode = (newState: boolean) => {
    AdminApiFactory(config)
      .toggleMaintenanceMode(newState)
      .then(res => {
        router.replace('/');
      })
      .catch(error => {
        displayToast(`Unable to toggle maintenance mode info due to ${error}`, ToastType.WARNING);
      });
  };

  return {
    getMaintenanceModeInfo,
    setMaintenanceMode
  };
}
