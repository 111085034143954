<template>
  <div>
    <patternlib-headline class="col-12 mb-header block" :headline="t('statistics.map')" heading-tag="h1">
    </patternlib-headline>
    <patternlib-tabs controlled ref="tabsRef">
      <patternlib-tabs-item :active="activeTab === 0" :label="t('statistics.appliancespercountry')">
      </patternlib-tabs-item>
      <patternlib-tabs-item :active="activeTab === 1" :label="t('statistics.applianceswithlocations')">
      </patternlib-tabs-item>
    </patternlib-tabs>
    <div v-show="activeTab === 0" class="pt-2">
      <div ref="countryOverviewMapRef" style="width: 100%; height: 60vh"></div>
    </div>
    <div v-show="activeTab === 1" class="pt-2">
      <div ref="appliancesOverviewMapRef" style="width: 100%; height: 60vh" class="mb-4"></div>
    </div>
  </div>
</template>

<script src="./StatisticsMapView.ts" lang="ts" />
