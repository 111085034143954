import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-2" }
const _hoisted_2 = {
  ref: "countryOverviewMapRef",
  style: {"width":"100%","height":"60vh"}
}
const _hoisted_3 = { class: "pt-2" }
const _hoisted_4 = {
  ref: "appliancesOverviewMapRef",
  style: {"width":"100%","height":"60vh"},
  class: "mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_headline = _resolveComponent("patternlib-headline")!
  const _component_patternlib_tabs_item = _resolveComponent("patternlib-tabs-item")!
  const _component_patternlib_tabs = _resolveComponent("patternlib-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_patternlib_headline, {
      class: "col-12 mb-header block",
      headline: _ctx.t('statistics.map'),
      "heading-tag": "h1"
    }, null, 8, ["headline"]),
    _createVNode(_component_patternlib_tabs, {
      controlled: "",
      ref: "tabsRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_patternlib_tabs_item, {
          active: _ctx.activeTab === 0,
          label: _ctx.t('statistics.appliancespercountry')
        }, null, 8, ["active", "label"]),
        _createVNode(_component_patternlib_tabs_item, {
          active: _ctx.activeTab === 1,
          label: _ctx.t('statistics.applianceswithlocations')
        }, null, 8, ["active", "label"])
      ]),
      _: 1
    }, 512),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, null, 512)
    ], 512), [
      [_vShow, _ctx.activeTab === 0]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, null, 512)
    ], 512), [
      [_vShow, _ctx.activeTab === 1]
    ])
  ]))
}