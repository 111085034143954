import { ApplianceHistoryApiFactory, HistoryEntry } from '@/api';
import TheButton from '@/components/basic/Button/TheButton.vue';
import AppDropdown from '@/components/basic/Dropdown/AppDropdown.vue';
import MultiDropdown from '@/components/basic/Dropdown/MultiDropdown.vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import ChartSettingsComponent from '@/views/index/profile/ChartSettings.vue';
import AppModal from '@/components/basic/Modal/AppModal.vue';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted, onUnmounted, PropType, Ref, ref, watch } from 'vue';
import { Line as LineChart } from 'vue-chartjs';
import { useI18n } from 'vue-i18n';
import { Bar } from 'vue-chartjs';
import HistoryChart from './HistoryChart.vue';
import HistoryChartBasicInfo from './tutorial/HistoryChartBasicInfo.vue';
import { useLoginStore } from '@/store/LoginStore';
import { ChartData, ILineChartConfig, IntersectionLine, IScale } from '@/types/DeviceValueMapping';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import DemoTour from './tutorial/DemoTour';
import { useStorage } from '@vueuse/core';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import HttpHelper from '@/helper/HttpResponseHelper';
import { ApplianceHistoryKeyEnum } from '@/helper/ApplianceHistoryKeyEnum';

export default defineComponent({
  components: {
    AppModal,
    ChartSettingsComponent,
    LineChart,
    TheButton,
    AppDropdown,
    MultiDropdown,
    LoadingSpinner,
    TextPlaceHolder,
    HistoryChartBasicInfo,
    HistoryChart,
    Bar
  },
  props: {
    selectedHistoryType: { type: String, default: ApplianceHistoryKeyEnum.Base },
    chartsData: { type: Object as PropType<ChartData>, default: null },
    isDemoMode: {
      type: Boolean,
      required: false
    },
    serialId: { type: String, default: null },
    zones: { type: Object as PropType<string[]>, default: null },
    currentZoom: { type: Object as PropType<IScale>, default: null }
  },
  emits: ['onUpdatedIntersectionLine', 'onZoomReset'],
  setup(props, { emit }) {
    const { chartSettings } = storeToRefs(useLoginStore());
    const { t } = useI18n();
    const chartElementRef = ref();
    const intersectionLine = ref<IntersectionLine>();
    const tour = new DemoTour();
    const baseHistoryEntries: Ref<HistoryEntry[] | undefined> = ref([]);
    const zoneHistoryEntries: Ref<{ [key: string]: HistoryEntry[] }> = ref({});

    const { getConfig } = useHttpConfiguration();

    const isUserIntroduced = useStorage('isUserIntroduced', false);

    const useOverallView = computed(() => {
      return chartSettings.value.useOverallView;
    });

    const getRecentHistoryEntriesAppliance = async (key: string) => {
      const config = getConfig();
      if (key === ApplianceHistoryKeyEnum.Base) {
        const response = await ApplianceHistoryApiFactory(config).getRecentHistoryEntriesOfAppliance(props.serialId);

        return HttpHelper.getRequestData<HistoryEntry[]>(response, 200);
      } else if (key.includes(ApplianceHistoryKeyEnum.Zone)) {
        const type = key.split(ApplianceHistoryKeyEnum.Zone)[1];
        const response = await ApplianceHistoryApiFactory(getConfig()).getRecentHistoryEntriesOfApplianceZone(
          props.serialId,
          parseInt(type)
        );

        return HttpHelper.getRequestData<HistoryEntry[]>(response, 200);
      }

      return [];
    };

    const showChart = (chartData: ILineChartConfig) => {
      return chartData?.data?.datasets.length > 0;
    };

    const formatPath = (path: string | null | undefined) => {
      if (!path) {
        return '';
      }

      if (path.includes('PUT') || path.includes('GET') || path.includes('POST')) {
        return path.substring(4).trim();
      } else if (path.includes('PATCH')) {
        return path.substring(5).trim();
      } else {
        return path;
      }
    };

    const updateIntersectionLine = (updatedIL: IntersectionLine) => {
      intersectionLine.value = updatedIL;
      emit('onUpdatedIntersectionLine', intersectionLine.value);
    };

    const formatDateTime = (dateTime: string | undefined) => {
      const { timeZone } = storeToRefs(useLoginStore());

      return DateTimeFormatter.getFormattedDateTimeLongFormat(dateTime, timeZone.value);
    };

    const resetZoom = () => {
      emit('onZoomReset');
    };

    watch(chartElementRef, () => {
      if (!chartElementRef.value) return;

      if (props.isDemoMode || !isUserIntroduced.value) {
        setTimeout(() => {
          tour.startTour();
        }, 1000);
        isUserIntroduced.value = true;
      }
    });

    onMounted(() => {
      getRecentHistoryEntriesAppliance(ApplianceHistoryKeyEnum.Base).then(response => {
        baseHistoryEntries.value = response;
      });

      props.zones.forEach(zoneKey => {
        getRecentHistoryEntriesAppliance(zoneKey).then(response => {
          zoneHistoryEntries.value[zoneKey] = response;
        });
      });
    });

    onUnmounted(() => {
      tour.stopTour();
    });

    return {
      intersectionLine,
      chartElementRef,
      useOverallView: useOverallView,
      chartSettings,
      t,
      showChart,
      baseHistoryEntries,
      zoneHistoryEntries,
      formatPath,
      formatDateTime,
      updateIntersectionLine,
      resetZoom,
      ApplianceHistoryKeyEnum
    };
  }
});
