<template>
	<DataViewSkeleton
		:header="t('history.title')"
		:isLoading="isLoading"
		:error="{ isError: error.isError, useAction: true }"
		@onErrorActionClick="reload">
		<template #content>
			<patternlib-table
				data-cy="historyTable"
				v-if="data"
				ref="tableRef"
				implicit-ids
				no-grid-lines
				:no-data-label="$t('general.no_information_available')"
				:columns="[
					{ label: $t('device.serial_id'), align: 'left' },
					{ label: $t('device.name'), align: 'left' },
					{ label: $t('device.last_searched_at'), align: 'left' },
					{ label: $t('device.type'), align: 'left' },
				]"
				:actions="[{ label: $t('general.show') }]">
				<template v-for="item in data ?? []" :key="item">
					<patternlib-table-cell
						:value="item.deviceSerialId"></patternlib-table-cell>
					<patternlib-table-cell
						:value="item.deviceName ?? '-'"></patternlib-table-cell>
					<patternlib-table-cell
						:value="item.createdAtUtc"></patternlib-table-cell>
					<patternlib-table-cell
						:value="item.targetView"></patternlib-table-cell>
				</template>
			</patternlib-table>
		</template>
	</DataViewSkeleton>
</template>

<script src="./HistoryView.ts" lang="ts"></script>
