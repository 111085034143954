<template>
	<patternlib-texttoggle
		v-if="options"
		:disabled="disabled"
		:value="active"
		name="texttoggle"
		size="small"
		width="auto"
		ref="toggle">
		<patternlib-texttoggle-item
			v-for="({ label }, index) in options"
			:key="index"
			:value="index">
			{{ label }}
		</patternlib-texttoggle-item>
	</patternlib-texttoggle>
</template>

<script lang="ts" src="./ToggleButton.ts" />
