<template>
	<patternlib-dropdown
		:value="activeItem"
		:disabled="disabled"
		ref="dropdownRef"
		:label="label"
		:placeholder="placeholder"
		:showRemoveIcon="!hideRemoveIcon"
		prevent-input="false"
		additional-info=""
		additional-info-position="top"
		size="undefined"
		status="default"
		success-text="Success"
		error-text="Error"
		warning-text="Warning"
		width="100%"
		filterable="true">
		<template v-if="optionAsObject">
			<patternlib-option
				:disabled="!val"
				v-for="(val, key) in options"
				:key="key"
				:value="key"
				:label="`${key} (${val})`">
			</patternlib-option>
		</template>
		<template v-else>
			<patternlib-option
				:disabled="!key"
				v-for="key in options"
				:key="key"
				:value="key"
				:label="key" />
		</template>
	</patternlib-dropdown>
</template>
<script setup lang="ts">
import { PatternLibGenericEvent } from '@/types/patternlib';
import { PropType, defineProps, ref, onMounted, defineEmits, watch } from 'vue';

const props = defineProps({
	options: Object as PropType<Array<string>>,
	disabled: Boolean,
	label: String,
	placeholder: String,
	activeItem: String,
	hideRemoveIcon: { type: Boolean, default: false },
	optionAsObject: { type: Boolean, default: false },
});
const emit = defineEmits(['select']);
const dropdownRef = ref();

const onChange = (event: PatternLibGenericEvent) => {
	const selectedIndex: string = event.detail.selected?.value;
	emit('select', selectedIndex);
};

watch(
	() => props.activeItem,
	() => {
		//Patternlib is buggy, workaround to display changes
		if (!props.activeItem) {
			dropdownRef.value.value = undefined;
		}
	}
);

onMounted(() => {
	dropdownRef.value.addEventListener('lhSelect', onChange);
});
</script>
