<template>
  <patternlib-accordion>
    <patternlib-accordion-item :label="label">
      <patternlib-list-item v-for="setting of settings" :key="setting.key" itemHeight="36px" width="100%">
        <div style="display: grid; grid-template-columns: 12fr 0fr; justify-items: flex-start">
          <span style="font-family: 'LiebherrText-Regular', sans-serif">
            {{ setting.key }}
          </span>
          <patternlib-checkbox
            :name="`${identifier}-${setting.key}`"
            :checked="setting.value"
            ref="checkBoxRef"
            label=""
            status="default"
            required="false"
            disabled="false"
            indeterminate="false"
          >
          </patternlib-checkbox>
        </div>
      </patternlib-list-item>
    </patternlib-accordion-item>
  </patternlib-accordion>
</template>

<script lang="ts" src="./SettingsList.ts" />
