<template>
  <div style="display: flex; justify-content: space-between">
    <patternlib-tabs ref="tabsRef" class="w-full">
      <patternlib-tabs-item :active="chartSettingsActiveTab === 0" label="General"></patternlib-tabs-item>
      <patternlib-tabs-item :active="chartSettingsActiveTab === 1" label="Base state"> </patternlib-tabs-item>
      <patternlib-tabs-item :active="chartSettingsActiveTab === 2" label="ZONE state"></patternlib-tabs-item>
    </patternlib-tabs>
  </div>
  <div class="mx-3 mt-3 max-h-96 w-full overflow-y-auto">
    <div v-show="chartSettingsActiveTab === 0">
      <div class="my-2 flex flex-row items-center gap-2">
        <patternlib-toggle ref="synchronizedChartsToggle" :checked="localChartSettings.synchronizedCharts">
        </patternlib-toggle>
        <span>{{ t('profile.chartSettings.synchronizedCharts') }}</span>
      </div>
      <div class="my-2 flex flex-row items-center gap-2">
        <patternlib-toggle ref="mergedChartsToggleRef" :checked="localChartSettings.showMergedCharts">
        </patternlib-toggle>
        <span>{{ t('profile.chartSettings.show_merged_charts') }}</span>
      </div>

      <div class="my-6 flex flex-col items-start">
        <patternlib-headline :headline="$t('profile.chartSettings.viewOptions')" headingTag="h4" class="mb-2" />
        <div class="flex gap-6" style="display: flex; justify-content: space-between; flex-direction: column">
          <patternlib-radiobutton
            horizontal
            ref="layoutBtnRef"
            :value="localChartSettings.useOverallView ? 'overallView' : 'singleView'"
          >
            <patternlib-radiobutton-item value="overallView" :checked="chartSettings.useOverallView">
              {{ $t('profile.chartSettings.overallView') }}
            </patternlib-radiobutton-item>
            <patternlib-radiobutton-item
              style="margin-left: 8rem"
              value="singleView"
              :checked="!localChartSettings.useOverallView"
            >
              {{ $t('profile.chartSettings.singleView') }}
            </patternlib-radiobutton-item>
          </patternlib-radiobutton>
          <div style="display: flex; gap: 3rem">
            <div class="bg-slate-100">
              <div class="font-mono flex items-center gap-12 px-8 pb-8">
                <div class="flex flex-col items-center gap-2">
                  <span class="m-0 h-2 p-0 text-[10px]">Zone0</span>
                  <div v-for="n in 3" :key="n" class="shadow-md mt-2 h-8 w-24 rounded-lg bg-white"></div>
                </div>
                <div class="flex flex-col items-center gap-2">
                  <span class="m-0 h-2 p-0 text-[10px]">Zone1</span>
                  <div v-for="n in 3" :key="n" class="shadow-md mt-2 h-8 w-24 rounded-lg bg-white"></div>
                </div>
              </div>
            </div>
            <div class="bg-slate-100">
              <div class="font-mono flex items-center gap-12 px-8 pb-8">
                <div class="flex flex-col items-center gap-2">
                  <div
                    class="shadow-md mb-4 h-4 w-24 self-start rounded-lg bg-white text-center text-[10px] leading-3 after:content-['_▾']"
                  >
                    Zone0
                  </div>

                  <div v-for="n in 3" :key="n" class="shadow-md h-8 w-48 rounded-lg bg-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-6">
        <patternlib-headline :headline="$t('profile.chartSettings.predefinedFilters')" headingTag="h4" class="mb-2" />
        <div class="my-2 flex flex-row items-center gap-2">
          <patternlib-toggle
            ref="predefinedFilterToggleRef"
            :checked="localChartSettings.predefinedFilterCooling"
            name="coolingFilter"
          />
          <span>{{ $t('profile.chartSettings.predefinedFilterCooling') }}</span>
        </div>
      </div>
    </div>
    <div v-show="chartSettingsActiveTab === 1">
      <span class="text-h5 font-semibold">{{ t('profile.chartSettings.chartSelectionLabel') }}</span>
      <div class="mt-2" style="display: flex; justify-content: space-between">
        <patternlib-searchbox ref="baseSearchBox" type="default" placeholder="Search ..." readonly="false" width="50%">
        </patternlib-searchbox>
        <div style="padding-top: 8px; margin-right: 18px">
          <patternlib-checkbox
            name="base-selectAll"
            ref="checkboxBaseRefAll"
            :label="selectAllCbLabel()"
            status="default"
            required="false"
            :checked="areAllBaseStatesChecked"
            :indeterminate="indeterminateBaseSignalsState"
            disabled="false"
          >
          </patternlib-checkbox>
        </div>
      </div>

      <div v-if="allBaseStates">
        <div v-for="(item, index) in Object.keys(allBaseStates as BaseStateSettings)" :key="index">
          <SettingsList
            v-if="allBaseStates[item].length > 0"
            :identifier="'base'"
            :label="`/appliance/${item}`"
            :settings="allBaseStates[item]"
            @evtChartSettingsUpdated="updateChartSettingsProp"
          ></SettingsList>
        </div>
      </div>
    </div>

    <div v-show="chartSettingsActiveTab === 2">
      <span class="text-h5 font-semibold">{{ t('profile.chartSettings.chartSelectionLabel') }}</span>
      <div class="mt-2" style="display: flex; justify-content: space-between">
        <patternlib-searchbox ref="zoneSearchBox" type="default" placeholder="Search ..." readonly="false" width="50%">
        </patternlib-searchbox>
        <div style="padding-top: 8px; margin-right: 18px">
          <patternlib-checkbox
            name="zone-selectAll"
            ref="checkboxZoneRefAll"
            :label="selectAllZoneCbLabel()"
            status="default"
            required="false"
            :checked="areAllZoneStatesChecked"
            :indeterminate="indeterminateZoneSignalsState"
            disabled="false"
          >
          </patternlib-checkbox>
        </div>
      </div>

      <div v-if="allZoneStates">
        <div v-for="(item, index) in Object.keys(allZoneStates as ZoneStateSettings)" :key="index">
          <SettingsList
            v-if="allZoneStates[item].length > 0"
            :identifier="'zone'"
            :label="`/zone/{zone}/${item}`"
            :settings="allZoneStates[item]"
            @evtChartSettingsUpdated="updateChartSettingsProp"
          ></SettingsList>
        </div>
      </div>
    </div>

    <div class="chart-settings-btn-container">
      <TheButton :label="t('general.cancel')" class="mr-1 mt-4" type="secondary" size="small" @click="close" />
      <TheButton
        :label="t('profile.chartSettings.resetButton')"
        class="mr-1 mt-4"
        type="secondary"
        size="small"
        icon="filter-reset"
        @click="resetSettings"
      />
      <TheButton class="mt-4" :label="t('general.save_settings')" size="small" @click="saveSettings" />
    </div>
  </div>
</template>

<style lang="scss" src="./ChartSettings.scss" />
<script src="./ChartSettings" lang="ts"></script>
