import { ToastType, useGlobalUIStore } from '@/store/GlobalUIStore';

export function useToast() {
	const toasts = useGlobalUIStore();

	const displayToast = (
		message: string,
		type: ToastType = ToastType.DEFAULT
	) => {
		toasts.addToast(message, type);
	};
	return displayToast;
}
