export enum ModalTypeEnum {
	ICEMAKER,
	ZONEFAILURE,
	AUTODOOR,
	DOOR,
	BIOFRESH,
	BIOFRESHPLUS,
	DEFROSTHEATER,
	FAN,
	AirSensor,
	EvaporatorSensor,
	IceMakerAirSensor,
	AMBIENTSENSOR,
	COMPRESSOR,
	COMPRESSORERROR,
	CONDENSERERROR
}