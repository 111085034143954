import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "font-head text-h4 ml-4" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "font-head text-h4 ml-4" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "font-head text-h4 ml-4 pt-4" }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "font-head text-h4 ml-4 pt-4" }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { class: "font-head text-h4 ml-4 pt-4" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "font-head text-h4 ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model && _ctx.connectedAppliancesModes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.applianceBaseModes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('general.appliance')) + " - " + _toDisplayString(_ctx.$t('statistics.title')), 1),
                _createVNode(_component_apexchart, {
                  type: "bar",
                  height: "300",
                  options: _ctx.activatedChartOptions(false, false),
                  series: _ctx.getApplianceBaseModesChartSeries
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.coolingZonesModes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('general.coolerzone')) + " - " + _toDisplayString(_ctx.$t('statistics.title')), 1),
                _createVNode(_component_apexchart, {
                  type: "bar",
                  height: "300",
                  options: _ctx.activatedChartOptions(false, true),
                  series: _ctx.getModesChartSeries(_ctx.coolingZonesModes)
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.freezerZonesModes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('general.freezerzone')) + " - " + _toDisplayString(_ctx.$t('statistics.title')), 1),
                _createVNode(_component_apexchart, {
                  type: "bar",
                  height: "300",
                  options: _ctx.activatedChartOptions(false, true),
                  series: _ctx.getModesChartSeries(_ctx.freezerZonesModes)
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.wineZonesModes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('general.winezone')) + " - " + _toDisplayString(_ctx.$t('statistics.title')), 1),
                _createVNode(_component_apexchart, {
                  type: "bar",
                  height: "300",
                  options: _ctx.activatedChartOptions(false, true),
                  series: _ctx.getModesChartSeries(_ctx.wineZonesModes)
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.bioFreshZonesModes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('general.biofreshzone')) + " - " + _toDisplayString(_ctx.$t('statistics.title')), 1),
                _createVNode(_component_apexchart, {
                  type: "bar",
                  height: "300",
                  options: _ctx.activatedChartOptions(false, true),
                  series: _ctx.getModesChartSeries(_ctx.bioFreshZonesModes)
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_ctx.appliancesActivatedModes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('statistics.activatedfunctionsofconnectedappliances')), 1),
            _createVNode(_component_apexchart, {
              type: "bar",
              height: "300",
              options: _ctx.activatedChartOptions(true, false, _ctx.$t('statistics.numberofactivations').toString()),
              series: _ctx.getActivatedModesChartSeries
            }, null, 8, ["options", "series"])
          ]))
        : _createCommentVNode("", true)
  ]))
}