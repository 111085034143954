import { DateTime, Zone } from 'luxon';
import { IOptions } from './ChartsBuilder';
import { Base, BaseState, HistoryItem, ZoneState } from '@/api';
import {
  ChartSettingsType,
  ChartType,
  ILineChartDataSet,
  ILineChartOption,
  LineType
} from '@/types/DeviceValueMapping';
import ChartsConfig from './HistoryChartsConfiguration';
import { storeToRefs } from 'pinia';
import { useLoginStore } from '@/store/LoginStore';
import { ApplianceHistoryKeyEnum } from '../ApplianceHistoryKeyEnum';
import { BaseHistoryKeyEnum } from '../BaseHistoryKeyEnum';
import { ZoneHistoryKeyEnum } from '../ZoneHistoryKeyEnum';

export default class LineChartHelper {
  public static generateLineChart(
    historyData: Base | Zone | undefined,
    options: IOptions,
    accessKey: string,
    baseState: BaseState,
    zoneStates: ZoneState[]
  ) {
    if (!historyData) {
      return;
    }

    const datasets: Array<ILineChartDataSet> = [];
    const configAccessKey =
      accessKey === ApplianceHistoryKeyEnum.Base ? ApplianceHistoryKeyEnum.Base : ApplianceHistoryKeyEnum.Zone;
    const temperatureUnit = options.temperatureUnit;
    const userSettings = options.userSettings[configAccessKey] as Record<string, boolean>;
    const typeConfig: Record<string, ChartSettingsType> = ChartsConfig[configAccessKey];

    for (const i of Object.keys(historyData)) {
      const key = i as keyof Base | Zone;
      const zoneData = (historyData as any)[i];
      const configuration = typeConfig[i] as ILineChartOption;
      const showChart = userSettings[i];

      if (!configuration || !showChart || !zoneData || configuration.type !== ChartType.LINECHART) continue;

      const dataset = LineChartHelper.createLineChartDataSet(
        zoneData,
        configuration,
        key as string,
        temperatureUnit,
        accessKey,
        baseState,
        zoneStates[i]
      );
      datasets.push(dataset);
    }
    return datasets;
  }

  private static createLineChartDataSet(
    data: Array<HistoryItem>,
    options: ILineChartOption,
    label: string,
    temperatureUnit: number,
    accessKey: string,
    baseState: BaseState,
    zoneState: ZoneState
  ): ILineChartDataSet {
    const { timeZone } = storeToRefs(useLoginStore());
    const mappedData: HistoryItem[] = [];

    data.forEach(entry => {
      const dateTimeUtc = new Date(entry.x as string).getTime();
      let value: number | undefined | null = undefined;

      const dateTimeISO = DateTime.fromMillis(dateTimeUtc).toISO({ includeOffset: false });
      const dateTimeFormatted = DateTime.fromISO(dateTimeISO + 'Z' ?? '', { zone: timeZone.value }).toFormat(
        'yyyy-MM-dd HH:mm:ss'
      );

      // If appliance temperature unit is set to °F
      if (temperatureUnit === 1) {
        if (entry.y && label.toLowerCase().includes('sensortemperaturevalue')) {
          // By default, sensor send value in °C
          // If appliance set temperature is Fahrenheit, then we need to convert these values to °F
          value = (entry.y as any) * 1.8 + 32;
          entry.z = 1; // 1: °F
        } else if (label.toLowerCase().includes('temperature') && entry.z === 0) {
          value = (entry.y as any) * 1.8 + 32;
          entry.z = 1;
        } else {
          value = entry.y as any;
        }
      } else {
        value = entry.y as any;
      }

      mappedData.push({ x: dateTimeFormatted, y: value as any, z: entry.z });
    });

    let shortText = '';

    if (accessKey === ApplianceHistoryKeyEnum.Base) {
      shortText = this.getBaseLabelShortText(label, baseState);
    } else if (accessKey === ApplianceHistoryKeyEnum.Zone) {
      shortText = this.getZoneLabelWithShortText(label, zoneState);
    }

    if (!options) {
      return this.createDataDefaultDataSet(label, shortText, mappedData);
    }

    const settings = options;

    let lineType;
    switch (settings?.lineType) {
      case LineType.DASHED:
        lineType = [10, 15];
        break;
      case LineType.HIDDEN:
        lineType = [0, 1];
        break;
      case LineType.NORMAL:
        lineType = [1, 1];
        break;
      default:
        lineType = [1, 1];
    }

    return {
      key: label,
      label: options.label,
      shortText: shortText,
      backgroundColor: '#fff',
      borderColor: options.color,
      color: options.color,
      borderDash: lineType,
      data: mappedData,
      pointStyle: 'circle',
      borderWidth: 2,
      fill: true,
      pointRadius: 5,
      pointHoverRadius: 15,
      spanGaps: true
    };
  }

  private static getBaseLabelShortText(labelString: string, baseState: BaseState) {
    const label = labelString.toString().trim().toLowerCase();

    if (label === BaseHistoryKeyEnum.AmbientSensorTemperatureValue.toLowerCase() && baseState?.ambientSensorShortText) {
      return `${baseState?.ambientSensorShortText}`;
    } else if (label === BaseHistoryKeyEnum.CompressorValue.toLowerCase() && baseState?.compressorShortText) {
      return baseState?.compressorShortText;
    } else if (label === BaseHistoryKeyEnum.CondenserFanValue.toLowerCase() && baseState?.condenserFanShortText) {
      return baseState?.condenserFanShortText;
    }

    return '';
  }

  private static getZoneLabelWithShortText(labelString: string, zoneState: ZoneState) {
    const label = labelString.toString().trim().toLowerCase();

    if (
      label === ZoneHistoryKeyEnum.BiofreshplusUpperAirBaffleValue.toLowerCase() &&
      zoneState?.biofreshplusUpperAirBaffleShortText
    ) {
      return `${zoneState?.biofreshplusUpperAirBaffleShortText}`;
    } else if (
      label === ZoneHistoryKeyEnum.BiofreshplusLowerAirBaffleValue.toLowerCase() &&
      zoneState?.biofreshplusLowerAirBaffleShortText
    ) {
      return `${zoneState?.biofreshplusLowerAirBaffleShortText}`;
    } else if (label === ZoneHistoryKeyEnum.FanValue.toLowerCase() && zoneState?.fanShortText) {
      return `${zoneState?.fanShortText}`;
    } else if (label === ZoneHistoryKeyEnum.HumidityFanValue.toLowerCase() && zoneState?.humidityFanShortText) {
      return `${zoneState?.humidityFanShortText}`;
    } else if (label === ZoneHistoryKeyEnum.AirSensorTemperatureValue.toLowerCase() && zoneState?.airSensorShortText) {
      return `${zoneState?.airSensorShortText}`;
    } else if (
      label === ZoneHistoryKeyEnum.EvaporatorSensorTemperatureValue.toLowerCase() &&
      zoneState?.evaporatorSensorShortText
    ) {
      return `${zoneState?.evaporatorSensorShortText}`;
    } else if (
      label === ZoneHistoryKeyEnum.IceMakerAirSensorTemperatureValue.toLowerCase() &&
      zoneState?.iceMakerAirSensorShortText
    ) {
      return `${zoneState?.iceMakerAirSensorShortText}`;
    } else if (
      label === ZoneHistoryKeyEnum.BiofreshAirSensorTemperatureValue.toLowerCase() &&
      zoneState?.biofreshAirSensorTemperatureValue
    ) {
      return `${zoneState?.biofreshAirSensorShortText}`;
    }
    return '';
  }

  private static createDataDefaultDataSet = (label: string, shortText: string, data: any): ILineChartDataSet => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    return {
      key: '',
      label: label,
      shortText: shortText,
      backgroundColor: '#fff',
      borderColor: randomColor,
      color: randomColor,
      stepped: false,
      borderDash: [3, 5],
      data: data,
      pointStyle: 'circle',
      borderWidth: 2,
      fill: true,
      pointRadius: 5,
      pointHoverRadius: 15,
      spanGaps: true
    };
  };
}
