export default class CookieHelper {
	public static getCookieByName(name: string) {
		const cookie: Record<string, string> = {};
		document.cookie.split(';').forEach(function (el) {
			const [key, value] = el.split('=');
			cookie[key.trim()] = value;
		});

		return cookie[name];
	}
}
