import { BaseInfo, BaseState } from '@/api';
import TextPill from '@/components/basic/KeyValueView/components/TextPill.vue';
import {
  EntryType,
  KeyValueViewConfig,
  PillOptions,
  PillStatusEnum
} from '@/components/basic/KeyValueView/KeyValueView';
import KeyValueView from '@/components/basic/KeyValueView/KeyValueView.vue';
import Modal from '@/components/basic/Modal/AppModal.vue';
import { useLoginStore } from '@/store/LoginStore';
import { BaseStateHelper } from '@/views/index/smartdevice/components/base-state/BaseStateHelper';
import { ModalTypeEnum } from '@/views/index/smartdevice/components/ModalTypeEnum';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { TimePeriodHelper } from '../TimePeriodHelper';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';

enum ModalType {
  COMPRESSOR
}

type TModalSettings = {
  type?: ModalType;
  isModalOpen: boolean;
  currentModal: string;
  title?: string;
  img?: string;
  icon?: string;
  data?: Record<string, unknown>;
  config?: KeyValueViewConfig<unknown>;
};

export default defineComponent({
  name: 'BaseState',
  components: { KeyValueView, Modal, TextPill },
  props: {
    deviceSerialId: {
      type: String,
      required: true
    },
    isAvailableAtSmartDevice: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object as PropType<BaseInfo>,
      default: () => ({})
    },
    baseState: {
      type: Object as PropType<BaseState>,
      default: () => ({})
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { timeZone } = storeToRefs(useLoginStore());

    const modalSettings = ref();

    modalSettings.value = reactive<TModalSettings>({
      isModalOpen: false,
      currentModal: '',
      data: undefined,
      config: undefined,
      title: '',
      icon: ''
    });

    const formatDateTime = (datetime: string) => {
      return DateTimeFormatter.getFormattedDateTimeLongFormat(`${datetime}Z`, timeZone.value);
    };

    const defaultPillOptions: Record<string, PillOptions> = {
      0: { type: PillStatusEnum.DEFAULT, value: t('general.off') },
      1: { type: PillStatusEnum.SUCCESS, value: t('general.on') }
    };

    const advancedPillOptions: Record<string, PillOptions> = {
      0: {
        type: PillStatusEnum.DEFAULT,
        value: t('general.off')
      },
      1: {
        type: PillStatusEnum.SUCCESS,
        value: t('general.on_filter_ok')
      },
      2: {
        type: PillStatusEnum.WARNING,
        value: t('general.on_filter_expired')
      }
    };

    const displayModal = (key: ModalTypeEnum) => {
      const { baseState, baseInfo } = props;

      switch (key) {
        case ModalTypeEnum.COMPRESSOR:
          modalSettings.value = BaseStateHelper.getModalSettings(baseState, baseInfo, ModalTypeEnum.COMPRESSOR, t);
          break;
        case ModalTypeEnum.AMBIENTSENSOR:
          modalSettings.value = BaseStateHelper.getModalSettings(baseState, baseInfo, ModalTypeEnum.AMBIENTSENSOR, t);
          break;
        case ModalTypeEnum.CONDENSERERROR:
          modalSettings.value = BaseStateHelper.getModalSettings(baseState, baseInfo, ModalTypeEnum.CONDENSERERROR, t);
          break;
        case ModalTypeEnum.COMPRESSORERROR:
          modalSettings.value = BaseStateHelper.getModalSettings(baseState, baseInfo, ModalTypeEnum.COMPRESSORERROR, t);
          break;
      }
    };

    const handleModalClosure = () => {
      modalSettings.value.isModalOpen = false;
      modalSettings.value.img = undefined;
      modalSettings.value.type = undefined;
    };

    const createBaseStateTable = () => {
      const { baseInfo, baseState } = props;
      const applicationState = BaseStateHelper.getStateAndApplianceMode(baseState.state).applicationState;
      const applianceMainMode = BaseStateHelper.getStateAndApplianceMode(baseState.state).applianceMainMode;

      const settings: KeyValueViewConfig<any> = {
        item: {
          height: '48px',
          labelWidth: '50%',
          noSeparator: false
        },
        props: {
          state: {
            description: { text: t('device.base.state') },
            type: EntryType.COMPUTED,
            renderAsHTML: true,
            isHidden: false,
            computeCB: () => {
              return applicationState;
            }
          },
          mode: {
            description: { text: t('device.base.mode') },
            type: EntryType.COMPUTED,
            renderAsHTML: true,
            isHidden: false,
            computeCB: () => {
              return applianceMainMode;
            }
          },
          partyMode: {
            description: { text: t('device.base.party_mode') },
            isHidden: baseInfo.capsPartyMode !== 1,
            type: EntryType.PILL,
            pillOptions: defaultPillOptions
          },
          holidayMode: {
            description: { text: t('device.base.holiday_mode') },
            type: EntryType.PILL,
            isHidden: baseInfo.capsHolidayMode !== 1,
            pillOptions: defaultPillOptions
          },
          energySaverMode: {
            description: {
              text: t('device.base.energy_saver_mode')
            },
            type: EntryType.PILL,
            isHidden: baseInfo.capsEnergySaverMode !== 1,
            pillOptions: defaultPillOptions
          },
          nightMode: {
            description: { text: t('device.base.night_mode') },
            type: EntryType.PILL,
            isHidden: baseInfo.capsNightMode !== 1,
            pillOptions: defaultPillOptions
          },
          sabbathMode: {
            description: { text: t('device.base.sabbath_mode') },
            type: EntryType.PILL,
            isHidden: baseInfo.capsSabbathMode !== 1,
            pillOptions: defaultPillOptions
          },
          childLock: {
            description: { text: t('device.base.child_lock') },
            type: EntryType.PILL,
            isHidden: baseInfo.capsChildLock !== 1,
            pillOptions: defaultPillOptions
          },
          ecoMode: {
            description: { text: t('device.base.eco_mode') },
            type: EntryType.PILL,
            isHidden: baseState.ecoMode === null,
            pillOptions: {
              0: {
                type: PillStatusEnum.DEFAULT,
                value: t('general.off')
              },
              1: {
                type: PillStatusEnum.SUCCESS,
                value: t('device.base.eco_mode')
              },
              2: {
                type: PillStatusEnum.SUCCESS,
                value: t('device.base.eco_mode_plus')
              }
            }
          },
          dustFilter: {
            description: {
              text: `${t('device.base.dust_filter')} ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.reminderDustFilterShortText
              )}`
            },
            type: EntryType.PILL,
            isHidden: baseInfo.capsDustFilter !== 1,
            pillOptions: advancedPillOptions
          },
          waterFilter: {
            description: {
              text: `${t('device.base.water_filter')} ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.reminderWaterFilterShortText
              )}`
            },
            type: EntryType.PILL,
            isHidden: baseInfo.capsWaterFilter !== 1,
            pillOptions: advancedPillOptions
          },
          airFilter: {
            description: {
              text: `${t('device.base.air_filter')} ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.reminderAirFilterShortText
              )}`
            },
            type: EntryType.PILL,
            isHidden: baseInfo.capsAirFilter !== 1,
            pillOptions: advancedPillOptions
          },
          waterSystemWaterSafetyValveState: {
            description: {
              text: `${t('device.base.water_system_safety_valve')} ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.waterSystemWaterSafetyValveShortText
              )}`
            },
            type: EntryType.PILL,
            isHidden: baseState.waterSystemWaterSafetyValveState === null,
            pillOptions: {
              0: {
                type: PillStatusEnum.DEFAULT,
                value: t('general.closed')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.opened')
              }
            }
          },
          waterSystemWaterDispenserValveState: {
            description: {
              text: `${t(
                'device.base.water_system_water_dispenser_valve'
              )} ${BaseStateHelper.getAdditionalDescriptionText(baseState.waterSystemWaterDispenserValveShortText)}`
            },
            type: EntryType.PILL,
            isHidden: baseState.waterSystemWaterDispenserValveState === null,
            pillOptions: {
              0: {
                type: PillStatusEnum.DEFAULT,
                value: t('general.closed')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.opened')
              }
            }
          },
          waterSystemWaterIcecubeValveState: {
            description: {
              text: `${t('device.base.water_system_ice_cube_valve')} ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.waterSystemWaterIcecubeValveShortText
              )}`
            },
            type: EntryType.PILL,
            isHidden: baseState.waterSystemWaterIcecubeValveState === null,
            pillOptions: {
              0: {
                type: PillStatusEnum.DEFAULT,
                value: t('general.closed')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.opened')
              }
            }
          }
        }
      };

      return {
        config: settings,
        data: { ...baseInfo, ...baseState }
      };
    };

    const hasBaseData = computed((): boolean => {
      return props.baseInfo && props.baseInfo.serialNo !== null;
    });

    const createInfoStateTable = () => {
      const { baseInfo, baseState } = props;

      const settings: KeyValueViewConfig<unknown> = {
        item: {
          height: '48px',
          labelWidth: '50%',
          noSeparator: false
        },
        props: {
          failureAlarmState: {
            description: {
              text: t('device.base.failure_alarm_state')
            },
            type: EntryType.PILL,
            isHidden: baseInfo.capsFailureAlarm !== 1,
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.inactive')
              },
              1: { type: PillStatusEnum.WARNING, value: t('general.active') },
              2: {
                type: PillStatusEnum.ALERT,
                value: t('general.active_and_confirmed')
              }
            }
          },
          temperatureUnit: {
            description: { text: t('basestate.temperature_unit') },
            type: EntryType.TEXT,
            isHidden: false
          },
          compressorType: {
            description: { text: t('device.base.compressor') },
            type: EntryType.CUSTOM,
            isHidden: baseState?.compressorType === null,
            pillOptions: {
              0: { type: PillStatusEnum.DEFAULT, value: 'GQ013' },
              1: { type: PillStatusEnum.DEFAULT, value: 'GQ011' }
            }
          },
          condenserFanType: {
            description: { text: t('device.base.condenser_type') },
            type: EntryType.PILL,
            isHidden: baseInfo.capsCondenserFan !== 1,
            pillOptions: {
              0: { type: PillStatusEnum.DEFAULT, value: 'GQ032' },
              1: { type: PillStatusEnum.DEFAULT, value: 'GQ042' },
              2: { type: PillStatusEnum.DEFAULT, value: 'GQ033' }
            }
          },
          coolingSystemType: {
            description: {
              text: t('device.base.cooling_system_type')
            },
            type: EntryType.PILL,
            pillOptions: {
              null: { type: PillStatusEnum.DEFAULT, value: 'No valve' },
              0: { type: PillStatusEnum.DEFAULT, value: 'Stop Valve' },
              1: { type: PillStatusEnum.DEFAULT, value: 'Magnetic Valve' },
              2: { type: PillStatusEnum.DEFAULT, value: 'Stepper Valve' },
              3: { type: PillStatusEnum.DEFAULT, value: 'Damper' },
              4: {
                type: PillStatusEnum.DEFAULT,
                value: 'Micro Expansion Valve'
              }
            }
          },
          powerFailureCount: {
            description: {
              text: t('device.base.power_failure_count')
            },
            type: EntryType.TEXT,
            isHidden: false
          },
          workingTime: {
            description: { text: t('device.base.working_time') },
            type: EntryType.COMPUTED,
            isHidden: false,
            renderAsHTML: true,
            computeCB: () => {
              // In seconds
              return baseState.workingTime ? TimePeriodHelper.mapTime(baseState.workingTime) : 0;
            }
          },
          workingTimeResetCount: {
            description: {
              text: t('device.base.working_time_reset')
            },
            type: EntryType.TEXT,
            isHidden: false
          },
          workingTimeBeforeChange: {
            description: {
              text: t('device.base.working_time_before_change')
            },
            type: EntryType.COMPUTED,
            renderAsHTML: true,
            isHidden: false,
            computeCB: () => {
              // In seconds
              return baseState.workingTimeBeforeChange
                ? TimePeriodHelper.mapTime(baseState.workingTimeBeforeChange)
                : 0;
            }
          },
          busErrorCount: {
            description: {
              text: t('device.base.bus_error_count')
            },
            type: EntryType.TEXT,
            isHidden: false
          },
          ambientSensorErrorGeneral: {
            description: {
              text: `AmbientSensor (ErrorGeneral) ${BaseStateHelper.getAdditionalDescriptionText(
                baseState.ambientSensorShortText
              )}`
            },
            type: EntryType.CUSTOM,
            isHidden: baseState?.ambientSensorErrorGeneral === null,
            pillOptions: {
              0: { type: PillStatusEnum.SUCCESS, value: t('general.no') },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          compressorErrorGeneral: {
            description: { text: 'Compressor (ErrorGeneral)' },
            type: EntryType.CUSTOM,
            isHidden: baseState?.compressorErrorGeneral === null,
            pillOptions: {
              0: { type: PillStatusEnum.SUCCESS, value: t('general.no') },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          condenserFanErrorGeneral: {
            description: { text: 'Condenser Fan (ErrorGeneral)' },
            type: EntryType.CUSTOM,
            isHidden: baseState?.condenserFanErrorGeneral === null,
            pillOptions: {
              0: { type: PillStatusEnum.SUCCESS, value: t('general.no') },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          failureAlarmCodes: {
            description: { text: t('basestate.failure_alarm_codes') },
            type: EntryType.TEXT,
            isHidden: false
          }
        }
      };

      const temperatureUnit = baseState.temperatureUnit == 0 ? '°C' : '°F';

      return {
        config: settings,
        data: {
          ...baseInfo,
          ...baseState,
          temperatureUnit: temperatureUnit
        }
      };
    };

    const baseStateRenderProps = computed(() => createBaseStateTable());
    const infoStateRenderProps = computed(() => createInfoStateTable());

    return {
      formatDateTime,
      infoStateRenderProps,
      baseStateRenderProps,
      modalSettings,
      displayModal,
      ModalTypeEnum,
      handleModalClosure,
      hasBaseData
    };
  }
});
