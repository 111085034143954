import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87cbff92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-block" }
const _hoisted_2 = {
  class: "mb-block",
  "data-cy": "lh-history"
}
const _hoisted_3 = {
  key: 0,
  class: "mb-block grid grid-cols-6 gap-6"
}
const _hoisted_4 = { class: "device-serial-id" }
const _hoisted_5 = { class: "appliance-image" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "device-serial-id" }
const _hoisted_8 = { class: "appliance-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_teaser = _resolveComponent("patternlib-teaser")!
  const _component_SearchComponent = _resolveComponent("SearchComponent")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_patternlib_icon = _resolveComponent("patternlib-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataViewSkeleton = _resolveComponent("DataViewSkeleton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_patternlib_teaser, {
          "data-cy": "lh-teaser",
          image: "./assets/teaser/01.png",
          headline: _ctx.$t('teaser.title'),
          "image-object-fit": "cover",
          text: _ctx.$t('teaser.description')
        }, null, 8, ["headline", "text"])
      ]),
      _createVNode(_component_SearchComponent, { class: "mb-block" }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DataViewSkeleton, {
            header: _ctx.$t('history.title'),
            smallHeader: "",
            isLoading: _ctx.isLoading,
            pageLayout: false,
            error: { isError: _ctx.error.isError }
          }, {
            content: _withCtx(() => [
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyItems, (item, index) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        "data-cy": "lh-historyItem",
                        key: item?.deviceSerialId ?? index,
                        to: {
                    name: 'DefaultDeviceView',
                    params: { serialNumber: item.deviceSerialId, deviceType: item.targetView }
                  },
                        class: "history-item col-span-6 md:col-span-2 xl:col-span-1"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(item.deviceSerialId), 1),
                          _createElementVNode("div", _hoisted_5, [
                            (_ctx.isFetchingImages)
                              ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
                              : (_ctx.historyImages[index])
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    src: _ctx.historyImages[index],
                                    alt: item.deviceSerialId ?? undefined
                                  }, null, 8, _hoisted_6))
                                : (_openBlock(), _createBlock(_component_patternlib_icon, {
                                    key: 2,
                                    "icon-name": "refrigeration-freezing",
                                    size: "128px"
                                  }))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    }), 128)),
                    _createVNode(_component_router_link, {
                      "data-cy": "lh-navHistoryPage",
                      to: { name: 'HistoryView' },
                      class: "history-item col-span-6 md:col-span-2 xl:col-span-1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('history.show_total')), 1),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_patternlib_icon, {
                            "icon-name": "history",
                            size: "",
                            class: "hydrated"
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    (_ctx.historyItems.length >= 0 && _ctx.historyItems.length < 6)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(6 - _ctx.historyItems.length - 1, (n) => {
                          return (_openBlock(), _createElementBlock("div", {
                            "data-cy": "lh-historyPlaceholderItem",
                            class: "history-item placeholder-item col-span-6 md:col-span-2 xl:col-span-1",
                            key: n
                          }))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header", "isLoading", "error"])
        ])
      ])
    ])
  ]))
}