<template>
  <v-app>
    <div class="app-container">
      <GlobalConfirmationDialog />
      <GlobalToast />
      <div class="app-container__main">
        <AppHeader />
        <NavigationBar />
        <router-view></router-view>
      </div>
      <AppFooter />
    </div>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useLoginStore } from './store/LoginStore';
import GlobalToast from '@/components/global/GlobalToast.vue';
import GlobalConfirmationDialog from '@/components/global/GlobalConfirmationDialog.vue';
import AppFooter from '@/components/global/AppFooter.vue';
import AppHeader from '@/components/global/AppHeader.vue';
import CookieHelper from './helper/CookieHelper';
import NavigationBar from '@/components/global/NavigationBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    GlobalToast,
    GlobalConfirmationDialog,
    AppFooter,
    AppHeader,
    NavigationBar
  },

  setup() {
    const loginStore = useLoginStore();

    const redirectToLastUsedRoute = () => {
      const redirectToCookie = CookieHelper.getCookieByName('redirect_to');
      if (redirectToCookie != '') {
        document.cookie = 'redirect_to=';
        //router.push(`${redirectToCookie}`);
      }
    };
    onMounted(() => {
      initializeLoginStoreWithoutSession();
    });

    const initializeLoginStoreWithoutSession = () => {
      loginStore.initializeLanguage();
      loginStore.initializeDevMode();
      loginStore.initializeChartSettings();
      loginStore.initializeTimeZone();
      loginStore.initializeNotifications();
      redirectToLastUsedRoute();
    };
  },
  data() {
    return {
      buttonEnabled: false,
      accessTokenExpireInSeconds: 0,
      userRoles: []
    };
  },
  methods: {
    async setButtonEnabled() {
      if (this.accessTokenExpireInSeconds > 0) {
        this.buttonEnabled = true;
      } else {
        this.buttonEnabled = false;
      }
      this.accessTokenExpireInSeconds--;
    }
  }
});
</script>

<style lang="scss" src="./App.scss"></style>
