import { BaseHistoryKeyEnum } from '@/helper/BaseHistoryKeyEnum';
import { BaseStateSettings, ZoneStateSettings } from './StateSettings';
import { useLoginStore } from '@/store/LoginStore';
import { storeToRefs } from 'pinia';
import { ChartUserSettings } from '@/types/DeviceValueMapping';

export class BaseStateChartSettings {
  public static getBaseStateChartSettings(chartSettings: ChartUserSettings): BaseStateSettings {
    return {
      state: [
        { key: 'applianceState', value: chartSettings.base.applianceState },
        { key: 'applianceMainMode', value: chartSettings.base.applianceMainMode },
        { key: 'holidayMode', value: chartSettings.base.holidayMode },
        { key: 'partyMode', value: chartSettings.base.partyMode },
        { key: 'energySaverMode', value: chartSettings.base.energySaverMode },
        { key: 'sabbathMode', value: chartSettings.base.sabbathMode },
        { key: 'childLock', value: chartSettings.base.childLock },
        { key: 'nightMode', value: chartSettings.base.nightMode },
        { key: 'ecoMode', value: chartSettings.base.ecoMode },
        { key: 'failureAlarmState', value: chartSettings.base.failureAlarmState }
      ],
      netState: [{ key: 'netInterfaceRssi', value: chartSettings.base.netInterfaceRssi }],
      ambientSensor: [
        { key: 'ambientSensorTemperatureValue', value: chartSettings.base.ambientSensorTemperatureValue }
      ],
      compressor: [
        { key: 'compressorValue', value: chartSettings.base.compressorValue },
        { key: 'compressorErrorGeneral', value: chartSettings.base.compressorErrorGeneral }
      ],
      condenserFan: [
        { key: 'condenserFanValue', value: chartSettings.base.condenserFanValue },
        { key: 'condenserFanErrorGeneral', value: chartSettings.base.condenserFanErrorGeneral }
      ],
      coolingSystem: [
        { key: 'coolingSystemState', value: chartSettings.base.coolingSystemState },
        { key: 'coolingSystemPositionValue', value: chartSettings.base.coolingSystemPositionValue }
      ],
      reminder: [
        { key: 'dustFilter', value: chartSettings.base.dustFilter },
        { key: 'waterFilter', value: chartSettings.base.waterFilter },
        { key: 'airFilter', value: chartSettings.base.airFilter }
      ],
      statusLight: [{ key: 'statusLightState', value: chartSettings.base.statusLightState }],
      presentationLight: [{ key: 'presentationLightValue', value: chartSettings.base.presentationLightValue }],
      waterSystem: [
        {
          key: 'waterSystemWaterDispenserValveState',
          value: chartSettings.base.waterSystemWaterDispenserValveState
        },
        { key: 'waterSystemWaterIceCubeValveState', value: chartSettings.base.waterSystemWaterIceCubeValveState },
        { key: 'waterSystemWaterSafetyValveState', value: chartSettings.base.waterSystemWaterSafetyValveState }
      ],
      busNodes: [{ key: 'softwareVersion', value: chartSettings.base.softwareVersion }]
    };
  }
}

export class ZoneStateChartSettings {
  public static getZoneStateChartSettings(chartSettings: ChartUserSettings): ZoneStateSettings {
    return {
      state: [
        { key: 'state', value: chartSettings.zone.state },
        { key: 'superCool', value: chartSettings.zone.superCool },
        { key: 'superFrost', value: chartSettings.zone.superFrost },
        { key: 'cleaningMode', value: chartSettings.zone.cleaningMode }
      ],
      temperature: [
        { key: 'temperatureDisplayed', value: chartSettings.zone.temperatureDisplayed },
        { key: 'temperatureSetpoint', value: chartSettings.zone.temperatureSetpoint },
        { key: 'temperatureAlarmUpperState', value: chartSettings.zone.temperatureAlarmUpperState },
        {
          key: 'temperatureAlarmUpperTemperatureValue',
          value: chartSettings.zone.temperatureAlarmUpperTemperatureValue
        },
        { key: 'temperatureAlarmLowerState', value: chartSettings.zone.temperatureAlarmLowerState },
        {
          key: 'temperatureAlarmLowerTemperatureValue',
          value: chartSettings.zone.temperatureAlarmLowerTemperatureValue
        }
      ],
      iceMaker: [
        { key: 'iceMakerState', value: chartSettings.zone.iceMakerState },
        { key: 'iceMakerWaterEmptyAlarm', value: chartSettings.zone.iceMakerWaterEmptyAlarm },
        { key: 'iceMakerDrawerMissingAlarm', value: chartSettings.zone.iceMakerDrawerMissingAlarm },
        { key: 'iceMakerDrawerFullAlarm', value: chartSettings.zone.iceMakerDrawerFullAlarm },
        { key: 'iceMakerFailureAlarm', value: chartSettings.zone.iceMakerFailureAlarm },
        { key: 'iceMakerTrayMotorState', value: chartSettings.zone.iceMakerTrayMotorState },
        { key: 'iceMakerFanValue', value: chartSettings.zone.iceMakerFanValue },
        { key: 'iceMakerWaterPumpState', value: chartSettings.zone.iceMakerWaterPumpState },
        { key: 'iceMakerWaterInletHeaterState', value: chartSettings.zone.iceMakerWaterInletHeaterState },
        { key: 'iceMakerWaterTankSensorState', value: chartSettings.zone.iceMakerWaterTankSensorState },
        { key: 'iceMakerDrawerSensorState', value: chartSettings.zone.iceMakerDrawerSensorState },
        { key: 'iceMakerAirSensorErrorGeneral', value: chartSettings.zone.iceMakerAirSensorErrorGeneral },
        { key: 'iceMakerAirSensorTemperatureValue', value: chartSettings.zone.iceMakerAirSensorTemperatureValue },
        { key: 'iceMakerDrawerSensorErrorGeneral', value: chartSettings.zone.iceMakerDrawerSensorErrorGeneral }
      ],
      defrost: [
        { key: 'defrostState', value: chartSettings.zone.defrostState },
        { key: 'defrostHeaterState', value: chartSettings.zone.defrostHeaterState },
        { key: 'defrostHeaterErrorGeneral', value: chartSettings.zone.defrostHeaterErrorGeneral },
        { key: 'defrostManualDefrost', value: chartSettings.zone.defrostManualDefrost },
        { key: 'defrostWaterDrainHeaterState', value: chartSettings.zone.defrostWaterDrainHeaterState },
        {
          key: 'defrostPhasesCountMaxTimeReachedCount',
          value: chartSettings.zone.defrostPhasesCountMaxTimeReachedCount
        },
        { key: 'defrostPhasesCountInefficientCount', value: chartSettings.zone.defrostPhasesCountInefficientCount }
      ],
      alarms: [
        { key: 'powerFailureAlarmUpperState', value: chartSettings.zone.powerFailureAlarmUpperState },
        {
          key: 'powerFailureAlarmUpperTemperatureValue',
          value: chartSettings.zone.powerFailureAlarmUpperTemperatureValue
        },
        { key: 'powerFailureAlarmLowerState', value: chartSettings.zone.powerFailureAlarmLowerState },
        {
          key: 'powerFailureAlarmLowerTemperatureValue',
          value: chartSettings.zone.powerFailureAlarmLowerTemperatureValue
        }
        // {key: 'emergencyAlarmState', value: chartSettings.value.zone.},
      ],
      autoDoor: [
        { key: 'autodoorKnockSensorState', value: chartSettings.zone.autodoorKnockSensorState },
        { key: 'autodoorMotorErrorGeneral', value: chartSettings.zone.autodoorMotorErrorGeneral },
        { key: 'autodoorMotorErrorOverheat', value: chartSettings.zone.autodoorMotorErrorOverheat },
        { key: 'autodoorMotorState', value: chartSettings.zone.autodoorMotorState },
        { key: 'autodoorMotorTemperatureValue', value: chartSettings.zone.autodoorMotorTemperatureValue },
        { key: 'autodoorObstacleAlarm', value: chartSettings.zone.autodoorObstacleAlarm },
        { key: 'autodoorOverheatAlarm', value: chartSettings.zone.autodoorOverheatAlarm }
      ],
      humidity: [
        { key: 'humidityDisplayed', value: chartSettings.zone.humidityDisplayed },
        { key: 'humiditySetpoint', value: chartSettings.zone.humiditySetpoint },
        { key: 'humidityReminderState', value: chartSettings.zone.humidityReminderState }
      ],
      bioFresh: [
        { key: 'bioFresh', value: chartSettings.zone.bioFresh },
        { key: 'biofreshAirSensorTemperatureValue', value: chartSettings.zone.biofreshAirSensorTemperatureValue },
        { key: 'biofreshAirSensorErrorGeneral', value: chartSettings.zone.biofreshAirSensorErrorGeneral }
      ],
      bioFreshPlus: [
        { key: 'biofreshplusHeaterState', value: chartSettings.zone.biofreshplusHeaterState },
        { key: 'biofreshplusHumidifierState', value: chartSettings.zone.biofreshplusHumidifierState },
        { key: 'biofreshplusLightState', value: chartSettings.zone.biofreshplusLightState }
      ],
      humidifier: [{ key: 'humidifierState', value: chartSettings.zone.humidifierState }],
      door: [
        { key: 'door', value: chartSettings.zone.door },
        { key: 'doorAlarmState', value: chartSettings.zone.doorAlarmState },
        { key: 'doorLockState', value: chartSettings.zone.doorLockState }
      ],
      fan: [
        { key: 'fanValue', value: chartSettings.zone.fanValue },
        { key: 'fanErrorGeneral', value: chartSettings.zone.fanErrorGeneral }
      ],
      humidityFan: [
        { key: 'humidityFanValue', value: chartSettings.zone.humidityFanValue },
        { key: 'humidityFanErrorGeneral', value: chartSettings.zone.humidityFanErrorGeneral }
      ],
      light: [{ key: 'lightValue', value: chartSettings.zone.lightValue }],
      airSensor: [
        { key: 'airSensorTemperatureValue', value: chartSettings.zone.airSensorTemperatureValue },
        { key: 'airSensorErrorGeneral', value: chartSettings.zone.airSensorErrorGeneral }
      ],
      evaporatorSensor: [
        { key: 'evaporatorSensorTemperatureValue', value: chartSettings.zone.evaporatorSensorTemperatureValue },
        { key: 'evaporatorSensorErrorGeneral', value: chartSettings.zone.evaporatorSensorErrorGeneral }
      ],
      humiditySensor: [
        { key: 'humiditySensorValue', value: chartSettings.zone.humiditySensorValue },
        { key: 'humiditySensorErrorGeneral', value: chartSettings.zone.humiditySensorErrorGeneral }
      ],
      safetySensor: [
        { key: 'safetySensorTemperatureValue', value: chartSettings.zone.safetySensorTemperatureValue },
        { key: 'safetySensorErrorGeneral', value: chartSettings.zone.safetySensorErrorGeneral }
      ],
      productSensor: [
        { key: 'productSensorTemperatureValue', value: chartSettings.zone.productSensorTemperatureValue },
        { key: 'productSensorErrorGeneral', value: chartSettings.zone.productSensorErrorGeneral }
      ],
      waterDispenser: [
        { key: 'waterDispenserState', value: chartSettings.zone.waterDispenserState },
        { key: 'waterDispenserErrorGeneral', value: chartSettings.zone.waterDispenserErrorGeneral }
      ],
      temperatureCompensationHeater: [
        { key: 'temperatureCompensationHeaterState', value: chartSettings.zone.temperatureCompensationHeaterState }
      ],
      wineHeater: [{ key: 'wineHeaterState', value: chartSettings.zone.wineHeaterState }]
    };
  }
}
