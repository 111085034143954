import { ApplianceStateApiFactory, ZoneInfo, ZoneState } from '@/api';
import TextPill from '@/components/basic/KeyValueView/components/TextPill.vue';
import { EntryType, KeyValueViewConfig, PillStatusEnum } from '@/components/basic/KeyValueView/KeyValueView';
import KeyValueView from '@/components/basic/KeyValueView/KeyValueView.vue';
import Modal from '@/components/basic/Modal/AppModal.vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import HttpHelper from '@/helper/HttpResponseHelper';
import { i18n } from '@/plugins/i18n';
import { useLoginStore } from '@/store/LoginStore';
import { VueHttpRequestStateT } from '@/types';
import { ModalTypeEnum } from '@/views/index/smartdevice/components/ModalTypeEnum';
import { ZoneStateHelper } from '@/views/index/smartdevice/components/zone-state/ZoneStateHelper';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ZoneState',
  components: {
    Modal,
    KeyValueView,
    TextPill,
    TextPlaceHolder,
    LoadingSpinner
  },
  props: {
    serialId: {
      type: String,
      required: true
    },
    isAvailableAtSmartDevice: {
      type: Boolean,
      default: false
    }
  },
  emits: ['evtZoneState'],
  setup(props, { emit }) {
    const httpReq = reactive<VueHttpRequestStateT>({ isLoading: false });
    const zoneState = ref<Array<ZoneState> | undefined>([]);
    const zoneInfo = ref<Array<ZoneInfo> | undefined>([]);
    const zoneData = ref<Array<ZoneState & ZoneInfo>>([]);
    const { timeZone } = storeToRefs(useLoginStore());
    const { t } = useI18n();
    let temperatureUnit = '(°C)';
    const modalSettings = ref();
    const { getConfig } = useHttpConfiguration();
    const config = getConfig();

    modalSettings.value = {
      isModalOpen: false,
      currentModal: '',
      data: undefined,
      config: undefined,
      title: '',
      icon: ''
    };

    const zoneInfoRequest = ApplianceStateApiFactory(config).getZoneInfo(props.serialId);
    const zoneStateRequest = ApplianceStateApiFactory(config).getZoneState(props.serialId);

    const formatBioFreshPlus = (value: number): string => {
      switch (value) {
        case 0:
          return '0°/0°';
        case 1:
          return '0°/-2°';
        case 2:
          return '-2°/0°';
        case 3:
          return '-2°/-2°';
      }
      return '';
    };

    const calcAlarmState = (alarmState?: number | null | undefined) => {
      const formattedAlarmState = Number(alarmState);
      if (formattedAlarmState != 0) {
        return 1;
      } else {
        return 0;
      }
    };

    const countZoneAlarm = (zoneId: number) => {
      if (!zoneId) {
        return 0;
      }

      const currentZone = zoneState.value?.find(f => f.id === zoneId);
      if (!currentZone) {
        return 0;
      }

      if (currentZone) {
        return (
          calcAlarmState(currentZone.doorAlarmState) +
          calcAlarmState(currentZone.temperatureAlarmLowerState) +
          calcAlarmState(currentZone.temperatureAlarmUpperState) +
          calcAlarmState(currentZone.powerFailureAlarmLowerState) +
          calcAlarmState(currentZone.powerFailureAlarmUpperState)
        );
      }

      return 0;
    };

    const createZoneStateTable = (zone: ZoneState & ZoneInfo) => {
      const config: KeyValueViewConfig<
        ZoneState & {
          id: unknown;
        } & ZoneInfo
      > = {
        item: { height: '48px', labelWidth: '50%', noSeparator: false },
        props: {
          state: {
            description: { text: t('device.zone.state') },
            type: EntryType.PILL,
            isHidden: false,
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: { value: t('general.on'), type: PillStatusEnum.SUCCESS }
            }
          },
          door: {
            description: {
              text: `${t('device.zone.door_state')} ${ZoneStateHelper.getAdditionalDescriptionText(zone.doorShortText)}`
            },
            type: EntryType.CUSTOM,
            isHidden: false,
            pillOptions: {
              0: { value: t('general.closed'), type: PillStatusEnum.SUCCESS },
              1: { value: t('general.opened'), type: PillStatusEnum.WARNING }
            }
          },
          superCool: {
            description: { text: t('device.zone.super_cool') },
            type: EntryType.PILL,
            isHidden: false,
            hideIfNotAvailable: true,
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: { value: t('general.on'), type: PillStatusEnum.SUCCESS }
            }
          },
          superFrost: {
            description: { text: t('device.zone.super_frost') },
            type: EntryType.PILL,
            isHidden: false,
            hideIfNotAvailable: true,
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: { value: t('general.on'), type: PillStatusEnum.SUCCESS }
            }
          },
          iceMakerState: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: { text: t('device.zone.ice_maker') },
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: { value: t('general.on'), type: PillStatusEnum.SUCCESS },
              2: { value: t('icemaker.max_ice'), type: PillStatusEnum.SUCCESS }
            }
          },
          autoDoorMode: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: { text: t('device.zone.auto_door') },
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: {
                value: t('autodoor.open_and_close'),
                type: PillStatusEnum.SUCCESS
              },
              2: {
                value: t('autodoor.close'),
                type: PillStatusEnum.SUCCESS
              },
              3: { value: t('autodoor.open'), type: PillStatusEnum.SUCCESS }
            }
          },
          cleaningMode: {
            description: { text: t('device.zone.cleaning_mode') },
            type: EntryType.PILL,
            isHidden: false,
            hideIfNotAvailable: true,
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.DEFAULT },
              1: { value: t('general.on'), type: PillStatusEnum.SUCCESS }
            }
          },
          bioFresh: {
            description: { text: t('general.biofresh') },
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            pillOptions: {
              0: { value: '0', type: PillStatusEnum.DEFAULT },
              1: { value: '1', type: PillStatusEnum.DEFAULT },
              2: { value: '2°', type: PillStatusEnum.DEFAULT },
              3: { value: '3', type: PillStatusEnum.DEFAULT },
              4: { value: '4', type: PillStatusEnum.DEFAULT },
              5: { value: '5', type: PillStatusEnum.DEFAULT },
              6: { value: '6', type: PillStatusEnum.DEFAULT },
              7: { value: '7', type: PillStatusEnum.DEFAULT },
              8: { value: '8', type: PillStatusEnum.DEFAULT },
              9: { value: '9', type: PillStatusEnum.DEFAULT }
            }
          },
          bioFreshPlus: {
            description: { text: t('device.zone.bioFreshPlus') },
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            pillOptions: {
              0: { value: '0°/0°', type: PillStatusEnum.DEFAULT },
              1: { value: '0°/-2°', type: PillStatusEnum.DEFAULT },
              2: { value: '-2°/0°', type: PillStatusEnum.DEFAULT },
              3: { value: '-2°/-2°', type: PillStatusEnum.DEFAULT }
            }
          },
          humidityReminderState: {
            description: { text: t('device.zone.humidity_reminder_state') },
            type: EntryType.PILL,
            isHidden: zone.capsHumidityReminder !== 1,
            hideIfNotAvailable: true,
            pillOptions: {
              0: { value: t('general.off'), type: PillStatusEnum.SUCCESS },
              1: { value: t('device.zone.humidity_reminder_active_no_refill'), type: PillStatusEnum.WARNING },
              2: { value: t('device.zone.humidity_reminder_active_refill'), type: PillStatusEnum.ALERT }
            }
          },
          temperatureDisplayed: {
            description: {
              text: `${t('zonestate.temperature_displayed')} ${temperatureUnit}`
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT
          },
          temperatureSetpoint: {
            description: {
              text: `${t('zonestate.temperature_setpoint')} ${temperatureUnit}`
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT
          },
          id: {
            type: EntryType.CUSTOM,
            description: { text: 'Alarm' }
          },
          defrostCountValue: {
            description: { text: t('device.zone.defrost_count') },
            type: EntryType.TEXT,
            isHidden: false,
            hideIfNotAvailable: true
          },
          defrostPhasesCountMaxTimeReachedCount: {
            description: { text: t('device.zone.defrost_count_max_time_out') },
            type: EntryType.TEXT,
            isHidden: false,
            hideIfNotAvailable: true,
            tooltip: true,
            tooltipContent: 'Number of DefrostCycles ended due to heater timeout'
          },
          defrostPhasesCountInefficientCount: {
            description: { text: t('device.zone.defrost_inefficient_count') },
            type: EntryType.TEXT,
            isHidden: false,
            hideIfNotAvailable: true,
            tooltip: true,
            tooltipContent: 'Number of 5 timeout DefrostCycles in a row'
          },
          temperatureCelsiusMin: {
            description: {
              text: t('zoneinfo.min_temperature_celsius')
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            isHidden: zoneState.value?.some(x => x.temperatureUnit !== 0)
          },
          temperatureCelsiusMax: {
            description: {
              text: t('zoneinfo.max_temperature_celsius')
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            isHidden: zoneState.value?.some(x => x.temperatureUnit !== 0)
          },
          temperatureFahrenheitMin: {
            description: {
              text: t('zoneinfo.min_temperature_fahrenheit')
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            isHidden: zoneState.value?.some(x => x.temperatureUnit === 0)
          },
          temperatureFahrenheitMax: {
            description: {
              text: t('zoneinfo.max_temperature_fahrenheit')
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            isHidden: zoneState.value?.some(x => x.temperatureUnit === 0)
          },
          humiditySetpoint: {
            description: {
              text: `${t('zonestate.humidity_setpoint')}`
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT
          },
          humidityDisplayed: {
            description: {
              text: `${t('zonestate.humidity_displayed')}`
            },
            hideIfNotAvailable: true,
            type: EntryType.TEXT
          },
          defrostHeaterErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `DefrostHeater (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(
                zone.defrostHeaterShortText
              )}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          fanErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `Fan (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(zone.fanShortText)}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          airSensorErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `Air sensor (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(zone.airSensorShortText)}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          evaporatorSensorErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `Evaporator sensor (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(
                zone.evaporatorSensorShortText
              )}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          iceMakerAirSensorErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `IceMakerAirSensor (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(
                zone.iceMakerAirSensorShortText
              )}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          safetySensorTemperatureValue: {
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            description: {
              text: `Safety sensor ${ZoneStateHelper.getAdditionalDescriptionText(zone.safetySensorShortText)}`
            }
          },
          safetySensorErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.PILL,
            description: {
              text: `Safety sensor (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(
                zone.safetySensorShortText
              )}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          productSensorTemperatureValue: {
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            description: {
              text: `Product sensor ${ZoneStateHelper.getAdditionalDescriptionText(zone.productSensorShortText)}`
            }
          },
          productSensorErrorGeneral: {
            hideIfNotAvailable: true,
            type: EntryType.PILL,
            description: {
              text: `Product sensor (ErrorGeneral) ${ZoneStateHelper.getAdditionalDescriptionText(
                zone.productSensorShortText
              )}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.SUCCESS,
                value: t('general.no')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.yes')
              }
            }
          },
          humidifierState: {
            hideIfNotAvailable: true,
            type: EntryType.CUSTOM,
            description: {
              text: `Humidifier State ${ZoneStateHelper.getAdditionalDescriptionText(zone.humidifierShortText)}`
            },
            pillOptions: {
              0: {
                type: PillStatusEnum.DEFAULT,
                value: t('general.off')
              },
              1: {
                type: PillStatusEnum.WARNING,
                value: t('general.on')
              }
            }
          },
          temperatureAlarmUpperDelay: {
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            description: {
              text: t('zonestate.temp_alarm_upper_delay')
            }
          },
          temperatureAlarmLowerDelay: {
            hideIfNotAvailable: true,
            type: EntryType.TEXT,
            description: {
              text: t('zonestate.temp_alarm_lower_delay')
            }
          }
        }
      };
      return config;
    };

    const getHeadline = (zone: ZoneState) => {
      return `${t('device.zone.' + String(zone.typeName).toLowerCase())} - ${zone.zoneIndex}`;
    };

    const formatDateTime = (datetime: string) => {
      return DateTimeFormatter.getFormattedDateTimeLongFormat(`${datetime}Z`, timeZone.value);
    };

    const displayModal = (key: ModalTypeEnum, zoneId: number) => {
      if (zoneId === -1) {
        return;
      }

      const zone = zoneState.value?.find(el => el.id === zoneId);

      if (!zone) {
        return;
      }

      retrieveModalSettings(key, zone, zoneId);
    };

    const handleModalClosure = () => {
      modalSettings.value.isModalOpen = false;
      modalSettings.value.img = undefined;
      modalSettings.value.type = undefined;
    };

    const formatToDateTime = (timeInSeconds: number) => {
      if (!timeInSeconds) {
        return '-';
      }
      return DateTime.fromSeconds(timeInSeconds)
        .setLocale(i18n.global.locale.value)
        .toLocaleString(DateTime.DATETIME_SHORT);
    };

    const headerTitle = (zone: ZoneState) => {
      return `${zone.typeName} - ${zone.zoneIndex}`;
    };

    const transformToZoneData = () => {
      if (zoneState.value && zoneInfo.value) {
        temperatureUnit = zoneState.value[0]?.temperatureUnit === 0 ? temperatureUnit : '(°F)';
        zoneData.value = zoneState.value.map(state => {
          const info = zoneInfo.value?.find(x => x.zoneIndex === state.zoneIndex);
          const zone: ZoneState & ZoneInfo = {
            serialNo: state.serialNo,
            id: state?.id,
            type: info?.type,
            typeName: info?.typeName,
            zoneIndex: info?.zoneIndex,
            state: state?.state,
            door: state?.door,
            superCool: state?.superCool,
            superFrost: state?.superFrost,
            iceMakerState: state?.iceMakerState,
            autoDoorMode: state?.autoDoorMode,
            cleaningMode: state?.cleaningMode,
            bioFreshPlus: state?.bioFreshPlus,
            bioFresh: state?.bioFresh,
            biofreshStepShortText: state.biofreshStepShortText,
            biofreshStepValue: state?.biofreshStepValue,
            biofreshStepMin: state?.biofreshStepMin,
            biofreshStepMax: state?.biofreshStepMax,
            biofreshAirSensorShortText: state?.biofreshAirSensorShortText,
            biofreshAirSensorTemperatureValue: state?.biofreshAirSensorTemperatureValue,
            biofreshAirSensorErrorGeneral: state?.biofreshAirSensorErrorGeneral,
            biofreshAirSensorErrlogTime: state?.biofreshAirSensorErrlogTime,
            biofreshAirSensorErrlogCount: state?.biofreshAirSensorErrlogCount,
            temperatureDisplayed: state?.temperatureDisplayed,
            temperatureSetpoint: state?.temperatureSetpoint,
            defrostCountValue: state?.defrostCountValue,
            defrostPhasesCountMaxTimeReachedCount: state?.defrostPhasesCountMaxTimeReachedCount,
            defrostPhasesCountInefficientCount: state?.defrostPhasesCountInefficientCount,
            temperatureCelsiusMin: info?.temperatureCelsiusMin,
            temperatureCelsiusMax: info?.temperatureCelsiusMax,
            temperatureFahrenheitMin: info?.temperatureFahrenheitMin,
            temperatureFahrenheitMax: info?.temperatureFahrenheitMax,
            changedAtUtc: state?.changedAtUtc,
            defrostHeaterErrorGeneral: state?.defrostHeaterErrorGeneral,
            defrostHeaterErrlogTime: state?.defrostHeaterErrlogTime,
            defrostHeaterErrlogCount: state?.defrostHeaterErrlogCount,
            fanErrorGeneral: state?.fanErrorGeneral,
            fanErrlogCount: state?.fanErrlogCount,
            fanErrlogTime: state?.fanErrlogTime,
            airSensorErrorGeneral: state?.airSensorErrorGeneral,
            airSensorErrlogCount: state?.airSensorErrlogCount,
            airSensorErrlogTime: state?.airSensorErrorGeneral,
            evaporatorSensorErrorGeneral: state?.evaporatorSensorErrorGeneral,
            evaporatorSensorErrlogCount: state?.evaporatorSensorErrlogCount,
            evaporatorSensorErrlogTime: state?.evaporatorSensorErrlogTime,
            iceMakerAirSensorErrorGeneral: state?.iceMakerAirSensorErrorGeneral,
            iceMakerAirSensorErrlogCount: state?.iceMakerAirSensorErrlogCount,
            iceMakerAirSensorErrlogTime: state?.iceMakerAirSensorErrlogTime,
            doorShortText: state?.doorShortText,
            doorOpenCountShortText: state?.doorOpenCountShortText,
            biofreshplusUpperAirBaffleShortText: state?.biofreshplusUpperAirBaffleShortText,
            biofreshplusLowerAirBaffleShortText: state?.biofreshplusLowerAirBaffleShortText,
            biofreshplusHeaterShortText: state?.biofreshplusHeaterShortText,
            biofreshplusLightShortText: state?.biofreshplusLightShortText,
            biofreshplusHumidifierShortText: state?.biofreshplusHumidifierShortText,
            biofreshplusModeShortText: state?.biofreshplusModeShortText,
            iceMakerStatusShortText: state?.iceMakerStatusShortText,
            iceMakerWaterTankSensorShortText: state?.iceMakerWaterTankSensorShortText,
            iceMakerIceCubeSizeShortText: state?.iceMakerIceCubeSizeShortText,
            iceMakerEjectionCountShortText: state?.iceMakerEjectionCountShortText,
            iceMakerTrayMotorShortText: state?.iceMakerTrayMotorShortText,
            iceMakerWaterPumpShortText: state?.iceMakerWaterPumpShortText,
            iceMakerAirSensorShortText: state?.iceMakerAirSensorShortText,
            iceMakerDrawerSensorShortText: state?.iceMakerDrawerSensorShortText,
            iceMakerWaterInletHeaterShortText: state?.iceMakerWaterInletHeaterShortText,
            iceMakerLightShortText: state?.iceMakerLightShortText,
            autoDoorKnockSensorShortText: state?.autoDoorKnockSensorShortText,
            autoDoorOpenCountShortText: state?.autoDoorOpenCountShortText,
            defrostHeaterShortText: state?.defrostHeaterShortText,
            defrostWaterDrainHeaterShortText: state?.defrostWaterDrainHeaterShortText,
            fanShortText: state?.fanShortText,
            airSensorShortText: state?.airSensorShortText,
            evaporatorSensorShortText: state?.evaporatorSensorShortText,
            humidityFanShortText: state?.humidityFanShortText,
            waterDispenserShortText: state?.waterDispenserShortText,
            wineHeaterShortText: state?.wineHeaterShortText,
            humidityDisplayed: state?.humidityDisplayed,
            humiditySetpoint: state?.humiditySetpoint,
            safetySensorTemperatureValue: state?.safetySensorTemperatureValue,
            safetySensorShortText: state?.safetySensorShortText,
            safetySensorErrorGeneral: state?.safetySensorErrorGeneral,
            productSensorErrorGeneral: state?.productSensorErrorGeneral,
            productSensorShortText: state?.productSensorShortText,
            productSensorTemperatureValue: state?.productSensorTemperatureValue,
            humidifierState: state?.humidifierState,
            humidifierShortText: state?.humidifierShortText
          };
          return zone;
        });
      }
    };

    const fetchZoneData = async () => {
      const [zoneInfoResponse, zoneStateResponse] = await Promise.all([zoneInfoRequest, zoneStateRequest]);

      zoneInfo.value = HttpHelper.extractRequestDataWithoutError<Array<ZoneInfo>>(zoneInfoResponse);
      zoneState.value = HttpHelper.extractRequestDataWithoutError<Array<ZoneState>>(zoneStateResponse);

      emit('evtZoneState', zoneState.value);

      transformToZoneData();
    };

    const retrieveModalSettings = (key: ModalTypeEnum, zone: ZoneState, zoneId: number) => {
      switch (key) {
        case ModalTypeEnum.ZONEFAILURE:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.ZONEFAILURE, t);
          break;

        case ModalTypeEnum.ICEMAKER:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.ICEMAKER, t);
          break;
        case ModalTypeEnum.AUTODOOR:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.AUTODOOR, t);
          break;
        case ModalTypeEnum.DOOR:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.DOOR, t);
          break;
        case ModalTypeEnum.BIOFRESHPLUS:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.BIOFRESHPLUS, t);
          break;
        case ModalTypeEnum.BIOFRESH:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.BIOFRESH, t);
          break;
        case ModalTypeEnum.DEFROSTHEATER:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.DEFROSTHEATER, t);
          break;
        case ModalTypeEnum.FAN:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.FAN, t);
          break;
        case ModalTypeEnum.AirSensor:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.AirSensor, t);
          break;
        case ModalTypeEnum.EvaporatorSensor:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.EvaporatorSensor, t);
          break;
        case ModalTypeEnum.IceMakerAirSensor:
          modalSettings.value = ZoneStateHelper.getModalSettings(zoneId, zone, ModalTypeEnum.IceMakerAirSensor, t);
          break;
      }
    };

    const zoneStateRenderProps = (zone: ZoneState & ZoneInfo) => {
      return createZoneStateTable(zone);
    };

    onMounted(async () => {
      await fetchZoneData();
    });

    return {
      zoneState,
      zoneInfo,
      zoneStateRenderProps,
      displayModal,
      countZoneAlarm,
      formatDateTime,
      modalSettings,
      handleModalClosure,
      httpReq,
      formatToDateTime,
      headerTitle,
      zoneData,
      getHeadline,
      ModalTypeEnum
    };
  }
});
