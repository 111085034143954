export enum OperationStatus {
  OFF,
  ON
}
export enum AvailableStatus {
  AVAILABLE,
  NOT_AVAIABLE
}
export enum FilterStatus {
  Expired,
  Expired_and_confirmed,
  Ok
}

export enum DoorState {
  CLOSED,
  MOVING,
  MOVING2,
  OPEN
}
export enum IceMakerState {
  AVAILABLE,
  AVAILABLE_MAX_ICE,
  NOT_AVAIABLE
}

export default class ChartConstants {
  static readonly BASE_COLORS = class {
    static readonly currentTemperature = '#12c8ff';
    static readonly setTemperature = '#769da8';
    static readonly ALARM_TEMPERATURE = '#a11b00';
    static readonly ICEMAKER = '#3de2ff';

    static readonly OK = '#12c8ff';
    static readonly WARNING = '#eda302';
    static readonly ERROR = '#f55d42';
    static readonly CONFIRMED = '#1b6be3';
    static readonly BASE = '#e9f2f2';
    static readonly FANSPEED_1 = '#af93bf';
    static readonly FANSPEED_2 = '#93B9BF';
    static readonly COLOR_TEAL = '#008080';
    static readonly COLOR_SALMON = '#fa8072';
    static readonly COLOR_DARK_GREY = '#62788d';
    static readonly COLOR_YELLOW = '#ffc200';
    static readonly COLOR_PINK = '#ffb3b3';
    static readonly COLOR_ORANGE = '#ffcc80';
    static readonly COLOR_LILA = '#ccb3ff';
    static readonly COLOR_GREEN: string = '#66d27c';
    static readonly COLOR_SOME_PURPLE: string = '#5499c7';
    static readonly COLOR_DARK_PINK: string = '#ea4897';
    static readonly COLOR_PURPLE: string = '#5948ea';
    static readonly COLOR_SOMETHING: string = '#d500ff';
  };
  static readonly TimelineValues = class {
    static readonly ALARM = {
      0: 'Inactive',
      1: 'Active',
      2: 'Active and confirmed'
    };
    static readonly DOOR_LOCK_STATE = {
      0: 'Door (Unlocked)',
      1: 'Door (Locked)',
      2: 'Emergency access'
    };
    static readonly FILTER = {
      0: 'Off',
      1: 'On (Filter Ok)',
      2: 'On (Expired)'
    };
    static readonly OPERATION_STATUS = {
      0: 'OFF',
      1: 'ON'
    };

    static readonly NET_INTERFACE_RSSI_STATE = {
      0: 'LOW',
      1: 'MEDIUM',
      2: 'HIGH'
    };

    static readonly DEFROST_STATE = {
      0: 'DEFROST_OFF',
      1: 'DEFROST_PREDEFROST',
      2: 'DEFROST_OFF_AFTER_PREDEFROST',
      3: 'DEFROST_HEATING',
      4: 'DEFROST_OFF_AFTER_HEATING',
      5: 'DEFROST_ZONE1_PHASE',
      6: 'DEFROST_HOTGAS_PREDEFROST',
      7: 'DEFROST_HOTGAS_PRESTOP_HEATING'
    };

    static readonly HUMIDITY_REMINDER = {
      0: 'OFF',
      1: 'ON (No refill)',
      2: 'ON (Refill required)'
    };

    static readonly ICE_MAKERSTATE = {
      0: 'OFF',
      1: 'ON',
      2: 'MAX_ICE'
    };

    static readonly ICE_MAKER_TRAY_MOTOR_STATE = {
      0: 'UNKNOWN',
      1: 'HOME',
      2: 'EJECTION',
      3: 'EJECTION_BACK',
      4: 'ABORT',
      5: 'BREAK',
      6: 'CONTINUE'
    };

    static readonly DOOR = { 0: 'CLOSED', 1: 'OPEN', 2: 'OPEN', 3: 'OPEN' };
    static readonly BIOFRESH = {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9'
    };
    static readonly BASE_STATE = {
      0: 'APPLICATION_INIT_NO',
      1: 'APPLICATION_INIT_HW',
      2: 'APPLICATION_WAIT_FOR_SYSTEM_INIT',
      3: 'APPLICATION_INIT_MAINMODES',
      4: 'APPLICATION_RUNNING_MAINMODES',
      5: 'APPLICATION_STOPPED',
      6: 'APPLICATION_INIT_MAINMODES_POWERFAILURE',
      7: 'APPLICATION_FACTORY_RESET_NEEDED',
      8: 'APPLICATION_ROBUSTNESS_RESET',
      252: 'APPLICATION_ERROR_NV',
      253: 'APPLICATION_ERROR_PERS',
      254: 'APPLICATION_ERROR_HW',
      255: 'APPLICATION_ERROR_GENERIC'
    };

    static readonly BASE_MAIN_MODE = {
      0: 'MAINMODE_COOLING',
      1: 'MAINMODE_DEMO',
      2: 'MAINMODE_REMOTE',
      3: 'MAINMODE_RFU',
      4: 'MAINMODE_EOL_TEST',
      5: 'MAINMODE_EMC_TEST',
      6: 'MAINMODE_SERVICE',
      7: 'MAINMODE_BATTERY',
      8: 'MAINMODE_DEMO_REMOTE'
    };

    static readonly COOLING_STATE = {
      0: 'POS_INIT',
      1: 'POS_CLOSE',
      2: 'POS_A',
      3: 'POS_B',
      4: 'POS_C',
      5: 'POS_MID',
      6: 'POS_OPEN'
    };

    static readonly STATUS_LIGHT_STATE = {
      0: 'OFF',
      1: 'OK',
      2: 'ALARM'
    };

    static readonly WATER_VALVE_STATE = {
      0: 'CLOSE',
      1: 'OPEN'
    };

    static readonly SENSOR_KNOCK_STATE = {
      0: 'IDLE',
      1: 'KNOCK'
    };

    static readonly AUTO_DOOR_MOTOR_ERROR = {
      0: 'OK',
      1: 'ERROR'
    };

    static readonly SERVICE_MODE_STATE = {
      0: 'NORMAL_MODE',
      1: 'SERVICE_MODE'
    };

    static readonly ECO_MODE_STATE = {
      0: 'OFF',
      1: 'ECO_MODE',
      2: 'ECO_MODE_PLUS'
    };

    static readonly TEMP_MODE = { 0: 'Celcius', 1: 'Fahrenheit' };
  };

  static readonly TimelineColors = class {
    static readonly ALARM = {
      Inactive: ChartConstants.BASE_COLORS.BASE,
      Active: ChartConstants.BASE_COLORS.WARNING,
      'Active and confirmed': ChartConstants.BASE_COLORS.CONFIRMED
    };
    static readonly DOOR_LOCK_STATE = {
      'Door (Unlocked)': ChartConstants.BASE_COLORS.BASE,
      'Door (Locked)': ChartConstants.BASE_COLORS.OK,
      'Emergency access': ChartConstants.BASE_COLORS.ERROR
    };
    static readonly TEMP_MODE = {
      Celcius: ChartConstants.BASE_COLORS.OK,
      Fahrenheit: ChartConstants.BASE_COLORS.CONFIRMED
    };
    static readonly OPERATION_STATUS = {
      OFF: ChartConstants.BASE_COLORS.BASE,
      ON: ChartConstants.BASE_COLORS.OK
    };
    static readonly ICE_MAKERSTATE = {
      OFF: ChartConstants.BASE_COLORS.BASE,
      ON: ChartConstants.BASE_COLORS.OK,
      MAX_ICE: ChartConstants.BASE_COLORS.WARNING
    };

    static readonly DEFROST_STATE = {
      DEFROST_OFF: ChartConstants.BASE_COLORS.BASE,
      DEFROST_PREDEFROST: ChartConstants.BASE_COLORS.OK,
      DEFROST_OFF_AFTER_PREDEFROST: ChartConstants.BASE_COLORS.COLOR_LILA,
      DEFROST_HEATING: ChartConstants.BASE_COLORS.ERROR,
      DEFROST_OFF_AFTER_HEATING: ChartConstants.BASE_COLORS.COLOR_PURPLE,
      DEFROST_ZONE1_PHASE: ChartConstants.BASE_COLORS.COLOR_SALMON,
      DEFROST_HOTGAS_PREDEFROST: ChartConstants.BASE_COLORS.COLOR_SALMON,
      DEFROST_HOTGAS_PRESTOP_HEATING: ChartConstants.BASE_COLORS.COLOR_SOMETHING
    };

    static readonly ICE_MAKER_TRAY_MOTOR_STATE = {
      UNKNOWN: ChartConstants.BASE_COLORS.WARNING,
      HOME: ChartConstants.BASE_COLORS.BASE,
      EJECTION: ChartConstants.BASE_COLORS.COLOR_TEAL,
      EJECTION_BACK: ChartConstants.BASE_COLORS.COLOR_SALMON,
      ABORT: ChartConstants.BASE_COLORS.COLOR_LILA,
      BREAK: ChartConstants.BASE_COLORS.COLOR_GREEN,
      CONTINUE: ChartConstants.BASE_COLORS.COLOR_DARK_PINK
    };

    static readonly DOOR = {
      CLOSED: ChartConstants.BASE_COLORS.BASE,
      OPEN: ChartConstants.BASE_COLORS.OK
    };
    static readonly BIOFRESH = {
      0: ChartConstants.BASE_COLORS.BASE,
      1: ChartConstants.BASE_COLORS.BASE,
      2: ChartConstants.BASE_COLORS.BASE,
      3: ChartConstants.BASE_COLORS.BASE,
      4: ChartConstants.BASE_COLORS.BASE,
      5: ChartConstants.BASE_COLORS.BASE,
      6: ChartConstants.BASE_COLORS.BASE,
      7: ChartConstants.BASE_COLORS.BASE,
      8: ChartConstants.BASE_COLORS.BASE,
      9: ChartConstants.BASE_COLORS.BASE
    };
    static readonly BASE_STATE = {
      APPLICATION_INIT_NO: ChartConstants.BASE_COLORS.WARNING,
      APPLICATION_INIT_HW: ChartConstants.BASE_COLORS.COLOR_LILA,
      APPLICATION_WAIT_FOR_SYSTEM_INIT: ChartConstants.BASE_COLORS.COLOR_PINK,
      APPLICATION_INIT_MAINMODES: ChartConstants.BASE_COLORS.OK,
      APPLICATION_RUNNING_MAINMODES: ChartConstants.BASE_COLORS.BASE,
      APPLICATION_STOPPED: ChartConstants.BASE_COLORS.COLOR_SOME_PURPLE,
      APPLICATION_INIT_MAINMODES_POWERFAILURE: ChartConstants.BASE_COLORS.WARNING,
      APPLICATION_FACTORY_RESET_NEEDED: ChartConstants.BASE_COLORS.COLOR_SALMON,
      APPLICATION_ROBUSTNESS_RESET: ChartConstants.BASE_COLORS.COLOR_GREEN,
      APPLICATION_ERROR_NV: ChartConstants.BASE_COLORS.ERROR,
      APPLICATION_ERROR_PERS: ChartConstants.BASE_COLORS.ERROR,
      APPLICATION_ERROR_HW: ChartConstants.BASE_COLORS.ERROR,
      APPLICATION_ERROR_GENERIC: ChartConstants.BASE_COLORS.ERROR
    };

    static readonly BASE_MAIN_MODE = {
      MAINMODE_COOLING: ChartConstants.BASE_COLORS.BASE,
      MAINMODE_DEMO: ChartConstants.BASE_COLORS.BASE,
      MAINMODE_REMOTE: ChartConstants.BASE_COLORS.OK,
      MAINMODE_RFU: ChartConstants.BASE_COLORS.COLOR_LILA,
      MAINMODE_EOL_TEST: ChartConstants.BASE_COLORS.COLOR_ORANGE,
      MAINMODE_EMC_TEST: ChartConstants.BASE_COLORS.COLOR_DARK_PINK,
      MAINMODE_SERVICE: ChartConstants.BASE_COLORS.COLOR_YELLOW,
      MAINMODE_BATTERY: ChartConstants.BASE_COLORS.WARNING,
      MAINMODE_DEMO_REMOTE: ChartConstants.BASE_COLORS.COLOR_SOME_PURPLE
    };

    static readonly FILTER = {
      Off: ChartConstants.BASE_COLORS.BASE,
      'On (Filter Ok)': ChartConstants.BASE_COLORS.OK,
      'On (Expired)': ChartConstants.BASE_COLORS.WARNING
    };

    static readonly NET_INTERFACE_RSSI = {
      LOW: ChartConstants.BASE_COLORS.ERROR,
      MEDIUM: ChartConstants.BASE_COLORS.WARNING,
      HIGH: ChartConstants.BASE_COLORS.BASE
    };

    static readonly HUMIDITY_REMINDER = {
      OFF: ChartConstants.BASE_COLORS.BASE,
      'ON (No refill)': ChartConstants.BASE_COLORS.WARNING,
      'ON (Refill required)': ChartConstants.BASE_COLORS.ERROR
    };

    static readonly COOLING_STATE = {
      POS_INIT: ChartConstants.BASE_COLORS.OK,
      POS_CLOSE: ChartConstants.BASE_COLORS.ERROR,
      POS_A: '#AF93BF',
      POS_B: '#93B9BF',
      POS_C: '#BF93A3',
      POS_MID: ChartConstants.BASE_COLORS.COLOR_SALMON,
      POS_OPEN: ChartConstants.BASE_COLORS.COLOR_TEAL
    };
    static readonly STATUS_LIGHT_STATE = {
      OFF: ChartConstants.BASE_COLORS.BASE,
      OK: ChartConstants.BASE_COLORS.OK,
      ALARM: ChartConstants.BASE_COLORS.ERROR
    };
    static readonly WATER_VALVE_STATE = {
      CLOSE: ChartConstants.BASE_COLORS.BASE,
      OPEN: ChartConstants.BASE_COLORS.OK
    };

    static readonly KNOCK_SENSOR_STATE = {
      IDLE: ChartConstants.BASE_COLORS.BASE,
      KNOCK: ChartConstants.BASE_COLORS.WARNING
    };

    static readonly AUTO_DOOR_MOTOR_ERROR = {
      OK: ChartConstants.BASE_COLORS.BASE,
      ERROR: ChartConstants.BASE_COLORS.ERROR
    };

    static readonly SERVICE_MODE_STATE = {
      NORMAL_MODE: ChartConstants.BASE_COLORS.BASE,
      SERVICE_MODE: ChartConstants.BASE_COLORS.WARNING
    };

    static readonly ECO_MODE_STATE = {
      OFF: ChartConstants.BASE_COLORS.BASE,
      ECO_MODE: ChartConstants.BASE_COLORS.COLOR_LILA,
      ECO_MODE_PLUS: ChartConstants.BASE_COLORS.COLOR_TEAL
    };
  };
}
