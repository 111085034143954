import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { slot: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_image = _resolveComponent("patternlib-image")!
  const _component_patternlib_modal = _resolveComponent("patternlib-modal")!

  return (_openBlock(), _createBlock(_component_patternlib_modal, {
    class: "appModal",
    ref: "modalRef",
    "allow-close-on-backdrop": "true",
    height: "auto",
    "max-height": "100vh",
    "hide-cancel": "true",
    "hide-submit": "true",
    "icon-name": _ctx.icon,
    "keep-open-on-confirm": "false",
    "margin-top": "auto",
    "modal-title": _ctx.title,
    show: "true",
    "show-icon": "true",
    width: "700px",
    "img-position": "top"
  }, {
    default: _withCtx(() => [
      (_ctx.imgSrc)
        ? (_openBlock(), _createBlock(_component_patternlib_image, {
            key: 0,
            slot: "img",
            src: _ctx.imgSrc,
            alt: "Testimage",
            width: "100%",
            height: "100%"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["icon-name", "modal-title"]))
}