<template>
  <div>
    <patternlib-headline
      v-if="config.header"
      :separator="config?.header.hasSeperator && 'bottom'"
      :headline="config?.header.value"
      heading-tag="h3"
      class="mb-header block"
    >
    </patternlib-headline>

    <patternlib-list width="100%" textwrap>
      <template v-for="(value, key) in config.props" :key="key">
        <!-- Ignore props -->
        <span v-if="value.isHidden"></span>
        <span v-else-if="value.hideIfNotAvailable && (data[key] === null || data[key] === undefined)"></span>

        <!-- Custom Slot -->
        <patternlib-list-item
          v-else-if="value.type === 2"
          :item-height="config?.item.height"
          :label-width="config?.item.labelWidth"
        >
          <slot name="customItem" :key="key" :options="value" :value="data[key]"></slot>
        </patternlib-list-item>

        <patternlib-list-item v-else :item-height="config?.item.height" :label-width="config?.item.labelWidth">
          <span v-html="value.description?.text"></span>
          <!-- Pill -->
          <span slot="value" v-if="value.type === 1 && value.pillOptions">
            <patternlib-tooltip tooltip-position="center" v-if="value.tooltip">
              <span>
                <Pill :options="value.pillOptions" :property="data[key]" />
              </span>
              <div slot="content" style="padding: 10px">{{ data[value.tooltipContent ?? ''] }}</div>
            </patternlib-tooltip>
            <Pill v-else :options="value.pillOptions" :property="data[key]" />
          </span>
          <!-- COMPUTED (HTML Rendered)-->
          <span
            slot="value"
            v-if="value.type === 3 && value.renderAsHTML"
            v-html="value.computeCB && value.computeCB(data[key] ?? 1)"
          >
          </span>
          <!-- COMPUTED as Value-->
          <span slot="value" v-else-if="value.type === 3">
            <patternlib-tooltip tooltip-position="right" v-if="value.tooltip">
              <span>
                {{ value.computeCB ? value.computeCB(data[key] ?? 1) : '' }}
              </span>
              <div slot="content" style="padding: 10px">
                {{ value.tooltipContent ?? '' }}
              </div>
            </patternlib-tooltip>
          </span>
          <!-- TEXT-->
          <span slot="value" v-else-if="value.type === 0">
            <div v-if="value.tooltip">
              <patternlib-tooltip tooltip-position="right" style="cursor: pointer">
                <span>
                  {{ renderValue(data[key]) }}
                </span>
                <div slot="content" style="padding: 10px">
                  {{ value.tooltipContent ?? '' }}
                </div>
              </patternlib-tooltip>
            </div>
            <div v-else>
              {{ renderValue(data[key]) }}
            </div>
          </span>
        </patternlib-list-item>
      </template>
    </patternlib-list>
  </div>
</template>

<script lang="ts" src="./KeyValueView"></script>
