import { ZoneState } from '@/api';
import {
  EntryType,
  KeyValueViewConfig,
  PillOptions,
  PillStatusEnum
} from '@/components/basic/KeyValueView/KeyValueView';
import { ModalSettings } from '@/views/index/smartdevice/components/ModalSettings';
import { ModalTypeEnum } from '@/views/index/smartdevice/components/ModalTypeEnum';
import { TimePeriodHelper } from '@/views/index/smartdevice/components/TimePeriodHelper';

export class ZoneStateHelper {
  private static getIceMakerModalData(zone: ZoneState, t: any) {
    const pillOption: Record<string, PillOptions> = {
      0: {
        type: PillStatusEnum.SUCCESS,
        value: t('general.inactive')
      },
      1: {
        type: PillStatusEnum.ALERT,
        value: t('general.active')
      },
      2: {
        type: PillStatusEnum.ALERT,
        value: t('general.active_and_confirmed')
      }
    };

    const data = {
      waterEmptyAlarm: zone.iceMakerWaterEmptyAlarm ?? 0,
      drawerMissingAlarm: zone.iceMakerDrawerMissingAlarm ?? 0,
      fullAlarm: zone.iceMakerDrawerFullAlarm ?? 0,
      drawerSensorErrorGeneral: zone.iceMakerAirSensorErrorGeneral ?? 0,
      statusValue: zone.iceMakerStatusValue ?? 0,
      operatingTime: zone.iceMakerOperatingTime ?? 0,
      ejectionCount: zone.iceMakerEjectionCountValue ?? 0,
      trayMotorState: zone.iceMakerTrayMotorState ?? 0,
      fanShortText: zone.iceMakerFanShortText ?? '-',
      fanValue: zone.iceMakerFanValue ?? '-',
      fanMin: zone.iceMakerFanMin ?? '-',
      fanMax: zone.iceMakerFanMax ?? '-',
      cubeSize: zone.iceMakerCubeSize ?? '-'
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        waterEmptyAlarm: {
          description: { text: t('icemaker.water_empty_alarm') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        drawerMissingAlarm: {
          description: {
            text: t('icemaker.drawer_missing_alarm')
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        fullAlarm: {
          description: {
            text: t('icemaker.drawer_full_alarm')
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        drawerSensorErrorGeneral: {
          description: {
            text: `${t('icemaker.drawer_sensor_error_general')} ${this.getAdditionalDescriptionText(
              zone.iceMakerDrawerSensorShortText
            )}`
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        statusValue: {
          description: {
            text: `${t('icemaker.status_value')} ${this.getAdditionalDescriptionText(zone.iceMakerStatusShortText)}`
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.DEFAULT,
              value: t('icemaker.status_idle')
            },
            1: {
              type: PillStatusEnum.SUCCESS,
              value: t('icemaker.status_busy')
            },
            2: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.status_motor_error')
            },
            3: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.status_drawer_missing')
            },
            4: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.status_drawer_full')
            }
          }
        },
        operatingTime: {
          description: {
            text: t('icemaker.operating_time')
          },
          isHidden: false,
          type: EntryType.COMPUTED,
          renderAsHTML: true,
          computeCB: () => {
            // In seconds
            return zone.iceMakerOperatingTime ? TimePeriodHelper.mapTime(zone.iceMakerOperatingTime) : 0;
          }
        },
        ejectionCount: {
          description: {
            text: `${t('icemaker.ejection_count')} ${this.getAdditionalDescriptionText(
              zone.iceMakerEjectionCountShortText
            )}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        trayMotorState: {
          description: {
            text: `${t('icemaker.tray_motor_state')} ${this.getAdditionalDescriptionText(
              zone.iceMakerTrayMotorShortText
            )}`
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.tray_motor_pos_unknown')
            },
            1: {
              type: PillStatusEnum.DEFAULT,
              value: t('icemaker.tray_motor_pos_home')
            },
            2: {
              type: PillStatusEnum.DEFAULT,
              value: t('icemaker.tray_motor_pos_ejection')
            },
            3: {
              type: PillStatusEnum.DEFAULT,
              value: t('icemaker.tray_motor_pos_ejection_back')
            },
            4: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.tray_motor_pos_abort')
            },
            5: {
              type: PillStatusEnum.WARNING,
              value: t('icemaker.tray_motor_pos_break')
            },
            6: {
              type: PillStatusEnum.DEFAULT,
              value: t('icemaker.tray_motor_pos_continue')
            }
          }
        },
        fanShortText: {
          description: {
            text: `${t('icemaker.fan_type')} ${this.getAdditionalDescriptionText(zone.iceMakerFanShortText)}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        fanValue: {
          description: {
            text: t('icemaker.fan_value')
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        fanMin: {
          description: {
            text: t('icemaker.fan_min')
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        fanMax: {
          description: {
            text: t('icemaker.fan_max')
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        cubeSize: {
          description: {
            text: `${t('icemaker.cube_size')}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getAutoDoorModalData(zone: ZoneState, t: any) {
    const data = {
      calibration: zone.autoDoorCalibration,
      delay: zone.autoDoorDelay ?? 0,
      signal: zone.autoDoorSignal ?? 0,
      obstacleAlarm: zone.autoDoorObstacleAlarm ?? 0,
      overheatAlarm: zone.autoDoorOverheatAlarm ?? 0,
      autoDoorOpenCountValue: zone.autoDoorOpenCountValue ?? 0,
      autoDoorCloseCountValue: zone.autoDoorCloseCountValue ?? 0,
      autoDoorKnockSensorCount: zone.autoDoorKnockSensorCount ?? 0,
      autoDoorOpenPositionValue: zone.autoDoorOpenPositionValue ?? 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        calibration: {
          description: { text: t('autodoor.calibration') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.WARNING,
              value: t('autodoor.not_calibrated')
            },
            1: {
              type: PillStatusEnum.SUCCESS,
              value: t('autodoor.calibrated')
            }
          }
        },
        delay: {
          description: { text: t('autodoor.delay') },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        signal: {
          description: { text: t('autodoor.signal') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.ALERT,
              value: t('general.disabled')
            },
            1: {
              type: PillStatusEnum.SUCCESS,
              value: t('general.enabled')
            }
          }
        },
        obstacleAlarm: {
          description: { text: t('autodoor.obstacle_alarm') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.SUCCESS,
              value: t('general.inactive')
            },
            1: {
              type: PillStatusEnum.WARNING,
              value: t('general.active')
            },
            2: {
              type: PillStatusEnum.ALERT,
              value: t('general.active_and_confirmed')
            }
          }
        },
        overheatAlarm: {
          description: { text: t('general.overheat_alarm') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.SUCCESS,
              value: t('general.inactive')
            },
            1: {
              type: PillStatusEnum.WARNING,
              value: t('general.active')
            },
            2: {
              type: PillStatusEnum.ALERT,
              value: t('general.active_and_confirmed')
            }
          }
        },
        autoDoorOpenCountValue: {
          description: {
            text: `${t('autodoor.open_count')} ${this.getAdditionalDescriptionText(zone.autoDoorOpenCountShortText)}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        autoDoorCloseCountValue: {
          description: { text: t('autodoor.close_count') },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        autoDoorKnockSensorCount: {
          description: {
            text: `${t('autodoor.knock_sensor_count')} ${this.getAdditionalDescriptionText(
              zone.autoDoorKnockSensorShortText
            )}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        autoDoorOpenPositionValue: {
          description: { text: `${t('autodoor.open_position')}` },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getZoneFailureModalData(zone: ZoneState, t: any) {
    const pillOption: Record<string, PillOptions> = {
      0: {
        value: t('general.inactive'),
        type: PillStatusEnum.SUCCESS
      },
      1: { value: t('general.active'), type: PillStatusEnum.WARNING },
      2: {
        value: t('general.active_and_confirmed'),
        type: PillStatusEnum.ALERT
      }
    };

    const data = {
      doorAlarm: zone.doorAlarmState ?? 0,
      tempLower: zone.temperatureAlarmLowerState ?? 0,
      tempUpper: zone.temperatureAlarmUpperState ?? 0,
      powerLower: zone.powerFailureAlarmLowerState ?? 0,
      powerUpper: zone.powerFailureAlarmUpperState ?? 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        doorAlarm: {
          description: { text: t('alarm.door_alarm') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        tempLower: {
          description: {
            text: t('alarm.temperature_alarm_lower')
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        tempUpper: {
          description: {
            text: t('alarm.temperature_alarm_upper')
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        powerLower: {
          description: { text: t('alarm.power_alarm_lower') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        },
        powerUpper: {
          description: { text: t('alarm.power_alarm_upper') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: pillOption
        }
      }
    };
    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getBioFreshPlusModalData(zone: ZoneState, t: any) {
    const data = {
      state: zone.biofreshplusModeState ?? 0,
      upperAirBaffle: zone.biofreshplusUpperAirBaffleValue ?? 0,
      lowerAirBaffle: zone.biofreshplusLowerAirBaffleValue ?? 0,
      humidifierState: zone.biofreshplusHumidifierState ?? 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        state: {
          description: { text: t('biofreshplus.state_mode') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.DEFAULT,
              value: '0°/0°'
            },
            1: {
              type: PillStatusEnum.DEFAULT,
              value: '0°/-2°'
            },
            2: {
              type: PillStatusEnum.DEFAULT,
              value: '-2°/0°'
            },
            3: {
              type: PillStatusEnum.DEFAULT,
              value: '-2°/-2°'
            }
          }
        },
        upperAirBaffle: {
          description: {
            text: `${t('biofreshplus.upper_air_baffle')} ${this.getAdditionalDescriptionText(
              zone.biofreshplusUpperAirBaffleShortText
            )}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        lowerAirBaffle: {
          description: {
            text: `${t('biofreshplus.lower_air_baffle')} ${this.getAdditionalDescriptionText(
              zone.biofreshplusLowerAirBaffleShortText
            )}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        humidifierState: {
          description: {
            text: `${t('biofreshplus.humidifier_state')} ${this.getAdditionalDescriptionText(
              zone.biofreshplusHumidifierShortText
            )}`
          },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.DEFAULT,
              value: t('general.off')
            },
            1: {
              type: PillStatusEnum.DEFAULT,
              value: t('general.on')
            }
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getBioFreshModalData(zone: ZoneState, t: any) {
    const data = {
      stepValue: zone.biofreshStepValue ?? 0,
      stepMin: zone.biofreshStepMin ?? 0,
      stepMax: zone.biofreshStepMax ?? 0,
      airSensorTempValue:
        zone.biofreshAirSensorTemperatureValue !== null ? `${zone.biofreshAirSensorTemperatureValue} °C` : '-',
      airSensorErrorGeneral: zone.biofreshAirSensorErrorGeneral ? zone.biofreshAirSensorErrorGeneral : 0,
      airSensorErrorLogCount: zone.biofreshAirSensorErrlogCount ?? 0,
      airSensorErrorLogTime: zone.biofreshAirSensorErrlogTime ? zone.biofreshAirSensorErrlogTime : 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        stepValue: {
          description: {
            text: `${t('biofresh.step_value')} ${this.getAdditionalDescriptionText(zone.biofreshStepShortText)}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        stepMin: {
          description: {
            text: t('biofresh.step_min')
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        stepMax: {
          description: {
            text: t('biofresh.step_max')
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        airSensorTempValue: {
          description: {
            text: `${t('biofresh.air_sensor_temp_value')} ${this.getAdditionalDescriptionText(
              zone.biofreshAirSensorShortText
            )}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        airSensorErrorGeneral: {
          description: { text: 'Error General' },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: { value: t('general.no'), type: PillStatusEnum.SUCCESS },
            1: { value: t('general.yes'), type: PillStatusEnum.WARNING }
          }
        },
        airSensorErrorLogCount: {
          description: { text: 'Error log count' },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        airSensorErrorLogTime: {
          description: { text: 'Error log time' },
          type: EntryType.COMPUTED,
          isHidden: false,
          hideIfNotAvailable: true,
          renderAsHTML: true,
          computeCB: (value: number) => {
            // Time in seconds
            return TimePeriodHelper.mapTime(value);
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getDoorModalData(zone: ZoneState, t: any) {
    const data = {
      door: zone.door ?? 0,
      doorAlarmState: zone.doorAlarmState ?? 0,
      doorOpenCount: zone.doorOpenCountValue ?? 0,
      doorAlarmDelayValue: zone.doorAlarmDelayValue ?? 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        door: {
          description: { text: t('door.state') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.SUCCESS,
              value: t('door.closed')
            },
            1: {
              type: PillStatusEnum.WARNING,
              value: t('door.open')
            }
          }
        },
        doorAlarmState: {
          description: { text: t('door.alarm_state') },
          type: EntryType.PILL,
          isHidden: false,
          hideIfNotAvailable: true,
          pillOptions: {
            0: {
              type: PillStatusEnum.DEFAULT,
              value: t('door.alarm_state_inactive')
            },
            1: {
              type: PillStatusEnum.WARNING,
              value: t('door.alarm_state_active')
            },
            2: {
              type: PillStatusEnum.WARNING,
              value: t('door.alarm_state_active_confirmed')
            }
          }
        },
        doorOpenCount: {
          description: {
            text: `${t('door.open_count')} ${this.getAdditionalDescriptionText(zone.doorOpenCountShortText)}`
          },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        doorAlarmDelayValue: {
          description: { text: `${t('door.delay_alarm')} ` },
          type: EntryType.COMPUTED,
          isHidden: false,
          hideIfNotAvailable: true,
          renderAsHTML: true,
          computeCB: (value: number) => {
            // Time in seconds
            if (value === 0) {
              return t('general.disabled');
            } else {
              return TimePeriodHelper.mapTime(value);
            }
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  private static getErrorGeneralModalData(
    errorGeneral: number | undefined | null,
    errorLogCount: number | undefined | null,
    errorLogTime: number | undefined | null,
    t: any
  ) {
    const data = {
      errorGeneral: errorGeneral === 0 ? t('general.no') : t('general.yes'),
      errorLogCount: errorLogCount ?? 0,
      errorLogTime: errorLogTime ? errorLogTime : 0
    };

    const config: KeyValueViewConfig<unknown> = {
      item: { height: '48px', labelWidth: '50%', noSeparator: false },
      props: {
        errorGeneral: {
          description: { text: 'Error General' },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        errorLogCount: {
          description: { text: 'Error log count' },
          type: EntryType.TEXT,
          isHidden: false,
          hideIfNotAvailable: true
        },
        errorLogTime: {
          description: { text: 'Error log time' },
          type: EntryType.COMPUTED,
          isHidden: false,
          hideIfNotAvailable: true,
          renderAsHTML: true,
          computeCB: (value: number) => {
            // Time in seconds
            return TimePeriodHelper.mapTime(value);
          }
        }
      }
    };

    return {
      data,
      config,
      isModalOpen: false,
      currentModal: ''
    };
  }

  public static getModalSettings(zoneId: number, zone: ZoneState, modalType: ModalTypeEnum, t: any) {
    let modalData = {};
    let modalConfig;
    let modalTitle = '';

    switch (modalType) {
      case ModalTypeEnum.ICEMAKER: {
        const modalConfiguration = this.getIceMakerModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('icemaker.settings');
        break;
      }
      case ModalTypeEnum.AUTODOOR: {
        const modalConfiguration = this.getAutoDoorModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('autodoor.settings');
        break;
      }
      case ModalTypeEnum.BIOFRESHPLUS: {
        const modalConfiguration = this.getBioFreshPlusModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('biofreshplus.settings');

        break;
      }
      case ModalTypeEnum.BIOFRESH: {
        const modalConfiguration = this.getBioFreshModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('biofresh.settings');
        break;
      }
      case ModalTypeEnum.ZONEFAILURE: {
        const modalConfiguration = this.getZoneFailureModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.failure_alarm');

        break;
      }
      case ModalTypeEnum.DOOR: {
        const modalConfiguration = this.getDoorModalData(zone, t);
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('door.settings');

        break;
      }
      case ModalTypeEnum.DEFROSTHEATER: {
        const modalConfiguration = this.getErrorGeneralModalData(
          zone.defrostHeaterErrorGeneral,
          zone.defrostHeaterErrlogCount,
          zone.defrostHeaterErrlogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.defrost_heater_error_general');

        break;
      }
      case ModalTypeEnum.FAN: {
        const modalConfiguration = this.getErrorGeneralModalData(
          zone.fanErrorGeneral,
          zone.fanErrlogCount,
          zone.fanErrlogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.fan_error_general');

        break;
      }
      case ModalTypeEnum.AirSensor: {
        const modalConfiguration = this.getErrorGeneralModalData(
          zone.airSensorErrorGeneral,
          zone.airSensorErrlogCount,
          zone.airSensorErrlogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.air_sensor_error_general');

        break;
      }
      case ModalTypeEnum.EvaporatorSensor: {
        const modalConfiguration = this.getErrorGeneralModalData(
          zone.evaporatorSensorErrorGeneral,
          zone.evaporatorSensorErrlogCount,
          zone.evaporatorSensorErrlogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.evaporator_sensor_error_general');

        break;
      }
      case ModalTypeEnum.IceMakerAirSensor: {
        const modalConfiguration = this.getErrorGeneralModalData(
          zone.iceMakerAirSensorErrorGeneral,
          zone.iceMakerAirSensorErrlogCount,
          zone.iceMakerAirSensorErrlogTime,
          t
        );
        modalData = modalConfiguration.data;
        modalConfig = modalConfiguration.config;
        modalTitle = t('device.zone.ice_maker_air_sensor_error_general');

        break;
      }
    }

    const modalSettings: ModalSettings = {
      data: modalData,
      config: modalConfig,
      icon: t('refrigeration-freezing'),
      title: modalTitle,
      zoneId: zoneId,
      isModalOpen: true,
      type: modalType,
      currentModal: ''
    };

    return modalSettings;
  }

  public static getAdditionalDescriptionText(shortText: string | undefined | null) {
    if (shortText) {
      return `(${shortText})`;
    }
    return '';
  }
}
