<template>
	<div>
		<patternlib-dropzone
			accepted-files="application/zip,application/x-zip-compressed"
			:action="backendApiUrl"
			additional-info="only zip accepted"
			auto-process-queue="false"
			drop-here-text="or drag and drop files here.<br>(max 28MB)"
			label="Add zip"
			language="en"
			loading="false"
			max-filesize="28"
			multiple="false"
			name="dropzone"
			new-file-text="new file"
			status="default"
			submit-button-selector="#submitDropzone">
		</patternlib-dropzone>
		<br />
		<patternlib-button
			label="submit"
			id="submitDropzone"
			v-if="buttonEnabled"></patternlib-button>
		<patternlib-button label="submit" disabled v-else></patternlib-button>
	</div>
</template>

<script src="./Bn010View.ts" lang="ts"></script>
