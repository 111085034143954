<template>
	<!-- <patternlib-headline
			separator="bottom"
			:headline="t('profile.title')"
			heading-tag="h3"></patternlib-headline>
		<div class="flex flex-row justify-end">
			<TheButton
				label="Logout"
				class="mt-8"
				@click="redirectToLoginClient" />
		</div> -->

	<LoadingSpinner
		class="h-[80vh] border border-dashed"
		data-cy="spinner"
		:text="t('login.loading_text')"></LoadingSpinner>
</template>

<script src="./LoginView.ts" lang="ts"></script>
