import {
  ApplianceHistoryApiFactory,
  ApplianceHistoryResponse,
  AvailableIntervals,
  BaseInfo,
  BaseState,
  Zone,
  ZoneState
} from '@/api';
import TheButton from '@/components/basic/Button/TheButton.vue';
import AppDropdown from '@/components/basic/Dropdown/AppDropdown.vue';
import MultiDropdown from '@/components/basic/Dropdown/MultiDropdown.vue';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { useFetch } from '@/composables/common/useFetch';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import ChartSettings from '@/helper/HistoryCharts/ChartsBuilder';
import ChartSettingsComponent from '@/views/index/profile/ChartSettings.vue';
import AppModal from '@/components/basic/Modal/AppModal.vue';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted, onUnmounted, PropType, Ref, ref, watch } from 'vue';
import { Line as LineChart } from 'vue-chartjs';
import { useI18n } from 'vue-i18n';
import { Bar } from 'vue-chartjs';
import { useLoginStore } from '@/store/LoginStore';
import ChartJSConfiguration from '@/helper/HistoryCharts/ChartJSConfiguration';
import { ChartData, IChartOptionSettings, IntersectionLine, IScale } from '@/types/DeviceValueMapping';
import { PatternLibDatePickerEvent } from '@/types/patternlib';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import HistoryCharts from './HistoryCharts.vue';
import { DateTime } from 'luxon';
import { ApplianceHistoryKeyEnum } from '@/helper/ApplianceHistoryKeyEnum';

type DatePickerStateT = {
  value: {
    start: string;
    end: string;
  };
  max: string;
  min: string;
};

export default defineComponent({
  components: {
    AppModal,
    ChartSettingsComponent,
    LineChart,
    TheButton,
    AppDropdown,
    MultiDropdown,
    LoadingSpinner,
    TextPlaceHolder,
    Bar,
    HistoryCharts
  },
  props: {
    serialId: {
      type: String,
      required: true
    },
    isDemoMode: {
      type: Boolean,
      required: false
    },
    baseState: {
      type: Object as PropType<BaseState>,
      default: {} as BaseState
    },
    baseInfo: {
      type: Object as PropType<BaseInfo>,
      default: {} as BaseInfo
    },
    zoneStates: {
      type: Object as PropType<ZoneState[]>,
      default: () => [] as ZoneState[]
    },
    availableHistoryInterval: {
      type: Object as PropType<AvailableIntervals>,
      default: undefined
    }
  },
  setup(props) {
    const { chartSettings } = storeToRefs(useLoginStore());
    const { t } = useI18n();
    const { getConfig } = useHttpConfiguration();
    const observerRef = ref();
    const selectedHistoryType = ref<string>();
    const datePickerRef = ref();
    const selectedTimeRange = ref<DatePickerStateT>();
    const isSettingsModalOpen = ref(false);
    const chartElementRef = ref();
    const intersectionLine = ref<IntersectionLine>();
    const currentZoom = ref<IScale>();
    const { timeZone } = storeToRefs(useLoginStore());
    const { language } = storeToRefs(useLoginStore());
    const zoomedStart = ref();
    const zoomedEnd = ref();
    const zones: Ref<string[]> = ref([]);

    const availableIntervals: Ref<AvailableIntervals | undefined> = ref({
      firstMessageAtUtc: '',
      lastMessageAtUtc: ''
    });

    const dropDownOptions = computed(() => {
      const historyKeys: string[] = [ApplianceHistoryKeyEnum.Base];

      if (historyDeviceZoneDataRequest.data.value) {
        Object.keys(historyDeviceZoneDataRequest.data.value.zones as { [key: string]: Zone | undefined }).forEach(
          key => {
            historyKeys.push(key);
          }
        );
      }
      return historyKeys;
    });

    const canHistoryRequestBeExecuted = () => {
      return (
        selectedTimeRange.value?.value &&
        !historyDeviceBaseDataRequest.data.value &&
        !historyDeviceBaseDataRequest.isLoading.value &&
        !historyDeviceZoneDataRequest.data.value &&
        !historyDeviceZoneDataRequest.isLoading.value
      );
    };

    const getHistoricDeviceBaseData = () => {
      const config = getConfig();

      if (!selectedTimeRange.value?.value) {
        throw new Error();
      }

      const { start, end } = selectedTimeRange.value.value;

      const startDate = new Date(start).toISOString();
      const endDate = new Date(end).toISOString();

      return ApplianceHistoryApiFactory(config).getApplianceBaseHistory(props.serialId, startDate, endDate);
    };

    const historyIntervalsLoaded = computed(() => {
      if (availableIntervals.value?.firstMessageAtUtc && availableIntervals.value?.lastMessageAtUtc) {
        return true;
      }
      return false;
    });

    const datePickerLocale = computed(() => {
      return language.value;
    });

    const getHistoricDeviceZoneData = () => {
      const config = getConfig();

      if (!selectedTimeRange.value?.value) {
        throw new Error();
      }

      const { start, end } = selectedTimeRange.value.value;

      const startDate = new Date(start).toISOString();
      const endDate = new Date(end).toISOString();

      return ApplianceHistoryApiFactory(config).getApplianceZoneHistory(
        props.serialId,
        startDate,
        endDate,
        props.baseInfo.zones as number
      );
    };

    const historyDeviceBaseDataRequest = useFetch(getHistoricDeviceBaseData, 200, false);
    const historyDeviceZoneDataRequest = useFetch(getHistoricDeviceZoneData, 200, false);

    const initDatepicker = () => {
      const firstMessageAtUtc = availableIntervals.value?.firstMessageAtUtc;
      const lastMessageAtUtc = availableIntervals.value?.lastMessageAtUtc;

      if (!firstMessageAtUtc || !lastMessageAtUtc) return;

      const dateOneWeekAgo = DateTime.fromISO(lastMessageAtUtc).startOf('day').minus({ days: 7 });

      let startDate = firstMessageAtUtc;

      if (dateOneWeekAgo > DateTime.fromISO(firstMessageAtUtc)) {
        startDate = dateOneWeekAgo.toISO() as string;
      }

      const initialDatePickerRange: DatePickerStateT = {
        max: DateTime.fromISO(lastMessageAtUtc).endOf('day').toISO() as string,
        min: DateTime.fromISO(firstMessageAtUtc).startOf('day').toISO() as string,
        value: {
          start: startDate,
          end: lastMessageAtUtc
        }
      };

      selectedTimeRange.value = initialDatePickerRange;
    };

    watch(
      () => availableIntervals.value,
      () => {
        initDatepicker();
        if (canHistoryRequestBeExecuted()) {
          historyDeviceBaseDataRequest.executeRequest();
          historyDeviceZoneDataRequest.executeRequest();
        }
      }
    );

    const handleDatePickerSubmit = (event: PatternLibDatePickerEvent) => {
      // Reset zoom
      zoomedStart.value = '';
      zoomedEnd.value = '';

      const updatedRange = event.detail.value;
      if (!selectedTimeRange.value) {
        return;
      }

      const startDate = DateTime.fromJSDate(updatedRange.start).startOf('day').toISO();

      const endDate = DateTime.fromJSDate(updatedRange.end).endOf('day').toISO();

      selectedTimeRange.value = {
        ...selectedTimeRange.value,
        value: { start: startDate as string, end: endDate as string }
      };

      datePickerRef.value.errorText = t('historyCharts.datepicker_success');
      historyDeviceBaseDataRequest.executeRequest();
      historyDeviceZoneDataRequest.executeRequest();
    };

    watch(
      () => datePickerRef.value,
      () => {
        datePickerRef.value?.addEventListener('lhChange', handleDatePickerSubmit);
      }
    );

    watch(historyDeviceZoneDataRequest.data, () => {
      const zoneHistories = historyDeviceZoneDataRequest.data.value?.zones;
      zones.value = [];

      Object.keys(zoneHistories as { [key: string]: Zone }).forEach(zoneKey => {
        if (zoneHistories && zoneHistories[zoneKey]) {
          zones.value.push(zoneKey);
        }
      });
    });

    watch(
      () => timeZone.value,
      () => {
        // It's better to re-render history charts after changing timezone
        historyDeviceBaseDataRequest.executeRequest();
        historyDeviceZoneDataRequest.executeRequest();
      }
    );

    watch(
      () => language.value,
      () => {
        // It's better to re-render history charts after changing language
        historyDeviceBaseDataRequest.executeRequest();
        historyDeviceZoneDataRequest.executeRequest();
      }
    );

    const chartsData = computed((): ChartData | undefined => {
      const baseState = props.baseState;
      const zoneStates = props.zoneStates;

      const historyData = extractApplianceHistory();

      if (!historyData) {
        return undefined;
      }

      const options: IChartOptionSettings = {
        interval: {
          from: zoomedStart.value ? zoomedStart.value : new Date(selectedTimeRange.value?.value.start ?? '').getTime(),
          to: zoomedEnd.value ? zoomedEnd.value : new Date(selectedTimeRange.value?.value.end ?? '').getTime()
        },
        onScaleChange: scale => {
          currentZoom.value = scale;
          zoomedStart.value = scale.min;
          zoomedEnd.value = scale.max;
        },
        synchronizedMode: chartSettings.value.synchronizedCharts
      };

      const chartsDatasets = ChartSettings.createChartData(historyData, baseState, zoneStates, {
        accessKey: chartSettings.value.useOverallView ? undefined : selectedHistoryType.value,
        userSettings: chartSettings.value,
        interval: options.interval,
        temperatureUnit: props.baseState.temperatureUnit as number
      });

      const timelineOptions = ChartJSConfiguration.generateBarChartTimeLineOptions(options);
      const lineChartOptions = ChartJSConfiguration.generateLineAreaOptions(options);

      return {
        datasets: chartsDatasets,
        options: {
          timeline: timelineOptions,
          lineChart: lineChartOptions
        }
      };
    });

    const extractApplianceHistory = (): ApplianceHistoryResponse | undefined => {
      const historyBaseData = historyDeviceBaseDataRequest.data.value;
      const historyZoneData = historyDeviceZoneDataRequest.data.value;

      if (!historyBaseData) return undefined;

      const historyData: ApplianceHistoryResponse = {
        base: historyBaseData.baseHistory,
        zones: historyZoneData?.zones
      };

      return historyData;
    };

    const updateDropdown = (option: string) => {
      selectedHistoryType.value = option as keyof ApplianceHistoryResponse;
    };

    const toggleSettingsModal = () => {
      isSettingsModalOpen.value = !isSettingsModalOpen.value;
    };

    const useOverallView = computed(() => {
      return chartSettings.value.useOverallView;
    });

    const resetZoom = () => {
      const zoom = {
        min: new Date(selectedTimeRange.value?.value.start ?? '').getTime(),
        max: new Date(selectedTimeRange.value?.value.end ?? '').getTime()
      };

      currentZoom.value = zoom;
      zoomedStart.value = '';
      zoomedEnd.value = '';
    };

    const updateIntersectionLine = (updatedIL: IntersectionLine) => {
      intersectionLine.value = updatedIL;
    };

    const formatPath = (path: string | null | undefined) => {
      if (!path) {
        return '';
      }

      if (path.includes('PUT') || path.includes('GET') || path.includes('POST')) {
        return path.substring(4).trim();
      } else if (path.includes('PATCH')) {
        return path.substring(5).trim();
      } else {
        return path;
      }
    };

    const formatDateTime = (dateTime: string | undefined) => {
      const { timeZone } = storeToRefs(useLoginStore());

      return DateTimeFormatter.getFormattedDateTimeLongFormat(dateTime, timeZone.value);
    };

    onMounted(() => {
      availableIntervals.value = props.availableHistoryInterval;
    });

    onUnmounted(() => {
      availableIntervals.value = { firstMessageAtUtc: '', lastMessageAtUtc: '' };
    });

    return {
      datePickerRef,
      isSettingsModalOpen,
      intersectionLine,
      chartElementRef,
      useOverallView: useOverallView,
      updateDropdown,
      dropDownOptions,
      selectedHistoryType,
      historyDeviceBaseDataRequest,
      historyDeviceZoneDataRequest,
      chartSettings,
      selectedTimeRange,
      updateIntersectionLine,
      toggleSettingsModal,
      datePickerLocale,
      t,
      historyIntervalsLoaded,
      resetZoom,
      observerRef,
      currentZoom,
      zones,
      formatPath,
      formatDateTime,
      chartsData
    };
  }
});
