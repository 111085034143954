/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, PropType } from 'vue';
import Pill from './components/TextPill.vue';

import { isEmpty, isString, isNull, isUndefined } from 'lodash';

export enum EntryType {
  TEXT,
  PILL,
  CUSTOM,
  COMPUTED
}

export enum PillStatusEnum {
  SUCCESS = 'success',
  ALERT = 'alert',
  WARNING = 'warning',
  DEFAULT = 'default'
}

export type PillOptions = {
  value: string | number;
  type: PillStatusEnum;
};

interface BaseProps {
  type: EntryType;
  isHidden?: boolean;
  hideIfNotAvailable?: boolean;
  tooltip?: boolean;
  tooltipContent?: string;
  description?: TDescription;
}
interface TDescription {
  text: string;
  icon?: string;
}
interface Text extends BaseProps {
  type: EntryType.TEXT;
}

interface Pill extends BaseProps {
  type: EntryType.PILL;
  pillOptions?: Record<string, PillOptions>;
}

interface Computed extends BaseProps {
  type: EntryType.COMPUTED;
  computeCB: (value: any) => string;
  description?: TDescription;
  renderAsHTML?: boolean;
}

interface Custom extends BaseProps {
  type: EntryType.CUSTOM;
  pillOptions?: Record<string, PillOptions>;
}

type ListEntryTypes = Pill | Text | Computed | Custom;

export type KeyValueViewConfig<T> = {
  header?: { value: string; hasSeperator: boolean };
  item: { height: string; labelWidth: string; noSeparator: boolean };
  props: { [Key in keyof T]: ListEntryTypes };
};

export default defineComponent({
  name: 'KeyValueView',
  components: { Pill },
  props: {
    config: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<KeyValueViewConfig<any>>,
      required: true
    },
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<Record<string, any>>,
      required: true
    }
  },
  setup(props) {
    const EMPTY_VAL = '-';
    const renderValue = (value: string | number) => {
      if (isUndefined(value) || isNull(value)) {
        return EMPTY_VAL;
      }

      if (isString(value) && isEmpty(value)) {
        return EMPTY_VAL;
      }

      return value;
    };

    return { renderValue };
  }
});
