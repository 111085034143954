import { i18n } from 'src/plugins/i18n';
import { useLoginStore } from 'src/store/LoginStore';
import axios from 'axios';
import { defineComponent } from 'vue';
import { uuid } from 'vue-uuid';

export default defineComponent({
	name: 'ServicesView',
	data() {
		return {
			showPageContent: false,
			monitoringDataHistory: [
				{
					serviceName: '',
					endpoints: [
						{
							endpointName: '',
							values: [
								{
									status: '',
									availablePercentage: 0,
									month: '',
									year: '',
								},
							],
						},
					],
				},
			],
			tableColumnsHeader: [
				{
					label: '',
					align: '',
					sorting: false,
				},
			],
			monitoringDataCurrent: {},
			availableMonths: 0,
			serviceDisabled: true,
		};
	},
	beforeMount() {
		document.cookie = 'redirect_to=services';
	},
	async mounted() {
		if (!this.serviceDisabled) {
			await this.executeApiCalls();
		}
	},

	methods: {
		async executeApiCalls() {
			await this.getServiceMonitoringHistory();
			//await this.getServiceMonitoringCurrent();

			if (this.monitoringDataHistory && this.monitoringDataCurrent) {
				if (this.monitoringDataHistory.length > 0) {
					this.monitoringDataHistory.forEach((service) => {
						service.endpoints.forEach((endpoint) => {
							if (endpoint.values.length > this.availableMonths) {
								//this.availableMonths = endpoint.values.length;
							}
						});
					});

					// Set table column headers
					this.tableColumnsHeader = [];

					this.tableColumnsHeader.push({
						label: i18n.global.t('service'),
						align: 'left',
						sorting: false,
					}); // Empty colum header for first column

					this.monitoringDataHistory[0].endpoints[0].values.forEach(
						(value) => {
							this.tableColumnsHeader.push({
								label: value.month + ' (' + value.year + ')',
								align: 'left',
								sorting: false,
							});
						}
					);

					this.showPageContent = true;
				}
			}
		},
		async getServiceMonitoringHistory() {
			const loginStore = useLoginStore();
			axios.defaults.headers.common = {
				Authorization: `Bearer ${loginStore.accessToken}`,
			};

			await axios
				.get(
					`${process.env.VUE_APP_BACKEND_BASE_URL}/appanda/api/v2/services/gethistory`
				)
				.then((response) => {
					this.monitoringDataHistory = response.data;
				})
				.catch((error) => {
					this.showErrorToastMessage(
						'ServiceMonitoring: ' + error.message
					);
				});
		},
		async getServiceMonitoringCurrent() {
			const loginStore = useLoginStore();
			axios.defaults.headers.common = {
				Authorization: `Bearer ${loginStore.accessToken}`,
			};

			await axios
				.get(
					`${process.env.VUE_APP_BACKEND_BASE_URL}/appanda/api/v2/services/getcurrent`
				)
				.then((response) => {
					this.monitoringDataCurrent = response.data;
				})
				.catch((error) => {
					this.showErrorToastMessage(
						'ServiceMonitoring: ' + error.message
					);
				});
		},
		showErrorToastMessage(errorMsg: string) {
			const app_div = document.getElementById('app');
			const patternlib_toast = document.createElement('patternlib-toast');
			patternlib_toast.setAttribute('position', 'bottom-left');
			patternlib_toast.addEventListener('lhClose', () => {
				app_div?.removeChild(patternlib_toast);
			});

			app_div?.prepend(patternlib_toast);

			if (patternlib_toast != null) {
				patternlib_toast.createToastItem({
					itemId: uuid.v4(),
					message: errorMsg,
					showIcon: false,
					type: 'warning',
					showUndo: false,
					autoHide: true,
					delay: 6000,
					subline: '',
				});
			}
		},
	},
});
