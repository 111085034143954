export class BaseInfoMapper {
	public static mapPbType(pbType: number | undefined | null): string {
		// Type of power board
		switch (pbType) {
			case 0:
				return 'PB001';
			case 1:
				return 'PB002';
			case 2:
				return 'PB003';
			case 3:
				return 'PB004';
			case 4:
				return 'PB005';
			case 5:
				return 'PB006';
			default:
				return '-';
		}
	}
	
	public static mapUiType(uiType: number | undefined | null): string {
		// Type and version of Ui
		switch (uiType) {
			case 0:
				return 'HDT_LH_MINIMAL';
			case 1:
				return 'HDT_LH_LOW_COST';
			case 10:
				return 'HDT_LH_BASIC';
			case 11:
				return 'HDT_LH_ADVANCED';
			case 12:
				return 'IDT_LH_BASIC';
			case 13:
				return 'IDT_LH_ADVANCED';
			case 20:
				return 'HDT_MIELE_BASIC';
			case 21:
				return 'HDT_MIELE_PRESTIGE' ;
			case 22:
				return 'IDT_MIELE_BASIC';
			case 23:
				return 'IDT_MIELE_PRESTIGE';
			default:
				return '-';
		}
	}
}