<template>
  <patternlib-main-navigation ref="headerNavRef">
    <patternlib-main-navigation-item v-for="route in navBarRoutes" :key="route.name" :subid="route.name">
      <a @click="navigateToRoute(route)" target="_self"> {{ t(route.meta?.label as string) ?? '' }}</a>
    </patternlib-main-navigation-item>
  </patternlib-main-navigation>
</template>
<script lang="ts" setup>
import { routes } from '@/router/index';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/LoginStore';
import { storeToRefs } from 'pinia';

const router = useRouter();
const { t } = useI18n();
const headerNavRef = ref();
const { isUserAdmin } = storeToRefs(useLoginStore());

const navBarRoutes = computed(() => {
  let filteredRoutes = routes.filter(r => r.meta?.isNavBarRoute);

  if (!isUserAdmin.value) {
    filteredRoutes = filteredRoutes.filter(r => !r.meta?.isAdminRoute);
  }
  return filteredRoutes;
});

const navigateToRoute = (route: any) => {
  router.replace({ name: route.name });
};
</script>
<style lang="scss" src="./GlobalStyles.scss" />
