<template>
  <div>
    <patternlib-headline
        separator="bottom"
        class="mb-header block"
        :headline="$t('device.base.state')"
        heading-tag="h3"></patternlib-headline>

    <div v-if="hasBaseData">
      <div class="mb-1 flex flex-col gap-x-12 lg:flex-row">
        <KeyValueView class="lg:w-1/2" :config="baseStateRenderProps.config" :data="baseStateRenderProps.data">
        </KeyValueView>

        <KeyValueView class="lg:w-1/2" :config="infoStateRenderProps.config" :data="infoStateRenderProps.data">
          <template #customItem="{ key, options, value }">
            <template v-if="key === 'compressorType'">
            <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.COMPRESSOR)">
              {{ options.description.text }}
              <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon>
            </span>
              <span slot="value">
              <TextPill :options="options.pillOptions" :property="value"/>
            </span>
            </template>

            <template v-if="key === 'ambientSensorErrorGeneral'">
            <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.AMBIENTSENSOR)">
              {{ options.description.text }}
              <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon>
            </span>
              <span slot="value">
              <TextPill :options="options.pillOptions" :property="value"/>
            </span>
            </template>

            <template v-if="key === 'compressorErrorGeneral'">
            <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.COMPRESSORERROR)">
              {{ options.description.text }}
              <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon>
            </span>
              <span slot="value">
              <TextPill :options="options.pillOptions" :property="value"/>
            </span>
            </template>

            <template v-if="key === 'condenserFanErrorGeneral'">
            <span role="button" class="customListEntry" @click="displayModal(ModalTypeEnum.CONDENSERERROR)">
              {{ options.description.text }}
              <patternlib-icon icon-name="link" size="16px" bold> </patternlib-icon>
            </span>
              <span slot="value">
              <TextPill :options="options.pillOptions" :property="value"/>
            </span>
            </template>
          </template>
        </KeyValueView>
      </div>

      <div class="additional-information">
			<span
      >{{ $t('baseinfo.title') }}:
				{{ $t('general.last_updated_at') }}
				{{ formatDateTime(String(baseInfo.changedAtUtc)) }}</span
      >
        <br/>
        <span
        >{{ $t('basestate.title') }}:
				{{ $t('general.last_updated_at') }}
				{{ formatDateTime(String(baseState.changedAtUtc)) }}</span
        >
      </div>
    </div>
    <div v-else>
      <p>{{ $t('general.no_information_available') }}</p>
    </div>

    <Modal
        v-if="modalSettings.isModalOpen"
        @onClose="handleModalClosure"
        :icon="modalSettings.icon"
        :title="modalSettings.title"
        :imgSrc="modalSettings.img">
      <KeyValueView :config="modalSettings.config" :data="modalSettings.data"/>
    </Modal>
  </div>
</template>

<script src="./BaseStateComponent.ts" lang="ts"></script>
<style src="./BaseStateComponent.scss" lang="scss" scoped></style>
