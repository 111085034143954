<script lang="ts">
import { HouseholdDto, NetStateDto } from '@/api';
import { EntryType, KeyValueViewConfig, PillStatusEnum } from '@/components/basic/KeyValueView/KeyValueView.vue';
import TextPlaceholder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import KeyValueView from '@/components/basic/KeyValueView/KeyValueView.vue';
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SoftwareInformation',
  components: { KeyValueView, TextPlaceholder },
  props: {
    isAvailableAtSmartDevice: {
      type: Boolean,
      default: false
    },
    serialId: {
      type: String,
      required: true
    },
    household: {
      type: Object as PropType<HouseholdDto>
    },
    netState: {
      type: Object as PropType<NetStateDto>
    }
  },
  setup(props) {
    const { t } = useI18n();
    const generateDeviceDataConfig = () => {
      const config: KeyValueViewConfig<
        HouseholdDto & {
          deviceName: unknown;
          upn: unknown;
          isConnected: unknown;
          isAvailableAtSmartDevice: string;
        }
      > = {
        item: { height: '48px', labelWidth: '50%', noSeparator: false },
        props: {
          deviceName: {
            description: { text: t('device.name') },
            type: EntryType.TEXT
          },
          upn: {
            description: { text: t('general.upn') },
            type: EntryType.TEXT
          },
          householdId: {
            description: { text: t('household.id') },
            type: EntryType.TEXT
          },
          isConnected: {
            description: { text: t('general.state') },
            type: EntryType.PILL,
            pillOptions: {
              UNSPECIFIED: { type: PillStatusEnum.DEFAULT, value: t('general.not_specified') },
              IDLE: { type: PillStatusEnum.DEFAULT, value: t('general.idle') },
              CONNECTING: { type: PillStatusEnum.WARNING, value: t('general.connecting') },
              CONNECTED_NETWORK: { type: PillStatusEnum.SUCCESS, value: t('general.connected_network') },
              CONNECTED_CLOUD: { type: PillStatusEnum.SUCCESS, value: t('general.connected_cloud') },
              ERROR_NO_LINK_INVALID_CREDENTIALS: {
                type: PillStatusEnum.ALERT,
                value: t('general.error_no_link_invalid_credentials')
              },
              ERROR_NO_IP_ADDRESS: { type: PillStatusEnum.ALERT, value: t('general.error_no_ip_address') },
              ERROR_NO_CLOUD_CONNECTION: { type: PillStatusEnum.ALERT, value: t('general.error_no_cloud_connection') },
              INVALID: { type: PillStatusEnum.WARNING, value: t('general.invalid') }
            }
          },
          isAvailableAtSmartDevice: {
            description: {
              text: t('device.smartdevice_data_available')
            },
            type: EntryType.PILL,
            isHidden: false,
            pillOptions: {
              true: { value: t('general.yes'), type: PillStatusEnum.SUCCESS },
              false: { value: t('general.no'), type: PillStatusEnum.WARNING }
            }
          }
        }
      };

      const device = getDeviceBySerialId(props.serialId);

      const data: Record<string, unknown> = {
        householdId: props.household?.householdId as string,
        upn: getUpns(props.household?.upnList ?? []),
        deviceName: device?.nickname,
        isConnected: props.netState?.state,
        isAvailableAtSmartDevice: props.isAvailableAtSmartDevice
      };

      return { config, data };
    };

    const getUpns = (upnList?: Array<string>) => {
      if (!upnList) return undefined;
      return upnList.join('|');
    };

    const getDeviceBySerialId = (id: string) => {
      const device = props.household?.deviceList?.find(f => f.deviceId === id);

      return device;
    };

    const data = computed(() => generateDeviceDataConfig());

    const isDataAvailable = () => {
      return props.household;
    };

    return { data, isDataAvailable };
  }
});
</script>
<template>
  <div>
    <TextPlaceholder v-if="!isDataAvailable()" :text="$t('device.smartDevice.errorText')" />

    <KeyValueView v-else :data="data.data" :config="data.config" />
  </div>
</template>
