<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { BaseInfo, DeviceDto, Deviceplate } from '@/api';
import { SmartDeviceMapper } from '@/helper/SmartDeviceMapper';
import { useI18n } from 'vue-i18n';
import { EntryType, KeyValueViewConfig } from '@/components/basic/KeyValueView/KeyValueView';
import KeyValueView from '@/components/basic/KeyValueView/KeyValueView.vue';
import SerialNumberFormater from '@/helper/SerialNumberFormatter';

type TCustomDataType = {
  connectivity_solution?: string;
  valueClass?: string;
  serialId?: string;
} & DeviceDto &
  Deviceplate &
  any;

export default defineComponent({
  name: 'SoftwareInformation',
  components: { KeyValueView },
  props: {
    serialId: {
      type: String,
      required: true
    },
    isAvailableAtSmartDevice: {
      type: Boolean,
      default: false
    },
    plate: {
      type: Object as PropType<Deviceplate>
    },
    device: {
      type: Object as PropType<DeviceDto>
    },
    baseInfo: {
      type: Object as PropType<BaseInfo>
    }
  },
  setup(props) {
    const { t } = useI18n();
    const generateDeviceDataConfig = () => {
      const isHidden = false;

      const config: KeyValueViewConfig<TCustomDataType> = {
        header: { value: t('device.data'), hasSeperator: true },
        item: { height: '48px', labelWidth: '50%', noSeparator: false },
        props: {
          articleDescriptionProduct: {
            description: { text: t('device.model_description') },
            type: EntryType.TEXT
          },
          valueClass: {
            description: { text: t('device.value_class') },
            type: EntryType.TEXT,
            isHidden: isHidden
          },
          serialNumber: {
            description: { text: t('device.serial_id') },
            type: EntryType.TEXT,
            isHidden: false
          },
          productId: {
            description: { text: t('device.service_number') },
            type: EntryType.TEXT,
            isHidden: false
          },
          supplierId: {
            description: { text: t('device.supplier') },
            type: EntryType.TEXT,
            isHidden: props.baseInfo?.supplierId === null
          },
          externalArticleDescription: {
            description: {
              text: t('device.external_article_description')
            },
            type: EntryType.TEXT,
            isHidden: isHidden
          },
          sku: {
            description: { text: t('device.sku') },
            type: EntryType.TEXT,
            isHidden: isHidden
          },
          index: {
            description: { text: t('device.index') },
            type: EntryType.TEXT,
            isHidden: false
          },
          generation: {
            description: { text: t('device.generation') },
            type: EntryType.TEXT,
            isHidden: isHidden
          },
          displayPosition: {
            description: { text: t('device.display_position') },
            type: EntryType.TEXT,
            isHidden: isHidden
          },
          connectivitySolutionType: {
            description: {
              text: t('device.connectivity_solution_type')
            },
            type: EntryType.TEXT,
            isHidden: isHidden
          }
        }
      };

      const data: TCustomDataType = {
        ...props.device,
        serialId: SerialNumberFormater.format2SerialNumber(props.serialId),
        serialNumber: SerialNumberFormater.format2SerialNumber(props.serialId),
        valueClass: SmartDeviceMapper.transformValueClass(props.device?.valueClass ?? ''),
        index: props.plate?.index,
        connectivitySolutionType: props.plate?.connectivitySolutionType,
        supplierId: mapSupplierId(props.baseInfo?.supplierId),
        externalArticleDescription: props.device?.externalArticleDescription
      };

      return { config, data };
    };

    const data = computed(() => generateDeviceDataConfig());

    const mapSupplierId = (supplierId: number | undefined | null) => {
      switch (supplierId) {
        case 0:
          return '0';
        case 1:
          return 'Liebherr_Consumer';
        case 2:
          return 'Liebherr_B/I';
        case 3:
          return 'Miele_Consumer';
        case 4:
          return 'VZUG_Consumer';
        case 5:
          return 'Bora_Consumer';
        case 6:
          return 'Landig_GameCooler';
        case 7:
          return 'Landig_DryAger';
        case 8:
          return 'Wepa';
        default:
          return '';
      }
    };

    return { data };
  }
});
</script>
<template>
  <div>
    <KeyValueView :data="data.data" :config="data.config" />
  </div>
</template>
