/* tslint:disable */
/* eslint-disable */
/**
 * AppAnDa backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DataAvailability } from '../model';
// @ts-ignore
import { ProblemDetails } from '../model';
/**
 * ApplianceDataApi - axios parameter creator
 * @export
 */
export const ApplianceDataApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isDataAvailable: async (deviceSerialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceSerialId' is not null or undefined
      assertParamExists('isDataAvailable', 'deviceSerialId', deviceSerialId);
      const localVarPath = `/appanda/api/v2/ApplianceData/{deviceSerialId}/isDataAvailable`.replace(
        `{${'deviceSerialId'}}`,
        encodeURIComponent(String(deviceSerialId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ApplianceDataApi - functional programming interface
 * @export
 */
export const ApplianceDataApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ApplianceDataApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isDataAvailable(
      deviceSerialId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isDataAvailable(deviceSerialId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * ApplianceDataApi - factory interface
 * @export
 */
export const ApplianceDataApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApplianceDataApiFp(configuration);
  return {
    /**
     *
     * @param {string} deviceSerialId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isDataAvailable(deviceSerialId: string, options?: any): AxiosPromise<DataAvailability> {
      return localVarFp.isDataAvailable(deviceSerialId, options).then(request => request(axios, basePath));
    }
  };
};

/**
 * ApplianceDataApi - interface
 * @export
 * @interface ApplianceDataApi
 */
export interface ApplianceDataApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceDataApiInterface
   */
  isDataAvailable(deviceSerialId: string, options?: AxiosRequestConfig): AxiosPromise<DataAvailability>;
}

/**
 * ApplianceDataApi - object-oriented interface
 * @export
 * @class ApplianceDataApi
 * @extends {BaseAPI}
 */
export class ApplianceDataApi extends BaseAPI implements ApplianceDataApiInterface {
  /**
   *
   * @param {string} deviceSerialId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceDataApi
   */
  public isDataAvailable(deviceSerialId: string, options?: AxiosRequestConfig) {
    return ApplianceDataApiFp(this.configuration)
      .isDataAvailable(deviceSerialId, options)
      .then(request => request(this.axios, this.basePath));
  }
}
