import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2 flex max-h-[70vh] w-[1080px] flex-row overflow-y-scroll border-2 border-solid border-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_pdf_embed = _resolveComponent("vue-pdf-embed")!
  const _component_DataViewSkeleton = _resolveComponent("DataViewSkeleton")!

  return (_openBlock(), _createBlock(_component_DataViewSkeleton, {
    header: _ctx.$t('privacyView.privacyHeader'),
    isLoading: false,
    error: { isError: !_ctx.filePath }
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vue_pdf_embed, {
          source: _ctx.filePath,
          width: "1000"
        }, null, 8, ["source"])
      ])
    ]),
    _: 1
  }, 8, ["header", "error"]))
}