import ChartConstants from '@/constants/ChartConstants';
import { useLoginStore } from '@/store/LoginStore';
import { CustomTimeSeriesDataSet, IChartOptionSettings, IntersectionLine, IScale } from '@/types/DeviceValueMapping';
import DateTimeFormatter from '@/views/shared/DateTimeFormatter';
import {
  BarElement,
  CategoryScale,
  Chart,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipOptions
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { PanOptions, ZoomOptions } from 'chartjs-plugin-zoom/types/options';
import { de } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  BarElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  LinearScale,
  PointElement,
  CategoryScale
);

ChartJS.defaults.font.family = 'LiebherrText-Regular, sans-serif';

type PluginChart = ChartJS & {
  corsair: IntersectionLine;
  changeScaleMode: boolean;
};

export default class ChartJSConfiguration {
  private static TEXTCOLOR = '#6B7278';
  private static DEFAULT_COLOR = '#5789A4';
  private static SCALE_INSTANCE = 75;

  private static getCurrentScale(chart: Chart) {
    const chartType = (chart.config as { type: string })?.type;

    let zoom: IScale;
    const boxes = chart.boxes as any;
    if (chartType === 'bar') {
      zoom = {
        min: boxes[2]?.min,
        max: boxes[2]?.max
      };
    } else {
      zoom = {
        min: boxes[3]?.min,
        max: boxes[3]?.max
      };
    }
    return zoom;
  }

  static generateYAxis(maxTicks: number | undefined, isTimeLine = false) {
    const showLabels = true;

    const { chartSettings } = storeToRefs(useLoginStore());

    const mirroredConfig = {
      display: true,
      mirror: true,
      padding: 4,
      color: 'rgb(0,0,0)',
      font: {
        size: 12,
        family: 'LiebherrText-Regular, sans-serif'
      },
      major: { enabled: true },
      backdropPadding: 2,
      backdropColor: 'rgba(255, 255, 255, 0.65)',
      z: 100
    };

    const tickConfig = isTimeLine ? mirroredConfig : {};

    return {
      display: isTimeLine ? true : !chartSettings.value.showMergedCharts,
      ticks: {
        display: showLabels,
        align: 'center',
        maxRotation: 0,
        showLabelBackdrop: true,
        textStrokeWidth: 1,
        border: 1,
        maxTicksLimit: maxTicks,
        autoSkip: false,
        ...tickConfig
      },
      afterFit: function (scaleInstance: any) {
        scaleInstance.width = isTimeLine ? 25 : ChartJSConfiguration.SCALE_INSTANCE;
      }
    } as const;
  }

  static generateXAxis(min: number, max: number) {
    const { language } = storeToRefs(useLoginStore());
    return {
      min: min,
      max: max,
      adapters: { date: { locale: language.value === 'de' ? de : enGB } },
      display: 'auto',
      parsing: false,
      type: 'time',

      ticks: {
        source: 'auto',
        align: 'center',
        minRotation: 0,
        maxRotation: 45,
        maxTicksLimit: 10,
        autoSkip: true
      },

      time: {
        //2022-09-27 08:13:10'
        minUnit: 'minute',
        displayFormats: {
          quarter: 'MM yyyy',
          month: 'MM yyyy',
          day: 'dd MM',
          second: 'HH:mm:ss',
          minute: 'dd.MM HH:mm',
          hour: 'dd.MM HH:mm'
        }
      }
    } as const;
  }

  static generateLineAreaOptions(options: IChartOptionSettings): ChartOptions {
    const chartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: '#ffff',
      animation: false,
      datasets: {
        line: {
          pointRadius: 0 // disable for all `'line'` datasets,
        }
      },
      scales: {
        y: this.generateYAxis(4),
        x: {
          ...this.generateXAxis(options.interval.from, options.interval.to),
          grid: {
            display: true
          }
        }
      },
      plugins: {
        zoom: ChartJSConfiguration.getZoomOptions(options) as any,

        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            color: this.TEXTCOLOR,
            boxWidth: 17,
            boxHeight: 2.5,
            padding: 20,
            usePointStyle: false,
            pointStyle: 'react',
            generateLabels: function (chart: any) {
              const labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);

              for (const key in labels) {
                labels[key].fillStyle = labels[key].strokeStyle;
                labels[key].lineDash = [1, 1];
                labels[key].lineDashOffset = 0;
                labels[key].lineCap = 'square';
              }
              return labels;
            },
            font: {
              family: 'LiebherrText-Regular, sans-serif'
            }
          }
        },
        tooltip: {
          enabled: true,
          intersect: false,
          mode: 'point',
          backgroundColor: '#ffff',
          titleColor: this.TEXTCOLOR,
          bodyAlign: 'center',
          bodyColor: this.TEXTCOLOR,
          borderWidth: 1,
          borderColor: this.TEXTCOLOR,
          cornerRadius: 0,
          usePointStyle: true,
          boxPadding: 0,
          callbacks: {
            labelColor: function (tooltipItem: any, _chart: any) {
              return {
                backgroundColor: tooltipItem.element.options.borderColor,
                borderColor: ChartJSConfiguration.TEXTCOLOR
              };
            },
            title: function (items: any, _data: any) {
              const { timeZone } = storeToRefs(useLoginStore());
              const zoneName = DateTime.now().setZone(timeZone.value).offsetNameLong;

              return `${DateTimeFormatter.getFormattedDateTimeFromMilliseconds(items[0]?.parsed?.x)} ${zoneName}`;
            },
            label: function (tooltipItem) {
              const item = tooltipItem.dataset as unknown as Record<string, number>;
              const dataset: any = tooltipItem.raw;

              const shortText = item.shortText ? `(${item.shortText})` : '';
              let unit = '';
              if (dataset.z === 1) {
                unit = '°F';
              } else if (dataset.z === 0) {
                unit = '°C';
              } else {
                unit = '';
              }
              return `${item.label} ${shortText}: ${tooltipItem.formattedValue} ${unit}`;
            }
          }
        } as TooltipOptions
      }
    };
    return chartOptions;
  }

  static getBackgroundPlugin = () => {
    return {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart: any) => {
        const bc = chart.config.options.backgroundColor;
        if (bc) {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = bc;
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      }
    };
  };

  static getCustomScalePlugin = () => {
    const plugin: Plugin = {
      id: 'customScale',
      afterDatasetsDraw: chart => {
        const {
          ctx,
          chartArea: { left: x },
          scales: { y: yScale, x: xScale },
          data: { datasets }
        } = chart;

        const checkIfLabelIsOverlapping = (extremePoints: [number, number], label: string) => {
          const PIXEL_PER_CHAR = 6.5;
          const labelStart = xScale.getValueForPixel(x);
          const labelEnd = xScale.getValueForPixel(x + label.length * PIXEL_PER_CHAR);

          const [min, max] = extremePoints;

          if (!labelStart) return false;

          if (labelEnd) {
            return labelStart >= min && labelEnd <= max;
          }

          return labelStart >= min && labelStart <= max;
        };

        const data = datasets as Array<CustomTimeSeriesDataSet>;

        yScale.ticks.forEach(tick => {
          const firstItem = data.find((d: any) => d.stackLabel === tick.label);
          const label = isArray(tick.label) ? (tick.label as string[]).join('') : tick.label;

          const isLabelOverlapping = checkIfLabelIsOverlapping(firstItem?.extremePoints ?? [0, 0], label ?? '');

          const y = yScale.getPixelForValue(tick.value);
          ctx.font = isLabelOverlapping ? '14px LiebherrHead-Regular' : '12px LiebherrHead-Regular';
          ctx.globalCompositeOperation = 'source-atop'; // 'multiply';
          ctx.textAlign = 'start';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = `${isLabelOverlapping ? 'black' : 'black'}`;
          ctx.fillText(label ?? '', x, y);
          ctx.restore();
        });
      }
    };
    return plugin;
  };

  static drawMouseIntersectionLine(chart: PluginChart, intersectionLine: IntersectionLine) {
    const {
      ctx,
      chartArea: { top, bottom },
      scales: { x: xScale }
    } = chart;

    const { position, draw } = intersectionLine;

    if (!draw) {
      return;
    }

    const x = xScale.getPixelForValue(position.x);

    ctx.lineWidth = 0;
    ctx.setLineDash([]);
    ctx.strokeStyle = chart.changeScaleMode ? 'black' : 'red';

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);

    if (!chart.changeScaleMode) {
      ctx.stroke();
      ctx.restore();
    }
  }

  static getMouseIntersectionPlugin = (updateCB: (intersectionLineRef: IntersectionLine) => void) => {
    const plugin: Plugin = {
      id: 'intersectionLine',
      afterInit: (chart: PluginChart) => {
        updateCB({
          position: { x: 0, y: 0 },
          draw: false
        });

        chart.corsair = {
          position: { x: 0, y: 0 },
          draw: false
        };
      },
      afterEvent: (chart: PluginChart, evt) => {
        const {
          chartArea: { top, bottom, left, right },
          scales: { x: xScale }
        } = chart;
        const {
          event: { x, y }
        } = evt;

        if (!x || !y) return;
        const currXAxisVal = xScale.getValueForPixel(x) ?? 0;
        if (x < left || x > right || y < top || y > bottom) {
          chart.corsair = {
            position: { x: currXAxisVal, y: y },
            draw: false
          };

          updateCB({
            position: { x: currXAxisVal, y: y },
            draw: false
          });
          return;
        }

        chart.corsair = {
          position: { x: currXAxisVal, y: y },
          draw: true
        };

        updateCB({
          position: { x: currXAxisVal, y: y },
          draw: true
        });
      },
      afterDatasetsDraw: (chart: PluginChart, _, _opts) => {
        if (chart && chart.corsair) {
          this.drawMouseIntersectionLine(chart, chart.corsair);
        }
      }
    };
    return plugin;
  };

  static getZoomOptions = (options: IChartOptionSettings) => {
    const config: { zoom: ZoomOptions; pan: PanOptions } = {
      zoom: {
        onZoomComplete: ({ chart }) => {
          (chart as PluginChart).changeScaleMode = false;
        },
        onZoomStart: ({ chart }) => {
          (chart as PluginChart).changeScaleMode = true;
        },
        onZoom: ({ chart }) => {
          if (options.synchronizedMode) {
            const zoom = ChartJSConfiguration.getCurrentScale(chart);
            options.onScaleChange(zoom);
          }
        },

        pinch: {
          enabled: false
        },
        drag: {
          enabled: true,
          backgroundColor: '#edf1f7',
          borderWidth: 1,
          borderColor: '#5789A4',
          modifierKey: 'alt'
        },

        mode: 'x'
      },
      pan: {
        enabled: true,
        onPanComplete: ({ chart }) => {
          (chart as PluginChart).changeScaleMode = false;
          if (options.synchronizedMode) {
            const zoom = ChartJSConfiguration.getCurrentScale(chart);
            options.onScaleChange(zoom);
          }
        },
        onPanStart: ({ chart }) => {
          return ((chart as PluginChart).changeScaleMode = true);
        },
        mode: 'x'
      }
    };

    return config;
  };

  static generateBarChartTimeLineOptions(options: IChartOptionSettings) {
    const chartOptions: ChartOptions<'bar'> = {
      indexAxis: 'y' as const,

      resizeDelay: 20,
      responsive: true,
      animation: false,
      normalized: true,

      maintainAspectRatio: false,
      backgroundColor: '#ffff',
      scales: {
        x: {
          ...this.generateXAxis(options.interval.from, options.interval.to),
          stacked: true
        },
        y: {
          ...this.generateYAxis(20, true),
          stacked: true,
          grid: {
            display: true,
            tickColor: '#D3D8DD',
            borderWidth: 2,
            borderColor: '#D3D8DD',
            borderDash: [0, 0],
            offset: false,
            tickBorderDashOffset: 0,
            lineWidth: 0.4,
            drawBorder: false,
            drawOnChartArea: true
          } as any
        }
      },
      transitions: {
        zoom: {
          animation: {
            duration: 0
          }
        }
      },

      plugins: {
        zoom: ChartJSConfiguration.getZoomOptions(options),
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Timeline'
        },

        tooltip: {
          //change
          enabled: true,
          intersect: true,
          mode: 'nearest',
          backgroundColor: '#ffff',
          titleColor: this.TEXTCOLOR,
          bodyAlign: 'center',
          xAlign: 'left',
          yAlign: 'top',
          bodyColor: this.TEXTCOLOR,
          position: 'nearest',
          borderColor: this.TEXTCOLOR,
          cornerRadius: 0,
          usePointStyle: false,
          boxPadding: 5,
          callbacks: {
            title: function (tooltipItem) {
              const item = tooltipItem[0];

              const label = item.label.replaceAll(',', '');

              return `${label} - ${item.dataset.label}`;
            },
            label: function (tooltipItem) {
              return ChartJSConfiguration.getTimelineDataTooltip(tooltipItem);
            }
          }
        } as TooltipOptions
      }
    };
    return chartOptions;
  }

  private static getTimelineDataTooltip(tooltipItem: any) {
    const item = tooltipItem.dataset as unknown as Record<string, number>;
    const label = (tooltipItem.dataset as any).stackLabel as string;
    const stateLabel = (tooltipItem.dataset as any).label as string;

    const { timeZone } = storeToRefs(useLoginStore());
    const zoneName = DateTime.now().setZone(timeZone.value).offsetNameLong;
    const fromDate = DateTimeFormatter.getFormattedDateTimeFromMilliseconds(item.fromDate);
    const toDate = DateTimeFormatter.getFormattedDateTimeFromMilliseconds(item.toDate);

    const diffInMinutes = DateTime.fromMillis(item.toDate).diff(DateTime.fromMillis(item.fromDate), 'minutes').minutes;
    const signalDuration = 15; // 15 minutes
    const toolTipContent = 'Zoom-in to visualize detailed events';

    // There are some signals, which can have many data points.
    // Door, BiofreshPlusHumidifierState, Cooling System state etc
    if (label === 'Door') {
      if (
        (diffInMinutes < signalDuration && stateLabel === ChartConstants.TimelineValues.DOOR[1]) ||
        stateLabel === ChartConstants.TimelineValues.DOOR[0]
      ) {
        return `${fromDate} - ${toDate} ${zoneName}`;
      }
      return toolTipContent;
    } else if (label === 'BiofreshPlusHumidifierState') {
      if (
        (diffInMinutes < signalDuration && stateLabel === ChartConstants.TimelineValues.OPERATION_STATUS[1]) ||
        stateLabel === ChartConstants.TimelineValues.OPERATION_STATUS[0]
      ) {
        return `${fromDate} - ${toDate} ${zoneName}`;
      }
      return toolTipContent;
    } else if (label === 'CoolingSystemState') {
      if (diffInMinutes < 90) {
        return `${fromDate} - ${toDate} ${zoneName}`;
      }
      return toolTipContent;
    }
    return `${fromDate} - ${toDate} ${zoneName}`;
  }
}
