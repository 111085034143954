import { defineComponent, ref, watch } from 'vue';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { useFetch } from '@/composables/common/useFetch';
import { HistoryApiFactory, VwSearchHistory } from '@/api';
import DataViewSkeleton from '@/components/basic/DataViewSkeleton/DataViewSkeleton.vue';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import TextPlaceHolder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { PatternLibEvent } from '@/types/patternlib';

export default defineComponent({
  name: 'HistoryView',
  components: {
    DataViewSkeleton,
    LoadingSpinner,
    TextPlaceHolder
  },
  setup() {
    const { getConfig } = useHttpConfiguration();
    const tableRef = ref();
    const { t } = useI18n();
    const router = useRouter();
    const getData = () => {
      return HistoryApiFactory(getConfig()).getSearchHistory(-1);
    };
    const { data, error, isLoading, executeRequest } = useFetch<VwSearchHistory[]>(getData);

    const reload = () => {
      executeRequest();
    };

    const displayDeviceDetails = (deviceSerialId: string) => {
      const historyItem = data.value?.find(id => id.deviceSerialId === deviceSerialId);

      if (!historyItem) return;

      router.push({
        name: 'DefaultDeviceView',
        params: { serialNumber: deviceSerialId, deviceType: historyItem.targetView?.toLowerCase() }
      });
    };

    watch(
      () => tableRef.value,
      () => {
        tableRef.value?.addEventListener('lhActionClick', (e: PatternLibEvent) => {
          const deviceSerialId = e.detail?.clickedRow?.items[0].value;
          displayDeviceDetails(deviceSerialId);
        });
      }
    );

    return { data, error, isLoading, t, reload, tableRef };
  }
});
