import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-container"
}
const _hoisted_2 = { class: "flex-item big" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_headline = _resolveComponent("patternlib-headline")!
  const _component_patternlib_table_cell = _resolveComponent("patternlib-table-cell")!
  const _component_patternlib_icon = _resolveComponent("patternlib-icon")!
  const _component_patternlib_table = _resolveComponent("patternlib-table")!
  const _component_TextPlaceHolder = _resolveComponent("TextPlaceHolder")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_patternlib_headline, {
      separator: "bottom",
      class: "mb-header block",
      headline: _ctx.$t('device.configuration'),
      "heading-tag": "h3"
    }, null, 8, ["headline"]),
    (_ctx.deviceFiles && _ctx.deviceFiles.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_patternlib_table, {
              "implicit-ids": "",
              "no-grid-lines": "",
              columns: [
						{ label: _ctx.$t('file.id'), align: 'left' },
						{ label: _ctx.$t('file.name'), align: 'left' },
						{ label: _ctx.$t('file.type'), align: 'left' },
						{ label: _ctx.$t('file.last_update'), align: 'left' },
						{ label: 'Action', align: 'left' },
					]
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceFiles, (file, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: file.fileId ?? index
                  }, [
                    _createVNode(_component_patternlib_table_cell, {
                      value: file.fileId
                    }, null, 8, ["value"]),
                    _createVNode(_component_patternlib_table_cell, {
                      value: file.fileName
                    }, null, 8, ["value"]),
                    _createVNode(_component_patternlib_table_cell, {
                      value: file.fileType
                    }, null, 8, ["value"]),
                    _createVNode(_component_patternlib_table_cell, {
                      value: file.lastUpdate
                    }, null, 8, ["value"]),
                    _createVNode(_component_patternlib_table_cell, {
                      slot: `${index}-4`,
                      value: `row${index + 1}4`
                    }, {
                      default: _withCtx(() => [
                        (file.fileUrl)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "mx-3 flex flex-row gap-3 text-gray-600",
                              onClick: ($event: any) => (_ctx.downloadFile(index))
                            }, [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_patternlib_icon, {
                                  size: "32px",
                                  "icon-name": "download"
                                })
                              ]),
                              _createElementVNode("a", {
                                href: file.fileUrl,
                                target: "_blank",
                                class: "flex"
                              }, [
                                _createVNode(_component_patternlib_icon, {
                                  size: "32px",
                                  "icon-name": "visible"
                                })
                              ], 8, _hoisted_5)
                            ], 8, _hoisted_3))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["slot", "value"])
                  ], 64))
                }), 128))
              ]),
              _: 1
            }, 8, ["columns"])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_TextPlaceHolder, { key: 1 }))
  ]))
}