<template>
	<div>
		<patternlib-headline
			headline="Services"
			heading-tag="h1"></patternlib-headline>
		<br /><br />

		<div v-if="showPageContent && !serviceDisabled">
			<div
				v-for="service in monitoringDataHistory"
				:key="service.serviceName">
				<h3>{{ service.serviceName }}</h3>

				<div>
					<patternlib-table
						implicit-ids="true"
						:columns="tableColumnsHeader">
						<template
							v-for="(
								endpoint, endpointIndex
							) in service.endpoints"
							:key="endpoint.endpointName">
							<patternlib-table-cell
								:value="endpoint.endpointName"
								:slot="endpointIndex + '-0'"
								>{{
									endpoint.endpointName
								}}</patternlib-table-cell
							>
							<patternlib-table-cell
								v-for="(value, valueIndex) in endpoint.values"
								:key="value.month"
								:value="value.status"
								:slot="endpointIndex + '-' + (valueIndex + 1)">
								<div
									class="status-indicator success"
									v-if="value.status == 'GOOD'"></div>
								<div
									class="status-indicator warning"
									v-if="value.status == 'MIDDLE'"></div>
								<div
									class="status-indicator error"
									v-if="value.status == 'BAD'"></div>
							</patternlib-table-cell>
						</template>
					</patternlib-table>

					<!-- <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th v-for="date in service.endpoints[0].values" :key="date.month">{{ date.month }} ({{
                                        date.year
                                }})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="endpoint in service.endpoints" :key="endpoint.endpointName">
                                <td>{{ endpoint.endpointName }}</td>

                                <td v-for="value in endpoint.values" :key="value.month">
                                    <div class="status-indicator success" v-if="value.status == 'GOOD'"></div>
                                    <div class="status-indicator warning" v-if="value.status == 'MIDDLE'"></div>
                                    <div class="status-indicator error" v-if="value.status == 'BAD'"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
				</div>
			</div>
		</div>

		<div
			class="smartdevice-container loading"
			v-if="!showPageContent && !serviceDisabled">
			<div>
				<patternlib-spinner
					duration="1000"
					progress="25"
					spin="true"
					type="indeterminate"></patternlib-spinner>
			</div>
			<p>{{ $t('loading.hint') }}</p>
		</div>

		<div v-if="serviceDisabled">
			<div>{{ $t('general.service_disabled') }}</div>
			<br />
			<div>
				<patternlib-status-pill
					:label="$t('general.deactivated')"
					type="warning"></patternlib-status-pill>
			</div>
		</div>
	</div>
</template>

<script src="./ServicesView.ts" lang="ts"></script>
<style lang="scss" src="./ServicesView.scss"></style>
