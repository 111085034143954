import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "observerRef",
  class: "relative"
}
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between",
  style: {"align-items":"end"}
}
const _hoisted_3 = { class: "my-5 flex flex-row items-center" }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "mt-block flex flex-col gap-2 xl:flex-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_headline = _resolveComponent("patternlib-headline")!
  const _component_patternlib_datepicker = _resolveComponent("patternlib-datepicker")!
  const _component_TheButton = _resolveComponent("TheButton")!
  const _component_TextPlaceHolder = _resolveComponent("TextPlaceHolder")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!
  const _component_HistoryCharts = _resolveComponent("HistoryCharts")!
  const _component_ChartSettingsComponent = _resolveComponent("ChartSettingsComponent")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_patternlib_headline, {
      separator: "bottom",
      class: "mb-header block",
      headline: _ctx.t('history.chart_title'),
      "heading-tag": "h3"
    }, null, 8, ["headline"]),
    (_ctx.historyIntervalsLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_patternlib_datepicker, {
            "data-demo": "datepicker",
            ref: "datePickerRef",
            "date-range": "",
            size: "small",
            width: "40%",
            disabled: 
          !_ctx.historyIntervalsLoaded ||
          _ctx.historyDeviceBaseDataRequest.isLoading.value ||
          _ctx.historyDeviceZoneDataRequest.isLoading.value
        ,
            date: _ctx.selectedTimeRange?.value,
            "maximum-date": _ctx.selectedTimeRange?.max,
            "minimum-date": _ctx.selectedTimeRange?.min,
            applyButtonText: _ctx.t('historyCharts.fetchData_btn_label'),
            label: _ctx.t('historyCharts.selectTimeRange'),
            "disable-manual-input": "true",
            locale: _ctx.datePickerLocale
          }, null, 8, ["disabled", "date", "maximum-date", "minimum-date", "applyButtonText", "label", "locale"]),
          _createVNode(_component_TheButton, {
            "data-demo": "settingsBtn",
            icon: "settings",
            label: _ctx.t('profile.chartSettings.label'),
            fullWidth: true,
            width: "300px",
            disabled: _ctx.historyDeviceBaseDataRequest.isLoading.value || _ctx.historyDeviceZoneDataRequest.isLoading.value,
            onClick: _ctx.toggleSettingsModal
          }, null, 8, ["label", "disabled", "onClick"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.historyIntervalsLoaded)
        ? (_openBlock(), _createBlock(_component_TextPlaceHolder, {
            key: 0,
            class: "h-14 justify-end",
            text: _ctx.$t('historyCharts.no_data'),
            centered: ""
          }, null, 8, ["text"]))
        : (!_ctx.historyDeviceBaseDataRequest.data.value && !_ctx.historyDeviceZoneDataRequest.data.value)
          ? (_openBlock(), _createBlock(_component_TextPlaceHolder, {
              key: 1,
              class: "h-14 justify-end",
              icon: "digital-touch",
              text: _ctx.$t('historyCharts.placeholder_text'),
              centered: ""
            }, null, 8, ["text"]))
          : _createCommentVNode("", true)
    ]),
    (_ctx.historyDeviceBaseDataRequest.isLoading.value || _ctx.historyDeviceZoneDataRequest.isLoading.value)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
          key: 1,
          text: _ctx.t('historyCharts.loading_history_data'),
          class: "mt-10",
          "data-cy": "loadingSpinner"
        }, null, 8, ["text"]))
      : (_ctx.historyDeviceBaseDataRequest.data.value && _ctx.historyDeviceZoneDataRequest.data.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (!_ctx.useOverallView)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_AppDropdown, {
                    "data-demo": "singleViewDropdown",
                    class: "w-full xl:w-1/3",
                    activeItem: _ctx.selectedHistoryType,
                    options: _ctx.dropDownOptions,
                    onSelect: _ctx.updateDropdown,
                    disabled: false,
                    hideRemoveIcon: "true",
                    placeholder: "Select info type",
                    label: "Select info"
                  }, null, 8, ["activeItem", "options", "onSelect"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_HistoryCharts, {
              serialId: _ctx.serialId,
              chartsData: _ctx.chartsData,
              selectedHistoryType: _ctx.selectedHistoryType,
              zones: _ctx.zones,
              currentZoom: _ctx.currentZoom,
              onOnZoomReset: _ctx.resetZoom,
              onOnUpdatedIntersectionLine: _ctx.updateIntersectionLine
            }, null, 8, ["serialId", "chartsData", "selectedHistoryType", "zones", "currentZoom", "onOnZoomReset", "onOnUpdatedIntersectionLine"])
          ]))
        : _createCommentVNode("", true),
    (_ctx.isSettingsModalOpen)
      ? (_openBlock(), _createBlock(_component_AppModal, {
          key: 3,
          title: _ctx.t('profile.chartSettings.label'),
          icon: "settings",
          onOnClose: _ctx.toggleSettingsModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ChartSettingsComponent, { onClose: _ctx.toggleSettingsModal }, null, 8, ["onClose"])
          ]),
          _: 1
        }, 8, ["title", "onOnClose"]))
      : _createCommentVNode("", true)
  ], 512))
}