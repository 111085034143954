<template>
  <div>
    <div>
      <div class="mb-block">
        <patternlib-teaser
          data-cy="lh-teaser"
          image="./assets/teaser/01.png"
          :headline="$t('teaser.title')"
          image-object-fit="cover"
          :text="$t('teaser.description')"
        >
        </patternlib-teaser>
      </div>

      <SearchComponent class="mb-block" />

      <div>
        <div class="mb-block" data-cy="lh-history">
          <DataViewSkeleton
            :header="$t('history.title')"
            smallHeader
            :isLoading="isLoading"
            :pageLayout="false"
            :error="{ isError: error.isError }"
          >
            <template #content>
              <div class="mb-block grid grid-cols-6 gap-6" v-if="!isLoading">
                <router-link
                  data-cy="lh-historyItem"
                  v-for="(item, index) in historyItems"
                  :key="item?.deviceSerialId ?? index"
                  :to="{
                    name: 'DefaultDeviceView',
                    params: { serialNumber: item.deviceSerialId, deviceType: item.targetView }
                  }"
                  class="history-item col-span-6 md:col-span-2 xl:col-span-1"
                >
                  <div class="device-serial-id">{{ item.deviceSerialId }}</div>
                  <div class="appliance-image">
                    <LoadingSpinner v-if="isFetchingImages" />
                    <img
                      :src="historyImages[index]"
                      :alt="item.deviceSerialId ?? undefined"
                      v-else-if="historyImages[index]"
                    />
                    <patternlib-icon icon-name="refrigeration-freezing" v-else size="128px"> </patternlib-icon>
                  </div>
                </router-link>

                <router-link
                  data-cy="lh-navHistoryPage"
                  :to="{ name: 'HistoryView' }"
                  class="history-item col-span-6 md:col-span-2 xl:col-span-1"
                >
                  <div class="device-serial-id">{{ $t('history.show_total') }}</div>
                  <div class="appliance-image">
                    <patternlib-icon icon-name="history" size="" class="hydrated"></patternlib-icon>
                  </div>
                </router-link>

                <template v-if="historyItems.length >= 0 && historyItems.length < 6">
                  <div
                    data-cy="lh-historyPlaceholderItem"
                    class="history-item placeholder-item col-span-6 md:col-span-2 xl:col-span-1"
                    v-for="n in 6 - historyItems.length - 1"
                    :key="n"
                  ></div>
                </template>
              </div>
            </template>
          </DataViewSkeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./HomeView.ts" lang="ts"></script>
<style lang="scss" src="./HomeView.scss" scoped></style>
