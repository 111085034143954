<script setup lang="ts">
import { PatternLibEvent } from '@/types/patternlib';
import CmpHelper from '@/views/shared/CmpHelper';
import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const { t } = useI18n();
const router = useRouter();
const footer = ref();
const showMap = ref(false);

const footerLinks = computed(() => {
  return JSON.stringify([
    { label: t('general.imprint'), itemId: '/imprint' },
    { label: t('privacyView.privacyHeader'), itemId: '/privacy' },
    { label: t('general.privacy_settings'), itemId: 'privacy-setting' },
    { label: `Version: ${process.env.VUE_APP_DEPLOY_VERSION}`, itemId: 'version' }
  ]);
});

const scrollUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

onMounted(() => {
  footer.value?.addEventListener('lhClickArrowTop', scrollUp);
  footer.value?.addEventListener('lhClickLink', (e: PatternLibEvent) => {
    const path = e.detail.itemId;
    if (path === 'privacy-setting') {
      CmpHelper.showPrivacySettings();
      return;
    }

    if (path === 'version') {
      return;
    }

    if (!path) return;
    router.push(path.toString());
  });
});

onBeforeUnmount(() => {
  footer.value?.removeEventListener('lhClickArrowTop', scrollUp);
});
</script>

<template>
  <div class="footer">
    <patternlib-footer
      ref="footer"
      :additional-links="footerLinks"
      links=""
      copyrights="Copyright © Liebherr 2023"
      show-arrow-icon
    >
      <svg width="161" height="20" viewBox="0 0 161 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M148.505 9.67737H147.029V4.1936H148.505C150.474 4.1936 151.13 5.19068 151.13 6.93549C151.13 8.68029 150.474 9.67737 148.505 9.67737ZM155.895 11.6603C158.446 10.5647 159.576 8.70726 159.576 6.10839C159.576 3.13968 157.823 1.19871 154.891 0.485323C153.604 0.17129 151.521 0 149.631 0H138.429V20H147.029V13.5484H147.898L151.291 20H161L155.895 11.6603ZM124.153 9.71014H122.67V4.20288H124.153C126.13 4.20288 126.788 5.20417 126.788 6.95643C126.788 8.70885 126.13 9.71014 124.153 9.71014ZM131.515 11.6602C134.066 10.5645 135.195 8.7071 135.195 6.10823C135.195 3.13968 133.442 1.19871 130.511 0.485323C129.223 0.17129 127.141 0 125.251 0H114.085V20H122.67V13.4782H123.548L126.978 19.9997H136.62L131.515 11.6602ZM38.2924 11.8829H42.8715V7.97H38.2924L38.2834 4.34758H43.292V0H29.4117V19.9994H43.4348V15.6518H38.2834L38.2924 11.8829ZM105.508 11.8829H110.087V7.97H105.508L105.499 4.34758H110.507V0H96.6272V19.9994H110.65V15.6518H105.499L105.508 11.8829ZM9.01488 0H0.000159248L0 20H13.3076V14.9274H9.01488V0ZM57.144 16.3763H55.5221V11.4489H57.144C59.2507 11.4489 59.9103 12.4342 59.9103 13.9126C59.9103 15.3906 59.2507 16.3763 57.144 16.3763ZM55.5251 3.62292H56.9903C58.9467 3.62292 59.5996 4.61244 59.5996 6.01422C59.5996 7.41599 58.9467 8.40551 56.9903 8.40551H55.5251V3.62292ZM64.1208 9.97645C66.0783 9.57097 67.6866 7.92387 67.6866 5.37548C67.6866 2.76355 66.2016 1.05468 63.6839 0.426935C62.5784 0.150645 60.79 0 59.1666 0H46.858V19.9995H59.2548C60.9158 19.9995 62.7456 19.8489 63.8767 19.5729C66.4525 18.9461 67.973 17.2319 67.973 14.6235C67.973 11.9492 66.213 10.2695 64.1208 9.97645ZM83.7667 7.46H80.0461V0H71.0313V19.9994H80.0461V12.1052H83.7667V19.9994H92.7812V0H83.7667V7.46ZM16.462 20H25.6199V0H16.462V20Z"
          fill="white"
        />
      </svg>
    </patternlib-footer>
  </div>
</template>

<style lang="scss" src="./GlobalStyles.scss" />
