<template>
  <div class="mt-6 lg:mt-0 lg:w-1/2 lg:self-end">
    <div class="point-out">
      <patternlib-headline
        separator="none"
        :headline="$t('household.additional_registered_devices')"
        heading-tag="h4"
      ></patternlib-headline>
      <br />
      <table>
        <tr v-for="device in deviceList" :key="device.deviceId ?? ''">
          <td v-if="device.deviceId != serialId">{{ device.nickname }}</td>
          <td v-if="device.deviceId != serialId">{{ device.deviceId }}</td>
          <td style="text-align: right" v-if="device.deviceId != serialId">
            <span @click="navigateToDevice(device.deviceId ?? '')" class="textlink">
              {{ $t('device.go_to') }}
            </span>
          </td>
        </tr>

        <tr v-if="deviceList.length === 0 || (deviceList.length === 1 && deviceList[0].deviceId === serialId)">
          <td>{{ $t('general.no_information_available') }}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { HouseholdDto, SniApiFactory } from '@/api';
import { useHttpConfiguration } from '@/composables/common/useHttpConfiguration';
import { useToast } from '@/composables/common/useToast';
import { i18n } from '@/plugins/i18n';
import { ToastType } from '@/store/GlobalUIStore';
import { computed, PropType } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  serialId: String,
  household: { type: Object as PropType<HouseholdDto> }
});

const emit = defineEmits(['onNavigated']);

const router = useRouter();
const { getConfig } = useHttpConfiguration();
const displayToast = useToast();

const navigateToDevice = (serialId: string) => {
  getDeviceInfo(serialId);
};

const getDeviceInfo = async (serialNo: string) => {
  try {
    const { data, status } = await SniApiFactory(getConfig()).getDeviceInfo(serialNo);

    if (status !== 200 && status != 204) {
      throw new Error();
    }

    emit('onNavigated', data.deviceKind?.toLowerCase());

    router.push({
      name: 'DefaultDeviceView',
      params: { serialNumber: serialNo, deviceType: data.deviceKind?.toLowerCase() }
    });
  } catch (error) {
    displayToast(i18n.global.t('search.not_performed'), ToastType.WARNING);
    return;
  }
};

const deviceList = computed(() => {
  return props.household && props.household.deviceList ? props.household.deviceList : [];
});
</script>

<style lang="scss" src="../DeviceView.scss"></style>
