<template>
  <div class="selection-container">
    <div class="pb-4">{{ $t('statistics.description') }}</div>
    <div class="content pt-4">
      <patternlib-dropdown
        ref="domainRef"
        :value="selectedDomain"
        :label="$t('statistics.domain')"
        :placeholder="$t('statistics.domain')"
        disabled="false"
        size="small"
        width="50%"
        show-remove-icon="false"
        prevent-input="true"
      >
        <div v-for="(domain, index) of domains" :key="index">
          <patternlib-option :value="domain.value" :label="domain.value"></patternlib-option>
        </div>
      </patternlib-dropdown>

      <patternlib-dropdown
        ref="applianceTypeRef"
        :value="selectedApplianceType"
        :label="$t('statistics.appliancetype')"
        :placeholder="$t('statistics.appliancetype')"
        disabled="false"
        size="small"
        width="50%"
        show-remove-icon="true"
        prevent-input="true"
      >
        <div v-for="(appliance, index) of applianceTypes" :key="index">
          <patternlib-option :value="appliance.value" :label="appliance.value"></patternlib-option>
        </div>
      </patternlib-dropdown>

      <patternlib-dropdown
        ref="applianceModelRef"
        :value="selectedApplianceModel"
        :label="$t('statistics.appliancemodel')"
        :placeholder="$t('statistics.appliancemodel')"
        :disabled="selectedApplianceType === ''"
        size="small"
        width="50%"
        show-remove-icon="true"
        prevent-input="true"
      >
        <div v-for="(appliance, index) of applianceModels" :key="index">
          <patternlib-option :value="appliance.value" :label="appliance.value"></patternlib-option>
        </div>
      </patternlib-dropdown>
    </div>
  </div>
</template>

<style lang="scss" src="./ApplianceSelection.scss"></style>
<script setup lang="ts">
import { PatternLibGenericEvent } from '@/types/patternlib';
import { computed, onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export type Options = {
  label: string;
  value: string;
};

const props = defineProps({
  applianceModels: { type: Array<Options>, default: [] }
});

const emit = defineEmits<{
  (e: 'applianceTypeSelected', type: number, numberOfZones: number): void;
  (e: 'applianceModelSelected', model: string): void;
  (e: 'domainSelected', domain: string): void;
}>();

const applianceTypeRef = ref();
const applianceModelRef = ref();
const domainRef = ref();

const { t } = useI18n();
const selectedApplianceType: Ref<string | undefined> = ref('');
const selectedApplianceModel: Ref<string | undefined> = ref('');
const selectedDomain: Ref<string | undefined> = ref(t('general.all_domains'));

const applianceTypes = computed(() => {
  return [
    { label: t('general.cooler'), value: t('general.cooler') },
    { label: t('general.freezer'), value: t('general.freezer') },
    { label: t('general.combi'), value: t('general.combi') },
    { label: t('general.wine'), value: t('general.wine') },
    { label: t('general.biofresh'), value: t('general.biofresh') }
  ];
});

const domains = computed(() => {
  return [
    { label: t('general.all_domains'), value: t('general.all_domains') },
    { label: t('general.consumer'), value: t('general.consumer') },
    { label: t('general.business'), value: t('general.business') }
  ];
});

const onApplianceTypeSelection = (event: PatternLibGenericEvent) => {
  if (event.detail.selected) {
    mapApplianceType(event.detail.selected.value);
  } else {
    // Selection cleared
    emit('applianceTypeSelected', -1, 0);
    selectedApplianceType.value = '';
  }

  // On selecting appliance type, we need to reset appliance model.
  // Currently, dropdown value is not reset, problem with pattern lib component
  emit('applianceModelSelected', '');
  selectedApplianceModel.value = '';
};

const onDomainSelection = (event: PatternLibGenericEvent) => {
  selectedDomain.value = event.detail.selected.value;

  emit('domainSelected', selectedDomain.value as string);

  // On selecting appliance type, we need to reset appliance model.
  // Currently, dropdown value is not reset, problem with pattern lib component
  selectedApplianceModel.value = '';
  selectedApplianceType.value = '';
  emit('applianceTypeSelected', -1, 0);
  emit('applianceModelSelected', '');
};

const onApplianceModelSelection = (event: PatternLibGenericEvent) => {
  if (event.detail.selected) {
    const model = event.detail.selected.value;
    selectedApplianceModel.value = model;
    emit('applianceModelSelected', model);
  } else {
    emit('applianceModelSelected', '');
    selectedApplianceModel.value = '';
  }
};

const mapApplianceType = async (applianceType: string) => {
  let type: number;
  let numberOfZones: number;

  switch (applianceType) {
    case t('general.cooler'):
      type = 0;
      numberOfZones = 1;
      break;
    case t('general.freezer'):
      type = 1;
      numberOfZones = 1;
      break;
    case t('general.wine'):
      type = 2;
      numberOfZones = 1;
      break;
    case t('general.biofresh'):
      type = 4;
      numberOfZones = 1;
      break;
    case t('general.combi'):
      type = 5;
      numberOfZones = 2;
      break;
    default:
      type = -1;
      numberOfZones = 0;
      break;
  }
  selectedApplianceType.value = applianceType;
  emit('applianceTypeSelected', type, numberOfZones);
};

onMounted(() => {
  if (applianceTypeRef.value) {
    applianceTypeRef.value.addEventListener('lhSelect', onApplianceTypeSelection);
  }

  if (applianceModelRef.value) {
    applianceModelRef.value.addEventListener('lhSelect', onApplianceModelSelection);
  }

  if (domainRef.value) {
    domainRef.value.addEventListener('lhSelect', onDomainSelection);
  }
});
</script>
