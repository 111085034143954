import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_texttoggle_item = _resolveComponent("patternlib-texttoggle-item")!
  const _component_patternlib_texttoggle = _resolveComponent("patternlib-texttoggle")!

  return (_ctx.options)
    ? (_openBlock(), _createBlock(_component_patternlib_texttoggle, {
        key: 0,
        disabled: _ctx.disabled,
        value: _ctx.active,
        name: "texttoggle",
        size: "small",
        width: "auto",
        ref: "toggle"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ label }, index) => {
            return (_openBlock(), _createBlock(_component_patternlib_texttoggle_item, {
              key: index,
              value: index
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(label), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["disabled", "value"]))
    : _createCommentVNode("", true)
}