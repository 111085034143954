<template>
  <DataViewSkeleton :header="$t('admin.home.headline')">
    <template #content>
      <patternlib-list>
        <patternlib-list-item>
          <span>{{ $t('maintenanceModeView.maintenanceMode') }}</span>
          <span slot="value">
            <patternlib-toggle
              ref="toggleMaintenanceModeRef"
              :checked="isMaintenanceActivated"
              disabled="false"
              :label-off="$t('general.off')"
              :label-on="$t('general.on')"
              additional-info=""
              additional-info-position="top"
              name="toggle"
              status="default"
              success-text="Success"
              error-text="Error"
              warning-text="Warning"
            ></patternlib-toggle>
          </span>
        </patternlib-list-item>
      </patternlib-list>
    </template>
  </DataViewSkeleton>
</template>

<script src="./AdminHomeView.ts" lang="ts"></script>
