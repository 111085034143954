<template>
  <LoadingSpinner
    data-cy="result-spinner"
    class="h-[80vh] border border-dashed"
    :text="t('login.callback')"
  ></LoadingSpinner>
</template>

<script setup lang="ts">
import { useLoginStore } from '@/store/LoginStore';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LoadingSpinner from '@/components/basic/Spinner/LoadingSpinner.vue';
import { onMounted } from 'vue';

const { t } = useI18n();
const router = useRouter();

const loginStore = useLoginStore();

onMounted(() => {
  loginStore
    .loginResult()
    .then(() => {
      const nextRoute = loginStore.user?.state ?? '/';

      if (nextRoute === '/login-callback') {
        router.replace({ name: 'Login' });
      } else {
        router.push(nextRoute);
      }
    })

    .catch(err => {
      console.log(`Enter /login-callback with error ${err}`);
      router.replace({ name: 'Login' });
    });
});
</script>
