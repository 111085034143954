import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "customListEntry" }
const _hoisted_4 = {
  slot: "value",
  style: {"cursor":"pointer"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patternlib_icon = _resolveComponent("patternlib-icon")!
  const _component_KeyValueView = _resolveComponent("KeyValueView")!
  const _component_patternlib_table_cell = _resolveComponent("patternlib-table-cell")!
  const _component_patternlib_table = _resolveComponent("patternlib-table")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_KeyValueView, {
            config: _ctx.data.config,
            data: _ctx.data.data
          }, {
            customItem: _withCtx(({ options }) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(options.description.text), 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_patternlib_icon, {
                  "icon-name": "link",
                  size: "16px",
                  bold: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSoftwareDialog()))
                })
              ])
            ]),
            _: 1
          }, 8, ["config", "data"]),
          (_ctx.showBusNodesInfoModal)
            ? (_openBlock(), _createBlock(_component_AppModal, {
                key: 0,
                title: "State Bus Nodes Information",
                onOnClose: _ctx.toggleSoftwareDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_patternlib_table, {
                    "implicit-ids": "",
                    "no-grid-lines": "",
                    "no-data-label": _ctx.t('state_bus_nodes.no_data'),
                    columns: [
            { label: _ctx.t('state_bus_nodes.address'), align: 'left' },
            { label: _ctx.t('state_bus_nodes.whoami'), align: 'left' },
            { label: _ctx.t('state_bus_nodes.changed_at'), align: 'left' }
          ]
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateBusNodesInfo, (busNode, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          _createVNode(_component_patternlib_table_cell, {
                            value: busNode.busNodesAddress
                          }, null, 8, ["value"]),
                          _createVNode(_component_patternlib_table_cell, {
                            value: busNode.busNodesWhoami
                          }, null, 8, ["value"]),
                          _createVNode(_component_patternlib_table_cell, {
                            value: _ctx.getFormattedDateTime(busNode.changedAtUtc)
                          }, null, 8, ["value"])
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["no-data-label", "columns"])
                ]),
                _: 1
              }, 8, ["onOnClose"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}