<template>
  <div data-demo="historyChart">
    <div class="flex flex-row justify-between">
      <patternlib-headline
        separator="none"
        :headline="header"
        class="mb-header block"
        heading-tag="h6"
      ></patternlib-headline>
      <div class="mh-[300px]" data-demo="actions">
        <TheButton
          data-demo="zoomResetBtn"
          :disabled="!areDataAvailable"
          icon="filter-reset"
          size="small"
          type="secondary"
          @click="resetZoom()"
        />
        <TheButton
          data-demo="downloadBtn"
          :disabled="!areDataAvailable"
          class="mx-4"
          icon="download"
          type="secondary"
          size="small"
          @click="downloadChart()"
        />
      </div>
    </div>

    <div>
      <TextPlaceholder class="min-h-[500px]" centered v-if="!areDataAvailable" />
      <BarChart
        v-else-if="type === 'timeline'"
        style="margin-bottom: 3rem"
        ref="chartRef"
        :chart-options="chartOp"
        :chart-data="(data.data as ITimelineChartData)"
        :height="barHeight"
        :plugins="plugins"
      />
      <div v-else-if="type === 'line'" class="my-4 min-h-[500px]">
        <LineChart
          ref="chartRef"
          :chartData="(data.data as ITimelineChartData)"
          :chartOptions="chartOp"
          :plugins="plugins"
          :height="500"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheButton from '@/components/basic/Button/TheButton.vue';
import TextPlaceholder from '@/components/basic/Placeholder/TextPlaceholder.vue';
import ChartJSConfiguration from '@/helper/HistoryCharts/ChartJSConfiguration';
import {
  ILineChartConfig,
  ITimeLineChartConfig,
  ITimelineChartData,
  IScale,
  IntersectionLine
} from '@/types/DeviceValueMapping';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import _, { debounce } from 'lodash';
import { computed, defineProps, defineEmits, PropType, ref, watch } from 'vue';
import { Line as LineChart, Bar as BarChart } from 'vue-chartjs';

const emit = defineEmits(['resetZoom', 'updateIntersectionLine']);
const props = defineProps({
  data: {
    type: Object as PropType<ITimeLineChartConfig | ILineChartConfig>,
    required: true
  },
  option: {
    type: Object as PropType<ChartOptions>,
    required: true
  },
  intersectionLine: {
    type: Object as PropType<IntersectionLine>,
    required: true
  },
  isSynchronized: { type: Boolean, required: true },
  zoom: { type: Object as PropType<IScale>, required: false },
  type: { type: String as PropType<'line' | 'timeline'>, required: true },
  header: String
});

const chartRef = ref();

const plugins = computed(() => {
  const bg = ChartJSConfiguration.getBackgroundPlugin();
  const mouseInt = ChartJSConfiguration.getMouseIntersectionPlugin(debounceUpdateIntersectionLine);

  const plugins: Plugin[] = [bg, mouseInt];

  return plugins;
});

const debounceUpdateIntersectionLine = debounce((intersectionLine: IntersectionLine) => {
  emit('updateIntersectionLine', intersectionLine);
}, 100);

const resetZoom = () => {
  const chart = chartRef.value?.chart;
  if (!chart) return;

  if (props.isSynchronized || chart?.isZoomedOrPanned()) {
    chart?.resetZoom('none');
    emit('resetZoom');
  }
};

const areDataAvailable = computed(() => {
  if (props.data?.data.datasets.length === 0) return false;
  return true;
});

const downloadChart = () => {
  const img = chartRef.value?.chart?.toBase64Image();

  if (!img) return;

  // eslint-disable-next-line no-var
  const a = document.createElement('a');

  a.href = img;
  const date = new Date();
  const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

  a.download = `$${formattedDate}.jpeg`;
  a.click();
};

const barHeight = computed(() => {
  const elements = (props.data?.data as ITimelineChartData)?.labels;
  let height = 120;

  if (elements && elements?.length > 1) {
    height = elements.length * 50 + 60;
  }
  return height;
});

const chartOp = computed(() => {
  // The chartjs libary mutates the configuration object
  // Make sure each charts uses its own config
  return _.cloneDeep(props.option);
});

const zoomIn = (zoom?: IScale) => {
  const chart = chartRef.value?.chart as Chart;
  if (!zoom || !chart) return;
  chart.zoomScale('x', zoom, 'resize');
};

watch(
  () => props.zoom,
  () => {
    zoomIn(props.zoom);
  }
);

watch(
  () => props.intersectionLine,
  () => {
    const chart = chartRef.value?.chart;

    if (chart.canvas.id === 'bar-chart') {
      chart.clear();
      chart.height = barHeight.value;
      chart.resize(chart.width, chart.height);
    }

    if (chart && props.isSynchronized) {
      chart.render(); // could be crucial
      ChartJSConfiguration.drawMouseIntersectionLine(chart, props.intersectionLine);
    }
  }
);
</script>
