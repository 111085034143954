<template>
  <AppModal v-if="showModal" @onClose="showModal = false">
    <HistoryChartGIFStepper @onClose="showModal = false" />
  </AppModal>
  <patternlib-tooltip tooltip-position="left">
    <span
      ref="iconRef"
      data-demo="historyChartInfo"
      class="relative mr-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2"
    >
      <patternlib-icon iconName="info-circle" size="36px"> </patternlib-icon>
    </span>
    <div slot="content" style="text-align: left; padding: 10px" v-show="!showModal">
      <patternlib-headline
        separator="none"
        :headline="t('historyCharts.tutorial.heading')"
        heading-tag="h3"
      ></patternlib-headline>

      <KeyValueView :config="config" :data="data" />

      <TheButton
        class="my-3"
        :full-width="true"
        small
        @click="showModal = !showModal"
        :label="t('historyCharts.tutorial.tutorialBtnText')"
      />
    </div>
  </patternlib-tooltip>
</template>

<script lang="ts" setup>
import TheButton from '@/components/basic/Button/TheButton.vue';
import KeyValueView, { EntryType, KeyValueViewConfig } from '@/components/basic/KeyValueView/KeyValueView.vue';
import AppModal from '@/components/basic/Modal/AppModal.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import HistoryChartGIFStepper from './HistoryChartGIFStepper.vue';

type TChartTutorialShortcutOverview = {
  zoom: string;
  pan: string;
};

const showModal = ref(false);
const iconRef = ref();
const { t } = useI18n();

const config: KeyValueViewConfig<TChartTutorialShortcutOverview> = {
  item: { height: '48px', labelWidth: '50%', noSeparator: false },
  props: {
    zoom: {
      description: { text: t('historyCharts.tutorial.zoomLabel') },
      type: EntryType.TEXT
    },
    pan: {
      description: { text: t('historyCharts.tutorial.panLabel') },
      type: EntryType.TEXT
    }
  }
};

const data: TChartTutorialShortcutOverview = {
  zoom: t('historyCharts.tutorial.zoomText'),
  pan: t('historyCharts.tutorial.panText')
};
</script>
