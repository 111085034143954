import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createBlock(_component_LoadingSpinner, {
    class: "h-[80vh] border border-dashed",
    "data-cy": "spinner",
    text: _ctx.t('login.loading_text')
  }, null, 8, ["text"]))
}