import { createI18n } from 'vue-i18n';
import enMessages from 'src/lang/general/en.json';
import deMessages from 'src/lang/general/de.json';

type MessageSchema = typeof enMessages;

export const i18n = createI18n<[MessageSchema], 'en' | 'de', false>({
	locale: 'en',
	legacy: false,
	fallbackLocale: 'en',
	messages: {
		en: enMessages,
		de: deMessages,
	} as const,
});
