import { defineComponent, PropType } from 'vue';

export default defineComponent({
  emits: ['click'],
  props: {
    label: { type: String },
    width: { type: String, default: undefined },
    disabled: { type: Boolean },
    isLoading: { type: Boolean, default: false },
    type: { type: String, default: 'primary' },
    icon: { type: String },
    fullWidth: { type: Boolean, default: false },
    size: { type: String as PropType<'small' | 'big'>, default: 'big' }
  }
});
