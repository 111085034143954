import { defineComponent, onMounted } from 'vue';
import { router } from '@/router';
import { useLoginStore } from '@/store/LoginStore';

export default defineComponent({
  setup(props) {
    const loginStore = useLoginStore();

    onMounted(async () => {
      console.log('maintenance mode');
      if (!loginStore.isAuthenticated) {
        router.replace({ name: 'Login' });
      } else {
        await loginStore.checkStoredLogin();
      }
    });
  }
});
