<template>
  <SearchComponent :serialId="serialId" class="mb-block" />

  <div class="mt-block">
    <patternlib-headline class="col-12 mb-header block" :headline="t('device.default.headline')" heading-tag="h1">
    </patternlib-headline>

    <TextPlaceHolder v-if="error" class="min-h-[40vh] justify-end" useReload centered @click="fetchData" />

    <div v-else-if="showPageContent">
      <div class="grid grid-cols-12" v-if="device">
        <div class="col-span-12 justify-self-center lg:col-span-3 lg:mr-6 lg:justify-self-auto">
          <img
            class="max-h-full w-full max-w-xs lg:max-w-xs"
            :src="deviceImg ?? ''"
            :alt="device.articleDescriptionProduct ?? ''"
            v-if="deviceImg"
          />
          <patternlib-icon icon-name="refrigeration-freezing" v-else size="128px"></patternlib-icon>
        </div>

        <div class="order-2 col-span-12 flex flex-col lg:col-span-6 lg:mx-6">
          <DeviceData
            v-if="plate"
            class="mb-block"
            :serialId="serialId"
            :isAvailableAtSmartDevice="isAvailableAtSmartDevice"
            :plate="plate"
            :device="device"
            :baseInfo="baseInfo"
          />

          <SoftwareInformation
            class="mb-block"
            :isAvailableAtSmartDevice="isAvailableAtSmartDevice"
            :deviceSerialId="serialId"
            :baseInfo="baseInfo"
            :baseState="baseState"
            :stateBusNodes="stateBusNodes"
          >
          </SoftwareInformation>
        </div>

        <div
          v-if="deviceKind.toUpperCase() === DeviceKindEnum.CONSUMER"
          class="order-1 col-span-12 my-4 flex flex-col md:flex-row md:items-end md:justify-between lg:order-2 lg:col-span-3 lg:flex-col lg:items-center lg:justify-start"
        >
          <img
            class="w-72"
            src="/assets/deviceView/consumer/powered_by_smartdevice_dark.png"
            alt="powered_by_smartdevice"
          />
        </div>
        <div
          v-else-if="deviceKind.toUpperCase() === DeviceKindEnum.BUSINESS"
          class="order-1 col-span-12 my-4 flex flex-col md:flex-row md:items-end md:justify-between lg:order-2 lg:col-span-3 lg:flex-col lg:items-center lg:justify-start"
        >
          <img class="w-72" src="/assets/deviceView/business/powered_by_smamo.png" alt="powered_by_smartdevice" />
        </div>
      </div>
      <!-- Show SmartDevice data for only consumer devices -->
      <div v-if="deviceKind.toUpperCase() === DeviceKindEnum.CONSUMER">
        <patternlib-headline
          :separator="'bottom'"
          class="mb-header block"
          :headline="$t('device.smartDevice.label')"
          heading-tag="h3"
        >
        </patternlib-headline>

        <div class="mb-block flex flex-col gap-x-12 lg:flex-row">
          <SmartDeviceComponent
            class="lg:w-1/2"
            :isAvailableAtSmartDevice="isAvailableAtSmartDevice"
            :serialId="serialId"
            :household="household"
            :netState="netState"
          />
          <RegisteredDevicesOverviewComponent
            :serialId="serialId"
            :household="household"
            @onNavigated="handleNavigation"
          />
        </div>
      </div>

      <BaseStateComponent
        v-if="baseInfo && baseState"
        :isAvailableAtSmartDevice="isAvailableAtSmartDevice"
        class="mb-block"
        :baseInfo="baseInfo"
        :baseState="baseState"
        :deviceSerialId="serialId"
      >
      </BaseStateComponent>

      <ZoneStateComponent
        class="mb-block"
        :isAvailableAtSmartDevice="isAvailableAtSmartDevice"
        :serialId="serialId"
        @evtZoneState="handleZoneState"
      >
      </ZoneStateComponent>
      <OverviewChart
        class="mb-block"
        :serialId="serialId"
        :baseState="baseState"
        :baseInfo="baseInfo"
        :zoneStates="zoneStates"
        :availableHistoryInterval="availableIntervals"
      />
    </div>

    <LoadingSpinner
      v-else-if="!showPageContent"
      :text="$t('loading.hint')"
      class="mt-12 h-96 border border-dashed"
      data-cy="loadingSpinner"
    />
  </div>
</template>

<script src="./DefaultDeviceView.ts" lang="ts"></script>
<style src="../DeviceView.scss" lang="scss" scoped></style>
