import { defineComponent, onMounted, onUnmounted, Ref, ref } from 'vue';
import TheButton from '@/components/basic/Button/TheButton.vue';
import SettingsList from './components/SettingsList.vue';
import { useToast } from '@/composables/common/useToast';
import { useLoginStore } from '@/store/LoginStore';
import { ApplianceHistoryKeyEnum } from '@/helper/ApplianceHistoryKeyEnum';
import { ToastType } from '@/store/GlobalUIStore';
import { ChartUserSettings } from '@/types/DeviceValueMapping';
import {
  PatternLibEvent,
  PatternLibInputFieldEvent,
  PatternLibInputFieldChangeEvent,
  PatternLibSwitchEvent,
  PatternLibToggleEvent
} from '@/types/patternlib';
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { BaseStateSettings, ZoneStateSettings } from './StateSettings';
import { BaseStateChartSettings, ZoneStateChartSettings } from './StateChartSettings';
import { predefinedFilterSettings } from './PredefinedFilterSettings';

export default defineComponent({
  name: 'ChartSettings',
  components: { TheButton, SettingsList },
  emits: ['close'],

  setup(props, { emit }) {
    const displayToast = useToast();
    const { chartSettings, getDefaultChartSettings } = storeToRefs(useLoginStore());
    const { updateChartSettings, resetChartSettings } = useLoginStore();
    const { t } = useI18n();
    const chartSettingsActiveTab = ref(0);
    const synchronizedChartsToggle = ref();
    const mergedChartsToggleRef = ref();
    const predefinedFilterToggleRef = ref();
    const tabsRef = ref();
    const checkboxRef = ref();
    const checkboxBaseRefAll = ref();
    const checkboxZoneRefAll = ref();
    const temperatureModeToggle = ref();
    const localChartSettings = ref();
    const chartSettingsClone = ref();
    const layoutBtnRef = ref();
    const areAllBaseStatesChecked = ref(false);
    const indeterminateBaseSignalsState = ref(false);
    const areAllZoneStatesChecked = ref(false);
    const indeterminateZoneSignalsState = ref(false);
    const baseSearchBox = ref();
    const zoneSearchBox = ref();

    const allBaseStates: Ref<BaseStateSettings | undefined> = ref();

    const allZoneStates: Ref<ZoneStateSettings | undefined> = ref();

    // Make a clone copy
    chartSettingsClone.value = cloneDeep(chartSettings.value);
    localChartSettings.value = cloneDeep(chartSettings.value);

    const selectAllCbLabel = () => {
      return areAllBaseStatesChecked.value ? t('general.unselect_all') : t('general.select_all');
    };

    const selectAllZoneCbLabel = () => {
      return areAllZoneStatesChecked.value ? t('general.unselect_all') : t('general.select_all');
    };

    const selectUnSelectAllSettings = (e: PatternLibEvent) => {
      const { value, name } = e.detail;
      const type = name.split('-')?.[0] as ApplianceHistoryKeyEnum.Zone | ApplianceHistoryKeyEnum.Base;

      if (type === ApplianceHistoryKeyEnum.Base) {
        areAllBaseStatesChecked.value = Boolean(value);
        indeterminateBaseSignalsState.value = false;
      } else if (type === ApplianceHistoryKeyEnum.Zone) {
        areAllZoneStatesChecked.value = Boolean(value);
        indeterminateZoneSignalsState.value = false;
      }

      const settings = localChartSettings.value;

      Object.keys(settings[type]).forEach((x: any) => {
        settings[type][x] = value;
      });

      localChartSettings.value = settings;
      localChartSettings.value.predefinedFilterCooling = false;
      initializeAllChartSettings();
    };

    const updateChartSettingsProp = (setting: { key: string; value: boolean; identifier: string }) => {
      localChartSettings.value = {
        ...localChartSettings.value,
        [setting.identifier]: { ...localChartSettings.value[setting.identifier], [setting.key]: setting.value }
      } as unknown as ChartUserSettings;
      localChartSettings.value.predefinedFilterCooling = false;
      initializeAllChartSettings();
    };

    const saveSettings = () => {
      emit('close');
      setTimeout(() => {
        updateChartSettings(localChartSettings.value);
      });
    };

    const close = () => {
      localChartSettings.value = chartSettingsClone.value;
      emit('close');
    };

    const updateTemperatureMode = (e: PatternLibEvent) => {
      const { value } = e.detail;
      const updatedSettings = {
        ...localChartSettings.value,
        useFahrenheit: value
      } as unknown as ChartUserSettings;

      localChartSettings.value = updatedSettings;
    };

    const toggleMergedChartsView = (e: PatternLibEvent) => {
      const { value } = e.detail;

      const updatedSettings = {
        ...localChartSettings.value,
        showMergedCharts: value
      };

      localChartSettings.value = updatedSettings;
    };

    const changeTab = (event: PatternLibEvent) => {
      if (event.detail?.itemId !== undefined) chartSettingsActiveTab.value = event.detail.itemId as number;
    };

    const resetSettings = () => {
      emit('close');
      displayToast(t('profile.chartSettings.resetMessage'), ToastType.SUCCESS);
      resetChartSettings();
      localChartSettings.value = null;
    };

    const updateChartLayout = (e: PatternLibEvent) => {
      const value = e.detail.value;
      const nextValue = value === 'overallView';
      const updatedSettings = {
        ...localChartSettings.value,
        useOverallView: nextValue
      } as unknown as ChartUserSettings;
      localChartSettings.value = updatedSettings;
    };

    const updateZoomMode = (e: PatternLibSwitchEvent) => {
      const { value } = e.detail;
      localChartSettings.value = {
        ...localChartSettings.value,
        synchronizedCharts: value
      } as ChartUserSettings;
    };

    const handleSearch = (e: PatternLibInputFieldEvent | PatternLibInputFieldChangeEvent) => {
      if (typeof e.detail === 'object') {
        search(e.detail.value);
      } else {
        search(e.detail);
      }
    };

    const search = async (searchText: string) => {
      initializeAllChartSettings();

      if (!searchText) {
        return;
      }

      searchText = searchText.trim();

      if (chartSettingsActiveTab.value === 1) {
        if (allBaseStates.value) {
          for (const item of Object.keys(allBaseStates.value as BaseStateSettings)) {
            const settings = allBaseStates.value[item].filter((setting: { key: string; value: boolean }) =>
              setting.key.toLowerCase().includes(searchText.toLowerCase())
            );

            allBaseStates.value[item] = settings;
          }
        }
      } else if (chartSettingsActiveTab.value === 2) {
        if (allZoneStates.value) {
          for (const item of Object.keys(allZoneStates.value as ZoneStateSettings)) {
            const settings = allZoneStates.value[item].filter((setting: { key: string; value: boolean }) =>
              setting.key.toLowerCase().includes(searchText.toLowerCase())
            );

            allZoneStates.value[item] = settings;
          }
        }
      }
    };

    const initializeAllChartSettings = () => {
      allBaseStates.value = BaseStateChartSettings.getBaseStateChartSettings(localChartSettings.value);
      allZoneStates.value = ZoneStateChartSettings.getZoneStateChartSettings(localChartSettings.value);

      areAllBaseStatesChecked.value = Object.keys(localChartSettings.value.base).every(
        s => localChartSettings.value.base[s] === true
      );

      indeterminateBaseSignalsState.value = Object.keys(localChartSettings.value.base).some(
        s => localChartSettings.value.base[s] === false
      );

      areAllZoneStatesChecked.value = Object.keys(localChartSettings.value.zone).every(
        s => localChartSettings.value.zone[s] === true
      );

      indeterminateZoneSignalsState.value = Object.keys(localChartSettings.value.zone).some(
        s => localChartSettings.value.zone[s] === false
      );
    };

    const togglePredefinedFilter = (e: PatternLibToggleEvent) => {
      if (e.detail.name === 'coolingFilter' && e.detail.value) {
        const base = predefinedFilterSettings.getCoolingFilterBaseSettings();
        const zone = predefinedFilterSettings.getCoolingFilterZoneSettings();
        localChartSettings.value = { ...localChartSettings.value, base: base, zone: zone };
        localChartSettings.value.predefinedFilterCooling = true;
      } else {
        localChartSettings.value.predefinedFilterCooling = false;
        // Reset back to default
        localChartSettings.value = getDefaultChartSettings.value;
      }
      initializeAllChartSettings();
    };

    onMounted(() => {
      initializeAllChartSettings();

      setTimeout(() => {
        tabsRef.value?.addEventListener('lhChangeItem', changeTab);
        checkboxBaseRefAll.value?.addEventListener('lhChange', selectUnSelectAllSettings);
        checkboxZoneRefAll.value?.addEventListener('lhChange', selectUnSelectAllSettings);
        baseSearchBox.value?.addEventListener('lhEnterPress', handleSearch);
        baseSearchBox.value?.addEventListener('lhSearch', handleSearch);
        baseSearchBox.value?.addEventListener('lhChange', handleSearch);
        zoneSearchBox.value?.addEventListener('lhEnterPress', handleSearch);
        zoneSearchBox.value?.addEventListener('lhSearch', handleSearch);
        zoneSearchBox.value?.addEventListener('lhChange', handleSearch);
        layoutBtnRef.value?.addEventListener('lhChange', updateChartLayout);

        mergedChartsToggleRef.value?.addEventListener('lhChange', toggleMergedChartsView);
        predefinedFilterToggleRef.value?.addEventListener('lhChange', togglePredefinedFilter);
      }, 500);
    });

    onUnmounted(() => {
      checkboxRef.value?.forEach((item: HTMLElement) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        item?.removeEventListener('lhChange', updateChartSettingsProp)
      );
      synchronizedChartsToggle.value?.removeEventListener('lhChange', updateZoomMode);
      temperatureModeToggle.value?.removeEventListener('lhChange', updateTemperatureMode);
      layoutBtnRef.value?.removeEventListener('lhChange', updateChartLayout);
      checkboxBaseRefAll.value?.removeEventListener('lhChange', selectUnSelectAllSettings);
      checkboxZoneRefAll.value?.removeEventListener('lhChange', selectUnSelectAllSettings);
      baseSearchBox.value?.removeEventListener('lhEnterPress', handleSearch);
      baseSearchBox.value?.removeEventListener('lhSearch', handleSearch);
      baseSearchBox.value?.removeEventListener('lhChange', handleSearch);
      zoneSearchBox.value?.removeEventListener('lhEnterPress', handleSearch);
      zoneSearchBox.value?.removeEventListener('lhSearch', handleSearch);
      zoneSearchBox.value?.removeEventListener('lhChange', handleSearch);
      mergedChartsToggleRef.value?.removeEventListener('lhChange', toggleMergedChartsView);
      predefinedFilterToggleRef.value?.removeEventListener('lhChange', togglePredefinedFilter);
    });

    return {
      t,
      tabsRef,
      chartSettingsActiveTab,
      allBaseStates,
      areAllBaseStatesChecked,
      selectAllCbLabel,
      checkboxBaseRefAll,
      indeterminateBaseSignalsState,
      allZoneStates,
      areAllZoneStatesChecked,
      selectAllZoneCbLabel,
      checkboxZoneRefAll,
      indeterminateZoneSignalsState,
      updateChartSettingsProp,
      close,
      resetSettings,
      saveSettings,
      localChartSettings,
      chartSettings,
      baseSearchBox,
      zoneSearchBox,
      predefinedFilterToggleRef,
      mergedChartsToggleRef,
      layoutBtnRef
    };
  }
});
