import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
	props: {
		title: String,
		icon: String,
		imgSrc: { type: String, required: false },
	},
	emits: ['onClose'],
	setup(props, ctx) {
		const modalRef = ref();

		const handleClose = () => {
			ctx.emit('onClose');
		};

		onMounted(() => {
			if (modalRef.value) {
				modalRef.value.addEventListener('lhClose', handleClose);
			}
		});

		return { modalRef };
	},
});
